export const TOGGLE_SUBMIT_INTEREST_MODAL_ON_LOGIN_SIGNUP_SUCCESS = "TOGGLE_SUBMIT_INTEREST_MODAL_ON_LOGIN_SIGNUP_SUCCESS";
export const TOGGLE_SIGNUP_MODAL_ON_SUCCESS = "TOGGLE_SIGNUP_MODAL_ON_SUCCESS";
export const TOGGLE_LOGIN_MODAL_ON_SUCCESS = "TOGGLE_LOGIN_MODAL_ON_SUCCESS";
export const TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS = "TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS";
export const TOGGLE_NEW_LOGIN_MODAL_ON_SUCCESS = "TOGGLE_NEW_LOGIN_MODAL_ON_SUCCESS";
export const TOGGLE_SIGNUP_ON_LANDING_PAGE_ON_SUCCESS = "TOGGLE_SIGNUP_ON_LANDING_PAGE_ON_SUCCESS";
export const SHARE_REPORT_URL = "SHARE_REPORT_URL";
export const TOGGLE_SIGNUP_MODAL_AND_REDIRECT='TOGGLE_SIGNUP_MODAL_AND_REDIRECT'
export const TOGGLE_LOGIN_MODAL_AND_REDIRECT='TOGGLE_LOGIN_MODAL_AND_REDIRECT'
export const CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS='CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS'
export const CREATE_STRIPE_SESSION_ON_LOGIN_SUCCESS='CREATE_STRIPE_SESSION_ON_LOGIN_SUCCESS'
export const TOGGLE_LOGIN_MODAL_SCORE_CARD='TOGGLE_LOGIN_MODAL_SCORE_CARD';
export const SCORE_CARD_LOGIN='SCORE_CARD_LOGIN';
export const TOGGLE_CHOOSE_PKG_MODAL_ON_LOGIN_SIGNUP_SUCCESS = "TOGGLE_CHOOSE_PKG_MODAL_ON_LOGIN_SIGNUP_SUCCESS";
// export const TOGGLE_UNKNOWN_EMAIL_MODAL_ON_SUMBIT_SUCCESS = "TOGGLE_UNKNOWN_EMAIL_MODAL_ON_SUBMIT_SUCCESS";
export const TOGGLE_CHARGES_MODAL_ON_LOGIN_SIGNUP_SUCCESS = "TOGGLE_CHARGES_MODAL_ON_LOGIN_SIGNUP_SUCCESS";
export const CH_FILINGS_LOGIN='CH_FILINGS_LOGIN';
export const TOGGLE_LOGIN_MODAL_VALUATION_CALCULATOR = "TOGGLE_LOGIN_MODAL_VALUATION_CALCULATOR";
export const OPEN_NO_FINANCIAL_VALUATION_MODAL_ON_SUCCESS = "OPEN_NO_FINANCIAL_VALUATION_MODAL_ON_SUCCESS";
export const REDIRECT_TO_VALUATION_CALCULATOR_PAGE_ON_SUCCESS = "REDIRECT_TO_VALUATION_CALCULATOR_PAGE_ON_SUCCESS";
export const CREATE_STRIPE_SESSION_BUSINESS_PLAN_CREDITS = "CREATE_STRIPE_SESSION_BUSINESS_PLAN_CREDITS";