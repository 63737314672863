import { RESTAPI_URL } from '../common/config';
import { commonFetch } from './fetchComImpl'
import { refreshTokenApi } from './refreshTokenApi';

export const createStripeSessionApiCall = async (stripeCreateSessionObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/subscription/createStripeSession`,
            'POST',
            stripeCreateSessionObj,
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log('inside createStripeSessionApiCall ', error);
        throw error;
    }
}

export const subscriptionStatusApiCall = async (statusObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/subscription/subscriptionStatus`,
            'POST',
            { statusObj },
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log('inside subscriptionStatusApiCall ',error);
        throw error;
    }
}

export const cancelUserSubscriptionApi = async (cancellationObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/subscription/cancelSubscription`,
            'POST',
            cancellationObj,
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log('inside cancelUserSubscriptionApi',error);
        throw error;
    }
}

export const cancelUserFreeTrialSubscriptioApi = async () => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/subscription/cancelFreeTrial`, 
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
            return response
    } catch (error) {
        console.log('inside cancelUserFreeTrialSubscriptioApi', error);
        throw error;
    }
}