import {
    GET_BUSINESSPLAN_LOCATIONS_REQUEST, BUSINESS_PLAN_ONBOARD_BASIC_DATA,
    GET_BUSINESSPLAN_LOCATIONS_SUCCESS, TOGGLE_SUGGESTED_AREAS_MODAL,
    TOGGLE_ADD_LOCATION_MODAL,
    GET_ACTIVE_BUSINESSES_AND_SALES_REQ,
    GET_ACTIVE_BUSINESSES_AND_SALES_SUCCESS,
    SET_SELECTED_BUSINESS_LOCATIONS,
    GET_SUGGESTED_NEARBY_LOCATIONS_SUCCESS,
    GET_SUGGESTED_NEARBY_LOCATIONS_REQUEST,
    SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_REQUEST,
    GET_ALL_ONBOARD_BUSINESS_PLAN_REQUEST,
    GET_ALL_BUSINESS_PLAN_ONBOARD_SUCCESS,
    GET_BUSINESS_PLAN_ONBOARD_REQUEST,
    GET_BUSINESS_PLAN_ONBOARD_SUCCESS,
    GET_SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_SUCCESS,
    SET_BUSINESS_PLAN_ONBOARD_EMPTY,
    GET_ALL_KEY_PEOPLE_API_REQUEST,
    SET_ALL_KEY_PEOPLE_SUCCESS,
    GET_BUSINESS_PLAN_DESCRIPTION_OPENAI,
    GET_BUSINESS_PLAN_DESCRIPTION_OPENAI_SUCCESS,
    UPDATE_BUSINESS_PLANS_FOR_CREDITS
} from "./actionConstants";

export const setToggleSuggestedAreasModal = (toggleStatus) => ({
    type: TOGGLE_SUGGESTED_AREAS_MODAL,
    payload: { toggleStatus }
});

export const getBusinessPlanLocationRequest = () => ({
    type: GET_BUSINESSPLAN_LOCATIONS_REQUEST
})

export const getBusinessPlanLocationSuccess = (locations) => ({
    type: GET_BUSINESSPLAN_LOCATIONS_SUCCESS,
    payload: { locations }
})

export const setToggleAddLocationModal = (toggleStatus) => ({
    type: TOGGLE_ADD_LOCATION_MODAL,
    payload: { toggleStatus }
})

export const setSelectedBusinessLocations = (selectedBusinessLocations) => ({
        type: SET_SELECTED_BUSINESS_LOCATIONS,
        payload: { selectedBusinessLocations }
})

export const getActiveBusinessesAndSalesReq = (industryList) => ({
    type: GET_ACTIVE_BUSINESSES_AND_SALES_REQ,
    payload: { industryList }
})

export const getActiveBusinessesAndSalesSuccess = (combinedBusinessAndSalesObj) => ({
    type: GET_ACTIVE_BUSINESSES_AND_SALES_SUCCESS,
    payload: { combinedBusinessAndSalesObj }
})
export const getSuggestedNearbyLocationsRequest = (areaId, regionId, nearbyDistricts) => ({
    type: GET_SUGGESTED_NEARBY_LOCATIONS_REQUEST,
    payload: { areaId, regionId, nearbyDistricts }
})

export const getSuggestedNearbyLocationsSuccess = (suggestedNearbyLocations) => ({
    type: GET_SUGGESTED_NEARBY_LOCATIONS_SUCCESS,
    payload: { suggestedNearbyLocations }
})

export const setBusinessPlanOnboardObj = (businessPlanObj) => ({
    type: BUSINESS_PLAN_ONBOARD_BASIC_DATA,
    payload: { businessPlanObj }
});

export const saveOrUpadteBusinessPlanOnboardDataRequest = (businessPlanObj) => ({
    type: SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_REQUEST,
    payload: { businessPlanObj }
})

export const getSaveOrUpdateBusinessPlanOnboardSuccess = (businessPlanResp) => ({
    type: GET_SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_SUCCESS,
    payload: { businessPlanResp }
})

export const getAllOnboardBusinessPlanRequest = () => ({
    type: GET_ALL_ONBOARD_BUSINESS_PLAN_REQUEST,
    payload: {}
})

export const getAllOnboardBusinessPlanApiSuccess = (businessPlanListObj) => ({
    type: GET_ALL_BUSINESS_PLAN_ONBOARD_SUCCESS,
    payload: { businessPlanListObj }
})

export const getBusinessPlanOnboardDataRequest = (businessPlanId) => ({
    type: GET_BUSINESS_PLAN_ONBOARD_REQUEST,
    payload: { businessPlanId }
})

export const getBusinessPlanOnboardApiSuccess = (businessPlan) => ({
    type: GET_BUSINESS_PLAN_ONBOARD_SUCCESS,
    payload: { businessPlan }
})

export const setBusinessPlanOnboardEmptyObj = (businessPlan) => ({
    type: SET_BUSINESS_PLAN_ONBOARD_EMPTY,
    payload: {}
})

export const getAllKeyPeopleRequest = (companyId) => ({
    type: GET_ALL_KEY_PEOPLE_API_REQUEST,
    payload: {companyId}
})

export const getAllKeyPeopleApiSuccess = (allKeyPeoples) => ({
    type: SET_ALL_KEY_PEOPLE_SUCCESS,
    payload: { allKeyPeoples }
})

export const getBusinessDescriptionOpenAi = (businessPlanConvo) => ({
    type: GET_BUSINESS_PLAN_DESCRIPTION_OPENAI,
    payload: { businessPlanConvo }
})

export const getBusinessDescriptionOpenAiApiSuccess = (businessPlanDescription) => ({
    type: GET_BUSINESS_PLAN_DESCRIPTION_OPENAI_SUCCESS,
    payload: { businessPlanDescription }
})

export const updateBusinessPlansForCredits = (bussPlanObj) => ({
    type: UPDATE_BUSINESS_PLANS_FOR_CREDITS,
    payload: { bussPlanObj }
})