import { call, put, select, takeLatest } from "redux-saga/effects";
import { 
    getPopularCompaniesApi  
} from "../api/popularCompaniesApi";
import { 
    getPopularCompaniesSuccess, appendPopularCompaniesByScroll
} from "../redux/actions/popularCompaniesAction"
import { 
    GET_POPULAR_COMPANIES_REQUEST 
} from "../redux/actions/actionConstants";

function* getPopularCompaniesRequestSaga(action) {
    try {
        const activeAlpha = yield select(state=>state.popularCompanies.activeAlpha);
        const pageNumber = yield select(state=>state.popularCompanies.pageNumber)
        if(action.payload.alpha !== activeAlpha || action.payload.pageNumber !== pageNumber){
            const response = yield call(getPopularCompaniesApi, action.payload.alpha,action.payload.pageNumber,action.payload.size);
            if (response.error) {
                console.log('inside getPopularCompaniesRequestSaga ', response.error);
            } else {
                const {popularCompanies:{data = []},totalRecords=0,activeAlpha="a",pageNumber=1,size=0}=response;
                yield put(getPopularCompaniesSuccess(data,totalRecords,activeAlpha,pageNumber,size));
            }
        }
    } catch (error) {
        console.log('inside getPopularCompangetPopularCompaniesiesRequestSaga ', error);
    }
}

export function* watchGetPopularCompaniesRequest() {
    yield takeLatest(GET_POPULAR_COMPANIES_REQUEST, getPopularCompaniesRequestSaga);
}