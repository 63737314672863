import {
    GET_POPULAR_COMPANIES_SUCCESS
} from "../actions/actionConstants";

const popularCompaniesReducer = (state = { popularCompaniesList: [] }, action) => {
    switch (action.type) {
        case GET_POPULAR_COMPANIES_SUCCESS:
            return { ...state, popularCompaniesList: action.payload.popularCompanies, 
                totalRecordsPerAlphabet:action.payload.totalRecords,
                activeAlpha:action.payload.activeAlpha,
                pageNumber: action.payload.pageNumber,
                size: action.payload.size
            };
        default:
            return state;
    }
}

export default popularCompaniesReducer;