import {
    GET_INDUSTRY_PROFILE_REQUEST, GET_INDUSTRY_PROFILE_FAILED, GET_INDUSTRY_PROFILE_SUCCESS,
    GET_INDUSTRY_PROFILE_SEARCH_RESULT, GET_INDUSTRY_PROFILE_SEARCH_SUCCESS,
    GET_INDUSTRY_PROFILE_SEARCH_FAILED,
    EMPTY_INDUSTRY_PROFILE_DATA
} from './actionConstants';

export const getIndustryProfileRequest = industryId => ({
    type: GET_INDUSTRY_PROFILE_REQUEST,
    payload: { industryId }
})

export const getIndustryProfileFailed = message => ({
    type: GET_INDUSTRY_PROFILE_FAILED,
    payload: { message }
})

export const getIndustryProfileSuccess = industryProfileData => ({
    type: GET_INDUSTRY_PROFILE_SUCCESS,
    payload: { industryProfileData }
})

export const getIndustryProfileSearchResult = (searchedText,eventPage="") => ({
    type: GET_INDUSTRY_PROFILE_SEARCH_RESULT,
    payload: { searchedText, eventPage }
});

export const getIndustryProfileSearchSuccess = searchedResult => ({
    type: GET_INDUSTRY_PROFILE_SEARCH_SUCCESS,
    payload: { searchedResult }
});

export const getIndustryProfileSearchFailed = message => ({
    type: GET_INDUSTRY_PROFILE_SEARCH_FAILED,
    payload: { message }
});

export const setIndustryProfileDataEmpty = () => ({
    type: EMPTY_INDUSTRY_PROFILE_DATA
})