import { 
    GET_LOCATION_PROFILE_FAILED, GET_LOCATION_PROFILE_REQUEST, 
    GET_LOCATION_PROFILE_SEARCH_FAILED, GET_LOCATION_PROFILE_SEARCH_REQUEST, 
    GET_LOCATION_PROFILE_SEARCH_SUCCESS, GET_LOCATION_PROFILE_SUCCESS, 
    CLEAR_LOCATION_PROFILE_DATA 
} from "./actionConstants";

export const getLocationProfileRequest = (locationId) => ({
    type: GET_LOCATION_PROFILE_REQUEST,
    payload: { locationId }
});

export const getLocationProfileSuccess = (locationProfileObj) => ({
    type: GET_LOCATION_PROFILE_SUCCESS,
    payload: { locationProfileObj }
});

export const getLocationProfileFailed = (errorMessage) => ({
    type: GET_LOCATION_PROFILE_FAILED,
    payload: { errorMessage }
});

export const getLocationProfileSearchRequest =(searchText, eventPage="")=>({
    type: GET_LOCATION_PROFILE_SEARCH_REQUEST,
    payload: { searchText,eventPage }
});

export const getLocationProfileSearchSuccess =(searchResults)=>({
    type: GET_LOCATION_PROFILE_SEARCH_SUCCESS,
    payload: { searchResults }
});

export const getLocationProfileSearchFailed = (errorMessage) => ({
    type: GET_LOCATION_PROFILE_SEARCH_FAILED,
    payload: { errorMessage }
})

export const clearLocationProfileData =()=>({
    type: CLEAR_LOCATION_PROFILE_DATA
});