import { SHOW_DAILY_SEARCH_EXPIRE_MODAL } from '../../common/trackingConstants';
import { 
    GET_COMPANY_PROFILE_REQUEST, 
    GET_COMPANY_PROFILE_FAILED, 
    GET_COMPANY_PROFILE_SUCCESS, 
    SET_CURRENT_BALANCESHEET_CARD, 
    SET_CURRENT_PROFITLOSS_CARD,
    GET_COMPANY_HOUSE_FILINGS_REQUEST,
    GET_COMPANY_HOUSE_FILINGS_SUCCESS,
    GET_COMPANY_HOUSE_FILINGS_FAILED,
    GET_COMPANY_HOUSE_DOCUMENT_REQUEST,
    GET_COMPANY_HOUSE_DOCUMENT_FAILED,
    CHANGE_FINANCIAL_TAB,
    CHANGE_FINANCIALS_DATA_INDEX,
    GET_DIRECTOR_STATUS_LIST,
    GET_DIRECTOR_STATUS_LIST_SUCCESS,
    GET_DIRECTOR_STATUS_LIST_FAILED,
    GET_DIRECTOR_SHOW_MORE,
    GET_DIRECTOR_SHOW_MORE_SUCCESS,
    GET_DIRECTOR_SHOW_MORE_FAILED,
    GET_SHAREHOLDER_TYPE_LIST,
    GET_SHAREHOLDER_TYPE_LIST_SUCCESS,
    GET_SHAREHOLDER_TYPE_LIST_FAILED,
    GET_SHAREHOLDER_SHOW_MORE,
    GET_SHAREHOLDER_SHOW_MORE_SUCCESS,
    GET_SHAREHOLDER_SHOW_MORE_FAILED,
    EMPTY_COMPANY_PROFILE,
    GET_INDUSTRY_MULTIPLE_DATA,
    GET_INDUSTRY_MULTIPLE_DATA_SUCCESS,
    GET_INDUSTRY_MULTIPLE_DATA_FAILED,
    TOGGLE_CR_DOWNLOAD_LOADING_MODAL,
    GET_COMPANY_PAGE_URL,
    SET_SIMILAR_COMPANY_MODAL_TOGGLE_STATUS,
    CALL_CREDIT_REPORT_API,
    CHECK_PDF_CREATION_STATUS,
    SUBMIT_INVESTMENT_INTEREST_REQUEST,
    SUBMIT_INVESTMENT_INTEREST_FAILED,
    TOGGLE_SUBMIT_INVESTMENT_INTEREST_MODAL,
    SET_INDUSTRY_MIX_UPDATE_MODAL,
    SET_ADD_INDUSTRY_MIX_MODAL,
    SAVE_INDUSTRY_MIX_CHANGES,
    SAVE_INDUSTRY_MIX_CHANGES_FAILED,
    SET_INDUSTRY_MIX_LOADER,
    SET_INDUSTRY_MIX_UPDATE_SUCCESS,
    RESET_INDUSTRY_MIX_CHANGES,
    RESET_INDUSTRY_MIX_CHANGES_FAILED,
    DOWNLOAD_FINANCIALS,
    SET_AGREE_YES_CHOICE,
    CHANGE_VALUATION_SECTION_DATA,
    SET_SIMILAR_COMPANIES_LOADING_FLAG,
    GET_COMPANY_CHARGES_REQUEST,
    GET_COMPANY_CHARGES_SUCCESS,
    SET_COMPANY_CHARGES_MODAL,
    TOGGLE_VALUATION_INFO_MODAL,
    SET_FINANCIAL_VALUATION_USER_DATA,
    GET_COMPANY_VALUATION_FAILED,
    GET_COMPANY_VALUATION_SUCCESS,
    GET_COMPANY_VALUATION_REQUEST,
    SET_COMPANY_VALUATION_USER_EDIT,
    GET_COMPANY_VALUATION_USER_EDIT_SUCCESS,
    GET_COMPANY_VALUATION_USER_EDIT_FAILED,
    NO_FINANCIAL_VALUATION_MODAL,
    RESET_INDUSTRY_MIX_CHANGES_SUCCESS,
    TOGGLE_HEALTH_CHECK_MODAL,
    TOGGLE_BUY_CREDIT_REPORT_MODAL,
    SET_BUY_CREDIT_REPORT_MODAL_DATA,
    SET_UPDATED_MIX_INDUSTRIES,
    SET_COMPANY_PROFILE_VALUATION_MAPPING,
    GET_COMPANY_PSC_LIST_REQUEST,
    GET_COMPANY_PSC_LIST_SUCCESS,
    GET_COMPANY_PSC_LIST_FAILED,
} from './actionConstants';

export const getCompanyProfileRequest = (companyId, companyName) => ({
    type: GET_COMPANY_PROFILE_REQUEST,
    payload:{ companyId, companyName }
})

export const getCompanyProfileFailed = (message) => ({
    type: GET_COMPANY_PROFILE_FAILED,
    payload: { companyProfileFailedMsg: message }
})

export const getCompanyProfileSuccess = (companyProfile) => ({
    type: GET_COMPANY_PROFILE_SUCCESS,
    payload:{companyProfile}
})

export const getCompanyHouseFilingsFailed = (message) => ({
    type: GET_COMPANY_HOUSE_FILINGS_FAILED,
    payload: { companyHouseFilingsFailedMsg: message }
})

export const getCompanyHouseFilingsSuccess = (companyHouseFiling) => ({
    type: GET_COMPANY_HOUSE_FILINGS_SUCCESS,
    payload:{companyHouseFiling}
})

export const setCurrentBalanceSheetCard = (selectedCard) => ({
    type: SET_CURRENT_BALANCESHEET_CARD,
    payload:{selectedCard}
})

export const setCurrentProfitLossCard = (selectedCard) => ({
    type: SET_CURRENT_PROFITLOSS_CARD,
    payload: { selectedCard }
})

export const getCompanyHouseFilingsRequest = (companyObj) => ({
    type: GET_COMPANY_HOUSE_FILINGS_REQUEST,
    payload: { companyObj }
})

export const getCompanyHouseDocumentRequest = (docId) => ({
    type: GET_COMPANY_HOUSE_DOCUMENT_REQUEST,
    payload: { docId }
})

export const getCompanyHouseDocumentFailed = (message) => ({
    type: GET_COMPANY_HOUSE_DOCUMENT_FAILED,
    payload: { companyHouseDocumentFailedMsg: message }
})

export const changeFinancialTab = (tabName) => ({
    type: CHANGE_FINANCIAL_TAB,
    payload: { tabName }
});

export const changeFinancialsDataIndex = (newIndex) => ({
    type: CHANGE_FINANCIALS_DATA_INDEX,
    payload: { newIndex }
})

export const getDirectorStatusList = (companyId) => ({
    type: GET_DIRECTOR_STATUS_LIST,
    payload: { companyId }
})
export const getDirectorStatusListSuccess = (directorStatusList) => ({
    type: GET_DIRECTOR_STATUS_LIST_SUCCESS,
    payload: {directorStatusList}
})
export const getDirectorStatusListFailed = () => ({
    type: GET_DIRECTOR_STATUS_LIST_FAILED,
    payload: {  }
})

export const getDirectorShowMore = (obj) => ({
    type: GET_DIRECTOR_SHOW_MORE,
    payload: obj
})
export const getDirectorShowMoreSuccess = (directorList) => ({
    type: GET_DIRECTOR_SHOW_MORE_SUCCESS,
    payload: directorList
})
export const getDirectorShowMoreFailed = () => ({
    type: GET_DIRECTOR_SHOW_MORE_FAILED,
    payload: {  }
})


export const getShareholderTypeList = (companyId) => ({
    type: GET_SHAREHOLDER_TYPE_LIST,
    payload: { companyId }
})
export const getShareholderTypeListSuccess = (shTypeList) => ({
    type: GET_SHAREHOLDER_TYPE_LIST_SUCCESS,
    payload: {shTypeList}
})
export const getShareholderTypeListFailed = () => ({
    type: GET_SHAREHOLDER_TYPE_LIST_FAILED,
    payload: {  }
})

export const getShareholderShowMore = (obj) => ({
    type: GET_SHAREHOLDER_SHOW_MORE,
    payload: obj
})
export const getShareholderShowMoreSuccess = (resp) => ({
    type: GET_SHAREHOLDER_SHOW_MORE_SUCCESS,
    payload: resp
})
export const getShareholderShowMoreFailed = () => ({
    type: GET_SHAREHOLDER_SHOW_MORE_FAILED,
    payload: {  }
})
export const setCompanyProfile = (data) => ({
    type: EMPTY_COMPANY_PROFILE,
    payload: data
});

export const changeValuationSectionData = (valuationType) => ({
    type: CHANGE_VALUATION_SECTION_DATA,
    payload: { valuationType }
});

export const getIndustryMultipleData = (companyId,valuationType) => ({
    type: GET_INDUSTRY_MULTIPLE_DATA,
    payload: { companyId, valuationType }
});

export const getIndustryMultipleDataSuccess = (industryMultiple) => ({
    type: GET_INDUSTRY_MULTIPLE_DATA_SUCCESS,
    payload: { industryMultiple }
});

export const getIndustryMultipleDataFailed = (errorMessage) => ({
    type: GET_INDUSTRY_MULTIPLE_DATA_FAILED,
    payload: { errorMessage }
})

export const getCompanyPageUrl = (companyPageUrl) => ({
    type: GET_COMPANY_PAGE_URL,
    payload:{companyPageUrl}
})

export const setSimilarCompanyModalToggleStatus = (flag) => ({
    type: SET_SIMILAR_COMPANY_MODAL_TOGGLE_STATUS,
    payload:{flag}
})

export const callCreditReportApiAction = (crObj) => ({
    type: CALL_CREDIT_REPORT_API,
    payload:{crObj}
})

export const toggleCRDownloadLoadingModal=(downloadModalDataObj)=>({
    type:TOGGLE_CR_DOWNLOAD_LOADING_MODAL,
    payload: {downloadModalDataObj}
});

export const checkPdfCreationStatus = (cohId)=>({
    type:CHECK_PDF_CREATION_STATUS,
    payload:{cohId}
});

export const submitInvestmentInterestRequest = (userContactDetails,trackingInfo,modalType) => ({
    type: SUBMIT_INVESTMENT_INTEREST_REQUEST,
    payload: { userContactDetails,trackingInfo, modalType }
});

export const submitInvestmentInterestFailed = (errorMessage) => ({
    type: SUBMIT_INVESTMENT_INTEREST_FAILED,
    payload: { errorMessage }
});

export const toggleSubmitInvestmentInterestModal = ({
    toggleStatus, companyId=null, subject="", subHeading="", 
    heading=undefined, descriptionLabel=undefined, mailRecipient=undefined, 
    userMessage="",eventPage, eventType }) => ({
        type: TOGGLE_SUBMIT_INVESTMENT_INTEREST_MODAL,
        payload: { toggleStatus, companyId, subject, subHeading, userMessage,eventType, eventPage, heading, descriptionLabel, mailRecipient }
})

export const setIndustryMixUpdateModal = (toggleStatus, modalTexts = { heading : "Industry Breakdown", subHeading : "Create a customised industry breakdown that more accurately resembles the business operations and we will re-calculate the valuation.", showHeader: false },valuationStatus) => ({
    type: SET_INDUSTRY_MIX_UPDATE_MODAL,
    payload: { ...modalTexts, toggleStatus, valuationStatus }
})

export const setAddIndustryMixModal = (toggleStatus) => ({
    type: SET_ADD_INDUSTRY_MIX_MODAL,
    payload: { toggleStatus }
})

export const saveIndustryMixChanges = (industryMixObj) => ({
    type: SAVE_INDUSTRY_MIX_CHANGES,
    payload: { industryMixObj }
})

export const saveIndustryMixChangesFailed = (error) => ({
    type: SAVE_INDUSTRY_MIX_CHANGES_FAILED,
    payload: { error }
})

export const setIndustryMixLoader = (loaderObj) => ({
    type: SET_INDUSTRY_MIX_LOADER,
    payload: { loaderObj }
})

export const setIndustryMixUpdateSuccess = (status) => ({
    type: SET_INDUSTRY_MIX_UPDATE_SUCCESS,
    payload: { status }
})

export const resetIndustryMixChanges = (companyId,companyName,type, resetType) => ({
    type: RESET_INDUSTRY_MIX_CHANGES,
    payload: { companyId ,companyName,type, resetType}
});

export const resetIndustryMixUpdateSuccess = (status) => ({
    type: "RESET_INDUSTRY_MIX_UPDATE_SUCCESS",
    payload: { status }
})

export const resetIndustryMixChangesSuccess = (message) => ({
    type: RESET_INDUSTRY_MIX_CHANGES_SUCCESS,
    payload: { message }
});

export const resetIndustryMixChangesFailed = (error) => ({
    type: RESET_INDUSTRY_MIX_CHANGES_FAILED,
    payload: { error }
});

export const setUpdatedMixIndustries = (payload)=>({
    type : SET_UPDATED_MIX_INDUSTRIES,
    payload
})

export const downloadFinancials = (financials, companyId, companyName) => ({
    type: DOWNLOAD_FINANCIALS,
    payload: { financials, companyId, companyName }
})

export const setAgreeYesChoise = (isAgreeYesClicked) => ({
    type: SET_AGREE_YES_CHOICE,
    payload: { isAgreeYesClicked }
});

export const setSimilarCompaniesLoadingFlag = (flag) => ({
    type: SET_SIMILAR_COMPANIES_LOADING_FLAG,
    payload: { flag }
});

export const getCompanyChargesRequest = (chargesParam) => ({
    type: GET_COMPANY_CHARGES_REQUEST,
    payload: { chargesParam }
});

export const getCompanyChargesSuccess = (charges) => ({
    type: GET_COMPANY_CHARGES_SUCCESS,
    payload: { charges }
});

export const toggleCompanyChargesModal = (toggleStatus) => ({
    type: SET_COMPANY_CHARGES_MODAL,
    payload: { toggleStatus }
});

export const toggleValuationCalculatorModal = (toggleStatus) => ({
    type: TOGGLE_VALUATION_INFO_MODAL,
    payload: { toggleStatus }
});

export const getCompanyValuationRequest = (valuationParam) => ({
    type: GET_COMPANY_VALUATION_REQUEST,
    payload:{ valuationParam }
})

export const setFinancialValuationUserData = (valuationUserData) => ({
    type: SET_FINANCIAL_VALUATION_USER_DATA,
    payload: { valuationUserData }
});

export const getCompanyValutionDataFailed = (message) => ({
    type: GET_COMPANY_VALUATION_FAILED,
    payload: { getCompanyValutionDataFailedMsg: message }
})

export const getCompanyValutionDataSuccess = (companyValuation) => ({
    type: GET_COMPANY_VALUATION_SUCCESS,
    payload:{companyValuation}
})

export const sendCompanyValuationEditedData = (userValuationParams) => ({
    type: SET_COMPANY_VALUATION_USER_EDIT,
    payload:{userValuationParams}
})

export const getCompanyValutionUpdatedSuccess = (userEdited) => ({
    type: GET_COMPANY_VALUATION_USER_EDIT_SUCCESS,
    payload:{userEdited}
})
export const getCompanyValutionUpdatedFailed = (message) => ({
    type: GET_COMPANY_VALUATION_USER_EDIT_FAILED,
    payload:{message}
})
export const toggleHealthCheckModal = (toggleStatus) => ({
    type: TOGGLE_HEALTH_CHECK_MODAL,
    payload: { toggleStatus }
})
export const noFinancialValuationModal = (noFinancialValuationModal) => ({
    type: NO_FINANCIAL_VALUATION_MODAL,
    payload: { noFinancialValuationModal }
});


export const toggleBuyCreditReportModal = ({toggleStatus, companyId="", companyName="",regAddress="",eventPage}) => ({
    type: TOGGLE_BUY_CREDIT_REPORT_MODAL,
    payload: { toggleStatus, companyId, companyName, regAddress, eventPage }
});

export const setBuyCrModalData = (modalData) => ({
    type: SET_BUY_CREDIT_REPORT_MODAL_DATA,
    payload: { modalData }
})

export const setCompanyProfileValutionMappingObj = (valuationRptMapping) => ({
    type: SET_COMPANY_PROFILE_VALUATION_MAPPING,
    payload: { valuationRptMapping }
})

export const setDailyFreeExpireModalStatus = (showDailySearchExpireModal) => ({
    type: SHOW_DAILY_SEARCH_EXPIRE_MODAL,
    payload: { showDailySearchExpireModal }
})

export const getCompanyPscListRequest = (companyId) => ({
    type: GET_COMPANY_PSC_LIST_REQUEST,
    payload: {companyId}
}) 

export const getCompanyPscListSuccess = (companyPscData) => ({
    type: GET_COMPANY_PSC_LIST_SUCCESS,
    payload: {companyPscData}
})

export const getCompanyPscListFailed = (message) => ({
    type: GET_COMPANY_PSC_LIST_FAILED,
    payload: {message}
})