const fetch = require('node-fetch')
const jwt = require('jsonwebtoken')
const REST_API_URL = process.env['REACT_APP_RESTAPI_URL']
const ACTIVITY_TRACKING_FRONTEND_URL = process.env["ACTIVITY_TRACKING_FRONTEND_URL"]
const ACTIVITY_TRACKING_TOKEY_KEY = process.env["ACTIVITY_TRACKING_TOKEY_KEY"]
const SERVER_TOKEY_KEY = process.env["SERVER_TOKEY_KEY"]
const CRYPTO_KEY = process.env["CRYPTO_KEY"]
const CRYPTO_ALGORITHM = process.env["CRYPTO_ALGORITHM"]
const CRYPTO_IV = process.env["CRYPTO_IV"]
const axios = require('axios');
let moment = require('moment');
const crypto = require('crypto');


let callApi = async (URL, options, ipAddress) => {
  try {
    if (options.headers) {
      options.headers['content-type'] = 'application/json'
    } else {
      options.headers = { 'content-type': 'application/json' }
    }
    const uniqueKey = `${ipAddress}-${Date.now()}`;
    console.log(`Requesting resource from ${URL} (${uniqueKey})`)
    const response = await fetch(URL, options)
    console.log(`Receiving resource from ${URL} (${uniqueKey})`)
    const isJson = response && response.headers && response.headers.get('content-type')?.includes('application/json');
    const data = isJson ? await response.json() : null;
    if (!data && response) {
      let tId=options?.headers?.trackerId
      if(tId){
        console.log("if no data ,tracker Id", tId, URL);
      }
      console.log("Response content=",response.text(),"\n URL ", URL)

    }
    //const data = await response.json()
    if (response && response.status === 200) {
      //const data = await response.json()
      return { error: false, data, status: response.status }
    } else {
      return { error: true, data, status: response && response.status }
    }
  } catch (error) {
    console.log('Error inside server callApi for URL', URL, error)
    return { error: true }
  }
}

let callBlogApi = async (id, token, ipAddress) => {
  try {
    const URL = `${REST_API_URL}/blog/getBlogDetailsTitleAndImage/${id}`
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        resttoken: token,
        apicall: 'ssr-node-fetch',
      },
    }
    const response = await callApi(URL, options, ipAddress)
    return response
  } catch (error) {
    console.log('inside callBlogApi ', error)
    return { error: true }
  }
}

let callBlogDetailApi = async (id, token, ipAddress = null) => {
  try {
    const URL = `${REST_API_URL}/blog/getBlogDetail/${id}`
    let options = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "resttoken": token,
        "apicall": "ssr-node-fetch",
        "client-ip-address": ipAddress
      }
    }
    return await callApi(URL, options, ipAddress);
  } catch (error) {
    console.log("inside callBlogDetailApi ", error);
    return { error: true };
  }
}

let callBlogListApi = async (token, ipAddress = null) => {
  try {
    const URL = `${REST_API_URL}/blog/getBlogList`
    let options = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "resttoken": token,
        "apicall": "ssr-node-fetch",
        "client-ip-address": ipAddress
      }
    }
    const response = await callApi(URL, options, ipAddress);
    return response
  } catch (error) {
    console.log("inside callBlogDetailApi ", error);
    return { error: true };
  }
}

let getCompanyAndDirectorName = async (reportType, id, token) => {
  const response = await getCompanyAndDirectorData(reportType, id, token)
  return `Here's what I just found on pomanda.com - ${reportType === 'company'
    ? response.data.companyProfile.name
    : response.data.directorDetail.FirstName +
    '-' +
    response.data.directorDetail.LastName
    }`
}
let callApiUsingAxios = async (url, restApiToken, ipAddress, { accessToken, trackerId, sessionId }, method, data) => {

  let headers = {
    'Content-Type': 'application/json',
    "resttoken": restApiToken,
    "apicall": "ssr-node-fetch",
    "trackerid": trackerId,
    "sessionid": sessionId,
    
  }
  accessToken &&(headers["accesstoken"]= accessToken)
  ipAddress && (headers["client-ip-address"] = ipAddress)
  try {
    let obj = {
      method: method,
      url: url,
      headers: headers
    }
    data && (obj.data = data)
    const unqKey = `${ipAddress}-${Date.now()}`;
    console.log(`Requesting resource from ${url} (${unqKey})`)
    let resp = await axios(obj)
    console.log(`Receiving resource from ${url} (${unqKey})`)
    return resp
  }
  catch (error) {
    console.log("Error while calling api ", url, ipAddress, method, data);
    if (error.response) {
      console.log(error.response.status, error.response.statusText, error.response.data);
    } else {
      console.log(error.code, error.address);

    }
    return { error: true, status:error.response.status };
  }
}

const commonDataConfigApi = async (commonDataObj) => {
  try {
    const URL = `${REST_API_URL}/commonData/commonConfigData`
    let headers = {}
    headers = {
      'Content-Type': 'application/json',
      resttoken: commonDataObj.restApiToken,
      apicall: 'ssr-node-fetch',
      accesstoken: commonDataObj.accessToken,
      domain: commonDataObj.req.headers.host
    }
    let options = {
      method: 'post',
      body: JSON.stringify(commonDataObj.trackerObj),
      headers: headers,
    }
    const response = await callApi(URL, options, commonDataObj.ipAddress)
    return response
  } catch (error) {
    console.log('error in commonDataConfigApi', error)
  }
}

let getCompanyAndDirectorData = async (type,
  id,
  name,
  token,
  ipAddress,
  { accessToken,
    trackerId,
    sessionId }) => {

  let inTime = new Date()
  let url = ""
  accessToken && accessToken !== 'undefined'
    ? (accesstoken = accessToken)
    : (accesstoken = '')
  trackerId && trackerId !== 'undefined' ? (trackerId = trackerId) : (trackerId = '')
  sessionId && sessionId !== 'undefined' ? (sessionId = sessionId) : (sessionId = '')
  //   let options = {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       resttoken: token,
  //       trackerId: trackerId,
  //       sessionId: sessionId,
  //       apicall: 'ssr-node-fetch',
  //       'client-ip-address': ipAddress,
  //       accesstoken: accesstoken,
  //     },
  //   }
  if (type === "company") {
    url = `${REST_API_URL}/company/getCompanyProfile/${id}/${name}`
    let resp = await callApiUsingAxios(url, token, ipAddress, { accessToken, trackerId, sessionId }, 'get')
    printInOutTimeDifference(type + "_" + id, inTime, new Date())
    return resp
  } else {
    url = `${REST_API_URL}/director/getDirectorDetail`
    let resp = await callApiUsingAxios(url, token, ipAddress, { accessToken, trackerId, sessionId }, 'post', { pnr: id })
    printInOutTimeDifference(type + "_" + id, inTime, new Date())
    return resp

  }
}

let printInOutTimeDifference = (type, inTime, outTime) => {
  let diff = moment(outTime).diff(moment(inTime), 'seconds')
  if (diff >= 5) {
    console.log(` response  for request  ${type}   difference ${diff}`)
  }
}

const getActTrkToken = async () => {
  return await jwt.sign(
    { domain: ACTIVITY_TRACKING_FRONTEND_URL },
    ACTIVITY_TRACKING_TOKEY_KEY,
  )
}
const getRestapiToken = async (xForwordedIp, userAgent, language) => {
  const restToken = await jwt.sign({ xForwordedIp, userAgent, language }, SERVER_TOKEY_KEY);
  const token  = await encryptToken(restToken);
  return token
}

const encryptToken = async (token) =>{  
  try{
    let cipher = crypto.createCipheriv(CRYPTO_ALGORITHM, CRYPTO_KEY, CRYPTO_IV);
    let encryptedToken = cipher.update(token);
    encryptedToken = Buffer.concat([encryptedToken, cipher.final()]);
    return encryptedToken.toString('hex')
  }
  catch(Error)
  {
    console.log("Error while encrypting Token", Error)
  }
}

const trackerDataApi = async (trackerObj, restApiToken, accessToken) => {
  try {
    const URL = `${REST_API_URL}/tracker/saveTrackerData`
    let headers = {}
    accessToken && accessToken !== 'undefined'
      ? (accesstoken = accessToken)
      : (accesstoken = '')

    headers = {
      'Content-Type': 'application/json',
      resttoken: restApiToken,
      apicall: 'ssr-node-fetch',
      accesstoken: accesstoken,
    }
    let options = {
      method: 'post',
      body: JSON.stringify(trackerObj),
      headers: headers,
    }
    const response = await callApi(URL, options)
    return response
  } catch (error) {
    console.log('error in trackerDataApi', error)
  }
}

let actTrkServerApi = async (data, trackerId, sessionId) => {
  try {
    const tokenData = await getActTrkToken()
    if (tokenData) {
      console.log("trk/act start", trackerId);
      const URL = `${ACTIVITY_TRACKING_FRONTEND_URL}/trk/act`
      let options = {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          actTrkToken: tokenData,
          trackerId: trackerId,
          sessionId: sessionId,
        },
      }
     let resp= await callApi(URL, options, data.ipAddress)
      console.log("resp trk/act End",trackerId,JSON.stringify(resp));
    } else {
      console.log('error actTrkServerApi token not received', trackerId)
    }
  } catch (error) {
    console.log('Error inside actTrkServerApi ',trackerId, error)
  }
}

const getPopularCompanyData = async (indexField,pageNumber,size,token,ipAddress) => {
  try {
    const URL = `${REST_API_URL}/popularCompanies/getPopularCompanies/${indexField}/${pageNumber}/${size}`
    let options = {
      method: 'get',
      headers: {
        'content-type': 'application/json',
        "resttoken": token,
        "apicall": "ssr-node-fetch",
        "client-ip-address": ipAddress
      },
    }
    const response = await callApi(URL, options, ipAddress)
    return response.data;
  } catch (error) {
    console.log('Error getPopularCompanyData api call', error)
  }
}

let getIndustryProfileData = async (
  id,
  token,
  ipAddress,
  {trackerId,
  sessionId,
accessToken},
) => {
  let url = ''
  let options = {headers:{
    resttoken:token,
    apicall: "ssr-node-fetch",
    trackerId: trackerId && trackerId!=='undefined'?trackerId:'',
    sessionId: sessionId && sessionId!=='undefined'?sessionId:'',
    accesstoken: accessToken && accessToken !== 'undefined' ? accessToken :'',
    'client-ip-address':ipAddress
  }}
  url = `${REST_API_URL}/industry/getIndustryProfile/${id}`
  const temp = await callApi(url, options, ipAddress)
  return temp
}

let getCompanyValuationData = async (
  id,
  name,
  token,
  ipAddress,
  accessToken,
  trackerId,
  sessionId
) => {
  let url = ''
  url = `${REST_API_URL}/company/valuationOnLoad/${id}/${name}`
  const temp = await  callApiUsingAxios(url, token, ipAddress, { trackerId, sessionId, accessToken }, 'get')
  return temp
}

const getUserVariants = (token, ipAddress, trackerId, sessionId) => {
  return callApi(`${REST_API_URL}/ab-test`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      resttoken: token,
      trackerId: trackerId,
      sessionId: sessionId,
      apicall: 'ssr-node-fetch',
      'client-ip-address': ipAddress,
    },
  })
}

const getLocationProfileData = async (locationId, token, ipAddress) => {
  try {
    const URL = `${REST_API_URL}/location/getLocationProfile/${locationId}`
    let options = {
      method: "get",
      headers: {
        'Content-Type': 'application/json',
        "resttoken": token,
        "apicall": "ssr-node-fetch",
        "client-ip-address": ipAddress
      }
    }
    return await callApi(URL, options, ipAddress);
  } catch (error) {
    console.error('inside getLocationProfileData ', error);
  }
}

const getIndustrySicCodeData = async (section, pageNumber, size, token, ipAddress) => {
  try {
    const sicCodeUrl = `${REST_API_URL}/industrySicCodes/getIndustrySicCodes/${section}/${pageNumber}/${size}`
    const options = {
      method: "get",
      headers: {
        'Content-Type': 'application/json',
        "resttoken": token,
        "apicall": "ssr-node-fetch",
        "client-ip-address": ipAddress
      }
    }
    const response = await callApi(sicCodeUrl, options, ipAddress);
    return response.data
  } catch (error) {
    console.error('inside getIndustrySicCodeData', error);
  }
}

const getIndustrySicCodeCompanyList = async (sicCode, size, pageNumber, token, ipAddress) => {
  try {
    const companyListUrl = `${REST_API_URL}/industrySicCodes/getCompanyList/${sicCode}/${size}/${pageNumber}`
    const options = {
      method: "get",
      headers: {
        'Content-Type': 'application/json',
        "resttoken": token,
        "apicall": "ssr-node-fetch",
        "client-ip-address": ipAddress
      }
    }
    const response = await callApi(companyListUrl, options, ipAddress);
    return response.data
  } catch (error) {
    console.error('inside getIndustrySicCodeCompanyList', error);
  }
}

module.exports = {
  callBlogApi,
  getCompanyAndDirectorName,
  getCompanyAndDirectorData,
  actTrkServerApi,
  getPopularCompanyData,
  getRestapiToken,
  getIndustryProfileData,
  getUserVariants,
  callBlogDetailApi,
  callBlogListApi,
  getLocationProfileData,
  printInOutTimeDifference,
  trackerDataApi,
  getCompanyValuationData,
  commonDataConfigApi,
  getIndustrySicCodeData,
  getIndustrySicCodeCompanyList
}
