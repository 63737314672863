export const COOKIE_MSG_ACCEPTED = "COOKIE_MSG_ACCEPTED";
export const COLLAPSIBLE_MENU_TOGGLE = "COLLAPSIBLE_MENU_TOGGLE";
export const TOGGLE_SEARCH_ESTIMATION = 'TOGGLE_SEARCH_ESTIMATION';
export const SET_SEARCH_ESTIMATION = 'SET_SEARCH_ESTIMATION';
export const TOGGLE_SEARCH_FILTER = 'TOGGLE_SEARCH_FILTER';
export const TOGGLE_SEARCH_LIST_VIEW = 'TOGGLE_SEARCH_LIST_VIEW';
export const SET_COMPANY_SEARCH_APPLIED_FILTER = 'SET_COMPANY_SEARCH_APPLIED_FILTER';
export const SET_DIRECTOR_SEARCH_APPLIED_FILTER = 'SET_DIRECTOR_SEARCH_APPLIED_FILTER';
export const SET_SHAREHOLDER_SEARCH_APPLIED_FILTER = 'SET_SHAREHOLDER_SEARCH_APPLIED_FILTER';
export const SET_CHARGES_SEARCH_APPLIED_FILTER = 'SET_CHARGES_SEARCH_APPLIED_FILTER';
export const SET_INDUSTRY_SEARCH_APPLIED_FILTER = 'SET_INDUSTRY_SEARCH_APPLIED_FILTER';
export const SET_REGION_SEARCH_APPLIED_FILTER = 'SET_REGION_SEARCH_APPLIED_FILTER';
export const SET_FILTER_OBJECT_LIST = 'SET_FILTER_OBJECT_LIST';
// export const SET_COMPANY_FILTER_APPLIED_INDICATOR='SET_COMPANY_FILTER_APPLIED_INDICATOR';
// export const SET_DIRECTOR_FILTER_APPLIED_INDICATOR='SET_DIRECTOR_FILTER_APPLIED_INDICATOR';
export const GET_INDUSTRY_FILTER_DATA_REQUEST = 'GET_INDUSTRY_FILTER_DATA_REQUEST';
export const SET_INDUSTRY_FILTER_DATA = 'SET_INDUSTRY_FILTER_DATA';
export const GET_INDUSTRY_FILTER_DATA_FAILED = 'GET_INDUSTRY_FILTER_DATA_FAILED';

export const SHOW_ESTIMATION_TABLET = "SHOW_ESTIMATION_TABLET";
export const SHOW_SEARCH_OPTIONS_TABLET = "SHOW_SEARCH_OPTIONS_TABLET";

export const GET_COMPANY_PROFILE_REQUEST = "GET_COMPANY_PROFILE_REQUEST";
export const GET_COMPANY_PROFILE_SUCCESS = "GET_COMPANY_PROFILE_SUCCESS";
export const GET_COMPANY_PROFILE_FAILED = "GET_COMPANY_PROFILE_FAILED";
export const SET_CURRENT_BALANCESHEET_CARD = "SET_CURRENT_BALANCESHEET_CARD";
export const SET_CURRENT_PROFITLOSS_CARD = "SET_CURRENT_PROFITLOSS_CARD";
export const GET_COMPANY_HOUSE_FILINGS_REQUEST = "GET_COMPANY_HOUSE_FILINGS_REQUEST";
export const GET_COMPANY_HOUSE_FILINGS_SUCCESS = "GET_COMPANY_HOUSE_FILINGS_SUCCESS";
export const GET_COMPANY_HOUSE_FILINGS_FAILED = "GET_COMPANY_HOUSE_FILINGS_FAILED";
export const GET_COMPANY_HOUSE_DOCUMENT_REQUEST = "GET_COMPANY_HOUSE_DOCUMENT_REQUEST";
export const GET_COMPANY_HOUSE_DOCUMENT_FAILED = "GET_COMPANY_HOUSE_DOCUMENT_FAILED";
export const CHANGE_FINANCIAL_TAB = "CHANGE_FINANCIAL_TAB";
export const CHANGE_FINANCIALS_DATA_INDEX = "CHANGE_FINANCIALS_DATA_INDEX";
export const GET_COMPANY_SEARCH_RESULTS = "GET_COMPANY_SEARCH_RESULTS";
export const SEARCH_COMPANY_API = "SEARCH_COMPANY_API";
export const SEARCH_COMPANY_API_SUCCESS = "SEARCH_COMPANY_API_SUCCESS";
export const SET_CAPTCHA_STATUS = "SET_CAPTCHA_STATUS";
export const SEARCH_COMPANY_API_FAILED = "SEARCH_COMPANY_API_FAILED";
export const RESET_COMPANY_SEARCH_LIST = "RESET_COMPANY_SEARCH_LIST";
export const SET_COMPANY_SEARCH_PARAMS = "SET_COMPANY_SEARCH_PARAMS";
export const RESET_DIRECTOR_SEARCH_LIST = "RESET_DIRECTOR_SEARCH_LIST";
export const GET_DIRECTOR_STATUS_LIST = "GET_DIRECTOR_STATUS_LIST"
export const GET_DIRECTOR_STATUS_LIST_SUCCESS = "GET_DIRECTOR_STATUS_LIST_SUCCESS"
export const GET_DIRECTOR_STATUS_LIST_FAILED = "GET_DIRECTOR_STATUS_LIST_FAILED"
export const GET_DIRECTOR_SHOW_MORE = "GET_DIRECTOR_SHOW_MORE"
export const GET_DIRECTOR_SHOW_MORE_SUCCESS = "GET_DIRECTOR_SHOW_MORE_SUCCESS"
export const GET_DIRECTOR_SHOW_MORE_FAILED = "GET_DIRECTOR_SHOW_MORE_FAILED"
export const SET_DIRECTOR_SEARCH_PARAMS = "SET_DIRECTOR_SEARCH_PARAMS"
export const SET_INDUSTRY_MIX_UPDATE_MODAL = "SET_INDUSTRY_MIX_UPDATE_MODAL"
export const SET_ADD_INDUSTRY_MIX_MODAL = "SET_ADD_INDUSTRY_MIX_MODAL"
export const SAVE_INDUSTRY_MIX_CHANGES = "SAVE_INDUSTRY_MIX_CHANGES"
export const SAVE_INDUSTRY_MIX_CHANGES_FAILED = "SAVE_INDUSTRY_MIX_CHANGES_FAILED"
export const SET_INDUSTRY_MIX_LOADER = "SET_INDUSTRY_MIX_LOADER"
export const SET_INDUSTRY_MIX_UPDATE_SUCCESS = "SET_INDUSTRY_MIX_UPDATE_SUCCESS"
export const RESET_INDUSTRY_MIX_CHANGES = "RESET_INDUSTRY_MIX_CHANGES"
export const RESET_INDUSTRY_MIX_CHANGES_FAILED = "RESET_INDUSTRY_MIX_CHANGES_FAILED"
export const RESET_INDUSTRY_MIX_CHANGES_SUCCESS = "RESET_INDUSTRY_MIX_CHANGES_SUCCESS"
export const SET_UPDATED_MIX_INDUSTRIES = "SET_UPDATED_MIX_INDUSTRIES"
export const GET_COMPANY_PSC_LIST_REQUEST = "GET_COMPANY_PSC_LIST_REQUEST"
export const GET_COMPANY_PSC_LIST_SUCCESS = "GET_COMPANY_PSC_LIST_SUCCESS"
export const GET_COMPANY_PSC_LIST_FAILED = "GET_COMPANY_PSC_LIST_FAILED"

export const GET_SHAREHOLDER_TYPE_LIST = "GET_SHAREHOLDER_TYPE_LIST"
export const GET_SHAREHOLDER_TYPE_LIST_SUCCESS = "GET_SHAREHOLDER_TYPE_LIST_SUCCESS"
export const GET_SHAREHOLDER_TYPE_LIST_FAILED = "GET_SHAREHOLDER_TYPE_LIST_FAILED"
export const GET_SHAREHOLDER_SHOW_MORE = "GET_SHAREHOLDER_SHOW_MORE"
export const GET_SHAREHOLDER_SHOW_MORE_SUCCESS = "GET_SHAREHOLDER_SHOW_MORE_SUCCESS"
export const GET_SHAREHOLDER_SHOW_MORE_FAILED = "GET_SHAREHOLDER_SHOW_MORE_FAILED"

export const GET_CHARGES_TYPE_LIST = "GET_CHARGES_TYPE_LIST"
export const GET_CHARGES_TYPE_LIST_SUCCESS = "GET_CHARGES_TYPE_LIST_SUCCESS"
export const GET_CHARGES_TYPE_LIST_FAILED = "GET_CHARGES_TYPE_LIST_FAILED"
export const GET_CHARGES_SHOW_MORE = "GET_CHARGES_SHOW_MORE"
export const GET_CHARGES_SHOW_MORE_SUCCESS = "GET_CHARGES_SHOW_MORE_SUCCESS"
export const GET_CHARGES_SHOW_MORE_FAILED = "GET_CHARGES_SHOW_MORE_FAILED"

export const TOGGLE_EDIT_USER_PROFILE = "TOGGLE_EDIT_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const CLEAR_CHANGE_PASSWORD_ERROR_MESSAGE = "CLEAR_CHANGE_PASSWORD_ERROR_MESSAGE";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAILED = "CONTACT_US_FAILED";
export const SAVE_USER_PROFILE_FAILED = "SAVE_USER_PROFILE_FAILED";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";
export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const SAVE_USER_PROFILE_REQUEST = "SAVE_USER_PROFILE_REQUEST";
export const SAVE_USER_PROFILE_SUCCESS = "SAVE_USER_PROFILE_SUCCESS";
export const SAVE_VR_WHITE_LABEL_INFO_REQUEST = "SAVE_VR_WHITE_LABEL_INFO_REQUEST";
export const SET_VR_WHITE_LABEL_INFO = "SET_VR_WHITE_LABEL_INFO";
export const GET_VR_WHITE_LABEL_INFO_REQUEST = "GET_VR_WHITE_LABEL_INFO_REQUEST";
export const RESTRICT_ACCESS_FOR_PROSPER_USER = "RESTRICT_ACCESS_FOR_PROSPER_USER"
//loginsingup
export const RESET_PASSWORD_EMAIL_REQUEST = "RESET_PASSWORD_EMAIL_REQUEST";
export const RESET_PASSWORD_EMAIL_FAILED = "RESET_PASSWORD_EMAIL_FAILED";
export const RESET_PASSWORD_EMAIL_SUCESS = "RESET_PASSWORD_EMAIL_SUCESS";
export const CLEAR_RESET_PASSWORD_MESSAGE = "CLEAR_RESET_PASSWORD_MESSAGE";
export const RESET_PASSWORD_TOGGLE = "REST_PASSWORD_TOGGLE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const CHECK_RESET_PASWORD_LINK_VALID = "CHECK_RESET_PASWORD_LINK_VALID";
export const TOGGLE_RESET_PASSWORD_MESSAGE = "TOGGLE_RESET_PASSWORD_MESSAGE";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_RESPONSE_SUCCESS";
export const LOGIN_FAILED = "LOGIN_RESPONSE_FAILED";
export const SET_EMAIL_PASSWORD = "SET_EMAIL_PASSWORD";
export const SIGNUP_REQUEST = "signup request";
export const SIGNUP_RESPONSE_SUCCESS = "signup response success";
export const SIGNUP_RESPONSE_FAILED = "signup response failed";
export const CLEAR_RESET_PASSWORD_MESSAGE_LOGIN = "CLEAR_RESET_PASSWORD_MESSAGE_LOGIN";
export const TOGGLE_LOGIN_MODAL = "TOGGLE_LOGIN_MODAL";
export const TOGGLE_FORGOT_PASSWORD_MODAL = "TOGGLE_FORGOT_PASSWORD_MODAL";
export const TOGGLE_SIGNUP_MODAL = "TOGGLE_SIGNUP_MODAL";
export const TOGGLE_FORGOT_PASSWORD_LOGIN_MODAL = "TOGGLE_FORGOT_PASSWORD_LOGIN_MODAL";
export const SET_ON_SUCCESS_RENDER = "SET_ON_SUCCESS_RENDER";
export const TOGGLE_MENU_MODAL = "TOGGLE_MENU_MODAL";
export const TOGGLE_COMING_SOON_MODAL = "TOGGLE_COMING_SOON_MODAL";
export const TOGGLE_INFORMATION_MODAL = "TOGGLE_INFORMATION_MODAL";

export const EMPTY_COMPANY_PROFILE = "EMPTY_COMPANY_PROFILE"

export const GET_DIRECTOR_DATA_REQUEST = "GET_DIRECTOR_DATA_REQUEST";
export const GET_DIRECTOR_DATA_FAILED = "GET_DIRECTOR_DATA_FAILED";
export const GET_DIRECTOR_DATA_SUCCESS = "GET_DIRECTOR_DATA_SUCCESS";
export const GET_RELATED_DIRECTOR_REQUEST = "GET_RELATED_DIRECTOR_REQUEST";
export const GET_RELATED_DIRECTOR_FAILED = "GET_RELATED_DIRECTOR_FAILED";
export const GET_RELATED_DIRECTOR_SUCCESS = "GET_RELATED_DIRECTOR_SUCCESS";
export const GET_INDUSTRY_MULTIPLE_DATA = "GET_INDUSTRY_MULTIPLE_DATA";
export const GET_INDUSTRY_MULTIPLE_DATA_SUCCESS = "GET_INDUSTRY_MULTIPLE_DATA_SUCCESS";
export const GET_INDUSTRY_MULTIPLE_DATA_FAILED = "GET_INDUSTRY_MULTIPLE_DATA_FAILED";

export const SET_POWER_SEARCH_TYPE_REQUEST = "SET_POWER_SEARCH_TYPE_REQUEST";
export const SET_COMPANY_LIST_VIEW_SORTING = "SET_COMPANY_LIST_VIEW_SORTING";

export const GET_DIRECTOR_LIST_REQUEST = "GET_DIRECTOR_LIST_REQUEST";
export const GET_DIRECTOR_LIST_SUCCESS = "GET_DIRECTOR_LIST_SUCCESS";
export const GET_DIRECTOR_LIST_FAILED = "GET_DIRECTOR_LIST_FAILED";
export const SET_SCREEN_WIDTH = "SET_SCREEN_WIDTH";
export const SET_SELECTED_SUBSCRIPTION_PLAN = "SET_SELECTED_SUBSCRIPTION_PLAN";
export const SET_USERS_DAILY_SEARCH_INFO = "SET_USERS_DAILY_SEARCH_INFO";
export const CREATE_STRIPE_SESSION = "CREATE_STRIPE_SESSION";
export const CREATE_STRIPE_SESSION_FAILED = "CREATE_STRIPE_SESSION_FAILED";
export const CREATE_STRIPE_SESSION_SUCCESS = "CREATE_STRIPE_SESSION_SUCCESS";
export const SET_SUBSCRIPTION_STATUS_REQUEST = "SET_SUBSCRIPTION_STATUS_REQUEST";
export const SET_SUBSCRIPTION_STATUS_FAILED = "SET_SUBSCRIPTION_STATUS_FAILED";
export const SET_SUBSCRIPTION_STATUS_SUCCESS = "SET_SUBSCRIPTION_STATUS_SUCCESS";
export const SET_SCREEN_WIDTH_HEIGHT = "SET_SCREEN_WIDTH_HEIGHT";

export const SEND_ACTIVITY_TRACKING = "SEND_ACTIVITY_TRACKING";
export const GET_COMPANY_PAGE_URL = "GET_COMPANY_PAGE_URL";
export const TOGGLE_SHARE_URL_MODAL = "TOGGLE_SHARE_URL_MODAL";
export const TOGGLE_CONTENT_SHARE_MODAL = "TOGGLE_SELECT_CONTENT_SHARE_MODAL"

export const GET_ONLOAD_DATA_API = "GET_ONLOAD_DATA_API"
export const GET_ONLOAD_DATA_API_SUCCESS = "GET_ONLOAD_DATA_API_SUCCESS"
export const GET_ONLOAD_DATA_API_ERROR = "GET_ONLOAD_DATA_API_ERROR"
export const GET_PDF_REPORT = "GET_PDF_REPORT"
export const CHECK_FREE_DIRECTOR_SEARCH_FOR_ANONYMOUS = "CHECK_FREE_DIRECTOR_SEARCH_FOR_ANONYMOUS"
export const GET_CREDIT_REPORT = "GET_CREDIT_REPORT"
export const GET_CREDIT_REPORT_PPT_URL = "GET_CREDIT_REPORT_PPT_URL"
export const TOGGLE_NEW_SIGNUP_MODAL = "TOGGLE_NEW_SIGNUP_MODAL"
export const TOGGLE_NEW_LOGIN_MODAL = "TOGGLE_NEW_LOGIN_MODAL"
export const CLOSE_SUBSCRIPTION_MODAL = "CLOSE_SUBSCRIPTION_MODAL";
export const TOGGLE_SUBSCRIPTION_SCREEN = "TOGGLE_SUBSCRIPTION_SCREEN";
export const CANCEL_USER_SUBSCRIPTION_REQUEST = "CANCEL_USER_SUBSCRIPTION_REQUEST";
export const SHOW_CANCEL_SUBSCRIPTION_MODAL = "SHOW_CANCEL_SUBSCRIPTION_MODAL";
export const CANCEL_USER_SUBSCRIPTION_FAILED = "CANCEL_USER_SUBSCRIPTION_FAILED";
export const CHECK_FREE_SEARCH_FOR_ANONYMOUS = "CHECK_FREE_SEARCH_FOR_ANONYMOUS";
export const SET_HEADER_HIDE = "SET_HEADER_HIDE";
export const CHECK_FREE_SEARCH_ANONYMOUS_FOR_PRODUCT = "CHECK_FREE_SEARCH_ANONYMOUS_FOR_PRODUCT";
export const CHECK_STORAGE_AND_SEND_TRK_DATA = "CHECK_STORAGE_AND_SEND_TRK_DATA";
export const CANCEL_USER_FREE_TRIAL_SUBSCRIPTION_REQ = 'CANCEL_USER_FREE_TRIAL_SUBSCRIPTION_REQ';

export const SET_ACTIVITY_TRACKING_DATA = "SET_ACTIVITY_TRACKING_DATA"

export const SET_SUBSCRIPTION_MODAL_INITIAL_STATE = "SET_SUBSCRIPTION_MODAL_INITIAL_STATE"
export const SET_SUBSCRIPTION_MODAL_DATA = "SET_SUBSCRIPTION_MODAL_DATA"
export const HANDLE_SUBSCRIPTION_BTN = "HANDLE_SUBSCRIPTION_BTN"
export const SET_SIMILAR_COMPANY_MODAL_TOGGLE_STATUS = "SET_SIMILAR_COMPANY_MODAL_TOGGLE_STATUS"
export const HANDLE_LOGIN_SIGNUP_RESP = "HANDLE_LOGIN_SIGNUP_RESP"
export const SET_PLAN_SELECTED = "SET_PLAN_SELECTED"
export const HANDLE_PAYMENT_SUCCESS_ERROR = "HANDLE_PAYMENT_SUCCESS_ERROR"
export const CALL_CREDIT_REPORT_API = "CALL_CREDIT_REPORT_API"
export const UPDATE_USER_INFO = "UPDATE_USER_INFO"
export const RESET_STRIPE_SESSION_RETURN_DATA = "RESET_STRIPE_SESSION_RETURN_DATA"
export const TOGGLE_CR_DOWNLOAD_LOADING_MODAL = "TOGGLE_CR_DOWNLOAD_LOADING_MODAL";
export const CHECK_PDF_CREATION_STATUS = "CHECK_PDF_CREATION_STATUS";
export const UPDATE_USER_FREE_CR_COUNT = "UPDATE_USER_FREE_CR_COUNT"
export const CALL_TRACKER_API = "CALL_TRACKER_API"
export const CLOSE_ALL_LOGIN_SIGNUP_MODAL = "CLOSE_ALL_LOGIN_SIGNUP_MODAL"

export const GET_POPULAR_COMPANIES_REQUEST = "GET_POPULAR_COMPANIES_REQUEST"
export const GET_POPULAR_COMPANIES_SUCCESS = "GET_POPULAR_COMPANIES_SUCCESS"
export const GET_BLOG_LIST_REQUEST = "GET_BLOG_LIST_REQUEST";
export const GET_BLOG_LIST_SUCCESS = "GET_BLOG_LIST_SUCCESS";
export const GET_BLOG_DETAIL_REQUEST = "GET_BLOG_DETAIL_REQUEST";
export const GET_BLOG_DETAIL_SUCCESS = "GET_BLOG_DETAIL_SUCCESS";

export const GET_DIRECTOR_REPORT_LINK = "GET_DIRECTOR_REPORT_LINK";
export const EMPTY_DIRECTOR_PROFILE_DATA = "EMPTY_DIRECTOR_PROFILE_DATA";
export const SET_API_SUBSCRIPTION_PLANS = "SET_API_SUBSCRIPTION_PLANS"

export const CALL_SINGLE_SEARCH_API = "CALL_SINGLE_SEARCH_API"
export const CALL_SINGLE_SEARCH_API_ERROR = "CALL_SINGLE_SEARCH_API_ERROR"

export const SET_TRY_POWER_SEARCH = "SET_TRY_POWER_SEARCH"

export const SET_TREE_FILTER_QUERY_PARAMS = "SET_TREE_FILTER_QUERY_PARAMS"
export const SET_WATCHING_FILTER_QUERY_PARAMS = "SET_WATCHING_FILTER_QUERY_PARAMS"
export const SET_POWER_SEARCH_SHARE = "SET_POWER_SEARCH_SHARE"
export const SET_SAVED_SEARCHES = "SET_SAVED_SEARCHES";
export const SET_ADD_CURRENT_SEARCH = "SET_ADD_CURRENT_SEARCH";
export const SET_CONFIRMATION_MODAL = "SET_CONFIRMATION_MODAL";
export const GET_SAVE_SEARCHES = "GET_SAVE_SEARCHES";
export const SAVE_CURRENT_SEARCH = "SAVE_CURRENT_SEARCH";
export const SET_SAVED_SEARCHES_LIST = "SET_SAVED_SEARCHES_LIST";
export const DELETE_SAVED_SEARCH = "DELETE_SAVED_SEARCH";
export const SET_SAVE_CURRENT_SEARCH_ERROR = "SET_SAVE_CURRENT_SEARCH_ERROR";

export const DOWNLOAD_RESULTS_REQUEST = "DOWNLOAD_RESULTS_REQUEST";
export const INCREASE_DOWNLOAD_RESULT_LIMIT_REQUEST = "INCREASE_DOWNLOAD_RESULT_LIMIT_REQUEST";
export const ET_SIGNUP_SUCCESS_FROM_LANDING_PAGE = "ET_SIGNUP_SUCCESS_FROM_LANDING_PAGE";
export const TOGGLE_SORT_MODAL = "TOGGLE_SORT_MODAL"
export const TOGGLE_SUBMIT_INVESTMENT_INTEREST_MODAL = "TOGGLE_SUBMIT_INVESTMENT_INTEREST_MODAL";
export const SUBMIT_INVESTMENT_INTEREST_REQUEST = "SUBMIT_INVESTMENT_INTEREST_REQUEST";
export const SUBMIT_INVESTMENT_INTEREST_FAILED = "SUBMIT_INVESTMENT_INTEREST_FAILED";

export const CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA = "CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA";
export const CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA_SUCCESS = "CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA_SUCCESS";
export const CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA_ERROR = "CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA_ERROR";
export const CREATE_STRIPE_SESSION_ADD_CARD = "CREATE_STRIPE_SESSION_ADD_CARD";
export const TOGGLE_COMMON_MSG_MODAL = "TOGGLE_COMMON_MSG_MODAL";
export const SET_DELETE_SEARCH_CONFIRMATION = "SET_DELETE_SEARCH_CONFIRMATION";
export const SET_HEADER_SEARCH_TEXT = "SET_HEADER_SEARCH_TEXT";
export const SET_HEADER_SINGLE_SEARCH_OPTIONS = "SET_HEADER_SINGLE_SEARCH_OPTIONS";
export const DOWNLOAD_FINANCIALS = "DOWNLOAD_FINANCIALS";
export const SIGN_OUT_CLICK = "SIGN_OUT_CLICK"
export const SET_STICKY_HEADER_PROPS = "SET_STICKY_HEADER_PROPS"
export const COMPANY_HOUSE_EMAIL_NOTIFICATION = "COMPANY_HOUSE_EMAIL_NOTIFICATION"
export const COMPANY_HOUSE_EMAIL_NOTIFICATION_FLAG = "COMPANY_HOUSE_EMAIL_NOTIFICATION_FLAG"
export const SET_RESET_FILTER_TYPE = "SET_RESET_FILTER_TYPE";
export const SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL = "SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL";

//product page constants
export const SET_PRODUCT_PAGE_SCREEN = "SET_PRODUCT_PAGE_SCREEN";
export const SET_PRODUCT_PAGE_SCREEN_SUCCESS = "SET_PRODUCT_PAGE_SCREEN_SUCCESS";
export const TOGGLE_SINGLE_PRODUCT_MODAL = "TOGGLE_SINGLE_PRODUCT_MODAL";
export const HANDLE_PRODUCT_PLAN_BUTTON_CLICK = "HANDLE_PRODUCT_PLAN_BUTTON_CLICK";

export const SET_LOADING = "SET_LOADING";
export const SET_AGREE_YES_CHOICE = "SET_AGREE_YES_CHOICE";
export const CHANGE_VALUATION_SECTION_DATA = "CHANGE_VALUATION_SECTION_DATA";

//Industry Profile
export const TOGGLE_SEEMORE_MODAL = "TOGGLE_SEEMORE_MODAL";
export const GET_INDUSTRY_PROFILE_REQUEST = "GET_INDUSTRY_PROFILE_REQUEST";
export const GET_INDUSTRY_PROFILE_FAILED = "GET_INDUSTRY_PROFILE_FAILED";
export const GET_INDUSTRY_PROFILE_SUCCESS = "GET_INDUSTRY_PROFILE_SUCCESS";
export const GET_INDUSTRY_PROFILE_SEARCH_RESULT = "GET_INDUSTRY_PROFILE_SEARCH_RESULT";
export const GET_INDUSTRY_PROFILE_SEARCH_SUCCESS = "GET_INDUSTRY_PROFILE_SEARCH_SUCCESS";
export const EMPTY_INDUSTRY_PROFILE_DATA = "EMPTY_INDUSTRY_PROFILE_DATA";
export const GET_INDUSTRY_PROFILE_SEARCH_FAILED = "GET_INDUSTRY_PROFILE_SEARCH_FAILED";
export const SET_APPLIED_LIST_MODAL_TOGGLE_STATUS = "SET_APPLIED_LIST_MODAL_TOGGLE_STATUS";
export const SET_APPLIED_RESET_MODAL_TOGGLE_STATUS = "SET_APPLIED_RESET_MODAL_TOGGLE_STATUS";
export const SET_SIMILAR_COMPANIES_LOADING_FLAG = "SET_SIMILAR_COMPANIES_LOADING_FLAG";
export const TOGGLE_TEMPLATE_LIST_MODAL = "TOGGLE_TEMPLATE_LIST_MODAL";
export const SET_SYSTEM_INFO = "SET_SYSTEM_INFO";
export const TOGGLE_ESTIMATE_WARNING = "TOGGLE_ESTIMATE_WARNING";
export const SET_INDUSTRY_SEARCH_PARAMS = "SET_INDUSTRY_SEARCH_PARAMS";
export const GET_INDUSTRY_LIST_REQUEST = "GET_INDUSTRY_LIST_REQUEST";
export const SET_SHAREHOLDER_SEARCH_PARAMS = "SET_SHAREHOLDER_SEARCH_PARAMS";
export const GET_SHAREHOLDER_LIST_REQUEST = "GET_SHAREHOLDER_LIST_REQUEST";
export const SET_CHARGES_SEARCH_PARAMS = "SET_CHARGES_SEARCH_PARAMS";
export const GET_CHARGES_LIST_REQUEST = "GET_CHARGES_LIST_REQUEST";
export const GET_INDUSTRY_LIST_SUCCESS = "GET_INDUSTRY_LIST_SUCCESS";
export const GET_SHAREHOLDER_LIST_SUCCESS = "GET_SHAREHOLDER_LIST_SUCCESS";
export const GET_CHARGES_LIST_SUCCESS = "GET_CHARGES_LIST_SUCCESS";
export const RESET_INDUSTRY_SEARCH_LIST = "RESET_INDUSTRY_SEARCH_LIST";
export const RESET_CHARGES_SEARCH_LIST = "RESET_CHARGES_SEARCH_LIST";
export const RESET_SHAREHOLDER_SEARCH_LIST = "RESET_SHAREHOLDER_SEARCH_LIST";
export const SET_REGION_SEARCH_PARAMS = "SET_REGION_SEARCH_PARAMS";
export const GET_REGION_LIST_REQUEST = "GET_REGION_LIST_REQUEST";
export const GET_REGION_LIST_SUCCESS = "GET_REGION_LIST_SUCCESS";
export const RESET_REGION_SEARCH_LIST = "RESET_REGION_SEARCH_LIST";
export const SET_CHOOSE_PACKAGE_MODAL_DATA = "SET_CHOOSE_PACKAGE_MODAL_DATA";
export const SET_AB_TEST_USER_VARIANT = "SET_AB_TEST_USER_VARIANT";
export const GET_LOCATION_PROFILE_REQUEST = "GET_LOCATION_PROFILE_REQUEST";
export const GET_LOCATION_PROFILE_SUCCESS = "GET_LOCATION_PROFILE_SUCCESS";
export const GET_LOCATION_PROFILE_FAILED = "GET_LOCATION_PROFILE_FAILED";
export const GET_LOCATION_PROFILE_SEARCH_REQUEST = "GET_LOCATION_PROFILE_SEARCH_REQUEST";
export const GET_LOCATION_PROFILE_SEARCH_SUCCESS = "GET_LOCATION_PROFILE_SEARCH_SUCCESS";
export const GET_LOCATION_PROFILE_SEARCH_FAILED = "GET_LOCATION_PROFILE_SEARCH_FAILED";
export const CLEAR_LOCATION_PROFILE_DATA = "CLEAR_LOCATION_PROFILE_DATA";
export const RESET_ALL_SEARCH_PARAMS = "RESET_ALL_SEARCH_PARAMS";
export const GET_COMPANY_CHARGES_REQUEST = "GET_COMPANY_CHARGES_REQUEST";
export const GET_COMPANY_CHARGES_SUCCESS = "GET_COMPANY_CHARGES_SUCCESS";
export const SET_COMPANY_CHARGES_MODAL = "SET_COMPANY_CHARGES_MODAL";
export const TOGGLE_HEALTH_CHECK_MODAL = "TOGGLE_HEALTH_CHECK_MODAL";
export const UPDATE_USER_REPORT_CREDITS = "UPDATE_USER_REPORT_CREDITS";
export const TOGGLE_BUY_CREDIT_REPORT_MODAL = "TOGGLE_BUY_CREDIT_REPORT_MODAL";
export const SET_BUY_CREDIT_REPORT_MODAL_DATA = "SET_BUY_CREDIT_REPORT_MODAL_DATA";
export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY";
export const SET_ORDER_HISTORY = "SET_ORDER_HISTORY";
export const GET_VALUATION_REPORTS_LIST = "GET_VALUATION_REPORTS_LIST";
export const SET_VALUATION_REPORTS_LIST = "SET_VALUATION_REPORTS_LIST";
export const GET_VALUATION_REPORTS_PPT_REQUEST = 'GET_VALUATION_REPORTS_PPT_REQUEST';
export const GET_BUSINESS_PLAN_REPORTS_PPT_REQUEST = 'GET_BUSINESS_PLAN_REPORTS_PPT_REQUEST';
export const GET_BUSINESS_PLANS_LIST = "GET_BUSINESS_PLANS_LIST";
export const SET_BUSINESS_PLANS_LIST = "SET_BUSINESS_PLANS_LIST";
export const GET_CREDIT_REPORT_REQUEST = "GET_CREDIT_REPORT_REQUEST";
export const GET_COMPANY_REPORT_REQUEST = "GET_COMPANY_REPORT_REQUEST";
export const TOGGLE_FEATURE_LOCK_STATUS = "TOGGLE_FEATURE_LOCK_STATUS";
// Valuation Calculator
export const GET_COMPANY_VALUATION_REQUEST = "GET_COMPANY_VALUATION_REQUEST";
export const TOGGLE_VALUATION_INFO_MODAL = "TOGGLE_VALUATION_INFO_MODAL";
export const SET_FINANCIAL_VALUATION_USER_DATA = "SET_FINANCIAL_VALUATION_USER_DATA";
export const GET_COMPANY_VALUATION_SUCCESS = "GET_COMPANY_VALUATION_SUCCESS";
export const GET_COMPANY_VALUATION_FAILED = "GET_COMPANY_VALUATION_FAILED";
export const SET_COMPANY_VALUATION_USER_EDIT = "SET_COMPANY_VALUATION_USER_EDIT";
export const GET_COMPANY_VALUATION_USER_EDIT_SUCCESS = "GET_COMPANY_VALUATION_USER_EDIT_SUCCESS";
export const GET_COMPANY_VALUATION_USER_EDIT_FAILED = "GET_COMPANY_VALUATION_USER_EDIT_FAILED";
export const NO_FINANCIAL_VALUATION_MODAL = "NO_FINANCIAL_VALUATION_MODAL";
export const GET_COMPANY_VALUATION_REPORT_REQUEST = "GET_COMPANY_VALUATION_REPORT_REQUEST";
export const UPDATE_VALUATION_REPORT_CREDIT_SUCCESS = "UPDATE_VALUATION_REPORT_CREDIT_SUCCESS";
export const UPDATE_VALUATION_REPORT_CREDIT_REQUEST = "UPDATE_VALUATION_REPORT_CREDIT_REQUEST";
export const SET_COMPANY_PROFILE_VALUATION_MAPPING = "SET_COMPANY_PROFILE_VALUATION_MAPPING";
export const SET_WHITELIST_IP_STATUS = "SET_WHITELIST_IP_STATUS";
export const WHITE_LABEL_CONFIG_DATA = "WHITE_LABEL_CONFIG_DATA";
export const SET_ACCESS_TOKEN_STATUS = "SET_ACCESS_TOKEN_STATUS";
export const SET_FILTER_RESTRICTION_MODAL = "SET_FILTER_RESTRICTION_MODAL";
export const SET_DOWNLOAD_SEARCH_RESULTS_BTN_STATUS = "SET_DOWNLOAD_SEARCH_RESULTS_BTN_STATUS"
export const SET_GOOGLE_BOT_STATUS = "SET_GOOGLE_BOT_STATUS";

// Business Template Plan Builder
export const SET_BUSS_TEMP_MAIN_PAGE_DATA = "SET_BUSS_TEMP_MAIN_PAGE_DATA";
export const SET_BUSS_TEMP_PAGE_STATUS = "SET_BUSS_TEMP_PAGE_STATUS";
export const SAVE_BUSINESS_PLAN_DATA_REQUEST = "SAVE_BUSINESS_PLAN_DATA_REQUEST";
export const SAVE_BUSINESS_PLAN_DATA_SUCCESS = "SAVE_BUSINESS_PLAN_DATA_SUCCESS";
export const GET_INDUSTRY_DETAILS_REQUEST = "GET_INDUSTRY_DETAILS_REQUEST";
export const SET_INDUSTRY_DETAILS = "SET_INDUSTRY_DETAILS";
export const GET_BUSINESS_PLAN_DATA_REQUEST = "GET_BUSINESS_PLAN_DATA_REQUEST";
export const SAVE_FINANCIAL_DATA = "SAVE_FINANCIAL_DATA";
export const GET_FUTURE_FINANCIAL_PROJECTION_DATA_REQUEST = "GET_FUTURE_FINANCIAL_PROJECTION_DATA_REQUEST";
export const GET_SUGGESTED_COMPETITORS_DATA_REQUEST = "GET_SUGGESTED_COMPETITORS_DATA_REQUEST"
export const BUSINESS_PLAN_ONBOARD_BASIC_DATA = "BUSINESS_PLAN_ONBOARD_BASIC_DATA"
export const TOGGLE_SUGGESTED_AREAS_MODAL = "TOGGLE_SUGGESTED_AREAS_MODAL";
export const GET_BUSINESSPLAN_LOCATIONS_REQUEST = "GET_BUSINESSPLAN_LOCATIONS_REQUEST";
export const GET_BUSINESSPLAN_LOCATIONS_SUCCESS = "GET_BUSINESSPLAN_LOCATIONS_SUCCESS"
export const TOGGLE_ADD_LOCATION_MODAL = "TOGGLE_ADD_LOCATION_MODAL"
export const SET_SELECTED_BUSINESS_LOCATIONS = "SET_SELECTED_BUSINESS_LOCATIONS"
export const GET_ACTIVE_BUSINESSES_AND_SALES_REQ = "GET_ACTIVE_BUSINESSES_AND_SALES_REQ"
export const GET_ACTIVE_BUSINESSES_AND_SALES_SUCCESS = "GET_ACTIVE_BUSINESSES_AND_SALES_SUCCESS"
export const GET_SUGGESTED_NEARBY_LOCATIONS_REQUEST = "GET_SUGGESTED_NEARBY_LOCATIONS_REQUEST";
export const GET_SUGGESTED_NEARBY_LOCATIONS_SUCCESS = "GET_SUGGESTED_NEARBY_LOCATIONS_SUCCESS";
export const SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_REQUEST = "SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_REQUEST";
export const GET_SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_SUCCESS = "GET_SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_SUCCESS";
export const GET_ALL_ONBOARD_BUSINESS_PLAN_REQUEST = "GET_ALL_ONBOARD_BUSINESS_PLAN_REQUEST";
export const GET_ALL_BUSINESS_PLAN_ONBOARD_SUCCESS = "GET_ALL_BUSINESS_PLAN_ONBOARD_SUCCESS";
export const GET_BUSINESS_PLAN_ONBOARD_REQUEST = "GET_BUSINESS_PLAN_ONBOARD_REQUEST";
export const GET_BUSINESS_PLAN_ONBOARD_SUCCESS = "GET_BUSINESS_PLAN_ONBOARD_SUCCESS";
export const SET_BUSINESS_PLAN_ONBOARD_EMPTY = "SET_BUSINESS_PLAN_ONBOARD_EMPTY"
export const UPDATE_ACCOUNT_MENU = "UPDATE_ACCOUNT_MENU";
export const RESET_WHITE_LABEL_INFO_REQUEST = "RESET_WHITE_LABEL_INFO_REQUEST";
export const SET_WHITE_LABEL_FORM_SUBMIT_MSGS = "SET_WHITE_LABEL_FORM_SUBMIT_MSGS";
export const UPLOAD_AND_GET_TEMP_BUSS_PLAN_FILE_URL = "UPLOAD_AND_GET_TEMP_BUSS_PLAN_FILE_URL";
export const SET_BUSINESS_PLAN_MENU_DATA = "SET_BUSINESS_PLAN_MENU_DATA";
export const SET_BUSINESS_PLAN_UPLOADED_TEMP_FILES = "SET_BUSINESS_PLAN_UPLOADED_TEMP_FILES";
export const GET_BUSINESS_PLAN_COMPANY_INSIGHTS_DATA = "GET_BUSINESS_PLAN_COMPANY_INSIGHTS_DATA";
export const SET_BUSINESS_PLAN_COLOUR_SCHEME = "SET_BUSINESS_PLAN_COLOUR_SCHEME";
export const GET_BUSINESS_PLAN_PAGE_PREVIEW = "GET_BUSINESS_PLAN_PAGE_PREVIEW";
export const SET_BUSINESS_PLAN_PAGE_PREVIEW_HTML = "SET_BUSINESS_PLAN_PAGE_PREVIEW_HTML";
export const GET_BUSINESS_PLAN_INDUSTRY_INSIGHTS = "GET_BUSINESS_PLAN_INDUSTRY_INSIGHTS";
export const SET_BUSINESS_PLAN_INDUSTRY_INSIGHTS = "SET_BUSINESS_PLAN_INDUSTRY_INSIGHTS";
export const SET_BUSINESS_PLAN_PREVIEW_PAGE_LOADING = "SET_BUSINESS_PLAN_PREVIEW_PAGE_LOADING";
export const GET_ALL_KEY_PEOPLE_API_REQUEST = "GET_ALL_KEY_PEOPLE_API_REQUEST";
export const SET_ALL_KEY_PEOPLE_SUCCESS = "SET_ALL_KEY_PEOPLE_SUCCESS";
export const UPDATE_BUSINESS_PLANS_CREDIT_SUCCESS = "UPDATE_BUSINESS_PLANS_CREDIT_SUCCESS";
export const UPDATE_BUSINESS_PLANS_CREDIT_REQUEST = "UPDATE_BUSINESS_PLANS_CREDIT_REQUEST";
export const GET_BUSINESS_PLAN_DESCRIPTION_OPENAI = "GET_BUSINESS_PLAN_DESCRIPTION_OPENAI";
export const GET_BUSINESS_PLAN_DESCRIPTION_OPENAI_SUCCESS = "GET_BUSINESS_PLAN_DESCRIPTION_OPENAI_SUCCESS";
export const UPDATE_BUSINESS_PLANS_FOR_CREDITS = "UPDATE_BUSINESS_PLANS_FOR_CREDITS";

// competitive analysis action constants
export const ADD_COMPANY_TO_COMPETITIVE_ANALYSIS = "ADD_COMPANY_TO_COMPETITIVE_ANALYSIS";
export const GET_COMPETITOR_ANALYSIS_DATA = "GET_COMPETITOR_ANALYSIS_DATA";
export const SET_CURRENT_SELECTED_COMPETITOR = "SET_CURRENT_SELECTED_COMPETITOR";
export const REMOVE_COMPANY_FROM_COMPETITIVE_ANALYSIS = "REMOVE_COMPANY_FROM_COMPETITIVE_ANALYSIS";
export const UPDATE_SELECTED_COMPETITOR = "UPDATE_SELECTED_COMPETITOR";
export const DOWNLOAD_COMPETITOR_ANALYSIS_DATA = "DOWNLOAD_COMPETITOR_ANALYSIS_DATA";
export const GET_SELECTED_COMPETITORS_ANALYSIS_DATA = "GET_SELECTED_COMPETITORS_ANALYSIS_DATA";
export const SET_SELECTED_COMPETITORS = "SET_SELECTED_COMPETITORS";
export const ADD_SELECTED_COMPETITOR = "ADD_SELECTED_COMPETITOR";
export const REMOVE_SELECTED_COMPETITOR = "REMOVE_SELECTED_COMPETITOR"
export const GET_INDUSTRY_SIC_CODES_REQUEST = "GET_INDUSTRY_SIC_CODES_REQUEST";
export const GET_INDUSTRY_SIC_CODES_RESPONSE = "GET_INDUSTRY_SIC_CODES_RESPONSE";
export const GET_INDUSTRY_SIC_CODE_COMPANY_LIST_REQUEST = "GET_INDUSTRY_SIC_CODE_COMPANY_LIST_REQUEST";
export const GET_INDUSTRY_SIC_CODE_COMPANY_LIST_RESPONSE = "GET_INDUSTRY_SIC_CODE_COMPANY_LIST_RESPONSE";
export const RESET_COMPANY_LIST = "RESET_COMPANY_LIST";
export const SET_SELECTED_COMPETITORS_UPDATE_SUCCESS = "SET_SELECTED_COMPETITORS_UPDATE_SUCCESS";

// help centre action constants 
export const REMOVE_FORM_DATA_REQUEST = "REMOVE_FORM_DATA_REQUEST";
export const REMOVE_FORM_DATA_SUCCESS = "REMOVE_FORM_DATA_SUCCESS";
export const REMOVE_FORM_DATA_FAILED = "REMOVE_FORM_DATA_FAILED";

// common enquire modal
export const SET_ENQUIRE_MODAL_TOGGLE_STATUS = "SET_ENQUIRE_MODAL_TOGGLE_STATUS"

//watchList action constants
export const CREATE_WATCHLIST_REQUEST = "CREATE_WATCHLIST_REQUEST";
export const GET_UPDATED_WATCHLIST_AFTER_SUCCESS = "GET_UPDATED_WATCHLIST_AFTER_SUCCESS";
export const CREATE_WATCHLIST_FAILED = "CREATE_WATCHLIST_FAILED";
export const UPDATE_WATCHLIST_REQUEST = "UPDATE_WATCHLIST_REQUEST";
export const UPDATE_WATCHLIST_FAILED = "UPDATE_WATCHLIST_FAILED";
export const DELETE_WATCHLIST_REQUEST = "DELETE_WATCHLIST_REQUEST";
export const DELETE_WATCHLIST_FAILED = "DELETE_WATCHLIST_FAILED";
export const GET_WATCHLIST_REQUEST = "GET_WATCHLIST_REQUEST";
export const GET_WATCHLIST_FAILED = "GET_WATCHLIST_FAILED";

export const ADD_COMPANY_TO_WATCHLIST_REQUEST = "ADD_COMPANY_TO_WATCHLIST_REQUEST";
export const ADD_COMPANY_TO_WATCHLIST_FAILED = "ADD_COMPANY_TO_WATCHLIST_FAILED";
export const REMOVE_COMPANY_FROM_WATCHLIST_REQUEST = "REMOVE_COMPANY_FROM_WATCHLIST_REQUEST";
export const REMOVE_COMPANY_FROM_WATCHLIST_SUCCESS= "REMOVE_COMPANY_FROM_WATCHLIST_SUCCESS";
export const REMOVE_COMPANY_FROM_WATCHLIST_FAILED = "REMOVE_COMPANY_FROM_WATCHLIST_FAILED";

export const TOGGLE_CREATE_WATCHLIST_MODAL = 'TOGGLE_CREATE_WATCHLIST_MODAL';
export const TOGGLE_ADD_TO_WATCHLIST_MODAL = 'TOGGLE_ADD_TO_WATCHLIST_MODAL';
export const COMPANY_WATCHLIST_INFO_REQUEST = 'COMPANY_WATCHLIST_INFO_REQUEST';
export const COMPANY_WATCHLIST_INFO_SUCCESS = 'COMPANY_WATCHLIST_INFO_SUCCESS';
export const UPLOAD_CSV_TO_WATCHLIST_REQUEST = "UPLOAD_CSV_TO_WATCHLIST_REQUEST";
export const UPLOAD_CSV_TO_WATCHLIST_SUCCESS = "UPLOAD_CSV_TO_WATCHLIST_SUCCESS";
export const UPLOAD_CSV_TO_WATCHLIST_FAILED = "UPLOAD_CSV_TO_WATCHLIST_FAILED";
export const TOGGLE_ADD_EDIT_CP_WATCHLIST_MODAL = 'TOGGLE_ADD_EDIT_CP_WATCHLIST_MODAL'
export const TOGGLE_UPLOAD_CSV_TO_WATCHLIST_MODAL = "TOGGLE_UPLOAD_CSV_TO_WATCHLIST_MODAL";
export const GET_COMPANY_HOUSE_SH01_DATA = "GET_COMPANY_HOUSE_SH01_DATA"
export const COMPANY_HOUSE_SH01_DATA_SUCCESS = "COMPANY_HOUSE_SH01_DATA_SUCCESS"

//contact details profile   
export const TOGGLE_CONTACT_DETAILS_MODAL = "TOGGLE_CONTACT_DETAILS_MODAL";
export const GET_CONTACT_DETAILS_REQUEST = "GET_CONTACT_DETAILS_REQUEST";
export const SET_CONTACT_DETAILS_SUCCESS = "SET_CONTACT_DETAILS_SUCCESS";
export const GET_CONTACT_DETAILS_FAILED = "GET_CONTACT_DETAILS_FAILED";
export const GET_CONTACT_DETAILS_CUSTOM_SEARCH_REQUEST = "GET_CONTACT_DETAILS_CUSTOM_SEARCH_REQUEST";
export const SET_CONTACT_DETAILS_CUSTOM_SEARCH_SUCCESS = "SET_CONTACT_DETAILS_CUSTOM_SEARCH_SUCCESS";
export const GET_CONTACT_DETAILS_CUSTOM_SEARCH_FAILED = "GET_CONTACT_DETAILS_CUSTOM_SEARCH_FAILED";
export const REVEAL_CONTACT_DETAILS_REQUEST = "REVEAL_CONTACT_DETAILS_REQUEST"
export const REVEAL_CONTACT_DETAILS_SUCCESS = "REVEAL_CONTACT_DETAILS_SUCCESS"
export const GET_REVEALED_CONTACT_DETAILS_REQUEST = "GET_REVEALED_CONTACT_DETAILS_REQUEST"
export const GET_REVEALED_CONTACT_DETAILS_FAILED = "GET_REVEALED_CONTACT_DETAILS_FAILED"
export const SET_REVEALED_CONTACT_DETAILS_SUCCESS = "SET_REVEALED_CONTACT_DETAILS_SUCCESS";
export const SET_CONTACT_DETAILS_LOADING = "SET_CONTACT_DETAILS_LOADING";




