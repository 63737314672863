import { 
    SET_PRODUCT_PAGE_SCREEN_SUCCESS, TOGGLE_SINGLE_PRODUCT_MODAL
} from "../actions/actionConstants";
import products from '../../components/ProductPage/Products';

const INITIAL_STATE = {
    productScreen : {},
}



const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PRODUCT_PAGE_SCREEN_SUCCESS:
            return { ...state, productScreen: action.payload.productObj };
        case TOGGLE_SINGLE_PRODUCT_MODAL:
            return { ...state, singleProductModalDataObj: { 
                ...action.payload.modalDataObj, 
                product: { 
                    ...products[action.payload.modalDataObj.productName],
                    smallDescription: products[action.payload.modalDataObj.productName] && 
                        products[action.payload.modalDataObj.productName].getSmallDescription && 
                            products[action.payload.modalDataObj.productName].getSmallDescription(action.payload.modalDataObj.companyName ? 
                                action.payload.modalDataObj.companyName : "")
                }} 
            };
        default:
            return state;
    }
}

export default productReducer;