import { RESTAPI_URL } from '../common/config';
import { commonFetch } from './fetchComImpl';
import { refreshTokenApi } from './refreshTokenApi';

export const getBlogListApi = async (from, size) => {
    try {
        let url = '';
        if (from !== undefined && size !== undefined) {
            url = `${RESTAPI_URL}/blog/getBlogList/${from}/${size}`
        } else {
            url = `${RESTAPI_URL}/blog/getBlogList`
        }
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(url,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getBlogDetailApi = async (_id) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/blog/getBlogDetail/${_id}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}