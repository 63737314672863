import { call, takeLatest, put, all } from "redux-saga/effects";
import { getLocationProfileApiCall, getLocationProfileSearchApiCall } from "../api/locationProfileApi";
import { ET_LOCAITON_PROFILE_SEARCH_FAILED, ET_LOCAITON_PROFILE_SEARCH_SUCCESS } from "../common/trackingConstants";
import { GET_LOCATION_PROFILE_REQUEST, GET_LOCATION_PROFILE_SEARCH_REQUEST } from "../redux/actions/actionConstants";
import { sendActivityTracking } from "../redux/actions/commonAction";
import { getLocationProfileFailed, getLocationProfileSearchFailed, getLocationProfileSearchSuccess, getLocationProfileSuccess } from "../redux/actions/locationProfileAction";

function* getLocationProfileSaga(action){
    try {
        const locationProfileRes = yield call(getLocationProfileApiCall, action.payload.locationId);
        if(!locationProfileRes.error){
            yield put(getLocationProfileSuccess(locationProfileRes.locationProfile));
        } else {
            yield put(getLocationProfileFailed(locationProfileRes.message));
        }
    } catch (error) {
        console.error('inside getLocationProfileSaga ',error);
    }
}

export function* watchGetLocationProfileSaga(){
    yield takeLatest(GET_LOCATION_PROFILE_REQUEST,getLocationProfileSaga);
}

function* getLocationProfileSearchSaga(action) {
    try {
        const { searchText = "", eventPage = "" } = action.payload;
        if (searchText === "") {
            yield put(getLocationProfileSearchSuccess([]))
        } else {
            const response = yield call(getLocationProfileSearchApiCall, searchText);
            if (!response.error) {
                yield all([
                    put(getLocationProfileSearchSuccess(response.searchResults)),
                    put(sendActivityTracking({
                    eventPage,
                    eventType: ET_LOCAITON_PROFILE_SEARCH_SUCCESS,
                    attribute1: searchText,
                    attribute2: window.location.pathname
                }))]);
            } else {
                yield all([
                    put(getLocationProfileSearchFailed(response.message)),
                    put(sendActivityTracking({
                    eventPage,
                    eventType: ET_LOCAITON_PROFILE_SEARCH_FAILED,
                    attribute1: searchText,
                    attribute2: window.location.pathname
                }))]);
            }
        }
    } catch (error) {
        console.log('inside getLocationProfileSearchSaga ', error);
    }
}

export function* watchGetLocationProfileSearchSaga(){
    yield takeLatest(GET_LOCATION_PROFILE_SEARCH_REQUEST, getLocationProfileSearchSaga);
}