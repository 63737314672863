import React, { memo } from 'react';
import './ContactDetailsLoader.css';
import { useSelector } from 'react-redux';

export const ContactDetailsLoader = memo(function ContactDetailsLoader(props) {
  const headerProps = useSelector(state => state.common?.headerProps) || {}
  const { 
    show = true
  } = headerProps
  const {showBulkContactLoaderParams={}}=props
  const {
    percentage,
    bulkExportName = "",
    toggleModal,
    bulkContactExportStatus ="created",
  } = showBulkContactLoaderParams

  
  const roundPercentage = Math.round(Number(percentage)) || 1;
  const openModalCompleted = bulkContactExportStatus === "completed"
  const exportStatusText = bulkContactExportStatus === "processing" ? "in progress" : bulkContactExportStatus === "completed" ? "ready for review" : bulkContactExportStatus;

  return (
    <div className={`bulk-contact-details-loader ${show ? "" : "noHeaderNavbar" } ${roundPercentage > 0 ? "filled" : ""}`} style={{ "--percentage": `${roundPercentage}%` }} >
      <div className='loading-bar-info d-flex cursor-pointer' onClick={openModalCompleted ? toggleModal : null}>
      {roundPercentage === 100 ? "" : <img src="/assets/images/spinner_black.gif" className="mr-2" alt="spinner" width="15" height="15" />}
        {`${bulkExportName} Contact Bulk Export is ${exportStatusText} (${roundPercentage || 0 }%)`}
      </div>
    </div>
  )
})
