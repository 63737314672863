import { RESTAPI_URL } from '../common/config';
import { commonFetch } from './fetchComImpl';
import { refreshTokenApi } from './refreshTokenApi';
// import XLSX from 'xlsx';

import loadable from '@loadable/component'
const XLSXlib = loadable.lib(() => import('xlsx'))


import financialsFieldsAndDataKeyMapping from './financialFieldsAndDataKeyMapping.json';

export const getCompanyProfileApiCall = async (companyId, companyName) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/getCompanyProfile/${companyId}/${companyName}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getCompanyHouseFilingsApiCall = async (companyObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/companyHouseFillings/getCompanyHouseFilings/${companyObj.companyId}/${companyObj.category}/${companyObj.start_index}/${companyObj.items_per_page}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getCompanyHouseDocumentApiCall = async (docId) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/companyHouseFillings/getFilingPdfFileUrl/${docId}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getDirectorStatusList = async (companyId) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/getDirectorStatusList/${companyId}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getDirectorShowMoreAPI = async ({ companyId, status, from, size, order }) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/getShowMoreDirector/${companyId}/${status}/${from}/${size}/${order.field}/${order.value}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}


export const getShareholderTypeListApi = async (companyId) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/getShareholderTypeList/${companyId}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getShareholderShowMoreAPI = async ({ companyId, type, from, size }) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/getShowMoreShareholder/${companyId}/${type}/${from}/${size}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getIndustryMultipleApiCall = async ({ companyId, valuationType }) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/getIndustryMultiple/${companyId}/${valuationType}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {

    }
}
export const callCreditReportApi = async ({ companyId, userId, planSelected, companyName }) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/creditReport/getCreditReport/${companyId}/${userId}/${planSelected}/${companyName}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {

    }
}

export const checkPdfCreationApiCall = async (cohId) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/creditReport/checkCreditReportGenerated/${cohId}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.error('inside checkPdfCreationApiCall ', error);
        throw error;
    }
}

export const submitInterestApiCall = async (userContactDetails) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/submitInterest`,
            'POST',
            userContactDetails,
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.error('inside submitInterestApiCall ', error);
        throw error;
    }
}

export const saveIndustryMixChangesAPI = async (industryMixObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/saveIndustryMix`,
            'POST',
            industryMixObj,
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.error('inside saveIndustryMixChangesAPI ', error);
        throw error;
    }
}

export const resetIndustryMixChangesAPI = async ({ companyId, name, type, resetType }) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
        'POST',
        {},
        new Headers({
            'content-type': 'application/json'
        })
    );
        const response = await commonFetch(`${RESTAPI_URL}/company/resetIndustryMixEdit`,
            'POST',
            { companyId, name, type, resetType },
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.error('inside resetIndustryMixChangesAPI ', error);
        throw error;
    }
}

export const downloadFinancialsExcel = async (allFinancialData, companyId, companyName) => {
    if (allFinancialData) {
        let dataKeyArr = [
            { dataKey: "profitLoss", sheetName: "P&L" },
            { dataKey: "balanceSheet", sheetName: "Balance Sheet" },
            { dataKey: "cashFlow", sheetName: "Cash Flow" }
        ];
        const XLSX = await XLSXlib.load()
        let new_workbook = XLSX.utils.book_new();
        let headerArray = allFinancialData.profitLoss.turnoverData.reduce((prevObj, currObj) =>
            prevObj.concat(currObj.year), [companyName]);
        dataKeyArr.forEach(dataKeyObj => {
            let worksheetData = [headerArray];
            makeFinancialJsonForExcel(financialsFieldsAndDataKeyMapping[dataKeyObj.dataKey],
                allFinancialData[dataKeyObj.dataKey], worksheetData);
            let worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            XLSX.utils.book_append_sheet(new_workbook, worksheet, dataKeyObj.sheetName);
        });
        XLSX.writeFile(new_workbook, `${companyId}Financials.xlsx`);
    }
}

const makeFinancialJsonForExcel = (mapping, financialData, worksheetData) => {
    Object.keys(mapping).forEach(key => {
        let rowDataArr = [mapping[key]];
        financialData[key] && financialData[key].forEach(obj => {
            rowDataArr.push(obj.value);
        });
        worksheetData.push(rowDataArr);
    });
}

export const getCompanyChargesApi = async (chargesParam) => {
    try {
        const {
            companyId = "",
            count = 10
        } = chargesParam
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/getCompanyCharges/${companyId}/${count}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getCompanyValuationApi = async (valuationObject) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/valuationOnLoad`,
            'POST',
            valuationObject,
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}
export const updateValuationReportCreditApi = async (paramsObj) => {
    try {
        const {
            companyId='',
            companyName=''
        }=paramsObj
        const response = await commonFetch(`${RESTAPI_URL}/user/updateUserCredits/${companyId}/${companyName}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const setCompanyValuationDataApi = async (userValuationParams) => {
    try {
        const {
            companyId = "",
            companyName = "",
            valuationType = "",
            editedValue = ""
        } = userValuationParams
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/valuationCalculatorEditValue/${companyId}/${valuationType}/${editedValue}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getCompanyPscListApiCall = async (companyId) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/getCompanyPscList/${companyId}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log("error inside getCompanyPscListApiCall", error)
        throw error
    }
}

export const getCompanyHouseSH01ApiCall = async (companyId, companyName) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/companyHouseSH01DataReq`,
            'POST',
            {companyId, companyName},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log('error inside getCompanyHouseSH01ApiCall', error)
    }
}

export const getContactDetailsApiCall = async (contactDetailsParams) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/getCompanyContactDetails`,
            'POST',
            contactDetailsParams,
            new Headers({
                'content-type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside getContactDetailsApiCall', error)
        throw error
    }
}

export const getContactDetailsBulkSearchApiCall = async (contactBulkSearchParams) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/bulkContact/getBulkContactSearch`,
            'POST',
            contactBulkSearchParams,
            new Headers({
                'content-type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside getContactDetailsBulkSearchApiCall', error)
        throw error
    }
}
export const getContactDetailsBulkSearchResultDataApiCall = async (bulkSearchResultParams) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/getCompanyContactDetails`,
            'POST',
            bulkSearchResultParams,
            new Headers({
                'content-type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside getContactDetailsBulkSearchResultDataApiCall', error)
        throw error
    }
}
export const getContactDetailsBulkRevealResultDataApiCall = async (contactBulkRevealParams) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/bulkContact/getBulkContactRevealData`,
            'POST',
            contactBulkRevealParams,
            new Headers({
                'content-type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside getContactDetailsBulkSearchResultDataApiCall', error)
        throw error
    }
}

export const getContactDetailsBulkStatusCheckApiCall = async (contactBulkStatusCheckParams) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/bulkContact/getBulkContactStatusCheck`,
            'POST',
            contactBulkStatusCheckParams,
            new Headers({
                'content-type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside getContactDetailsBulkStatusCheckApiCall', error)
        throw error
    }
}

export const deleteSpecificBulkSearchResultApiCall = async (bulkSearchResultParams) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/bulkContact/deleteSpecificBulkContactRow`,
            'POST',
            bulkSearchResultParams,
            new Headers({
                'content-type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside deleteSpecificBulkSearchResultApiCall', error)
        throw error
    }
}
export const revealContactDetailsApiCall = async (obj) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/revealContactDetailsById`,
            'POST',
            obj,
            new Headers({
                'content-type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside getContactDetailsApiCall', error)
        throw error
    }
}

export const getRevealedContactDetailsApiCall = async (obj) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/company/getRevealedContactDetails`,
            'POST',
            obj,
            new Headers({
                'content-type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside getContactDetailsApiCall', error)
        throw error
    }
}

export const getBulkExportDownloadCsvApiCall = async (exportObj) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/bulkContact/getBulkExportDownloadCsv`,
            'POST',
            exportObj,
            new Headers({
                'content-type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside getBulkExportDownloadCsvApiCall', error)
        throw error
    }
}