import {
    COLLAPSIBLE_MENU_TOGGLE, TOGGLE_MENU_MODAL, UPDATE_ACCOUNT_MENU
} from '../actions/actionConstants';

import {
    ET_PROFILE_TAB_CLICK,
    ET_SUBSCRIPTION_TAB_CLICK,
    ET_ORDER_HISTORY_TAB_CLICK,
    ET_SIGNOUT_TAB_CLICK,
    ET_VALUATION_REPORTS_TAB_CLICK,
    ET_BUSINESS_PLANS_TAB_CLICK,
    ET_UPLOAD_WATCHLIST_CSV_TAB_CLICK,
    ET_WHITE_LABEL_TAB_CLICK,
    ET_CREDIT_TAB_CLICK,
    ET_ADDRESS_BOOK_TAB_CLICK
} from '../../common/trackingConstants';

const companyMenuItems = [
    { "name": "Company Information", "sectionId": "companyInformation" },
    { "name": "Valuation", "sectionId": "valuation" },
    { "name": "Health Check", "sectionId": "healthCheck", "isNew": false },
    { "name": "Financials", "sectionId": "financials" },
    { "name": "Credit Report", "sectionId": "creditReport" },
    { "name": "Ownership", "sectionId": "ownership" },
    // { "name": "Competitor Analysis", "sectionId": "competitor-analysis"},
    { "name": "Directors", "sectionId": "directors" },
    { "name": "Company Details", "sectionId": "companyDetails" },
    { "name": "Companies House Filings", "sectionId": "companiesHouse" }
]

const accountMenuItems = [
    { name: "Profile", link: "profile", trackingConstant: ET_PROFILE_TAB_CLICK },
    { name: "Subscription", link: "subscription", trackingConstant: ET_SUBSCRIPTION_TAB_CLICK },
    { name: "Order History", link: "order-history", trackingConstant: ET_ORDER_HISTORY_TAB_CLICK },
    { name: "Credits", link: "credits", trackingConstant: ET_CREDIT_TAB_CLICK },
    { name: "Valuation Reports", link: "valuation-reports", trackingConstant: ET_VALUATION_REPORTS_TAB_CLICK },
    { name: "White Label", link: "whitelabel", trackingConstant: ET_WHITE_LABEL_TAB_CLICK },
    { name: "Business Plans", link: "business-plans", trackingConstant: ET_BUSINESS_PLANS_TAB_CLICK },
    { name: "Address Book", link: "address-book", trackingConstant: ET_ADDRESS_BOOK_TAB_CLICK},
    { name: "Upload Watchlist CSV", link: "#", trackingConstant: ET_UPLOAD_WATCHLIST_CSV_TAB_CLICK },
    { name: "Sign Out", link: "#", trackingConstant: ET_SIGNOUT_TAB_CLICK },
]

const pomandaApiServiceMenuItems = [
    { name: "Introduction", link: "introduction" },
    { name: "Authentication", link: "authentication" },
    { name: "Subscription", link: "subscription" },
    { name: "Format & Pagination", link: "format-&-pagination" },
    { name: "Search", link: "search" },
    { name: "Company", link: "company" },
    { name: "Director", link: "director" }
]

const INITIAL_STATE = {
    companyMenuItems, accountMenuItems, isMenuModalOpen: false,pomandaApiServiceMenuItems,
    dataForShareModal: {}
}

const menuReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case COLLAPSIBLE_MENU_TOGGLE:
            return { ...state, toggleCollapseMenuStatus: action.payload };
        case TOGGLE_MENU_MODAL:
            return { ...state, isMenuModalOpen: action.payload.toggleStatus };
        case UPDATE_ACCOUNT_MENU:
            return { ...state, accountMenuItems: action.payload.accountMenuItems };
        default:
            return state;
    }
}

export default menuReducer;
