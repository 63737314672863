import { call, put, takeLatest, all } from "redux-saga/effects";
import { getReportApiCall, getCompanyReportUrlApiCall } from '../api/reportApi';
import { GET_PDF_REPORT } from "../redux/actions/actionConstants";
import localStorageApi from '../api/localStorageApi';
import { toggleCRDownloadLoadingModal } from "../redux/actions/companyAction";
import { CP_SUCCESS_PAYMENT_RESPONSE_MODAL, DOWNLOAD_COMPANY_REPORT_ERROR_MSG, DOWNLOAD_REPORT, DOWNLOAD_REPORT_ERROR, DOWNLOAD_REPORT_ERROR_MSG, DOWNLOAD_REPORT_MSG } from "../common/subscriptionConfigConst";
import { updateUserInfo } from "../redux/actions/userAccountAction";
import { GENERATE_COMPANY_REPORT, GET_COMPANY_REPORT_URL } from "../common/trackingConstants";

function* getPdfSaga(action){
    try {
        let infoPdfResp = yield call(getReportApiCall, action.payload.pdfReportObj.type, 
            action.payload.pdfReportObj.id, action.payload.pdfReportObj.userId);
        if(!infoPdfResp.error){
            if(infoPdfResp.isValidRequest){
                const renderedUrl = `${window.location.origin}/${infoPdfResp.pdfPath}`;
                let newWindow = window.open(renderedUrl, "_self");
                let deviceType = localStorageApi.getUserData()?.deviceType;
                if (deviceType === "Mobile") {
                    setTimeout(() => {
                        newWindow.close();
                    }, 5000);
                }
            } else {
                window.open("/", "_self");
            }
        } else {
            window.open("/", "_self");
        }
    } catch (error) {
        console.error(error);
    }
}
export function* watchGetReportPdf() {
    yield takeLatest(GET_PDF_REPORT, getPdfSaga);
}

function* generateCompanyReport(action) {
    try {
        const {
            reportType = "",
            userId = "",
            companyId = ""
        } = action.payload.reportObj;
        yield put(toggleCRDownloadLoadingModal({
            loaderImg: true,
            heading: DOWNLOAD_REPORT,
            subHeading: DOWNLOAD_REPORT_MSG,
            toggleStatus: true,
            showContinueBtn: true
        }))
        const savedCRMappingObj = yield call(getReportApiCall, reportType, companyId, userId);
        if (!savedCRMappingObj.error) {
            yield all([
                put(updateUserInfo(savedCRMappingObj.updatedUserObj)),
                put(toggleCRDownloadLoadingModal({
                    loaderImg: false,
                    heading: DOWNLOAD_REPORT,
                    subHeading: CP_SUCCESS_PAYMENT_RESPONSE_MODAL,
                    toggleStatus: true,
                    showContinueBtn: false,
                    userReportMappingId: savedCRMappingObj.userReportMappingId
                }))
            ])
        } else {
            yield put(toggleCRDownloadLoadingModal({
                loaderImg: false,
                heading: DOWNLOAD_REPORT_ERROR,
                subHeading: DOWNLOAD_COMPANY_REPORT_ERROR_MSG,
                toggleStatus: true,
                showContinueBtn: false
            }))
        }
    } catch (error) {
        console.log('error in calling generate company report saga', error)
        yield put(toggleCRDownloadLoadingModal({
            loaderImg: false,
            heading: DOWNLOAD_REPORT_ERROR,
            subHeading: DOWNLOAD_COMPANY_REPORT_ERROR_MSG,
            toggleStatus: true,
            showContinueBtn: false
        }))
    }
}

export function* watchGenerateCompanyReport() {
    yield takeLatest(GENERATE_COMPANY_REPORT, generateCompanyReport)
}

function* getCompanyReportUrl(action) {
    try {
        const docId = action.payload.docId;
        const resp = yield getCompanyReportUrlApiCall(docId);
        if(!resp.error) {
            return window.open(resp.companyReportUrl, "_self")
        }
        return window.open("/", "_self");
    } catch (error) {
        console.log('error in calling getCompanyReportUrl', error);
        window.open("/", "_self");
    }
}


export function* watchGetCompanyReportUrl() {
    yield takeLatest(GET_COMPANY_REPORT_URL, getCompanyReportUrl)
}