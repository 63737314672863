import React, { memo } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import "./FilterRestrictionModal.css";

export const FilterRestrictionModal = memo(function FilterRestrictionModal(props) {
    const {
        modalMessage = '',
        filterRestrictionBtnClick = () => { },
        toggle = () => { },
        modalInfo = {},
    } = props;
    const { showButton = true, btnLabel = ''} = modalInfo
    return (
        <Modal
            modalClassName="filter-restriction-modal"
            backdropClassName="filter-restriction-modal-backdrop"
            isOpen={true}
            fade={false}
            keyboard={true}
            backdrop={true} toggle={toggle}
        >
            <ModalBody className="cursor-default">
                <span className="filter-restriction-modal-close-btn" onClick={() => toggle(false)}>
                    <i className="fa fa-times " pomandae2e="close"></i>
                </span>
                <p className='filter-restriction-info'>{modalInfo?.heading || modalMessage}</p>
                {showButton && <button type="button"
                    className="filter-restriction-mod-btn cursor-pointer" onClick={filterRestrictionBtnClick} pomandae2e={btnLabel}>
                    {btnLabel || 'Learn More'}
                </button>}
            </ModalBody>
        </Modal>
    )
})