import {
    SET_PRODUCT_PAGE_SCREEN, SET_PRODUCT_PAGE_SCREEN_SUCCESS, 
    TOGGLE_SINGLE_PRODUCT_MODAL, HANDLE_PRODUCT_PLAN_BUTTON_CLICK 
} from "./actionConstants";

export const setProductPageScreen = screenObj => ({
    type: SET_PRODUCT_PAGE_SCREEN,
    payload: { screenObj }
});

export const setProductPageScreenSuccess = productObj => ({
    type: SET_PRODUCT_PAGE_SCREEN_SUCCESS,
    payload: { productObj }
});

export const toggleSingleProductModal = (modalDataObj) => ({
    type: TOGGLE_SINGLE_PRODUCT_MODAL,
    payload: { modalDataObj }
})

export const handleProductPlanButtonClick = (productObj,eventPage="") => ({
    type: HANDLE_PRODUCT_PLAN_BUTTON_CLICK,
    payload: { productObj,eventPage }
});
