import { 
    GET_POPULAR_COMPANIES_REQUEST, GET_POPULAR_COMPANIES_SUCCESS,
    APPPEND_POPULAR_COMPANIES_BY_SCROLL 
} from "./actionConstants";

export const getPopularCompaniesRequest = (alpha,pageNumber,size) => ({
    type: GET_POPULAR_COMPANIES_REQUEST,
    payload:{alpha,pageNumber,size}
})

export const getPopularCompaniesSuccess = (popularCompanies,totalRecords,activeAlpha,pageNumber,size) => ({
    type: GET_POPULAR_COMPANIES_SUCCESS,
    payload:{ popularCompanies,totalRecords,activeAlpha,pageNumber,size }
});

