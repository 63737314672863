import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
    callGetUserProfileApi,
    callSaveUserProfileApi,
    callChangePasswordApi,
    callContactUsApi,
    callgetCreditReportApi,
    callgetCreditReportPptApi,
    getSessionIdForAddNewCardApi, callMakeDefaultPaymentMethodApi,
    saveCompanyHouseEmailNotificationApi,
    getUserOrderHistoryApi,
    getValuationReportsListApi,
    getValuationReportPptRequestApi,
    getBusinessPlanReportsPptRequestApi,
    saveWhiteLabelInfoApi,
    getWhiteLabelInfoApi,
    resetWhiteLabelInfoApi,
    getBusinessPlansListApi,
    updateBusinessPlansCreditApi,
    regeneratePdfApi,
    regenerateCpPdfApi,
    submitInformationApi,
    getAddressBookListApi,
    addressBookCreateFileApi,
    addressBookDeleteFileApi,
    addressBookMoveFileApi,
    callDeleteUserPaymentMethodApi
} from '../api/userAccountApi';
import {
    getUserProfileSuccess,
    getUserProfileFailed,
    saveUserProfileFailed,
    toggleEditProfile,
    changePasswordFailed,
    changePasswordSuccess,
    clearChangePasswordErrorMessage,
    contactUsFailed,
    contactUsSuccess,
    callMakeDefaultPaymentMethodErrorAction,
    setDailySearchInfo,
    setOrderHistory,
    setValutionReportsList,
    updateValuationReportCreditSuccess,
    setVRWhiteLabelInfo,
    setWhiteLabelFormSubmitMsgs,
    setBusinessPlansList,
    updateBusinessPlansCreditSuccess,
    removeFormDataFailed,
    removeFormDataSuccess,
    setAddressBookListSuccess,
    getAddressBookListFailed,
    callDeletePaymentMethodSuccessAction,
    callDeletePaymentMethodErrorAction
} from '../redux/actions/userAccountAction';
import {
    GET_USER_PROFILE_REQUEST,
    SAVE_USER_PROFILE_REQUEST,
    CHANGE_PASSWORD_REQUEST,
    CONTACT_US_REQUEST,
    GET_CREDIT_REPORT,
    GET_CREDIT_REPORT_PPT_URL,
    CREATE_STRIPE_SESSION_ADD_CARD,
    CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA,
    SIGN_OUT_CLICK,
    COMPANY_HOUSE_EMAIL_NOTIFICATION,
    GET_ORDER_HISTORY,
    GET_VALUATION_REPORTS_LIST,
    GET_VALUATION_REPORTS_PPT_REQUEST,
    GET_BUSINESS_PLAN_REPORTS_PPT_REQUEST,
    UPDATE_VALUATION_REPORT_CREDIT_REQUEST,
    SAVE_VR_WHITE_LABEL_INFO_REQUEST,
    GET_VR_WHITE_LABEL_INFO_REQUEST,
    RESET_WHITE_LABEL_INFO_REQUEST,
    GET_BUSINESS_PLANS_LIST,
    UPDATE_BUSINESS_PLANS_CREDIT_REQUEST,
    GET_CREDIT_REPORT_REQUEST,
    GET_COMPANY_REPORT_REQUEST,
    REMOVE_FORM_DATA_REQUEST,
    GET_ADDRESS_BOOK_LIST_REQUEST,
    ADDRESS_BOOK_CREATE_FILE_REQUEST,
    ADDRESS_BOOK_DELETE_FILE_REQUEST,
    ADDRESS_BOOK_MOVE_FILE_REQUEST,
    CALL_DELETE_PAYMENT_METHOD_DATA
} from '../redux/actions/actionConstants';
import { reset, stopSubmit } from 'redux-form';
import { sendActivityTracking, setHideHeader } from '../redux/actions/commonAction';
import {
    EP_ACCOUNT,
    ET_CONTACT_US_SUBMIT_FAILED,
    ET_CONTACT_US_SUBMIT_SUCCESSFUL,
    ET_SAVE_PROFILE_FAILED,
    ET_SAVE_PROFILE_SUCCESSFUL,
    ET_CHANGE_PASSWORD_FAILED,
    ET_CHANGE_PASSWORD_SUCCESSFUL,
    ET_ADD_CARD_FAILED,
    ET_ADD_CARD_SUCCESSFUL,
    ET_MAKE_DEFAULT_PAYMENT_METHOD_FAILED,
    ET_MAKE_DEFAULT_PAYMENT_METHOD_SUCCESSFUL,
    ET_COMPANY_HOUSE_EMAIL_NOTIFICATION_SUCCESSFUL,
    ET_COMPANY_HOUSE_EMAIL_NOTIFICATION_FAILED,
    ET_VALUATION_CREDIT_UPDATE_SUCCESS,
    ET_VALUATION_CREDIT_UPDATE_FAILURE
} from '../common/trackingConstants';
import { createStripeSessionFailed, createStripeSessionSuccess } from '../redux/actions/subscriptionAction';
import localStorageApi from '../api/localStorageApi';
import { PROSPER_USER_TYPE, REACT_APP_PROSPER_SIGNOUT_REDIRCT_URL } from '../common/config';
import { getCompanyValutionDataFailed, setCompanyProfileValutionMappingObj, setContactDetailsSuccess } from '../redux/actions/companyAction';
import { updateValuationReportCreditApi } from '../api/companyApi';

function* getUserProfile(_action) {
    try {
        const userProfileResponse = yield call(callGetUserProfileApi);
        if (!userProfileResponse.error) {
            const {orderHistoryDocs, total: total1} = userProfileResponse?.userOrderHistory
            const {valuationReportMappingDocs, total: total2} = userProfileResponse?.valuationReports
            const {businessPlansMappingDocs, total: total3} = userProfileResponse?.businessPlans
            const orderHistoryArr = [...orderHistoryDocs, ...valuationReportMappingDocs, ...businessPlansMappingDocs]
            yield all([put(getUserProfileSuccess(userProfileResponse.userProfile,
                userProfileResponse.isPomandaUser,
                userProfileResponse.userOrderHistory = {orderHistoryDocs: sortReportsByPurchaseDate(orderHistoryArr), total: total1 + total2 + total3} ,
                userProfileResponse.userCardDetails,
                userProfileResponse.valuationReports)),
                put(setDailySearchInfo(false)),
                userProfileResponse?.userProfile?.userType === PROSPER_USER_TYPE && put(setHideHeader({ isPricingMenuShow: false }))
            ])
            } else {
            yield put(getUserProfileFailed(userProfileResponse.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getUserProfileFailed(error))
    }
}

export function* watchGetUserProfileSaga() {
    yield takeLatest(GET_USER_PROFILE_REQUEST, getUserProfile)
}

function* saveUserProfileSaga(action) {
    const {
        firstName = "",
        lastName = "",
        email = "",
        phoneNumber = "",
        companyDescription = ""
    } = action.payload.userInfo;
    try {
        const saveProfileRes = yield call(callSaveUserProfileApi, action.payload.userInfo);
        if (!saveProfileRes.error) {
            yield all([
                put(getUserProfileSuccess(saveProfileRes.userProfile)),
                put(toggleEditProfile(false))
            ]);
        } else {
            if (saveProfileRes.emailError) {
                yield put(stopSubmit('userProfileEditForm', {
                    email: saveProfileRes.message
                }))
            } else {
                yield put(saveUserProfileFailed(saveProfileRes.message));
            }
        }
        yield put(sendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: saveProfileRes.error ? ET_SAVE_PROFILE_FAILED : ET_SAVE_PROFILE_SUCCESSFUL,
            attribute1:`${firstName} ${lastName}`,
            attribute2:email,
            attribute3:phoneNumber,
            attribute4:companyDescription
        }))
    } catch (error) {
        console.log(error);
        yield put(saveUserProfileFailed(error))
        yield put(sendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: ET_SAVE_PROFILE_FAILED,
            attribute1:`${firstName} ${lastName}`,
            attribute2:email,
            attribute3:phoneNumber,
            attribute4:companyDescription
        }))
    }
}
export function* watchSaveUserProfileSaga() {
    yield takeLatest(SAVE_USER_PROFILE_REQUEST, saveUserProfileSaga)
}

function* changePasswordSaga(action) {
    try {
        yield put(clearChangePasswordErrorMessage());
        const changePasswordRes = yield call(callChangePasswordApi, action.payload.oldPassword, action.payload.newPassword);
        if (!changePasswordRes.error) {
            yield all([
                put(changePasswordSuccess(changePasswordRes.message)),
                put(reset('changePasswordForm'))
            ])
        } else {
            yield put(changePasswordFailed(changePasswordRes.message));
        }
        yield put(sendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: changePasswordRes.error ? ET_CHANGE_PASSWORD_FAILED : ET_CHANGE_PASSWORD_SUCCESSFUL,
        }))
    } catch (error) {
        console.log(error);
        yield put(changePasswordFailed(error))
        yield put(sendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: ET_CHANGE_PASSWORD_FAILED
        }))
    }
}

export function* watchChangePasswordSaga() {
    yield takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordSaga);
}

function* contactUsSaga(action) {
    const { values = {}, trackingInfo = {} } = action.payload.userInfo;
    try {
        const response = yield call(callContactUsApi, values);
        if (!response.error) {
            yield all([
                put(contactUsSuccess(response.message)),
                put(reset('contactUsForm'))
            ])
        } else {
            yield put(contactUsFailed(response.message))
        }
        yield put(sendActivityTracking({
            eventPage: trackingInfo.eventPage,
            eventType: response.error ? ET_CONTACT_US_SUBMIT_FAILED : ET_CONTACT_US_SUBMIT_SUCCESSFUL,
            attribute1: values.name,
            attribute2: values.email,
            attribute3: values.phoneNumber,
            attribute4: values.longMessage,
        }))
    } catch (error) {
        console.log(error);
        yield put(contactUsFailed(error.message))
        yield put(sendActivityTracking({
            eventPage: trackingInfo.eventPage,
            eventType: ET_CONTACT_US_SUBMIT_FAILED,
            attribute1: values.name,
            attribute2: values.email,
            attribute3: values.phoneNumber,
            attribute4: values.longMessage,
        }))
    }
}

export function* watchContactUsSaga() {
    yield takeLatest(CONTACT_US_REQUEST, contactUsSaga);
}

function* getCreditReportSaga(action) {
    try {
        const response = yield call(callgetCreditReportApi, action.payload.docId);
        if (!response.error) {
            const renderedUrl = `${window.location.origin}/${response.creditReportUrl}`;
            window.open(renderedUrl, "_self");
            // yield all([
            //     put(contactUsSuccess(response.message)),
            //     put(reset('contactUsForm'))
            // ])
        } else {
            // yield put(contactUsFailed(response.message))
        }
    } catch (error) {
        console.log(error);
        // yield put(contactUsFailed(error.message))
    }
}

function* getCreditReportPptSaga(action) {
    try {
        const data = {reportType: 'creditReport', docId: action.payload.docId}
        const response = yield call(callgetCreditReportPptApi, data);
        if (!response.error) {
            window.location.href = response.pptUrl
            window.open(window.location.href, '_blank');
        }
        setTimeout(function () {
            window.close();
        }, 1000);
    } catch (error) {
        console.log(error);
    }
}
export function* watchGetCreditReport() {
    yield takeLatest(GET_CREDIT_REPORT, getCreditReportSaga);
}

export function* watchGetCreditReportPpt() {
    yield takeLatest(GET_CREDIT_REPORT_PPT_URL, getCreditReportPptSaga);
}

function* callDeletePaymentMethodSaga(action) {
    try {
        const response = yield call(callDeleteUserPaymentMethodApi, action.payload);
        if (!response.error) {
            yield put(callDeletePaymentMethodSuccessAction(response.userCardDetails))
        } else {
            yield put(callDeletePaymentMethodErrorAction(response))
        }
    } catch (error) {
        console.log(error);
        yield put(callDeletePaymentMethodErrorAction(error))
    }
}

export function* watchDeletePaymentMethodApiSaga() {
    yield takeLatest(CALL_DELETE_PAYMENT_METHOD_DATA, callDeletePaymentMethodSaga);
}

function* callMakeDefaultPaymentMethodApiSaga(action) {
    try {
        const response = yield call(callMakeDefaultPaymentMethodApi, action.payload);
        if (!response.error) {
            yield put(getUserProfileSuccess(response.userDetails.userProfile,
                response.userDetails.isPomandaUser,
                response.userDetails.userOrderHistory,
                response.userDetails.userCardDetails))

        } else {
            yield put(callMakeDefaultPaymentMethodErrorAction())
        }
        yield put(sendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: response.error ? ET_MAKE_DEFAULT_PAYMENT_METHOD_FAILED : ET_MAKE_DEFAULT_PAYMENT_METHOD_SUCCESSFUL,
            // attribute1:`${firstName} ${lastName}`,
            // attribute2:email,
        }))
    } catch (error) {
        console.log(error);
        yield put(callMakeDefaultPaymentMethodErrorAction())
        yield put(sendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: ET_MAKE_DEFAULT_PAYMENT_METHOD_FAILED,
            // attribute1:`${firstName} ${lastName}`,
            // attribute2:email,
        }))
    }
}

export function* watchCallMakeDefaultPaymentMethodApiSaga() {
    yield takeLatest(CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA, callMakeDefaultPaymentMethodApiSaga);
}

export function* createAddCardSessionSaga(action) {
    try {
        action.payload = {
            redirectUrl: window.location.href
        }
        let response = yield call(getSessionIdForAddNewCardApi, action.payload);
        if (!response.error) {
            yield put(createStripeSessionSuccess(response.sessionIdObj));
        } else {
            yield put(createStripeSessionFailed(response.message));
        }
        yield put(sendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: response.error ? ET_ADD_CARD_FAILED : ET_ADD_CARD_SUCCESSFUL,
            // attribute1:`${firstName} ${lastName}`,
            // attribute2:email,
        }))
    } catch (error) {
        yield put(createStripeSessionFailed(error.message));
        yield put(sendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: ET_ADD_CARD_FAILED,
            // attribute1:`${firstName} ${lastName}`,
            // attribute2:email,
        }))
    }
}

export function* watchCreateAddCardSessionSaga() {
    yield takeLatest(CREATE_STRIPE_SESSION_ADD_CARD, createAddCardSessionSaga);
}

export function* signOutClickSaga(action){
    yield call(localStorageApi.removeUserData)
    const cookies = document.cookie;
    const cookiesArr = cookies.split(";")
    for (let i = 0; i < cookiesArr.length; ++i){
        const myCookieArr = cookiesArr[i].split("=");
        const name = myCookieArr[0].trim();
        yield call(localStorageApi.removeCookie,name);
    }
    yield put(sendActivityTracking({
        eventPage: EP_ACCOUNT,
        eventType: action.payload.data.eventType,
    }))
    if (sessionStorage.getItem("prosperUserStatus") === "true") {
        window.location.replace(REACT_APP_PROSPER_SIGNOUT_REDIRCT_URL)
    } else {
        window.location.assign("/")
    }
}
export function* watchSignOutClickSaga() {
    yield takeLatest(SIGN_OUT_CLICK, signOutClickSaga);
}

export function* saveCompanyHouseEmailNotification(action){
    try {
        const userInfo = yield call(saveCompanyHouseEmailNotificationApi, action.payload);
        if (!userInfo.error) {
            yield put(getUserProfileSuccess(userInfo.userProfile, userInfo.isPomandaUser, userInfo.userOrderHistory, userInfo.userCardDetails));
            yield put(sendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: ET_COMPANY_HOUSE_EMAIL_NOTIFICATION_SUCCESSFUL,
            attribute1: action.payload.flag?"true":"false"
            }))
        }  
    } catch (error) {
        console.log(error);
        yield put(sendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: ET_COMPANY_HOUSE_EMAIL_NOTIFICATION_FAILED,
            attribute1: action.payload.flag?"true":"false"
            }))
    }
}
export function* watchCompanyHouseEmailNotificationSaga() {
    yield takeLatest(COMPANY_HOUSE_EMAIL_NOTIFICATION, saveCompanyHouseEmailNotification);
}

function sortReportsByPurchaseDate(reports) {
    return reports.sort((a, b) => new Date(b?.purchaseDate) - new Date(a?.purchaseDate));
}

function* getOrderHistorySaga(action){
    try{
        let reportType = action.payload.filter?.reportType
        switch(reportType) {
            case 'creditReport' :
            case 'companyReport' :
            case 'contactDetails':
                let resp = yield call(getUserOrderHistoryApi, action.payload)
                if(!resp.error) {
                    yield put(setOrderHistory(resp.userOrderHistory)); 
                }
                break;
            case 'businessPlan':
                const response = yield call(getBusinessPlansListApi, action.payload);
                let { businessPlans } = response
                let { businessPlansMappingDocs: orderHistoryDocs, total } = businessPlans
                if (!response.error) {
                yield put(setOrderHistory({ orderHistoryDocs, total })); 
            }
                break
            case 'valuationReport': 
            const res = yield call(getValuationReportsListApi,action.payload);
            let { valuationReports } = res
            if(!res.error) {
                yield put(setOrderHistory({ orderHistoryDocs : valuationReports.valuationReportMappingDocs, total: valuationReports.total })); 
            }
                break;
            case 'all':
                const [orderHistoryResp, valuationReportsResp, businessPlanResp] = yield all([
                    call(getUserOrderHistoryApi, action.payload),
                    call(getValuationReportsListApi, action.payload),
                    call(getBusinessPlansListApi, action.payload)
                ]);
                if(!orderHistoryResp.error && !valuationReportsResp.error && !businessPlanResp.error){
                    const {orderHistoryDocs, total: total1} = orderHistoryResp.userOrderHistory
                    const {valuationReportMappingDocs, total: total2} = valuationReportsResp.valuationReports
                    const {businessPlansMappingDocs, total: total3} = businessPlanResp.businessPlans
                    const orderHistoryArr = [...orderHistoryDocs, ...valuationReportMappingDocs, ...businessPlansMappingDocs]
                    yield put(setOrderHistory({orderHistoryDocs: sortReportsByPurchaseDate(orderHistoryArr), total: total1 + total2 + total3}));
                }
                break;
        }
    }catch(error){
        console.error(error);
    }
}

export function* watchGetOrderHistorySaga(){
    yield takeLatest(GET_ORDER_HISTORY,getOrderHistorySaga);
}

function* getValuationReportsListSaga(action){
    try {
        const response = yield call(getValuationReportsListApi,action.payload);
        const reqFieldType = action.payload?.filter?.reqFieldType;
        if(!response.error){
            if(reqFieldType === "company_profile") {
                yield put(setCompanyProfileValutionMappingObj(response.valuationReports.valuationReportMappingDocs[0]))
            } else {
                yield put(setValutionReportsList(response.valuationReports))
            }
        }
    } catch (error) {
        console.error('inside getValuationReportsListSaga ',error);
    }
}

function* getValuationReportsPptRequestSaga(action){
    try {
        const data = {reportType:'valuationReport', pdfLink: action.payload.link}
        const response = yield call(getValuationReportPptRequestApi,data);
        if(!response.error){
            window.location.href = response.pptUrl
        }
        setTimeout(function () {
            window.close();
        }, 1000);
    } catch (error) {
        console.error('inside getValuationReportsListSaga ',error);
    }
}
function* getBusinessPlanReportsPptRequestSaga(action){
    try {
        const data = {reportType:'businessPlanReport', pdfLink: action.payload.link}
        const response = yield call(getBusinessPlanReportsPptRequestApi,data);
        if(!response.error){
            window.location.href = response.pptUrl
        }
        setTimeout(function () {
            window.close();
        }, 1000);
    } catch (error) {
        console.error('inside getValuationReportsListSaga ',error);
    }
}

export function* watchGetValuationReportsList(){
    yield takeLatest(GET_VALUATION_REPORTS_LIST,getValuationReportsListSaga);
}

export function* watchGetValuationReportsPptRequest(){
    yield takeLatest(GET_VALUATION_REPORTS_PPT_REQUEST,getValuationReportsPptRequestSaga);
}

export function* watchGetBusinessPlanReportsPptRequest(){
    yield takeLatest(GET_BUSINESS_PLAN_REPORTS_PPT_REQUEST,getBusinessPlanReportsPptRequestSaga);
}


function* getAddressBookListRequestSaga(action) {
    try {
        const response = yield call(getAddressBookListApi, action.payload.userId);
        if (!response.error) {
            yield put(setAddressBookListSuccess(response.result))
        } else {
            yield put(getAddressBookListFailed(response.message))
        }
    } catch (error) {
        console.error('inside getAddressBookListRequestSaga ', error);
    }
}

export function* watchGetAddressBookList(){
    yield takeLatest(GET_ADDRESS_BOOK_LIST_REQUEST,getAddressBookListRequestSaga);
}

function* addressBookCreateFileRequestSaga(action) {
    try {
        const response = yield call(addressBookCreateFileApi, action.payload.createParams);
        if (!response.error) {
            yield put(setAddressBookListSuccess(response.result))
        } else {
            yield put(getAddressBookListFailed(response.message))
        }
    } catch (error) {
        console.error('inside addressBookCreateFileSaga ', error);
    }
}

export function* watchAddressBookCreateFileSaga(){
    yield takeLatest(ADDRESS_BOOK_CREATE_FILE_REQUEST, addressBookCreateFileRequestSaga);
}

function* addressBookDeleteFileRequestSaga(action) {
    try {
        const response = yield call(addressBookDeleteFileApi, action.payload.deleteParams);
        if (!response.error) {
            if(response.result.addressBookList){
                yield put(setAddressBookListSuccess(response.result))
            } else {
                yield put(setContactDetailsSuccess(response.result))
            }
        } else {
            yield put(getAddressBookListFailed(response.message))
        }
    } catch (error) {
        console.error('inside addressBookDeleteFileRequestSaga ', error);
    }
}

export function* watchAddressBookDeleteFileSaga(){
    yield takeLatest(ADDRESS_BOOK_DELETE_FILE_REQUEST, addressBookDeleteFileRequestSaga);
}

function* addressBookMoveFileRequestSaga(action) {
    try {
        const response = yield call(addressBookMoveFileApi, action.payload.moveParams);
        if (!response.error) {
            if(response.result.addressBookList){
                yield put(setAddressBookListSuccess(response.result))
            } else {
                yield put(setContactDetailsSuccess(response.result))
            }
        } else {
            yield put(getAddressBookListFailed(response.message))
        }
    } catch (error) {
        console.error('inside addressBookMoveFileRequestSaga ', error);
    }
}

export function* watchAddressBookMoveFileSaga(){
    yield takeLatest(ADDRESS_BOOK_MOVE_FILE_REQUEST, addressBookMoveFileRequestSaga);
}


function* updateValuationReportCreditSaga(action) {
    const {
        eventPage,
        companyName,
        companyId,
        serviceRequested,
        userEmail
    } = action.payload.requestedParams;
    try {
        const response = yield call(updateValuationReportCreditApi, action.payload.requestedParams);
        if (!response.error) {
            yield put(updateValuationReportCreditSuccess(response));
        }
        yield put(sendActivityTracking({
            eventPage : eventPage,
            eventType : !response.error ? ET_VALUATION_CREDIT_UPDATE_SUCCESS : ET_VALUATION_CREDIT_UPDATE_FAILURE,
            attribute1 : companyName,
            attribute2 : companyId,
            attribute3 : serviceRequested,
            attribute4 : userEmail
        }))
    } catch (error) {
        yield put(getCompanyValutionDataFailed(error.message));
        yield put(sendActivityTracking({
            eventPage : eventPage,
            eventType : ET_VALUATION_CREDIT_UPDATE_FAILURE,
            attribute1 : companyName,
            attribute2 : companyId,
            attribute3 : serviceRequested,
            attribute4 : userEmail
        }))
    }
}

export function* watchUpdateValuationReportCreditRequest() {
    yield takeLatest(UPDATE_VALUATION_REPORT_CREDIT_REQUEST, updateValuationReportCreditSaga);
}

function* updateBusinessPlansCreditSaga(_action) {
    try {
        const response = yield call(updateBusinessPlansCreditApi);
        if (!response.error) {
            yield put(updateBusinessPlansCreditSuccess({updatedUserObj: response}));
        }
        // yield put(sendActivityTracking({
        //     eventPage : eventPage,
        //     eventType : !response.error ? ET_VALUATION_CREDIT_UPDATE_SUCCESS : ET_VALUATION_CREDIT_UPDATE_FAILURE,
        //     attribute1 : companyName,
        //     attribute2 : companyId,
        //     attribute3 : serviceRequested,
        //     attribute4 : userEmail
        // }))
    } catch (error) {
        console.log("error occured in business plan credits update", error)
        // yield put(sendActivityTracking({
        //     eventPage : eventPage,
        //     eventType : ET_VALUATION_CREDIT_UPDATE_FAILURE,
        //     attribute1 : companyName,
        //     attribute2 : companyId,
        //     attribute3 : serviceRequested,
        //     attribute4 : userEmail
        // }))
    }
}

export function* watchUpdateBusinessPlansCreditRequest() {
    yield takeLatest(UPDATE_BUSINESS_PLANS_CREDIT_REQUEST, updateBusinessPlansCreditSaga);
}

function* saveVRWhiteLabelDataSaga(action){
    try {
        const saveVRWhiteLabelDataResp = yield call(saveWhiteLabelInfoApi,action.payload.whiteLabelInfo);
        if(!saveVRWhiteLabelDataResp.error){
            yield all([
                put(setVRWhiteLabelInfo(saveVRWhiteLabelDataResp.valuationReportWhiteLabelObj)),
                put(setWhiteLabelFormSubmitMsgs("Information has been saved",""))
            ])
        } else {
            yield put(setWhiteLabelFormSubmitMsgs("","Information has not been saved. Try again."));
        }
    } catch (error) {
        console.log("inside saveVRWhiteLabelDataSaga ",error);
    }
}

export function* watchSaveVRWhiteLabelDataRequest(){
    yield takeLatest(SAVE_VR_WHITE_LABEL_INFO_REQUEST,saveVRWhiteLabelDataSaga);
}

function* getVRWhiteLabelInfoSaga(action){
    try {
        const vrWhiteLabelResp = yield call(getWhiteLabelInfoApi,action.payload.userId);
        if(!vrWhiteLabelResp.error){
            yield put(setVRWhiteLabelInfo(vrWhiteLabelResp.vrWhiteLabelObj));
        }
    } catch (error) {
        console.log("inside getVRWhiteLAbelInfoSaga ",error);
    }
}

export function* watchGetVRWhiteLabelInfo(){
    yield takeLatest(GET_VR_WHITE_LABEL_INFO_REQUEST,getVRWhiteLabelInfoSaga);
}

function* resetWhiteLabelInfoRequestSaga(action){
    try {
        const resetWhiteLabelInfoResp = yield call(resetWhiteLabelInfoApi,action.payload.userId);
        if(!resetWhiteLabelInfoResp.error){
            yield all([
                put(setVRWhiteLabelInfo(resetWhiteLabelInfoResp.vrWhiteLabelObj)),
                put(setWhiteLabelFormSubmitMsgs("Information has been reset.",""))
            ]);
        } else {
            yield put(setWhiteLabelFormSubmitMsgs("","Information has not been reset. Try again."));
        }
    } catch (error) {
        console.error("inside resetWhiteLabelInfoRequest ",error);
    }
}

export function* watchResetWhiteLabelInfo(){
    yield takeLatest(RESET_WHITE_LABEL_INFO_REQUEST,resetWhiteLabelInfoRequestSaga);
}

function* getBusinessPlansListSaga(action){
    try {
        const response = yield call(getBusinessPlansListApi,action.payload);
        if(!response.error){
            yield put(setBusinessPlansList(response.businessPlans));
        }
    } catch (error) {
        console.error('inside getBusinessPlansListSaga ',error);
    }
}

export function* watchGetBusinessPlansListSaga(){
    yield takeLatest(GET_BUSINESS_PLANS_LIST, getBusinessPlansListSaga);
}

export function* regeneratePdfSaga(action){
    try{
        const response = yield call(regeneratePdfApi,action.payload);
        if (!response.error) {
            const renderedUrl = `${window.location.origin}/${response.creditReportUrl}`;
            window.history.back()
            window.open(renderedUrl, "_blank")
        }
    }catch(error){
        console.log(error);
    }
}

export function* watchRegeneratePdfSaga(){
    yield takeLatest(GET_CREDIT_REPORT_REQUEST, regeneratePdfSaga);
}

export function* regenerateCpPdfSaga(action){
    try{
        const response = yield call(regenerateCpPdfApi,action.payload);
        if (!response.error) {
            const renderedUrl = `${window.location.origin}/${response.cpReportUrl}`;
            window.history.back()
            window.open(renderedUrl, "_blank")
        }
    }catch(error){
        console.log(error);
    }
}

export function* watchRegenerateCpPdfSaga(){
    yield takeLatest(GET_COMPANY_REPORT_REQUEST, regenerateCpPdfSaga);
}

function* removeFormDataSaga(action) {
    try{
        const response = yield call(submitInformationApi, action.payload);
        if(!response.error) {
            yield all([
                put(removeFormDataSuccess(response.message)),
                put(reset('formDataModal'))
            ])
        } else {
            yield put(removeFormDataFailed(response.message))
        }
    } catch(error) {
        console.log(error);
        yield put(removeFormDataFailed(error.message))
    }
}

export function* watchRemoveFormData() {
    yield takeLatest(REMOVE_FORM_DATA_REQUEST, removeFormDataSaga);
}