import React from 'react';
import App from './App';
import loadable from '@loadable/component'
import { setHideHeader, setUserVariant } from './redux/actions/commonAction';
import { Redirect } from 'react-router-dom';
import { getCompanyProfileFailed, getCompanyProfileSuccess } from './redux/actions/companyAction'
import {
  getCompanyAndDirectorData, getIndustryProfileData, getUserVariants,
  callBlogDetailApi, callBlogListApi, getLocationProfileData, getPopularCompanyData
} from './ServerService/apiService';
import { getDirectorDataSuccess } from './redux/actions/directorAction';
import {
  getBlogListSuccess, getBlogDetailSuccess
} from "./redux/actions/blogAction";
import { getIndustryProfileSuccess } from './redux/actions/industryProfileAction';
import { getLocationProfileFailed, getLocationProfileSuccess } from './redux/actions/locationProfileAction';
import { getPopularCompaniesSuccess } from './redux/actions/popularCompaniesAction';
import { EP_DIRECT_SEARCH_COMPANIES_PPC_LEADPAGE, EP_DIRECT_SELECT_PACKAGE_PPC_LEADPAGE, 
  EP_DIRECT_VALUATION_REPORT_PPC_LEADPAGE, EP_SEARCH_COMPANIES_PPC_LANDING_PAGE, 
  EP_SELECT_PACKAGE_PPC_LANDING_PAGE, EP_VALUATION_REPORT_PPC_HOMEPAGE, 
  EP_BUY_SELL_BUSINESS_PPC_HOME_PAGE, EP_BUSINESSPLAN_HOMEPAGE, EP_BUSINESS_PLAN_PPC_HOMEPAGE, 
  ET_BUSINESSPLAN_HOMEPAGE_ONLOAD, ET_BUSINESS_PLAN_PPC_HOMEPAGE_ONLOAD, EP_BUSINESS_PLAN_ONBOARD, ET_BUSINESS_PLAN_SAMPLE_CLICK, EP_BUSINESS_PLAN_ONBOARD_PPC 
} from './common/trackingConstants';
const GlossaryPage = loadable(() => import('./components/Glossary/GlossaryPage'));
const FAQPage = loadable(() => import('./components/Faqs/FAQPage'));
const SolutionsHomePage = loadable(() => import('./components/WhatWeDo/SolutionsHome'));
const LocationProfileComponent = loadable(() => import('./components/LocationProfileComponent/LocationProfileComponent'));
const BusinessPlanPPCHomePage = loadable(() => import('./components/BusinessPlanPPCHomePage/BusinessPlanPPCHomePage'));
const PomandaApi = loadable(() => import("./components/PomandaApi/PomandaApi"));
const SwoopLandingPage = loadable(() => import('./components/SwoopLandingPage/SwoopLandingPage'));
const BusinessValuationPricingPage = loadable(() => import('./components/BusinessValuationPricingPage/BusinessValuationPricingPage'));
const AboutUs = loadable(() => import( './components/AboutUs/AboutUs'));
const ContactUs = loadable(() => import( './components/ContactUsPage/ContactUs'));
const BusinessPlanOnboardPage = loadable(()=>import('./components/BusinessPlanOnboardPage/BusinessPlanOnboardPage'));
const BusinessPlanOnboardPPC = (props) => <BusinessPlanOnboardPage {...props} eventPage={EP_BUSINESS_PLAN_ONBOARD_PPC} />
const BusinessPlanOnboard = (props) => <BusinessPlanOnboardPage {...props} eventPage={EP_BUSINESS_PLAN_ONBOARD}  />
const SelectPackagePPCLandingPage = loadable(()=>import('./components/SelectPackagePPCLandingPage/SelectPackagePPCLandingPage'));
const {
  checkWhitelistIPS,
} = require('./ServerService/apiUtilities')
const HomePage = loadable(() => import('./components/HomepageNew/HomepageVer3'))
const EstimateHomePage = loadable(() => import('./components/EstimateHomePage/EstimateHomePage'));
const ProsperCompanyDirectorInfo = loadable(() => import('./components/ProsperCompanyDirectorInfo/ProsperCompanyDirectorInfo'));
const CompanyProfile = loadable(() => import('./components/CompanyProfile/CompanyProfile'))
const PopularCompanies = loadable(() => import('./components/PopularCompanies/PopularCompanies'))
const PowerSearch = loadable(() => import('./components/PowerSearch/PowerSearch'))
const Account = loadable(() => import('./components/Accounts/Account'))
const Director = loadable(() => import('./components/DirectorPage/DirectorPage'))
const CompanyHouseFilingDoc = loadable(() => import('./components/CompanyHouseFilingDoc/CompanyHouseFilingDoc'))
const CompanyHouse = loadable(() => import('./components/CompanyHouse/CompanyHouse'))
const CreditReportDoc = loadable(() => import('./components/CreditReportDoc/CreditReportDoc'))
const PPTReport = loadable(() => import('./components/common/PPTReport'))
const ABFLandingPage = loadable(() => import("./components/ABFLandingPage/ABFLandingPage"))
const BlogListPage = loadable(() => import("./components/BlogPage/BlogListPage"))
const BlogDetailPage = loadable(() => import('./components/BlogPage/BlogDetailPage'))
const ReportDownload = loadable(() => import("./components/ReportDownload/ReportDownload"))
const LandingPage = loadable(() => import("./components/LandingPageTemplate/LandingPage"))
const CreditReportHomePage = loadable(() => import('./components/CreditReportHomePage/CreditReportHomePage'));
const CompanyDirectorInformation = loadable(() => import('./components/CompanyDirectorInformation/CompanyDirectorInformation'));
const leadGenerationHomePage = loadable(() => import('./components/LeadGenerationHomepage/LeadGenerationHomepage'));
// const InvestmentHomePage = loadable(() => import('./components/InvestmentHomePage/InvestmentHomePage'));
const ProductPage = loadable(() => import('./components/ProductPage/ProductPage'));
const ScoreCardHomepage = loadable(() => import('./components/ScoreCardHomepage/ScoreCardHomepage'));
const IndustryHomepage = loadable(() => import('./components/IndustryInsightHomePage/IndustryInsightHomePage'));
const IndustryProfileComponent = loadable(() => import('./components/IndustryProfileComponent/IndustryProfileComponent'));
const ValuationCalculatorPage = loadable(() => import('./components/CompanyProfile/ValuationCalculator/ValuationCalculatorPage/ValuationCalculatorPage'));
// const BusinessPlanHomePage = loadable(() => import("./components/BusinessPlanHomePage/BusinessPlanHomePage"))
const CompetitorAnalysisPage = loadable(()=>import("./components/CompetitorAnalysis/CompetitorAnalysis"));
const LinkedInCallbackComp = loadable(()=>import("./components/LinkedInCallbackComp/LinkeInCallbackComp"));
const BuySellBusinessHomePage = loadable(()=>import("./components/BuySellBusinessHomePage/BuySellBusinessHomePage"));
//const StandardIndustryClassification = loadable(() => import("./components/StandardIndustryClassification/StandardIndustrySicCode/StandardIndustryClassificationSicCode"))
//const StandardIndustryClassificationCompanyList = loadable(() => import("./components/StandardIndustryClassification/StandardIndustryClassificationCompanyList/StandardIndustryClassificationCompanyList"))
// const { matches } = require('ip-matching')
const BusinessTemplateBuilder = loadable(() => import('./components/BusinessPlanTemplate/BusinessPlanTemplate'));
const HelpCentrePage = loadable(() => import("./components/HelpCentre/Pages/HelpCentrePage/HelpCentrePage"));
const ValuationReportPPCHomePage = loadable(() => import('./components/ValuationReportPPCHomePage/ValuationReportPPCHomePage'));
const CompaniesPPCLandingPage = loadable(() => import('./components/CompaniesPPCPage/CompaniesPPCLandingPage'));

const ValuationReportPPCHomePageWrapper = (props) => <ValuationReportPPCHomePage {...props} userType="basic" eventPage={EP_VALUATION_REPORT_PPC_HOMEPAGE}/>
const CompaniesPPCLandingPageWrapper = (props) => <CompaniesPPCLandingPage {...props} userType="basic" eventPage={EP_SEARCH_COMPANIES_PPC_LANDING_PAGE}/>
const SelectPackagePPCLandingPageWrapper = (props) => <SelectPackagePPCLandingPage {...props} userType="basic" eventPage={EP_SELECT_PACKAGE_PPC_LANDING_PAGE}/>
const DirectValuationReportPPCLeadpage = (props) => <ValuationReportPPCHomePage {...props} userType="anonymous" eventPage={EP_DIRECT_VALUATION_REPORT_PPC_LEADPAGE} />
const DirectCompaniesPPCLeadPage = (props) => <CompaniesPPCLandingPage {...props} userType="anonymous" eventPage={EP_DIRECT_SEARCH_COMPANIES_PPC_LEADPAGE} />
const DirectSelectPackagePPCLeadPage = (props) => <SelectPackagePPCLandingPage {...props} userType="anonymous" eventPage={EP_DIRECT_SELECT_PACKAGE_PPC_LEADPAGE} />
const BuySellBusinessHomePageWrapper = (props) => <BuySellBusinessHomePage {...props} userType="anonymous" eventPage={EP_BUY_SELL_BUSINESS_PPC_HOME_PAGE}/>
const CreditReportCompaniesPPCLandingPageWrapper = (props) => <CompaniesPPCLandingPage {...props} userType="basic" eventPage={EP_SEARCH_COMPANIES_PPC_LANDING_PAGE}/>
const BusinessPlanHomePageWrapper = (props) => <BusinessPlanPPCHomePage {...props} userType="basic" eventPage={EP_BUSINESSPLAN_HOMEPAGE} eventType={ET_BUSINESSPLAN_HOMEPAGE_ONLOAD}/>
const BusinessPlanPPCHomePageWrapper = (props) => <BusinessPlanPPCHomePage {...props} userType="anonymous" eventPage={EP_BUSINESS_PLAN_PPC_HOMEPAGE} eventType={ET_BUSINESS_PLAN_PPC_HOMEPAGE_ONLOAD}/>

export default [
  {
    component: App,
    routes: [
      {
        component: HomePage,
        path: '/',
        exact: true,
        loadData: async ({dispatch}) => {
          dispatch(setHideHeader({
            backgroundDesktop: "transparent",
            backgroundTab: "transparent",
            backgroundMob: "transparent",
            isSingleSearchbarSticky: false
          }))
        }
      },
      {
        component: HomePage,
        path: "/resetpassword/:email?",
        exact: true
      },
      {
        component: CompanyProfile,
        path: "/company/:companyId/:companyName",
        exact: true,

        loadData: async ({ dispatch }, { params }, token, ipAddress, userSession, reqHeaders) => {

        
          let companyName = params.companyName;
          // try {
          //   let isGoogleIp = false
          //   const matchResult = process.env['WHITELIST_IPS'] && process.env['WHITELIST_IPS'].trim().length > 0 &&
          //   process.env['WHITELIST_IPS'].split(',').map(ip => matches(ipAddress, ip))

          //   if(matchResult.includes(true)){
          //     isGoogleIp = true
          //   }
          //   const iswhitelistIP = checkWhitelistIPS(reqHeaders, ipAddress,  process.env['WHITELIST_IPS'])
          //   if (!iswhitelistIP) {
          //     const userVariant = await getUserVariants(token, ipAddress)
          //     if (!userVariant.error) {
          //       dispatch(setUserVariant(userVariant.data.variants));
          //     } else {
          //       dispatch(setUserVariant({ variant: "ORIGINAL", isWhiteListUser: true }))
          //     }
          //   } else {
          //     dispatch(setUserVariant({ variant: "ORIGINAL", isWhiteListUser: true }))
          //   }
          // } catch (error) {
          //   dispatch(setUserVariant({ variant: "ORIGINAL", isWhiteListUser: true }))
          //   console.log('error : ', error)
          // }
          // dispatch(setUserVariant({ variant: "ORIGINAL", isWhiteListUser: true }))

          try {
            let resp = await getCompanyAndDirectorData("company", params.companyId, companyName, token, ipAddress, userSession);
            (resp.error && resp.status === 404) ? dispatch(getCompanyProfileFailed(resp.error)) : dispatch(getCompanyProfileSuccess(resp.data.companyProfile));
            return
          } catch (error) {
            console.log('error : ', error)
          }
        }
      },
      {
        component: PowerSearch,
        path: [
          "/powersearch/company", 
          "/powersearch/director", 
          "/powersearch/industry", 
          "/powersearch/shareholder",
          "/powersearch/charges",
          "/powersearch/region"
        ],
        exact: true,
      },
      {
        component: Account,
        path: [
          "/account/profile", 
          "/account/contact-us", 
          "/account/legal-docs", 
          "/account/subscription", 
          "/account/order-history",
          "/account/credits",
          "/account/valuation-reports",
          "/account/whitelabel", 
          "/account/business-plans"
        ],
        exact: true
      },
      {
        component: Director,
        path: "/director/:pnr/:name",
        exact: true,
        loadData: async ({ dispatch }, { params }, token, ipAddress, userSession) => {
          let resp = await getCompanyAndDirectorData("director", params.pnr, null, token, ipAddress, userSession)
          !resp.error && dispatch(getDirectorDataSuccess(resp.data.directorDetail))
          return
        }
      },
      {
        component: CompanyHouseFilingDoc,
        path: "/company-house-doc/:docId",
        exact: true
      },
      {
        component: PopularCompanies,
        path: "/popular-companies/:indexField/:pageNumber",
        exact: true,
        // DO NOT REMOVE 
        loadData: async({ dispatch }, { params },token, ipAddress) => {
          const response = await getPopularCompanyData(params.indexField,params.pageNumber,500,token,ipAddress);
          const {data=[],totalRecords=0,activeAlpha="a",pageNumber=1,size=0}=response.popularCompanies;
          dispatch(getPopularCompaniesSuccess(data,totalRecords,activeAlpha,pageNumber,size));
          return
        }
      },
      {
        component: CompanyHouse,
        path: "/landingpage/companieshouse",
        exact: true,
        loadData: ({ dispatch }) => {
          dispatch(setHideHeader({ show: false }));
        }
      },
      {
        component: CreditReportDoc,
        path: ["/credit-report/:docId",
          "/credit-report/:cohId/generate-report"],
        exact: true
      },
      {
        component: PPTReport,
        path: ["/ppt-report/:reportType/:docId",
        "/ppt-report/:reportType/:filePath/:randomString/:fileName",
        "/ppt-report/:reportType/:reports/:filePath/:docId/:fileName"],
        exact: true
      },
      {
        component: ABFLandingPage,
        path: "/landingpage/altbusfunding",
        exact: true,
        loadData: ({ dispatch }) => {
          dispatch(setHideHeader({ show: false }));
        }
      },
      {
        component: ProsperCompanyDirectorInfo,
        path: "/landingpage/prosper2",
        exact: true
      },
      {
        component: BlogListPage,
        path: "/blog",
        exact: true,
        loadData: async ({ dispatch }, { params }, token, ipAddress) => {
          let response = await callBlogListApi(token, ipAddress)
          dispatch(getBlogListSuccess(response.data.blogList))
          return
        }
      },
      {
        component: BlogDetailPage,
        path: "/blog/:_id",
        exact: true,
        loadData: async ({ dispatch }, { params }, token, ipAddress) => {
          let response = await callBlogDetailApi(params._id, token, ipAddress)
          dispatch(getBlogDetailSuccess({ blogDetail: response.data.blogDetail, detailBlogList: response.data.blogList }))
          return
        }
      },
      {
        component: ReportDownload,
        path: [
          "/downloadreport/:reporttype/:id/:userId",
          "/downloadreport/:reporttype/:usrRptMappingId",
          "/downloadreport/:reporttype/:cohId/:userId/generate-report",
        ],
        exact: true
      },
      {
        component: LandingPage,
        path: "/landingpage/:type",
        exact: true,
        loadData: ({ dispatch }) => {
          dispatch(setHideHeader({ show: false }));
        }
      },
      {
        component: CreditReportHomePage,
        path: "/solutions/companycreditcheck",
        exact: true
      },
      {
        component : BusinessPlanHomePageWrapper,
        path : "/solutions/businessplan",
        exact : true,
        loadData: async ({dispatch}) => {
          dispatch(setHideHeader({
            backgroundDesktop: "transparent",
            backgroundTab: "transparent",
            backgroundMob: "transparent",
            customizedLogoType: "businessPlanPpc",
            showViewSampleReportBtn: true,
            showSingleSearchbar: false,
            showPowerSearchNav: false,
            isPricingMenuShow: false,
            showSolutionsNav: false,
            showAccountLoginNav: false,
            showSignupNav: false,
            sameImageForAllDevices: true,
            showCompanyValuationNav: true,
            showMenuToggleTabMob: false,
            customHeaderBtnTextProps: {
              deskTop: 'VIEW SAMPLE PLAN',
              mobile: 'VIEW SAMPLE PLAN',
              url: '/assets/Download/business_plan_sample_report.pdf',
              eventName: ET_BUSINESS_PLAN_SAMPLE_CLICK
          }
          }))
        }
      },
      {
        component : BusinessPlanPPCHomePageWrapper,
        path : "/solutions/businessplan/ppc",
        exact : true
      },
      {
        component: CompanyDirectorInformation,
        path: "/solutions/companyinformation",
        exact: true
      },
      {
        component: leadGenerationHomePage,
        path: "/solutions/leadgeneration",
        exact: true
      },
      // {
      //   component: InvestmentHomePage,
      //   path: "/solutions/investment",
      //   exact: true
      // },
      {
        component: ProductPage,
        path: "/product",
      }, {
        component: ScoreCardHomepage,
        path: "/solutions/scorecard",
        exact: true
      },
      {
        component: IndustryProfileComponent,
        path: "/industry/:industryId/:industryName",
        exact: true,
        loadData: async ({ dispatch }, { params }, token, ipAddress,userSession) => {
          try {
            let {
              industryId
            } = params;
            let resp = await getIndustryProfileData(industryId, token, ipAddress, userSession)
            dispatch(getIndustryProfileSuccess(resp.data.data));
            return
          } catch (error) {
            console.log('error : ', error.response)
          }
        }
      },
      {
        component: EstimateHomePage,
        path: "/solutions/estimates",
        exact: true
      },
      {
        component: ValuationReportPPCHomePageWrapper,
        path: "/solutions/companyvaluation",
        exact: true
      },
      {
        component: CompaniesPPCLandingPageWrapper,
        path: "/solutions/companyvaluation/companies",
        exact: true
      },
      {
        component : SelectPackagePPCLandingPageWrapper,
        path : "/solutions/companyvaluation/companies/:companyId/selectpackage",
        exact : true
      },
      {
        component : DirectValuationReportPPCLeadpage,
        path : "/solutions/companyvaluation/ppc",
        exact : true
      },
      {
        component : DirectCompaniesPPCLeadPage,
        path : "/solutions/companyvaluation/companies/ppc",
        exact : true
      },
      {
        component : DirectSelectPackagePPCLeadPage,
        path : "/solutions/companyvaluation/companies/:companyId/selectpackage/ppc",
        exact : true
      },
      {
        component: IndustryHomepage,
        path: "/solutions/industry",
        exact: true
      },
      {
        component: LocationProfileComponent,
        path: "/location/:locationId/:locationName",
        exact: true,
        loadData: async ({ dispatch }, { params }, token, ipAddress) => {
          try {
            let {
              locationId
            } = params;
            let resp = await getLocationProfileData(locationId, token, ipAddress);
            if(resp?.error){
              dispatch(getLocationProfileFailed(resp?.data?.message))
            } else {
              dispatch(getLocationProfileSuccess(resp?.data?.locationProfile));
            }
            return
          } catch (error) {
            console.log('error : ', error.response)
          }
        }
      },
      {
        component: SolutionsHomePage,
        path: "/solutions",
        exact: true
      },
      {
        component: ValuationCalculatorPage,
        path: "/company/:companyId/:companyName/valuation",
        exact: true,
        // loadData: async ({ dispatch }, { params }, token, ipAddress, accessToken, trackerId, sessionId) => {        }
      },
      {
        component: FAQPage,
        path: "/faqs",
        exact: true
      },
      {
        component: GlossaryPage,
        path: "/glossary",
        exact: true
      },
      {
        component: BusinessTemplateBuilder,
        path: [
          "/businessplan/:uniqueId/summary", "/businessplan/:uniqueId/description", "/businessplan/:uniqueId/history",
          "/businessplan/:uniqueId/history","/businessplan/:uniqueId/industry-insight","/businessplan/:uniqueId/key-people",
          "/businessplan/:uniqueId/objectives","/businessplan/:uniqueId/ownership","/businessplan/:uniqueId/strengths-weeknesses",
          "/businessplan/:uniqueId/personas","/businessplan/:uniqueId/competitive-analysis","/businessplan/:uniqueId/product-services",
          "/businessplan/:uniqueId/pricing-strategy", "/businessplan/:uniqueId/customer-acqusition", "/businessplan/:uniqueId/sales-forecast",
          "/businessplan/:uniqueId/location-of-operation", "/businessplan/:uniqueId/key-suppliers-relationships",
          "/businessplan/:uniqueId/staff-employment-plans", "/businessplan/:uniqueId/legal-regulatory-insurance", "/businessplan/:uniqueId/financial-projections",
          "/businessplan/:uniqueId/funding-requirement-funds", "/businessplan/:uniqueId/business-valuation", "/businessplan/:uniqueId/appendix", "/businessplan/:uniqueId/cashflow", "/businessplan/:uniqueId/branding-personalisation"
        ],
        exact: true,
      },
      {
        component: BusinessPlanOnboardPPC,
        path: ["/businessplanonboard/ppc", "/businessplanonboard/ppc/:businessType"],
        exact: true,
        loadData: ({ dispatch }) => {
          dispatch(setHideHeader({ show: false }));
        }
      },
      {
        component: BusinessPlanOnboard,
        path: ["/businessplanonboard", '/businessplanonboard/:businessType'],
        exact: true,
        loadData: ({ dispatch }) => {
          dispatch(setHideHeader({ show: false }));
        }
      },     
      {
        component: CompetitorAnalysisPage,
        path: "/competitoranalysis/:companyId/:companyName",
        exact: true
      },
      {
        component: LinkedInCallbackComp,
        path: "/auth/linkedin",
        exact: true,
        loadData: async ({dispatch}) => {
          try {
            dispatch(setHideHeader({show:false}));
          } catch (error) {
            console.log('error in loaddata of /auth/linkedin ',error);
            throw error;
          }
        }
      },{
        component: BuySellBusinessHomePageWrapper,
        path: "/solutions/companyvaluation/selling/ppc",
        exact: true
      },
      {
        component: CreditReportCompaniesPPCLandingPageWrapper,
        path: "/solutions/companycreditcheck/companies",
        exact: true,
        pageType:"creditcheck"
      },
      {
        component: AboutUs,
        path: "/about",
        exact: true,
      },
      {
        component: PomandaApi,
        path: ["/pomanda-api-service", "/pomanda-api-service/docs", "/pomanda-api-service/docs/:apiDoc"]
      },
      // {
      //   component: StandardIndustryClassification,
      //   path: "/sic/:index/:pagenumber",
      //   exact : true,
      //   loadData : async({ dispatch }, { params }, token, ipAddress) =>{
      //     try{
      //         const response = await getIndustrySicCodeData( params.index, params.pagenumber, 500, token, ipAddress);
      //         const {data=[], pageNumber=1, index='A', size=0, totalRecords=0} = response?.industrySicCodes;
      //         dispatch(getIndustrySicCodesResponse(data, pageNumber, index, size, totalRecords));
      //         return;
      //     }
      //     catch(error){
      //       console.log('error : ', error)
      //     }
      //   }
      // },
      // {
      //   component: StandardIndustryClassificationCompanyList,
      //   path: "/sic/:siccode/:sicdesc/:pageNumber/companylist",
      //   exact : true,
      //   loadData : async({ dispatch }, { params }, token, ipAddress) =>{
      //     try{
      //         const response = await getIndustrySicCodeCompanyList( params.siccode, 500, params.pageNumber, token, ipAddress);
      //         const {data=[], sicCode='0', size=0, totalRecords=0} = response?.companyList
      //         dispatch(getSicCodeBasedCompanyListResponse(data,  sicCode, size, totalRecords))
      //         return;
      //     }
      //     catch(error){
      //       console.log('error : ', error)
      //     }
      //   }
      // },
      {
        component: ContactUs,
        path: "/contact",
        exact: true,
      },
      {
        component: SwoopLandingPage,
        path: "/solutions/businessplan/swoop",
        loadData: ({dispatch}) => {
          dispatch(setHideHeader({
            showSingleSearchbar: false,
            showPowerSearchNav: false,
            showSolutionsNav: false,
            isPricingMenuShow: false,
            hideAccountTab: true,
            backgroundDesktop: "transparentBlack",
            backgroundTab: "transparentBlack",
            backgroundMob: "transparentBlack",
            headerCmpCssStr: 'businessplan-swoop-landing-page',
            customizedLogoType: true,
            openSignUpModalInPage: true
          }))
        }
      },
      {
        component: BusinessValuationPricingPage,
        path: "/company/:companyId/:companyName/valuationproductpage",
        exact: true
      },
      {
        component: HelpCentrePage,
        path: ["/help","/help/:menu","/help/:menu/:content"],
        // exact: true,
      },
      {
        path: "*",
        component: function () {
          return <Redirect to='/' />
        }

      }     
    ]
  }
];
