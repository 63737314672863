import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import loadable from '@loadable/component';
import './SingleSearchModalBar.css';
// import SingleSearchDropdown from '../HomePage/HomepageComponents';
const SingleSearchDropdown = loadable(() => import('../HomePage/HomepageComponents'));


export default class SingleSearchModalBar extends Component {

    render() {
        const {
            isOpen = false,
            inputPlaceholder = "",
            inputSearchValue = "",
            inputId = "",
            eventCreated = "",
            eventType = "",
            eventPage = "",
            eventCreatedOption = "",
            companyList = [],
            directorList = [],
            industryList = [],
            shareholderList = [],
            chargesList = [],
            regionList=[],
            directorCount = 0,
            companyCount = 0,
            industryCount = 0,
            shareholderCount = 0,
            chargesCount = 0,
            regionCount=0,
            headerSingleSearchOptions = {},
            optionOnclickFunction = () => { },
            singleSearchTypes = [],
            showDropDownTextOnly,
            modalHeading = "",
            modelCssClass = "",
            dropDownOptionOnClick = () => { }
        } = this.props;
        return (
            <Modal className={`single-search-modal-comp ${modelCssClass}`} isOpen={isOpen} fade={false} backdrop="static">
                {modalHeading && <ModalHeader>{modalHeading}
                    <i className="fas fa-times modal-close-btn mt-2"
                        onClick={() => this.props.handleModalBackLink(false)} /></ModalHeader>}
                <ModalBody>
                    <div className="search-modal-header">
                        {modalHeading ? <i className="fa fa-search search-modal-icon ml-auto"
                            pomandae2e="searchinputicon"></i> :
                            <i className="fas fa-arrow-left search-modal-close" pomandae2e="closebutton"
                                onClick={() => this.props.handleModalBackLink(false)}></i>}
                        <input type="text" id={inputId} pomandae2e="searchbar"
                            placeholder={inputPlaceholder} 
                            onKeyDown={(e) => this.props.handleOnKeyDown(e, eventCreated, eventType)} 
                            tabIndex="0" 
                            onChange={(e) => this.props.handleOnChange(e, eventType, eventCreated, eventPage)} 
                            value={inputSearchValue}
                            autoFocus={true} autoComplete="off" autoCorrect="off" spellCheck="off"
                        />
                        {!modalHeading && <i className="fa fa-search search-modal-icon ml-auto"
                            pomandae2e="searchinputicon" onClick={this.props.handleSearchClick}></i>}
                    </div>
                    <div className="dropdown">
                        <SingleSearchDropdown
                            searchTextboxValue={inputSearchValue}
                            companyList={companyList}
                            directorList={directorList}
                            industryList={industryList}
                            regionList={regionList}
                            shareholderList={shareholderList}
                            chargesList={chargesList}
                            directorCount={directorCount}
                            companyCount={companyCount}
                            industryCount={industryCount}
                            shareholderCount={shareholderCount}
                            regionCount={regionCount}
                            chargesCount={chargesCount}
                            eventPage={eventPage}
                            eventCreatedOptionClick={eventCreatedOption}
                            id={inputId}
                            isShow={true}
                            headerSingleSearchOptions={headerSingleSearchOptions}
                            optionOnclickFunction={optionOnclickFunction}
                            countOptionOnclick={this.props.countOptionOnclick}
                            singleSearchTypes={singleSearchTypes}
                            showDropDownTextOnly={showDropDownTextOnly}
                            dropDownOptionOnClick={dropDownOptionOnClick}
                        />
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}