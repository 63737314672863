import { call, put, select, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
    GET_INDUSTRY_DETAILS_REQUEST, SAVE_BUSINESS_PLAN_DATA_REQUEST,
    GET_BUSINESS_PLAN_DATA_REQUEST, SAVE_FINANCIAL_DATA,
    GET_FUTURE_FINANCIAL_PROJECTION_DATA_REQUEST,
    GET_SUGGESTED_COMPETITORS_DATA_REQUEST, 
    GET_BUSINESS_PLAN_COMPANY_INSIGHTS_DATA,
    UPLOAD_AND_GET_TEMP_BUSS_PLAN_FILE_URL,
    GET_BUSINESS_PLAN_PAGE_PREVIEW,
    GET_BUSINESS_PLAN_INDUSTRY_INSIGHTS
} from "../redux/actions/actionConstants";
import {
    getIndustryDetailsApi, saveBusinessPlanDataApi,
    getBusinessPlanDataApi, getSuggestedCompetitorsDataApi,
    saveFinancialProjectionDataApi, getFutureFinancialProjectionDataApi, 
    getBusinessPlanCompanyInsightsApi, uploadTempBussPlanFileApi,
    getBusinessPlanPagePreviewApi, 
    getBusinessPlanIndustryInsightsApi
} from "../api/businessPlanTemplateApi";
import {
    saveBusinessPlanDataSuccess, setBusinessPlanIndustryInsights, setBussPlanData, 
    setBussPlanPagePreviewHtml, setBussPlanUploadedTempFiles,
    setBussTempMainPageData, setIndustryDetails, setPreviewPageLoading
} from "../redux/actions/businessPlanTemplateAction";
import {
    editPastFinancialAndParseFinancialDbObjToFrontendFormat,
    editFutureFinancialAndParseFinancialDbObjToFrontendFormat
} from "../common/bussFinancialDbToFrontendFormat";
import { setLoading } from "../redux/actions/commonAction";
const businessPlanTemplateMenuDataFrmRedux = state=>state.businessPlanTemplate?.businessPlanTemplateMenuData || {};
const businessPlanUploadedTempFilesFrmRedux = state=>state.businessPlanTemplate?.uploadedTempFiles || [];

function* saveBusinessPlanDataSaga(action) {
    try {
        const { businessPlanObj = {}, isOpenReport = false } = action.payload;
        const response = yield call(saveBusinessPlanDataApi, {businessPlanObj, uploadedTempFiles: action.payload.uploadedTempFiles});
        if (response && !response.error) {
            yield all([
                put(saveBusinessPlanDataSuccess(response.businessPlanObj)),
                put(setBussPlanUploadedTempFiles([]))
            ]);
            if(isOpenReport){
                window.open(`${window.location.origin}/downloadreport/businessPlan/${businessPlanObj?.uniqueId}/${businessPlanObj?.userId}`,"_blank")
            }
        } 
        yield put(setLoading({status: false})); 
    } catch (error) {
        yield put(setLoading({status: false})); 
        console.log('inside saveBusinessPlanDataSaga ', error);
    }
}

export function* watchSaveBusinessPlanDataSaga() {
    yield takeLatest(SAVE_BUSINESS_PLAN_DATA_REQUEST, saveBusinessPlanDataSaga);
}

function* getIndustryDetailsSaga(action) {
    try {
        let industryDetailsResp = yield call(getIndustryDetailsApi, action.payload.sicCodes);
        if (industryDetailsResp && !industryDetailsResp.error) {
            yield put(setIndustryDetails(industryDetailsResp.industryDetails));
        }
    } catch (error) {
        console.log('inside getIndustryDetailsSaga ', error);
    }
}

export function* watchGetIndustryDetailsSaga() {
    yield takeLatest(GET_INDUSTRY_DETAILS_REQUEST, getIndustryDetailsSaga);
}

function* getBusinessPlanDataSaga(action) {
    try {
        let response = yield call(getBusinessPlanDataApi, action.payload.uniqueId);
        if (!response.error) {
            if (!response.isAuthorise) {
                window.open("/", "_self");
            } else {
                if(response?.businessPlanObj?.bussPlan?.data){
                    const financialDbObj = JSON.parse(JSON.stringify({
                        pastFinancialData: response.businessPlanObj?.bussPlan.data.financials?.data?.pastFinancialData,
                        futureFinancialData: response.businessPlanObj?.bussPlan.data.financials?.data?.futureFinancialData
                    }));
                    const parsedFinancialData = yield* getParsedPastAndFutureFinDataArr(financialDbObj);
                    const { financialProjectionPageData, bussinessValuationPageData } = yield* getFinProjAndBussValPageDataObjFrmParsedObj(parsedFinancialData);
                    response.businessPlanObj.bussPlan.data.financials.children.financialProjections.data = financialProjectionPageData;
                    response.businessPlanObj.bussPlan.data.financials.children.businessValuation.data = bussinessValuationPageData;
                }
                yield all([
                    put(saveBusinessPlanDataSuccess({...response.businessPlanObj?.bussPlan, status: response.businessPlanObj?.status}))
                ])
            }
        }
        yield put(setLoading({status: false}));
    } catch (error) {
        yield put(setLoading({status: false}));
        console.log('inside getBusinessPlanDataSaga ', error);
    }
}

export function* watchGetBusinessPlanDataSaga() {
    yield takeLatest(GET_BUSINESS_PLAN_DATA_REQUEST, getBusinessPlanDataSaga);
}

function* saveFinancialDataSaga(action) {
    try {
        const { value, date, mainType, isPastData, isTableEditable, isApiCallRequired = true, childObj, parentIndex } = action.payload.paramsObj;
        const businessPlanTemplateMenuData = yield select(businessPlanTemplateMenuDataFrmRedux);
        let financialDbObj = {
            pastFinancialData: businessPlanTemplateMenuData?.financials?.data?.pastFinancialData,
            futureFinancialData: businessPlanTemplateMenuData?.financials?.data?.futureFinancialData
        };
        financialDbObj = JSON.parse(JSON.stringify(financialDbObj));
        let oneYearFinancialObj = {}, financialObjIndex, parentColumData;
        // finding one year financial object to valuation calc api
        const { financialProjectionPageDataObj } = yield* getFinancialProjectionAndBussValuationPageData();
        const parentObj = financialProjectionPageDataObj.profitLoss[parentIndex]
        if (isPastData) {
            financialObjIndex = financialDbObj.pastFinancialData.findIndex(pastFinancialObj => pastFinancialObj.date === date);
            oneYearFinancialObj = financialDbObj.pastFinancialData[financialObjIndex];
            if (childObj && isTableEditable) {
                parentColumData = financialDbObj.pastFinancialData[financialObjIndex][parentObj.key]
                financialDbObj.pastFinancialData[financialObjIndex][parentObj.key] = getChildArrWithRespectParent(parentColumData, parentObj.key, childObj, date, value)?.parentColumData
            }
        } else {
            financialObjIndex = financialDbObj.futureFinancialData.findIndex(futureFinancialObj => futureFinancialObj.date === date);
            oneYearFinancialObj = financialDbObj.futureFinancialData[financialObjIndex];
            if (childObj && isTableEditable) {
                parentColumData = financialDbObj.futureFinancialData[financialObjIndex][parentObj.key]
                financialDbObj.futureFinancialData[financialObjIndex][parentObj.key] = getChildArrWithRespectParent(parentColumData, parentObj.key, childObj, date, value)?.parentColumData
            }
        }
        //making param object for valuation calc api
        const overviewPageObj = businessPlanTemplateMenuData.overview;
        const analysisPageObj = businessPlanTemplateMenuData.analysis;
        const saveFinancialDataApiParams = {
            financialCalData: oneYearFinancialObj,
            type: mainType,
            userValue: Number(value),
            companyName: overviewPageObj?.children?.description?.data?.companyName,
            companyId: overviewPageObj?.children?.description?.data?.companyId,
            indMix: analysisPageObj?.children?.industryInsight?.data?.indMix,
            companyType: ""
        };
        //making api call
        if (isApiCallRequired) {
            let response = yield call(saveFinancialProjectionDataApi, saveFinancialDataApiParams);
            if (!response.error) {
                //replacing old financial object with updated financial object for parsing
                if (financialObjIndex >= 0) {
                    const latestBusinessPlanTemplateMenuData = yield select(businessPlanTemplateMenuDataFrmRedux);
                    if (isPastData) {
                        financialDbObj.pastFinancialData = latestBusinessPlanTemplateMenuData?.financials?.data?.pastFinancialData;
                        financialDbObj.pastFinancialData.splice(financialObjIndex, 1, response.financialCalDataUpdated)
                    } else {
                        financialDbObj.futureFinancialData = latestBusinessPlanTemplateMenuData?.financials?.data?.futureFinancialData
                        financialDbObj.futureFinancialData.splice(financialObjIndex, 1, response.financialCalDataUpdated)
                    }
                }
            }
        }
        //parsing
        let editedFinancialData = yield* getParsedPastAndFutureFinDataArr(financialDbObj);
        const { financialProjectionPageData, bussinessValuationPageData } = yield* getFinProjAndBussValPageDataObjFrmParsedObj(editedFinancialData);
        yield* updateSalesValueInSalesForecart(mainType, date, value);
        yield all([
            put(setBussTempMainPageData({ parentLabel: "financials", data: financialDbObj })),
            put(setBussTempMainPageData({ parentLabel: "financials", childLabel: "financialProjections", data: financialProjectionPageData })),
            put(setBussTempMainPageData({ parentLabel: "financials", childLabel: "businessValuation", data: bussinessValuationPageData }))
        ]);
    } catch (error) {
        console.error('inside saveFinancialDataSaga ', error);
    }
}

export function* watchSaveFinancialDataSaga() {
    yield takeEvery(SAVE_FINANCIAL_DATA, saveFinancialDataSaga);
}

function* getFutureFinancialProjectionDataReqSaga(action) {
    try {
        const { isIndMixUpdated = false } = action.payload.paramsObj;
        const response = yield call(getFutureFinancialProjectionDataApi, action.payload.paramsObj);
        if (!response.error) {
            let businessPlanTemplateMenuData = yield select(businessPlanTemplateMenuDataFrmRedux);
            businessPlanTemplateMenuData = JSON.parse(JSON.stringify(businessPlanTemplateMenuData));
            let parsedFinancialData = null, financialDbObj = null;
            if (isIndMixUpdated) {
                financialDbObj = {
                    pastFinancialData: response?.financialProjectionData?.pastFinancialData,
                    futureFinancialData: response?.financialProjectionData?.futureFinancialData
                };
                parsedFinancialData = yield* getParsedPastAndFutureFinDataArr(financialDbObj);
            } else {
                financialDbObj = {
                    pastFinancialData: businessPlanTemplateMenuData?.financials?.data?.pastFinancialData?.length > 0
                        ? businessPlanTemplateMenuData?.financials?.data?.pastFinancialData : [],
                    futureFinancialData: businessPlanTemplateMenuData?.financials?.data?.futureFinancialData
                };
                response?.financialProjectionData?.futureFinancialData.forEach(respFutureFinObj => {
                    const objIndex = financialDbObj?.futureFinancialData.findIndex(futureFinObj => futureFinObj?.date === respFutureFinObj?.date);
                    financialDbObj?.futureFinancialData.splice(objIndex, 1, respFutureFinObj);
                });
                parsedFinancialData = yield* getParsedPastAndFutureFinDataArr(financialDbObj);
            }
            const { financialProjectionPageData, bussinessValuationPageData } = yield* getFinProjAndBussValPageDataObjFrmParsedObj(parsedFinancialData);
            yield all([
                put(setBussTempMainPageData({ parentLabel: "financials", data: financialDbObj })),
                put(setBussTempMainPageData({ parentLabel: "financials", childLabel: "financialProjections", data: financialProjectionPageData })),
                put(setBussTempMainPageData({ parentLabel: "financials", childLabel: "businessValuation", data: bussinessValuationPageData }))
            ]);
        }

    } catch (error) {
        console.log('inside getFutureFinancialProjectionDataReqSaga ', error);
    }
}

export function* watchGetFutureFinancialProjectionDataReqSaga() {
    yield takeLatest(GET_FUTURE_FINANCIAL_PROJECTION_DATA_REQUEST, getFutureFinancialProjectionDataReqSaga);
}

function* getFinancialProjectionAndBussValuationPageData() {
    try {
        const businessPlanTemplateMenuData = yield select(businessPlanTemplateMenuDataFrmRedux);
        const financialsPageObj = businessPlanTemplateMenuData.financials;
        const financialProjectionPageDataObj = JSON.parse(JSON.stringify(financialsPageObj?.children?.financialProjections.data));
        const businessValuationPageDataObj = JSON.parse(JSON.stringify(financialsPageObj?.children?.businessValuation?.data));
        return { financialProjectionPageDataObj, businessValuationPageDataObj };
    } catch (error) {
        throw error;
    }
}

function* updateSalesValueInSalesForecart(mainType, date, value) {
    if (mainType === "turnover") {
        const businessPlanTemplateMenuData = yield select(businessPlanTemplateMenuDataFrmRedux);
        if (businessPlanTemplateMenuData.financials?.data?.futureFinancialData) {
            const salesForecardPageDataObj = JSON.parse(JSON.stringify(businessPlanTemplateMenuData.salesMarketingStrategy?.children?.salesForecast?.data));
            const objectives = salesForecardPageDataObj?.objectives.map(objective => objective.date === date ? { ...objective, sales: Number(value) } : objective)
            yield put(setBussTempMainPageData({ parentLabel: "salesMarketingStrategy", childLabel: "salesForecast", data: { objectives } }))
        }
    }
}

function* getSuggestedCompetitorsData(action) {
    try {
        const response = yield call(getSuggestedCompetitorsDataApi, action.payload.companyId);
        if (!response.error) {
            yield put(setBussTempMainPageData({ parentLabel: "analysis", childLabel: "competitiveAnalysis", data: { suggestedCompetitors: response.suggestedCompetitors, prevCompanyId: action.payload.companyId } }))
        }
    } catch (error) {
        console.log("error inside getSuggestedCompetitorsSaga", error)
    }
}

export function* watchGetSuggestedCompetitorsSaga() {
    yield takeLatest(GET_SUGGESTED_COMPETITORS_DATA_REQUEST, getSuggestedCompetitorsData);
}

export function getChildArrWithRespectParent(parentColumData, parentName, childObj, date, value) {
    let filteredChildObj = {}, children = [];
    childObj?.values.forEach(item => item.date === date ? filteredChildObj = { label: childObj.label, value: Number(value) } : null)
    if (parentColumData?.hasOwnProperty("children")) {
        const childIndex = parentColumData.children?.findIndex(it => it.label === filteredChildObj.label)
        if (childIndex === -1) {
            parentColumData?.children.push(filteredChildObj)
        } else {
            parentColumData.children[childIndex] = filteredChildObj;
        }
    } else {
        children.push(filteredChildObj)
        parentColumData = { ...parentColumData, children }
    }
    childObj.values = childObj?.values.map(item => item.date === date ? { ...item, value: value } : item)

    const childValue = parentColumData.children.map(eachCell => eachCell.value).reduce((prev, next) => prev + next)
    if (parentColumData.value < childValue) {
        alert(`sum of the sub financial values of ${parentName} will not be exceded ${parentName} value`)
    }
    return { parentColumData, childObj }
}

function* uploadTempBussPlanFileSaga(action){
    try {
        const {
            uploadFileCmpObject = {}
        } = action.payload.uploadedFileObj;
        yield put(setLoading({status: true})); 
        const uploadedFileUrlObj = yield call(uploadTempBussPlanFileApi,action.payload.uploadedFileObj);
        if(!uploadedFileUrlObj.error){
            const businessPlanTemplateMenuData = yield  select(businessPlanTemplateMenuDataFrmRedux);
            let businessPlanTempFiles = yield  select(businessPlanUploadedTempFilesFrmRedux);
            let renderedUploadedTmpFiles = JSON.parse(JSON.stringify(businessPlanTempFiles));
            const validPathKeys = uploadFileCmpObject.filePath.split('.');
            let target = validPathKeys.slice(0, validPathKeys.length - 1).reduce((a, k) => {
                if(isNaN(k)) {
                    return a[k]
                } else {
                    const renderedObjList = Array.isArray(a) && a.filter((obj)=> obj?.id?.toString() === k?.toString());
                    return renderedObjList?.length && renderedObjList[0];
                }
            }, businessPlanTemplateMenuData);
            target[validPathKeys[validPathKeys.length -1]] = uploadedFileUrlObj.uploadedFileUrl;
            uploadFileCmpObject.fileName ? target.name = uploadFileCmpObject.fileName : null;
            if(!uploadFileCmpObject.isSavePermanently){
                const existingUploadedFileIndex = renderedUploadedTmpFiles.findIndex((tmpFilesObj)=>tmpFilesObj.destPath === uploadFileCmpObject.filePath);
                if(existingUploadedFileIndex > 0) {
                    renderedUploadedTmpFiles = renderedUploadedTmpFiles.map((tempFilesObj)=>{
                        tempFilesObj.destPath === uploadFileCmpObject.filePath ? 
                            {...tempFilesObj, fileUrl: uploadedFileUrlObj.uploadedFileUrl} : tempFilesObj;
                    });
                } else {
                    renderedUploadedTmpFiles.push({
                        ...uploadFileCmpObject,
                        fileUrl: uploadedFileUrlObj.uploadedFileUrl,
                        destPath: uploadFileCmpObject.filePath,
                    });
                }
                yield put(setBussPlanUploadedTempFiles(renderedUploadedTmpFiles));
            }
            yield put(setBussPlanData(businessPlanTemplateMenuData));
        } 
        yield put(setLoading({status: false})); 
    } catch (error) {
        yield put(setLoading({status: false})); 
        console.log("inside uploadTempBussPlanFileSaga ",error);
    }
}

export function* watchUploadTempBussPlanFileSaga(){
    yield takeLatest(UPLOAD_AND_GET_TEMP_BUSS_PLAN_FILE_URL,uploadTempBussPlanFileSaga);
}

function* getParsedPastAndFutureFinDataArr(financialDbObj){
    try {
        let parsedFinancialData = null;
        if (financialDbObj.pastFinancialData && financialDbObj.futureFinancialData) {
            const { financialProjectionPageDataObj, businessValuationPageDataObj } = yield* getFinancialProjectionAndBussValuationPageData();
            parsedFinancialData = yield call(editPastFinancialAndParseFinancialDbObjToFrontendFormat, financialDbObj, financialProjectionPageDataObj, businessValuationPageDataObj);
            parsedFinancialData = yield call(editFutureFinancialAndParseFinancialDbObjToFrontendFormat, financialDbObj, parsedFinancialData, parsedFinancialData);
        } else if (financialDbObj.pastFinancialData) {
            const { financialProjectionPageDataObj, businessValuationPageDataObj } = yield* getFinancialProjectionAndBussValuationPageData();
            parsedFinancialData = yield call(editPastFinancialAndParseFinancialDbObjToFrontendFormat, financialDbObj, financialProjectionPageDataObj, businessValuationPageDataObj);
        } else if (financialDbObj.futureFinancialData) {
            const { financialProjectionPageDataObj, businessValuationPageDataObj } = yield* getFinancialProjectionAndBussValuationPageData();
            parsedFinancialData = yield call(editFutureFinancialAndParseFinancialDbObjToFrontendFormat, financialDbObj, financialProjectionPageDataObj, businessValuationPageDataObj);
        }
        return parsedFinancialData;
    } catch (error) {
        console.log('inside getParsedPastAndFutureFinDataArr ', error);
        throw error;
    }
}

function* getFinProjAndBussValPageDataObjFrmParsedObj(parsedFinancialData) {
    const financialProjectionPageData = {
        profitLoss: parsedFinancialData?.profitLoss,
        balanceSheet: parsedFinancialData?.balanceSheet,
        turnoverGraphData: parsedFinancialData?.turnoverGraphData,
        grossProfitGraphData: parsedFinancialData?.grossProfitGraphData,
        operatingProfitGraphData: parsedFinancialData?.operatingProfitGraphData
    };
    const bussinessValuationPageData = {
        turnoverValuation: parsedFinancialData?.turnoverValuation,
        ebitdaValuation: parsedFinancialData?.ebitdaValuation,
        turnoverValuationGraphData: parsedFinancialData?.turnoverValuationGraphData,
        ebitdaValuationGraphData: parsedFinancialData?.ebitdaValuationGraphData
    };
    return { financialProjectionPageData, bussinessValuationPageData };
}

function* getBusinessPlanCompanyInsightSaga(action) {
    const {
        companyId = ""
    } = action.payload;
    try {
        const response = yield call(getBusinessPlanCompanyInsightsApi, companyId);
        const selectedCompetitors = yield select(state => state.businessPlanTemplate.businessPlanTemplateMenuData.analysis.children?.competitiveAnalysis?.data?.selectedCompetitors);
        if (!response.error) {
            const modifiedSelectedCompetitors = selectedCompetitors.map(selectedCompetitor => {
                if (selectedCompetitor.id === companyId) {
                    return { ...selectedCompetitor, ...response.insights }
                }
                return { ...selectedCompetitor }
            });
            yield put(setBussTempMainPageData({ parentLabel: "analysis", childLabel: "competitiveAnalysis", data: { selectedCompetitors: modifiedSelectedCompetitors } }))
        }
    } catch (error) {
        console.log('error in companyInsightsSaga', error)
    }
}

export function* watchBusinessPlanCompanyInsightsSaga() {
    yield takeLatest(GET_BUSINESS_PLAN_COMPANY_INSIGHTS_DATA, getBusinessPlanCompanyInsightSaga);
}

function* getBusinessPlanPagePreviewSaga(action) {
    const {
        previewObj = {}
    } = action.payload;
    try {
        const response = yield call(getBusinessPlanPagePreviewApi, previewObj);
        if (!response.error) {
            yield put(setBussPlanPagePreviewHtml(response));
        }
        yield put(setPreviewPageLoading({status: false}));
    } catch (error) {
        yield put(setPreviewPageLoading({status: false}));
        console.log('error in getBusinessPlanPagePreviewSaga', error)
    }
}

export function* watchGetBusinessPlanPagePreviewSaga() {
    yield takeLatest(GET_BUSINESS_PLAN_PAGE_PREVIEW, getBusinessPlanPagePreviewSaga);
}

function* getBusinessPlanIndustryInsightsSaga(action) {
    const {
        indMix = []
    } = action.payload;
    try {
        const response = yield call(getBusinessPlanIndustryInsightsApi, indMix);
        if (!response.error) {
            yield put(setBusinessPlanIndustryInsights(response.industryInsights));
        }
    } catch (error) {
        console.log('error in getBusinessPlanIndustryInsightsSaga', error)
    }
}

export function* watchGetBusinessPlanIndustryInsightsSaga() {
    yield takeLatest(GET_BUSINESS_PLAN_INDUSTRY_INSIGHTS, getBusinessPlanIndustryInsightsSaga);
}

