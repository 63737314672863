import { RESTAPI_URL } from '../common/config';
import { commonFetch } from './fetchComImpl';
import { refreshTokenApi } from './refreshTokenApi';


export const getIndustrySicCodesApi = async (section, pageNumber, size) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/industrySicCodes/getIndustrySicCodes/${section}/${pageNumber}/${size}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log("getIndustrySicCodesApi error", error);
        throw error;
    }

}

export const getCompanyListApi = async(sicCode, size, pageNumber) =>{
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );

        const response = await commonFetch(`${RESTAPI_URL}/industrySicCodes/getCompanyList/${sicCode}/${size}/${pageNumber}`,
            'GET',
            {},
            new Headers({
            'content-type': 'application/json'  
            })
        );

        return response

    }
    catch(error){
        console.log("getCompanyListApi error", error)
    }
}