import {
    SAVE_BUSINESS_PLAN_DATA_SUCCESS, SET_BUSINESS_PLAN_COLOUR_SCHEME,
    SET_BUSINESS_PLAN_INDUSTRY_INSIGHTS, SET_BUSINESS_PLAN_MENU_DATA,
    SET_BUSINESS_PLAN_PAGE_PREVIEW_HTML, SET_BUSINESS_PLAN_PREVIEW_PAGE_LOADING,
    SET_BUSINESS_PLAN_UPLOADED_TEMP_FILES,
    SET_BUSS_TEMP_MAIN_PAGE_DATA, SET_BUSS_TEMP_PAGE_STATUS,
    SET_INDUSTRY_DETAILS
} from "../actions/actionConstants";

const businessPlanTemplateReducer = (state = {
    uniqueId: "",
    purchaseDate: null,
    endDate: null,
    status: null,
    uploadedTempFiles: [],
    colourScheme: {
        title: {
            colour: "#FFFFFF",
            label: "Title Colour"
        },
        mainText: {
            colour: "#000000",
            label: "Main Text Colour"
        },
        headerFooter: {
            colour: "#2b4d7e",
            label: "Page Header/Footer Colour"
        },
        secondary: {
            colour: "#cb0245",
            label: "Secondary Colour"
        },
    },
    previewPageLoading: { status: false },
    previewPageHtml: "",
    initialBusinessPlanTemplateMenuData: {},
    businessPlanTemplateMenuData: {
        summary: {
            label: "executive summary",
            isCompleted: true,
            route: "/summary",
            data: {
                statement: "",
                summary: ""
            }
        },
        overview: {
            label: "business overview",
            showSlider: false,
            route: "/description",
            children: {
                description: {
                    label: "company description",
                    isCompleted: true,
                    route: "/description",
                    data: {
                        companyName: "",
                        companyId: "",
                        startDate: null,
                        telephoneNum: "",
                        website: "",
                        address: "",
                        description: "",
                    },

                },
                history: {
                    label: "company history",
                    isCompleted: true,
                    route: "/history",
                    data: {
                        history: "",
                        achievements: []
                    },

                },
                keyPeople: {
                    label: "Key People",
                    isCompleted: true,
                    route: "/key-people",
                    data: {
                        teamOverview: "",
                        keyPeoples: []
                    },

                },
                ownership: {
                    label: "Ownership",
                    isCompleted: true,
                    route: "/ownership",
                    data: {
                        shareholderTypeList: [],
                        shareholderList: [],
                        subsidiaryList: [],
                        parentCompany: "",
                        fundOwnershipHistory: ""
                    },

                }
            }
        },
        objectives: {
            label: "business objectives",
            isCompleted: true,
            route: "/objectives",
            data: {
                vision: "",
                objectives: []
            }
        },
        analysis: {
            label: "market analysis",
            showSlider: false,
            route: "/industry-insight",
            children: {
                industryInsight: {
                    label: "industry insight",
                    isCompleted: true,
                    route: "/industry-insight",
                    data: {
                        industryDescription: "",
                        businessTempIndustryEdit: false,
                        industryAnalysis: "",
                        indMix: []
                    },

                },
                personas: {
                    label: "Target Market & Personas",
                    isCompleted: true,
                    route: "/personas",
                    data: {
                        targetMarket: "",
                        targetPersonas: [],
                        marketLocations: []
                    },

                },
                strengthsWeeknesses: {
                    label: "Strengths & Weaknesses",
                    isCompleted: true,
                    route: "/strengths-weeknesses",
                    data: {
                        objectives: []
                    },

                },
                competitiveAnalysis: {
                    label: "Competitive Analysis",
                    isCompleted: true,
                    route: "/competitive-analysis",
                    data: {
                        selectedCompetitors: [],
                        suggestedCompetitors: [],
                        prevCompanyId: ''
                    },

                },
            }
        },
        salesMarketingStrategy: {
            label: "Sales & Marketing Strategy",
            showSlider: false,
            route: "/product-services",
            children: {
                productServices: {
                    label: "Products & Services",
                    isCompleted: true,
                    route: "/product-services",
                    data: {
                        productServices: []
                    },

                },
                pricingModelStrategy: {
                    label: "Pricing Model & Strategy",
                    isCompleted: true,
                    route: "/pricing-strategy",
                    data: {
                        strategy: ""
                    },

                },
                customerAcqusition: {
                    label: "Customer Acquistion",
                    isCompleted: true,
                    route: "/customer-acqusition",
                    data: {
                        customerAcqusitionOverView: ``,
                        marketingChannels: []
                    },

                },
                salesForecast: {
                    label: "Sales Forecast",
                    isCompleted: true,
                    route: "/sales-forecast",
                    data: {
                        salesOverview: "",
                        objectives: []
                    },

                },
            }
        },
        managementOperationalPlan: {
            label: "Management & Operational Plan",
            showSlider: false,
            route: "/location-of-operation",
            children: {
                locationOperation: {
                    label: "Location of Operation",
                    isCompleted: true,
                    route: "/location-of-operation",
                    data: {
                        locationList: []
                    },

                },
                keySuppliersRelationships: {
                    label: "Key Suppliers & Relationships",
                    isCompleted: true,
                    route: "/key-suppliers-relationships",
                    data: {
                        keySuppliers: []
                    },

                },
                staffEmploymentPlans: {
                    label: "Current Staff & Future Employment Plans",
                    isCompleted: true,
                    route: "/staff-employment-plans",
                    data: {
                        currentStaff: {},
                        futureStaff: {},
                        keyStaffRoleDesc: "",
                        prospectiveStaffRolesDesc: "",
                        currentlyEmployStaff: ""
                    },

                },
                legalRegulatoryInsurance: {
                    label: "Legal, Regulatory & Insurance",
                    isCompleted: true,
                    route: "/legal-regulatory-insurance",
                    data: {
                        legalConsideration: ""
                    },

                },
            }
        },
        financials: {
            label: "Financials",
            showSlider: false,
            route: "/financial-projections",
            children: {
                financialProjections: {
                    label: "Financial Projections",
                    isCompleted: true,
                    route: "/financial-projections",
                    data: {
                        profitLoss: [],
                        balanceSheet: []
                    },

                },
                fundingRequirementUseFunds: {
                    label: "Funding Requirement & Use Of Funds",
                    isCompleted: true,
                    route: "/funding-requirement-funds",
                    data: {
                        requirementFunds: [],
                        useOfFunds: []
                    },

                },
                businessValuation: {
                    label: "Business Valuation",
                    isCompleted: true,
                    route: "/business-valuation",
                    data: {
                        turnoverValuation: [],
                        ebitdaValuation: []
                    },

                }
            }
        },
        // appendix: {
        //     label: "Appendix",
        //     route: "/appendix",
        //     menuImage: {
        //         normal: "/assets/svgs/file_with_left_plus_black.svg",
        //         active: "/assets/svgs/file_with_left_plus_yellow.svg"
        //     },
        //     showSlider: false,
        //     data: {
        //         documentsArr: []
        //     }
        // },
        // cashFlowTemplate: {
        //     label: "Cash Flow Template",
        //     route: "/cashflow",
        //     menuImage: {
        //         normal: "/assets/svgs/file_with_left_plus_black.svg",
        //         active: "/assets/svgs/file_with_left_plus_yellow.svg"
        //     },
        //     showSlider: false,
        //     data: {}
        // },
        brandingPersonalisation: {
            label: "Branding & Personalisation",
            route: "/branding-personalisation",
            menuImage: {
                normal: "/assets/svgs/buss_brand_person_black.svg",
                active: "/assets/svgs/buss_brand_person_yellow.svg"
            },
            showSlider: false,
            data: {
                companyLogoObj: {},
                coverPageImage: {}
            }
        },

    }
}, action) => {
    switch (action.type) {

        case SET_BUSS_TEMP_MAIN_PAGE_DATA: {
            let newTemplateData = { ...state.businessPlanTemplateMenuData };
            const { parentLabel, childLabel, data } = action.payload.pageObj;
            if (childLabel) {
                newTemplateData[parentLabel].children[childLabel].data = { ...newTemplateData[parentLabel].children[childLabel].data, ...data }
            } else {
                newTemplateData[parentLabel].data = { ...newTemplateData[parentLabel].data, ...data }
            }
            return { ...state, businessPlanTemplateMenuData: newTemplateData }
        }
        case SET_BUSS_TEMP_PAGE_STATUS: {
            let newTemplateData = { ...state.businessPlanTemplateMenuData };
            const { parentLabel, childLabel, status } = action.payload.pageObj;
            if (childLabel) {
                newTemplateData[parentLabel].children[childLabel].isCompleted = status;
            } else {
                newTemplateData[parentLabel].isCompleted = status;
            }
            return { ...state, businessPlanTemplateMenuData: newTemplateData }
        }
        case SAVE_BUSINESS_PLAN_DATA_SUCCESS: {
            return {
                ...state,
                businessPlanTemplateMenuData: action.payload.planObj.data,
                uniqueId: action.payload.planObj.uniqueId,
                purchaseDate: action.payload.planObj.purchaseDate,
                businessPlanDataSaved: action.payload.planObj.userId ? true : false,
                endDate: action.payload.planObj.endDate,
                initialBusinessPlanTemplateMenuData: JSON.parse(JSON.stringify(action.payload.planObj.data)),
                colourScheme: action.payload.planObj.colourScheme || state.colourScheme,
                status: action.payload.planObj.status || state.status
            }
        }
        case SET_INDUSTRY_DETAILS: {
            let newTemplateData = { ...state.businessPlanTemplateMenuData };
            let indMix = [...newTemplateData.analysis.children.industryInsight.data.indMix];
            indMix = indMix.map(indMixObj => {
                return { ...indMixObj, ...action.payload.industryDetails?.find(indDetailObj => indDetailObj.SICCode === indMixObj.SIC2007) }
            });
            newTemplateData.analysis.children.industryInsight.data.indMix = indMix;
            return {
                ...state,
                businessPlanTemplateMenuData: newTemplateData
            }
        }
        case SET_BUSINESS_PLAN_MENU_DATA: {
            return {
                ...state,
                businessPlanTemplateMenuData: action.payload.businessPlanObj,
            }
        }
        case SET_BUSINESS_PLAN_UPLOADED_TEMP_FILES: {
            return {
                ...state,
                uploadedTempFiles: action.payload.uploadedTempFiles,
            }
        }
        case SET_BUSINESS_PLAN_COLOUR_SCHEME: {
            return {
                ...state,
                colourScheme: action.payload.colourScheme,
            }
        }
        case SET_BUSINESS_PLAN_PAGE_PREVIEW_HTML: {
            return {
                ...state,
                previewPageHtml: action.payload.previewPageHtml,
            }
        }
        case SET_BUSINESS_PLAN_INDUSTRY_INSIGHTS: {
            let newTemplateData = { ...state.businessPlanTemplateMenuData };
            newTemplateData.analysis.children.strengthsWeeknesses.data.pomandaIndustryInsights = action.payload.industryInsight
            return {
                ...state,
                businessPlanTemplateMenuData: newTemplateData
            }
        }
        case SET_BUSINESS_PLAN_PREVIEW_PAGE_LOADING: {
            return {
                ...state,
                previewPageLoading: action.payload.loadingObj
            }
        }
        case "RESET_BUSINESS_TEMPLATE_PLAN_DETAILS": {
            return {
                ...state,
                uniqueId: "",
                purchaseDate: null,
                endDate: null,
                status: null,
                uploadedTempFiles: [],
                colourScheme: {
                    title: {
                        colour: "#FFFFFF",
                        label: "Title Colour"
                    },
                    mainText: {
                        colour: "#000000",
                        label: "Main Text Colour"
                    },
                    headerFooter: {
                        colour: "#2b4d7e",
                        label: "Page Header/Footer Colour"
                    },
                    secondary: {
                        colour: "#cb0245",
                        label: "Secondary Colour"
                    },
                },
                previewPageLoading: { status: false },
                previewPageHtml: "",
                initialBusinessPlanTemplateMenuData: {},
            }
        }
        default:
            return state;
    }
}

export default businessPlanTemplateReducer;