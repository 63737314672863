import { call, put, select, takeLatest } from "redux-saga/effects";
import { getIndustrySicCodesResponse, getSicCodeBasedCompanyListResponse} from "../redux/actions/industrySicCodesAction";
import {GET_INDUSTRY_SIC_CODES_REQUEST, GET_INDUSTRY_SIC_CODE_COMPANY_LIST_REQUEST} from  "./../redux/actions/actionConstants"
import {getIndustrySicCodesApi, getCompanyListApi} from './../api/industrySicCodeApi'

function* getIndustrySicCodesRequestSaga(action){
    try{
        const index = yield select(state=>state.industrySicCodes.index)
        const pageNumber = yield select(state=>state.industrySicCodes.pageNumber)
        if(action.payload.pageNumber !== pageNumber || action.payload.index !== index)
        {
            const response = yield call(getIndustrySicCodesApi, action.payload.index, action.payload.pageNumber, action.payload.size)
            if(response.error){
                console.log("Error in getIndustrySicCodesRequestSaga", response.error)
            }
            else{
                const {data=[], pageNumber=1, index='A', size=0, totalRecords=0} = response.industrySicCodes
                yield put(getIndustrySicCodesResponse(data, pageNumber, index, size, totalRecords))
            }
        }
    }
    catch(error)
    {
        console.log("getIndustrySicCodesRequestSaga error", error)
    }

}

function* getCompanyListRequestSaga(action){
    try{
        if(action.payload.sicCode) {
            const response = yield call(getCompanyListApi, action.payload.sicCode, action.payload.size, action.payload.pageNumber)
            if(response.error){
                console.log("Error in getCompanyListRequestSaga", response.error)
            }
            else{
                const {data=[], sicCode='0', size=0, totalRecords=0} = response.companyList
                yield put(getSicCodeBasedCompanyListResponse(data,  sicCode, size, totalRecords))
            }
        }
    }
    catch(error){
        console.log("getCompanyListRequestSaga error", error)
    }
}

export function* watchGetIndustrySicCodesRequest(){
    yield takeLatest(GET_INDUSTRY_SIC_CODES_REQUEST, getIndustrySicCodesRequestSaga)
}

export function* watchGetSicCodeBasedCompanyListRequest(){
    yield takeLatest(GET_INDUSTRY_SIC_CODE_COMPANY_LIST_REQUEST, getCompanyListRequestSaga)
}