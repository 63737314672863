import {
    GET_DIRECTOR_DATA_FAILED,
    GET_DIRECTOR_DATA_SUCCESS,
    GET_RELATED_DIRECTOR_FAILED,
    GET_RELATED_DIRECTOR_SUCCESS,
    GET_DIRECTOR_REPORT_LINK,
    EMPTY_DIRECTOR_PROFILE_DATA
} from '../actions/actionConstants';

const INITIAL_STATE = {
    directorData : {},
    directorError : "",
    relatedDirectorError : "",
    relatedDirector : [],
    directorReportLink : ""
}

const directorReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_DIRECTOR_DATA_FAILED:
            return { ...state, directorError: action.payload.message }
        case GET_DIRECTOR_DATA_SUCCESS:
            return { ...state, directorData: action.payload.directorData, relatedDirector: action.payload.directorData.relatedDirectorList }
        case GET_RELATED_DIRECTOR_FAILED:
            return { ...state, relatedDirectorError: action.payload.message }
        case GET_RELATED_DIRECTOR_SUCCESS:
            return { ...state, relatedDirector: action.payload.relatedDirector }
        case GET_DIRECTOR_REPORT_LINK:
            return { ...state, directorReportLink: action.payload.directorReportLink }
        case EMPTY_DIRECTOR_PROFILE_DATA: 
            return { ...state, directorData: {} }
        default:
            return state;
    }
}

export default directorReducer;
