import React, { memo,useRef, useLayoutEffect } from 'react';
import * as d3 from 'd3';
import { withRouter } from 'react-router';

const D3TreeMap = (props) => {
    let divRef = useRef(null);
    const { data = {}, screen = {}, onClickEventType = "", colors=[], isWatchListProfile=false} = props;
    let prevNodeColor = "";
    useLayoutEffect(()=>{
        if(data && data.children && data.children.length>0){
            let width = divRef && divRef.current ? divRef.current.clientWidth : 600,
                height = divRef && divRef.current ? divRef.current.clientHeight : 350;
            let format = d3.format(",d");
            let color = d3.scaleOrdinal()
                .range((colors?.length ? colors : d3.schemeCategory10)
                    .map(function (c) { c = d3.rgb(c); c.opacity = colors?.length ? 1 : 0.6; return c; }));
            let toolTip = d3.select("body").append("div").attr("class", "node-tooltip")
    
            let treemap = data => d3.treemap()
                .tile(d3.treemapSquarify)
                .size([width, height])
                .padding(1)
                .round(true)
                (d3.hierarchy(data)
                .sum(d => parseInt(d.value))
                .sort((a, b) =>  b.height - a.height || parseInt(b.value) - parseInt(a.value)));
            
            const root = treemap(data);
            let parentDiv = d3.select(divRef.current);
            parentDiv.selectAll('*').remove();
                let nodes = parentDiv.selectAll(".node")
                .data(root.leaves())
                .enter().append("div")
                .attr("class", "node")
                .style("left", function (d) { return d.x0 + "px"; })
                .style("top", function (d) { return d.y0 + "px"; })
                .style("width", function (d) { return d.x1 - d.x0 + 2 + "px"; })
                .style("height", function (d) { return d.y1 - d.y0 + 2 +"px"; })
                .style("background", d => { while (d.depth > 1) d = d.parent; return color(d.data.name)})
                .on("mouseenter",event=> { 
                    prevNodeColor = event.target.style.background; 
                    const background = isWatchListProfile ? prevNodeColor : "linear-gradient(222.56deg, #FAD767 0%, #DE962C 100%)"
                    event.target.style.background = background;
                    event.target.querySelector(".node-label").classList.add("white");
                    event.target.querySelector(".node-value").classList.add("white");
                })
                .on("mouseleave",event=>{
                    event.target.style.background = prevNodeColor;
                    event.target.querySelector(".node-label").classList.remove("white");
                    event.target.querySelector(".node-value").classList.remove("white");
                    toolTip.style("display", "none");
                })
                .on("click",(_event, node) => {
                    const url = props.onClickUrl(node.data.id);
                    props.sendActivityTracking({eventType: onClickEventType, attribute3: url})
                    toolTip.style("display", "none");
                    props.resetAllSearchParams({ searchText: ""});
                    props.history.push(url);
                })
                .on("mousemove",(event,d)=>{
                    if(screen?.deviceType === "desktop_lg" || screen?.deviceType === "desktop_xl"){
                        toolTip.style("left", event.pageX + 10 + "px")
                        toolTip.style("top", event.pageY - 20 + "px")
                        toolTip.style("display", "block");
                        toolTip.html(`${d.data.name}: ${d.data.value}`)
                    }
                })
            
                nodes.append("div")
                .attr("class", "node-label")
                .text(function (d) { return d.data.name.substring(d.data.name.lastIndexOf(".") + 1).split(/(?=[A-Z][^A-Z])/g).join("\n"); })

                nodes.append("div")
                .attr("class", "node-value")
                .text(function (d) { return format(parseInt(d.data.value)); });
        }
    },[data,screen])

    return (
        <div ref={divRef} className="graph-parent"/>
    );
};

export default withRouter(memo(D3TreeMap));