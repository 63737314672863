import { GET_INDUSTRY_SIC_CODES_RESPONSE, 
        GET_INDUSTRY_SIC_CODE_COMPANY_LIST_RESPONSE, 
        RESET_COMPANY_LIST } from './../actions/actionConstants'

const industrySicCodesReducer = (state = {industrySicCodesList:[],companyList:[]}, action) =>{
    switch (action.type) {
        case GET_INDUSTRY_SIC_CODES_RESPONSE:
            return { ...state, industrySicCodesList: action.payload.industrySicCodes,  
                pageNumber: action.payload.pageNumber,
                activeIndex: action.payload.index,
                size: action.payload.size,
                totalRecordsPerSection: action.payload.totalRecords
            };
        case GET_INDUSTRY_SIC_CODE_COMPANY_LIST_RESPONSE:
            return { ...state, companyList: action.payload.companyList,  
                sicCode: action.payload.sicCode,
                size: action.payload.size,
                totalRecordsPersicCode: action.payload.totalRecordsPersicCode,
                inProgress: false
            };
        case RESET_COMPANY_LIST:
            return { ...state, totalRecordsPersicCode : 0, inProgress : true}
        default:
            return state;
    }
}

export default industrySicCodesReducer;
