export const validateBase64String = (base64Str) => {
    const base64Regex = /^\bdata:.*;base64,.*$/;
    return base64Regex.test(base64Str);
}

export const dataURIToBlob = (dataURI, filename) => {
    const arr = dataURI.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]);
    let n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}