import {
    TOGGLE_EDIT_USER_PROFILE,
    GET_USER_PROFILE_REQUEST,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILED,
    SAVE_USER_PROFILE_REQUEST,
    SAVE_USER_PROFILE_SUCCESS,
    SAVE_USER_PROFILE_FAILED,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILED,
    CLEAR_CHANGE_PASSWORD_ERROR_MESSAGE,
    CONTACT_US_REQUEST,
    CONTACT_US_SUCCESS,
    CONTACT_US_FAILED,
    SET_USERS_DAILY_SEARCH_INFO,
    GET_CREDIT_REPORT,
    GET_CREDIT_REPORT_PPT_URL,
    UPDATE_USER_INFO,
    UPDATE_USER_FREE_CR_COUNT,
    CREATE_STRIPE_SESSION_ADD_CARD,
    CALL_DELETE_PAYMENT_METHOD_DATA,
    CALL_DELETE_PAYMENT_METHOD_DATA_SUCCESS,
    CALL_DELETE_PAYMENT_METHOD_DATA_ERROR,
    CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA,
    CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA_ERROR, SIGN_OUT_CLICK,
    COMPANY_HOUSE_EMAIL_NOTIFICATION,
    COMPANY_HOUSE_EMAIL_NOTIFICATION_FLAG,
    UPDATE_USER_REPORT_CREDITS,
    GET_ORDER_HISTORY,
    SET_ORDER_HISTORY,
    GET_VALUATION_REPORTS_LIST,
    GET_VALUATION_REPORTS_PPT_REQUEST,
    GET_BUSINESS_PLAN_REPORTS_PPT_REQUEST,
    SET_VALUATION_REPORTS_LIST,
    UPDATE_VALUATION_REPORT_CREDIT_SUCCESS,
    UPDATE_VALUATION_REPORT_CREDIT_REQUEST,
    SAVE_VR_WHITE_LABEL_INFO_REQUEST,
    SET_VR_WHITE_LABEL_INFO,
    GET_VR_WHITE_LABEL_INFO_REQUEST,
    RESTRICT_ACCESS_FOR_PROSPER_USER,
    RESET_WHITE_LABEL_INFO_REQUEST,
    SET_WHITE_LABEL_FORM_SUBMIT_MSGS,
    GET_BUSINESS_PLANS_LIST,
    SET_BUSINESS_PLANS_LIST,
    UPDATE_BUSINESS_PLANS_CREDIT_SUCCESS,
    UPDATE_BUSINESS_PLANS_CREDIT_REQUEST,
    GET_CREDIT_REPORT_REQUEST,
    GET_COMPANY_REPORT_REQUEST,
    REMOVE_FORM_DATA_REQUEST,
    REMOVE_FORM_DATA_SUCCESS,
    REMOVE_FORM_DATA_FAILED,
    SET_ADDRESS_BOOK_LIST_SUCCESS,
    GET_ADDRESS_BOOK_LIST_REQUEST,
    GET_ADDRESS_BOOK_LIST_FAILED,
    ADDRESS_BOOK_CREATE_FILE_REQUEST,
    ADDRESS_BOOK_MOVE_FILE_REQUEST,
    ADDRESS_BOOK_DELETE_FILE_REQUEST,
    SET_CONTACT_CREDIT_COUNTS
} from './actionConstants'

export const toggleEditProfile = toggleStatus => ({
    type: TOGGLE_EDIT_USER_PROFILE,
    payload: { toggleStatus }
})

export const getUserProfileRequest = () => ({
    type: GET_USER_PROFILE_REQUEST
})

export const getUserProfileSuccess = (userProfile, pomandaUser, userOrderHistory={}, userCardDetails=[],valuationReports=[]) => ({
    type: GET_USER_PROFILE_SUCCESS,
    payload: { userProfile, pomandaUser, userOrderHistory, userCardDetails, valuationReports }
})

export const getUserProfileFailed = (message) => ({
    type: GET_USER_PROFILE_FAILED,
    payload: { message }
})

export const saveUserProfile = (userInfo) => ({
    type: SAVE_USER_PROFILE_REQUEST,
    payload: { userInfo }
})

export const saveUserProfileSuccess = () => ({
    type: SAVE_USER_PROFILE_SUCCESS
})

export const saveUserProfileFailed = (message) => ({
    type: SAVE_USER_PROFILE_FAILED,
    payload: { message }
})

export const changePasswordRequest = ({ oldPassword, newPassword }) => ({
    type: CHANGE_PASSWORD_REQUEST,
    payload: { oldPassword, newPassword }
})

export const changePasswordSuccess = (message) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: { message }
})

export const changePasswordFailed = (errorMessage) => ({
    type: CHANGE_PASSWORD_FAILED,
    payload: { errorMessage }
})

export const clearChangePasswordErrorMessage = () => ({
    type: CLEAR_CHANGE_PASSWORD_ERROR_MESSAGE
})

export const contactUsRequest = (userInfo) => ({
    type: CONTACT_US_REQUEST,
    payload: { userInfo }
})

export const contactUsSuccess = (successMessage) => ({
    type: CONTACT_US_SUCCESS,
    payload: { successMessage }
})

export const contactUsFailed = (errorMessage) => ({
    type: CONTACT_US_FAILED,
    payload: { errorMessage }
});

export const setDailySearchInfo = (isDailyFreeSearchExpired) => ({
    type: SET_USERS_DAILY_SEARCH_INFO,
    payload: { isDailyFreeSearchExpired }
})

export const getCreditReport = (docId) => ({
    type: GET_CREDIT_REPORT,
    payload: { docId }
});

export const getCreditReportPPT = (docId) => ({
    type: GET_CREDIT_REPORT_PPT_URL,
    payload: { docId }
});

export const updateUserInfo = (userProfile) => ({
    type: UPDATE_USER_INFO,
    payload: { userProfile }
})

export const updateUserFreeCRCountAction = (freeCRCount) => ({
    type: UPDATE_USER_FREE_CR_COUNT,
    payload: { freeCRCount }
})

export const callDeletePaymentMethodAction=(cardDetails)=>({
    type:CALL_DELETE_PAYMENT_METHOD_DATA,
    payload:cardDetails
})
export const callDeletePaymentMethodSuccessAction=(userCardDetails)=>({
    type:CALL_DELETE_PAYMENT_METHOD_DATA_SUCCESS,
    payload:{userCardDetails}
})
export const callDeletePaymentMethodErrorAction=(errorData)=>({
    type:CALL_DELETE_PAYMENT_METHOD_DATA_ERROR,
    payload:{errorData}
})

export const callMakeDefaultPaymentMethodAction = (cardDetails) => ({
    type: CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA,
    payload: cardDetails
})
 
export const callMakeDefaultPaymentMethodErrorAction = (errorData) => ({
    type: CALL_MAKE_DEFAULT_PAYMENT_METHOD_DATA_ERROR,
    payload: { errorData }
})
export const callAddCardCreateSessionAction = () => ({
    type: CREATE_STRIPE_SESSION_ADD_CARD,
    payload: {}
})

export const signOutClickAction=(data)=>({
    type:SIGN_OUT_CLICK,
    payload:{data}
})

export const companyHouseEmailNotification = (flag) => ({
    type:COMPANY_HOUSE_EMAIL_NOTIFICATION,
    payload:{flag}
})

export const companyHouseEmailNotificationFlag = (flag) => ({
    type:COMPANY_HOUSE_EMAIL_NOTIFICATION_FLAG,
    payload:{flag}
})

export const updateUserReportCredits = (creditCount) => ({
    type: UPDATE_USER_REPORT_CREDITS,
    payload: { creditCount }
})

export const setContactCreditCounts = (contactCreditCount) => ({
    type: SET_CONTACT_CREDIT_COUNTS,
    payload: { contactCreditCount }
})

export const getOrderHistory=(userId,filter)=>({
    type: GET_ORDER_HISTORY,
    payload: { userId, filter }
});

export const setOrderHistory=(orderHistory)=>({
    type: SET_ORDER_HISTORY,
    payload: { orderHistory }
});

export const addressBookCreateFileRequest=(createParams)=>({
    type: ADDRESS_BOOK_CREATE_FILE_REQUEST,
    payload: {createParams}
});

export const addressBookDeleteFileRequest=(deleteParams)=>({
    type: ADDRESS_BOOK_DELETE_FILE_REQUEST,
    payload: {deleteParams}
});

export const addressBookMoveFileRequest=(moveParams)=>({
    type: ADDRESS_BOOK_MOVE_FILE_REQUEST,
    payload: {moveParams}
});

export const getAddressBookListRequest=(userId)=>({
    type: GET_ADDRESS_BOOK_LIST_REQUEST,
    payload: {userId}
});

export const setAddressBookListSuccess=(addressBookList)=>({
    type: SET_ADDRESS_BOOK_LIST_SUCCESS,
    payload: { addressBookList }
});

export const getAddressBookListFailed=(error)=>({
    type: GET_ADDRESS_BOOK_LIST_FAILED,
    payload: { error }
});

export const getValutionReportsList=(userId,filter,companyId="")=>({
    type: GET_VALUATION_REPORTS_LIST,
    payload: { userId, filter ,companyId}
});

export const getValutionReportsPptRequest=(link)=>({
    type: GET_VALUATION_REPORTS_PPT_REQUEST,
    payload: { link}
});

export const getBusinessPlanReportPptRequest=(link)=>({
    type: GET_BUSINESS_PLAN_REPORTS_PPT_REQUEST,
    payload: { link}
});

export const setValutionReportsList=(valuationReports)=>({
    type: SET_VALUATION_REPORTS_LIST,
    payload: { valuationReports }
})

export const updateValuationReportCreditSuccess = (response) => ({
    type: UPDATE_VALUATION_REPORT_CREDIT_SUCCESS,
    payload:{ response }
})

export const updateBusinessPlansCreditSuccess = (response) => ({
    type: UPDATE_BUSINESS_PLANS_CREDIT_SUCCESS,
    payload:{ response }
})

export const updateValuationReportCreditRequest = (requestedParams) => ({
    type: UPDATE_VALUATION_REPORT_CREDIT_REQUEST,
    payload:{ requestedParams }
});

export const updateBusinessPlansCreditRequest = () => ({
    type: UPDATE_BUSINESS_PLANS_CREDIT_REQUEST,
    payload:{}
});

export const saveVRWhiteLabelDataRequest=(whiteLabelInfo)=>({
    type: SAVE_VR_WHITE_LABEL_INFO_REQUEST,
    payload: { whiteLabelInfo }
});

export const setVRWhiteLabelInfo=(whiteLabelInfo,successMsg,errorMsg)=>({
    type: SET_VR_WHITE_LABEL_INFO,
    payload: { whiteLabelInfo,successMsg,errorMsg }
});

export const getVRWhiteLabelInfoRequest=(userId)=>({
    type: GET_VR_WHITE_LABEL_INFO_REQUEST,
    payload: { userId }
})

export const setRestrictionForProsperUser = (isAccessable) => ({
    type : RESTRICT_ACCESS_FOR_PROSPER_USER,
    payload : {isAccessable}
});

export const resetWhiteLabelInfoRequest=(userId)=>({
    type: RESET_WHITE_LABEL_INFO_REQUEST,
    payload: { userId }
})

export const setWhiteLabelFormSubmitMsgs=(successMsg,errorMsg)=>({
    type: SET_WHITE_LABEL_FORM_SUBMIT_MSGS,
    payload: { successMsg, errorMsg }
})

export const getBusinessPlansList=(userId,filter,companyId="")=>({
    type: GET_BUSINESS_PLANS_LIST,
    payload: { userId, filter ,companyId}
});

export const setBusinessPlansList=(businessPlans)=>({
    type: SET_BUSINESS_PLANS_LIST,
    payload: { businessPlans }
});

export const regeneratePdfApiRequest=(cohId)=>({
    type: GET_CREDIT_REPORT_REQUEST,
    payload: {cohId}
});

export const regenerateCpPdfApiRequest=(payloadData)=>({
    type: GET_COMPANY_REPORT_REQUEST,
    payload: payloadData
});

export const removeFormDataRequest = (userInfo) => ({
    type: REMOVE_FORM_DATA_REQUEST,
    payload: userInfo
});

export const removeFormDataSuccess = (successMessage) => ({
    type: REMOVE_FORM_DATA_SUCCESS,
    payload: {successMessage}
});

export const removeFormDataFailed = (errorMessage) => ({
    type: REMOVE_FORM_DATA_FAILED,
    payload: {errorMessage}
})