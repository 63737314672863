import {
    BUSINESS_PLAN_ONBOARD_BASIC_DATA,
    GET_BUSINESSPLAN_LOCATIONS_SUCCESS,
    TOGGLE_SUGGESTED_AREAS_MODAL,
    TOGGLE_ADD_LOCATION_MODAL,
    SET_SELECTED_BUSINESS_LOCATIONS,
    GET_SUGGESTED_NEARBY_LOCATIONS_SUCCESS,
    GET_ALL_BUSINESS_PLAN_ONBOARD_SUCCESS,
    GET_BUSINESS_PLAN_ONBOARD_SUCCESS,
    GET_ACTIVE_BUSINESSES_AND_SALES_SUCCESS,
    GET_SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_SUCCESS,
    SET_BUSINESS_PLAN_ONBOARD_EMPTY,
    SET_ALL_KEY_PEOPLE_SUCCESS,
    GET_BUSINESS_PLAN_DESCRIPTION_OPENAI_SUCCESS
} from "../actions/actionConstants";

const businessPlanReducer = (state = {
    businessPlanOnboardObj: {
        industryEditFlag: false,
        industryDescription: "",
        businessPlanConvo: null,
        industryList: [],
        onboardQuestion: "",
        keyPeople: []
    }
}, action) => {
    switch (action.type) {
        case TOGGLE_SUGGESTED_AREAS_MODAL:
            return {
                ...state,
                isSuggestedAreasModalOpen: action.payload.toggleStatus
            }
        case GET_SUGGESTED_NEARBY_LOCATIONS_SUCCESS:
            return {
                ...state,
                suggestedAreaDetails: action.payload.suggestedNearbyLocations
            }
        case BUSINESS_PLAN_ONBOARD_BASIC_DATA:
            const newBusinessPlanOnboardObj = state?.businessPlanOnboardObj
            return {
                ...state,
                businessPlanOnboardObj: {
                    ...newBusinessPlanOnboardObj,
                    ...action.payload.businessPlanObj
                }
            }
        case GET_BUSINESSPLAN_LOCATIONS_SUCCESS:
            return {
                ...state,
                businessPlanLocations: action.payload.locations
            }
        case TOGGLE_ADD_LOCATION_MODAL:
            return {
                ...state,
                isAddLocationModalOpen: action.payload.toggleStatus
            }
        case GET_ACTIVE_BUSINESSES_AND_SALES_SUCCESS:
            return {
                ...state,
                selectedIndustriesSalesAndBusinesses: action.payload.combinedBusinessAndSalesObj
            }
        case SET_SELECTED_BUSINESS_LOCATIONS:
            return {
                ...state,
                selectedBusinessLocations: action.payload.selectedBusinessLocations
            }
        case GET_ALL_BUSINESS_PLAN_ONBOARD_SUCCESS:
            return {
                ...state,
                businessPlanListObj: action.payload.businessPlanListObj
            }
        case GET_BUSINESS_PLAN_ONBOARD_SUCCESS:
            return {
                ...state,
                selectedBusinessLocations: action.payload.businessPlan.locationList,
                businessPlanOnboardObj: action.payload.businessPlan
            }
        case SET_ALL_KEY_PEOPLE_SUCCESS:
            const existingBusinessPlanOnboard = state?.businessPlanOnboardObj
            let { allKeyPeoples = [] } = action.payload
            const keyPeople = allKeyPeoples?.map((obj, id) => { return { ...obj, name: `${obj.name?.firstName || ""} ${obj.name?.lastName || ""}`, id } })
            return {
                ...state,
                businessPlanOnboardObj: {
                    ...existingBusinessPlanOnboard,
                    keyPeople,
                    btnInActiveStatus: true
                }
            }
        case GET_SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_SUCCESS:
            const existingBusinessPlanOnboardObj = state?.businessPlanOnboardObj
            const { businessPlanId } = action.payload.businessPlanResp
            return {
                ...state,
                businessPlanOnboardObj: { ...existingBusinessPlanOnboardObj, businessPlanId }
            }
        case SET_BUSINESS_PLAN_ONBOARD_EMPTY:
            return {
                ...state,
                businessPlanOnboardObj: state?.businessPlanOnboardObj?.userType ? { userType: state?.businessPlanOnboardObj?.userType } : {}
            }
        case GET_BUSINESS_PLAN_DESCRIPTION_OPENAI_SUCCESS:
            const {
                conversation = null,
                bpResponse = {}
            } = action.payload.businessPlanDescription;
            const {
                description = "",
                SIC2007 = [],
                question = ""
            } = bpResponse;
            return {
                ...state,
                businessPlanOnboardObj: {
                    ...state.businessPlanOnboardObj,
                    industryDescription: description,
                    businessPlanConvo: conversation,
                    industryList: SIC2007,
                    onboardQuestion: question,
                }
            }
        default:
            return { ...state }
    }
}

export default businessPlanReducer;