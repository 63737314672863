import { GET_INDUSTRY_PROFILE_REQUEST, GET_INDUSTRY_PROFILE_FAILED, GET_INDUSTRY_PROFILE_SUCCESS,
    GET_INDUSTRY_PROFILE_SEARCH_SUCCESS, 
    EMPTY_INDUSTRY_PROFILE_DATA
} from "../actions/actionConstants";

const industryProfileReducer = (state = {
    isIndustryInfoShare: {},
}, action) => {
    switch (action.type) {
        case GET_INDUSTRY_PROFILE_REQUEST:
            return { ...state, industryId: action.payload.industryId };
        case GET_INDUSTRY_PROFILE_FAILED:
            return { ...state, industryProfileError: action.payload.message }
        case GET_INDUSTRY_PROFILE_SUCCESS:
            return { ...state, industryProfileData: action.payload.industryProfileData }
        case GET_INDUSTRY_PROFILE_SEARCH_SUCCESS:
                return { ...state, searchedResult: action.payload.searchedResult }
        case EMPTY_INDUSTRY_PROFILE_DATA: 
            return { ...state, industryProfileData: {} }
        default:
            return state;
    }
}
export default industryProfileReducer;