import { RESTAPI_URL } from '../common/config';
import { commonFetch } from './fetchComImpl'
import localStorageApi from './localStorageApi';
import analyticsApi from './analyticsApi'
import { refreshTokenApi } from './refreshTokenApi';
import moment from 'moment';

export async function signupApi(signupInfo) {
    try {
        let hubspotutkCookie = localStorageApi.getCookie("hubspotutk");
        let userData = localStorageApi.getUserData();
        signupInfo.hubspotutk = hubspotutkCookie ? hubspotutkCookie : null;
        signupInfo.ipAddress = userData?.ipAddress ? userData.ipAddress : null;
        // signupInfo.selectedSubscriptionPlan = selectedSubscriptionPlan;
        
        signupInfo.dailyFreeSearchesCount = createDailySearchCountObj();
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const signupResp = await commonFetch(`${RESTAPI_URL}/user/signup`,
            'POST',
            signupInfo,
            new Headers({
                'Content-Type': 'application/json'
            }));
        if (signupResp.error === true) {
            return signupResp;
        } 
        else {
            let trakerObj = localStorageApi.getUserData()
            trakerObj.accessToken = signupResp.token
            trakerObj.sessionEnd = trakerObj.accessToken ? 'true' : 'false'
            localStorageApi.setUserData(trakerObj);
            analyticsApi.setCustomData("userId", signupResp.userInfo._id);
            analyticsApi.setCustomData("userName", signupResp.userInfo.email);
            localStorageApi.setCookie('accessToken',  signupResp.token);
            localStorageApi.setCookie('sessionEnd', 'true');
            return signupResp
        }
    } catch (e) {
        console.error('inside loginSignupApi signupApi ',e);
        throw e;
    }
}

const createDailySearchCountObj = () => {
    const searchCountObj = localStorageApi.getSearchCountObj();
    if(searchCountObj && moment(searchCountObj.searchDate).isSame(moment(), 'day')){
        let companyIds = [], directorIds = [];
        searchCountObj.companyDirectorIds.forEach(element => {
            if(element.type==="company"){
                companyIds.push(element.id);
            } else {
                directorIds.push(element.id);
            }
        });
        return { companyIds, directorIds, searchDate: searchCountObj.searchDate };
    } else {
        return null;
    }
}
export const loginRequestApiCall = async (credentials) => {
    try {
        // credentials.selectedSubscriptionPlan = selectedSubscriptionPlan;
        let dailyFreeSearchesCount = createDailySearchCountObj();
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const loginResp = await commonFetch(`${RESTAPI_URL}/user/login`,
            'POST',
            { ...credentials, dailyFreeSearchesCount },
            new Headers({
                'Content-Type': 'application/json'
            }));

        if (loginResp.token) {
            let trakerObj = localStorageApi.getUserData()
            trakerObj.accessToken = loginResp.token
            trakerObj.sessionEnd = trakerObj.accessToken ? 'true' : 'false'
            localStorageApi.setUserData(trakerObj)
            analyticsApi.setCustomData("userId", loginResp.userId);
            analyticsApi.setCustomData("userName", loginResp.userInfo.email);
            localStorageApi.setCookie('accessToken', loginResp.token);
            localStorageApi.setCookie('sessionEnd', 'true');
        }
        return loginResp
    } catch (e) {
        console.log('inside loginRequestApiCall ', e);
        throw e;
    }
}

export const resetPasswordEmailApiCall = async (email) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const resetPasswordEmailRes = await commonFetch(`${RESTAPI_URL}/user/sendPasswordLinkEmail/${email}`,
            'GET',
            {},
            new Headers({
                'Content-Type': 'application/json'
            })
        );

        return resetPasswordEmailRes;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const checkResetPasswordLinkValidApiCall=async(email)=>{
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/checkResetPasswordLinkValid/${email}`,
            'GET',
            {},
            new Headers({
                'Content-Type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}
export const resetPasswordApiCall = async ({email, password}) => {
    try {
        const params = { email, password };
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/resetPassword`,
            'POST',
            params,
            new Headers({
                'Content-Type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const otherPlatformLoginApiCall = async (loginSignupParams) => {
    try {
        const dailyFreeSearchesCount = createDailySearchCountObj();
        const hubspotutkCookie = localStorageApi.getCookie("hubspotutk");
        const userData = localStorageApi.getUserData();
        loginSignupParams.hubspotutk = hubspotutkCookie ? hubspotutkCookie : null;
        loginSignupParams.ipAddress = userData.ipAddress ? userData.ipAddress : null;

        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const loginResp = await commonFetch(`${RESTAPI_URL}/user/otherPlatformLogin`,
            'POST',
            {...loginSignupParams, dailyFreeSearchesCount},
            new Headers({
                'Content-Type': 'application/json'
            })
        );
        if (loginResp.token) {
            const trakerObj = localStorageApi.getUserData()
            trakerObj.accessToken = loginResp.token
            trakerObj.sessionEnd = trakerObj.accessToken ? 'true' : 'false'
            localStorageApi.setUserData(trakerObj)
            analyticsApi.setCustomData("userId", loginResp.userId);
            analyticsApi.setCustomData("userName", loginResp.userInfo.email);
            localStorageApi.setCookie('accessToken', loginResp.token);
            localStorageApi.setCookie('sessionEnd', 'true');
        }
        return loginResp;
    } catch (error) {
        console.error('inside singleSignOnApiCall ',error);
        throw error;
    }
}