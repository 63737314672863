import { call, put, takeLatest } from "redux-saga/effects";
import {
    GET_BLOG_LIST_REQUEST, GET_BLOG_DETAIL_REQUEST
} from "../redux/actions/actionConstants";
import {
    getBlogListApi, getBlogDetailApi
} from "../api/blogApi";
import {
    getBlogListSuccess, getBlogDetailSuccess
} from "../redux/actions/blogAction";

function* getBlogListSaga(action) {
    try {
        let response = yield call(getBlogListApi,action.payload.from, action.payload.size);
        if (response.error) {
            // yield put(getShareholderTypeListFailed(response.message));
        } else {
            yield put(getBlogListSuccess(response.blogList,response.blogsCount));
        }
    } catch (error) {
        console.log('inside getBlogListSaga ', error);
    }
}

export function* watchgetBlogListSaga() {
    yield takeLatest(GET_BLOG_LIST_REQUEST, getBlogListSaga);
}

function* getBlogDetailSaga(action) {
    try {
        let response = yield call(getBlogDetailApi,action.payload._id);

        if (response.error) {
            // yield put(getShareholderTypeListFailed(response.message));
        } else {
            yield put(getBlogDetailSuccess({ blogDetail: response.blogDetail, detailBlogList: response.blogList }))
            // yield put(getBlogDetailSuccess({ blogDetail: response.blogDetail, detailBlogList: blogList }))

        }
    } catch (error) {
        console.log('inside getBlogListSaga ', error);
    }
}

export function* watchgetBlogDetailSaga() {
    yield takeLatest(GET_BLOG_DETAIL_REQUEST, getBlogDetailSaga);
}


