import { getDefaultFiltersForCompany } from '../../common/SearchCommonFunctions';
import {
    TOGGLE_SEARCH_ESTIMATION,
    SET_SEARCH_ESTIMATION,
    TOGGLE_SEARCH_FILTER,
    SET_COMPANY_SEARCH_APPLIED_FILTER,
    TOGGLE_SEARCH_LIST_VIEW,
    SET_FILTER_OBJECT_LIST,
    SHOW_SEARCH_OPTIONS_TABLET,
    SEARCH_COMPANY_API,
    SEARCH_COMPANY_API_SUCCESS,
    SEARCH_COMPANY_API_FAILED,
    RESET_COMPANY_SEARCH_LIST,
    SET_POWER_SEARCH_TYPE_REQUEST,
    GET_DIRECTOR_LIST_SUCCESS,
    GET_DIRECTOR_LIST_REQUEST,
    RESET_DIRECTOR_SEARCH_LIST,
    SET_COMPANY_SEARCH_PARAMS,
    SET_DIRECTOR_SEARCH_PARAMS,
    SET_DIRECTOR_SEARCH_APPLIED_FILTER,
    SET_SHAREHOLDER_SEARCH_APPLIED_FILTER,
    SET_INDUSTRY_SEARCH_APPLIED_FILTER,
    SET_INDUSTRY_FILTER_DATA,
    GET_INDUSTRY_FILTER_DATA_FAILED,
    SET_ACTIVITY_TRACKING_DATA,
    SET_TRY_POWER_SEARCH,
    SET_TREE_FILTER_QUERY_PARAMS,
    SET_SAVED_SEARCHES,
    SET_ADD_CURRENT_SEARCH,
    SET_SAVED_SEARCHES_LIST,
    SET_SAVE_CURRENT_SEARCH_ERROR,
    SET_RESET_FILTER_TYPE,
    SET_APPLIED_LIST_MODAL_TOGGLE_STATUS,
    SET_APPLIED_RESET_MODAL_TOGGLE_STATUS,
    TOGGLE_ESTIMATE_WARNING,
    SET_INDUSTRY_SEARCH_PARAMS,
    GET_INDUSTRY_LIST_REQUEST,
    GET_SHAREHOLDER_LIST_REQUEST,
    SET_SHAREHOLDER_SEARCH_PARAMS,
    GET_SHAREHOLDER_LIST_SUCCESS,
    GET_CHARGES_LIST_REQUEST,
    SET_CHARGES_SEARCH_PARAMS,
    GET_CHARGES_LIST_SUCCESS,
    GET_INDUSTRY_LIST_SUCCESS,
    RESET_INDUSTRY_SEARCH_LIST,
    RESET_CHARGES_SEARCH_LIST,
    SET_CHARGES_SEARCH_APPLIED_FILTER,
    RESET_SHAREHOLDER_SEARCH_LIST,
    SET_REGION_SEARCH_APPLIED_FILTER,
    SET_REGION_SEARCH_PARAMS,
    GET_REGION_LIST_REQUEST,
    GET_REGION_LIST_SUCCESS,
    RESET_REGION_SEARCH_LIST,
    SET_FILTER_RESTRICTION_MODAL,
    SET_DOWNLOAD_SEARCH_RESULTS_BTN_STATUS,
    SET_POWER_SEARCH_SHARE,
    TOGGLE_FEATURE_LOCK_STATUS,
    SET_WATCHING_FILTER_QUERY_PARAMS
} from '../actions/actionConstants';

const SEARCH_INITIAL_STATE = {
    searchlistCount: 3,
    cornerMorphsearchPosition: 0,
    isSearchFilter: false,
    isEstimateOn: true,
    appliedFilterList: getDefaultFiltersForCompany(),
    directorAppliedFilterList: {},
    industryAppliedFilterList: {},
    shareholderAppliedFilterList: {},
    chargesAppliedFilterList: {},
    regionAppliedFilterList: {},
    listView: true,
    filterObjectList: [],
    freePSearchApiCallTime: 5,
    isSearchOptionsTablet: false,
    searchCompanyList: [],
    searchCompanyCount: 0,
    companyQueryData: {
        sortField: "companyName",
        sortType: "asc",
        scrollPosition: {
            scroll: 0, 
            scrollRetain: false
        }
    },
    directorQueryData: {
        sortField: "FirstName",
        sortType: "asc",
        scrollPosition: {
            scroll: 0, 
            scrollRetain: false
        }
    },
    industryQueryData: {
        sortField: "industryName",
        sortType: "asc",
        scrollPosition: {
            scroll: 0, 
            scrollRetain: false
        }
    },
    shareholderQueryData: {
        sortField: "fullName",
        sortType: "asc",
        scrollPosition: {
            scroll: 0, 
            scrollRetain: false
        }
    },
    chargesQueryData: {
        sortField: "companyName",
        sortType: "asc",
        scrollPosition: {
            scroll: 0, 
            scrollRetain: false
        }
    },
    regionQueryData: {
        sortField: "regionName",
        sortType: "asc",
        scrollPosition: {
            scroll: 0, 
            scrollRetain: false
        }
    },
    searchIndustryList: [],
    searchIndustryCount: 0,
    searchShareholderList: [],
    searchShareholderCount: 0,
    searchChargesList: [],
    searchChargesCount: 0,
    searchDirectorList: [],
    searchDirectorCount: 0,
    searchRegionList: [],
    searchRegionCount: 0,
    powerSearchType: "",
    industryFilterList: [],
    locationFilterList: [],
    postCodeDistrictList: [],
    getIndustryFilterError: "",
    activityTracking: {},
    tryPowerSearch: false,
    tryPowerSearchData:{},
    powerSearchShare: {},
    showSavedSearches: false,
    showAddCurrentSearch: false,
    savedSearches: [],
    savedSearchesCount: 0,
    saveCurrentSearchError: "",
    industryListForMix: [],
    isAppliedListModalOpen: false,
    isRestConfirmModalOpen: false,
    estimateWarning: true,
    psEstimationList: ["company", "charges", "shareholder"],
    filterRestrictionModal: { status: false},
    downloadSearchResultsBtnStatus: false
};

const searchReducer = (state = {...SEARCH_INITIAL_STATE}, action) => {

    switch (action.type) {
        case TOGGLE_SEARCH_ESTIMATION:
            return { ...state, isEstimateOn: !state.isEstimateOn };
        case SET_SEARCH_ESTIMATION:
                return { ...state, isEstimateOn: action.payload.estToggleStatus };    
        case SHOW_SEARCH_OPTIONS_TABLET:
            return { ...state, isSearchOptionsTablet: action.payload };
        case TOGGLE_SEARCH_FILTER: {
            let oldState = { ...state };
            if (!action.payload.filterToggleState) {
                oldState.filterObjectList = [];
            };
            oldState.isSearchFilter = action.payload.filterToggleState;
            return { ...oldState };
        };
        case SET_COMPANY_SEARCH_APPLIED_FILTER:
            return {
                ...state,
                appliedFilterList: action.payload && action.payload !== null ? action.payload
                    : SEARCH_INITIAL_STATE.appliedFilterList
            };
        case SET_DIRECTOR_SEARCH_APPLIED_FILTER:
            return {
                ...state,
                directorAppliedFilterList: action.payload && action.payload !== null ? action.payload
                    : SEARCH_INITIAL_STATE.directorAppliedFilterList
            };
        case SET_INDUSTRY_SEARCH_APPLIED_FILTER:
            return {
                ...state,
                industryAppliedFilterList: action.payload && action.payload !== null ? action.payload
                    : SEARCH_INITIAL_STATE.industryAppliedFilterList
            };
        case SET_SHAREHOLDER_SEARCH_APPLIED_FILTER:
            return {
                ...state,
                shareholderAppliedFilterList: action.payload && action.payload !== null ? action.payload
                    : SEARCH_INITIAL_STATE.shareholderAppliedFilterList
            };
        case SET_CHARGES_SEARCH_APPLIED_FILTER:
            return {
                ...state,
                chargesAppliedFilterList: action.payload && action.payload !== null ? action.payload
                    : SEARCH_INITIAL_STATE.chargesAppliedFilterList
            };
        case SET_REGION_SEARCH_APPLIED_FILTER:
            return {
                ...state,
                regionAppliedFilterList: action.payload.appliedFilter && action.payload.appliedFilter !== null ? action.payload.appliedFilter
                    : SEARCH_INITIAL_STATE.regionAppliedFilterList
            };
        case SET_INDUSTRY_FILTER_DATA:
            const {
                industryFilterData = [],
                locationFilterData = [],
                postCodeDistrictList = [],
                industryListForMix = []
            } = action.payload.onloadFilterData;
            return { ...state, industryFilterList: industryFilterData, locationFilterList: locationFilterData, postCodeDistrictList: postCodeDistrictList, industryListForMix };
        case GET_INDUSTRY_FILTER_DATA_FAILED:
            return { ...state, getIndustryFilterError: action.payload };
        case TOGGLE_SEARCH_LIST_VIEW:
            return { ...state, listView: !state.listView };
        case SET_FILTER_OBJECT_LIST:
            return { ...state, filterObjectList: action.payload };
        case SEARCH_COMPANY_API:
            let newSearchParams = {...state.companyQueryData,...action.payload}
            delete newSearchParams.filters;
            delete newSearchParams.trackingObj;
            return { ...state, companyQueryData: newSearchParams }
        case SEARCH_COMPANY_API_SUCCESS:
            return {
                ...state,
                searchCompanyList: action.payload.companySearchResp.searchData,
                searchCompanyCount: action.payload.companySearchResp.total
            }
        case SEARCH_COMPANY_API_FAILED:
                return { ...state }
        case RESET_COMPANY_SEARCH_LIST:
            return { ...state, searchCompanyList: [], searchCompanyCount: 0 }
        case SET_COMPANY_SEARCH_PARAMS:
            let companyQueryData = { ...state.companyQueryData, ...action.payload.companyQueryData }
            return { ...state, companyQueryData }
        case SET_DIRECTOR_SEARCH_PARAMS:
            let directorQueryData = { ...state.directorQueryData, ...action.payload.directorQueryData }
            return { ...state, directorQueryData }
        case RESET_DIRECTOR_SEARCH_LIST:
            return { ...state, searchDirectorList: [], searchDirectorCount: 0 }
        case SET_POWER_SEARCH_TYPE_REQUEST:
            return { ...state, powerSearchType: action.payload.type }
        case GET_DIRECTOR_LIST_REQUEST:
            return { ...state, directorQueryData: { ...state.directorQueryData, ...action.payload.directorQueryData }}
        case GET_DIRECTOR_LIST_SUCCESS:
            return {
                ...state,
                searchDirectorList: action.payload.directorSearchResp.searchData,
                searchDirectorCount: action.payload.directorSearchResp.total
            }
        case SET_ACTIVITY_TRACKING_DATA:
            return {
                ...state,
                activityTracking: {
                    ...state.activityTracking,
                    [action.payload.trackingObj.type]: action.payload.trackingObj.obj
                }
            }
        case SET_TRY_POWER_SEARCH:
            return { ...state, tryPowerSearch: action.payload.tryStatus,tryPowerSearchData:action.payload.tryPowerSearchData
                }
        case SET_TREE_FILTER_QUERY_PARAMS:
            return { ...state, powerSearchShare: { treeQueryParams: action.payload.treeQueryParams } }
        case SET_WATCHING_FILTER_QUERY_PARAMS:
            return { ...state, powerSearchShare: { ...state.powerSearchShare,  ...action.payload.filterObj } }
        case SET_POWER_SEARCH_SHARE:
                return { ...state, powerSearchShare: { ...state.powerSearchShare,  ...action.payload.powerSearchShareObj } }
        case SET_SAVED_SEARCHES:
            return {...state, showSavedSearches:action.payload.status}
        case SET_ADD_CURRENT_SEARCH:
            return {...state, showAddCurrentSearch:action.payload.status}
        case SET_SAVED_SEARCHES_LIST:
            return {...state, savedSearches:action.payload.savedSearches.list, savedSearchesCount: action.payload.savedSearches.count}
        case SET_SAVE_CURRENT_SEARCH_ERROR:
            return {...state, saveCurrentSearchError:action.payload.errorMsg}
        case SET_RESET_FILTER_TYPE:
            return {...state, resetFilterType:action.payload.resetFilterType}
        case SET_APPLIED_LIST_MODAL_TOGGLE_STATUS:
            return { ...state, isAppliedListModalOpen: action.payload.toggleStatus };
        case SET_APPLIED_RESET_MODAL_TOGGLE_STATUS:
            return { ...state, isRestConfirmModalOpen: action.payload.toggleStatus };
        case TOGGLE_ESTIMATE_WARNING:
            return { ...state, estimateWarning: action.payload.toggleStatus };
        case SET_INDUSTRY_SEARCH_PARAMS:
        case GET_INDUSTRY_LIST_REQUEST:
            return { ...state, industryQueryData: { ...state.industryQueryData, ...action.payload.industryQueryData }}
        case GET_INDUSTRY_LIST_SUCCESS:
            return {
                ...state,
                searchIndustryList: action.payload.industrySearchResp.searchData,
                searchIndustryCount: action.payload.industrySearchResp.total
            }
        case RESET_INDUSTRY_SEARCH_LIST:
            return { ...state, searchIndustryList: [], searchIndustryCount: 0 }
        case SET_SHAREHOLDER_SEARCH_PARAMS:
        case GET_SHAREHOLDER_LIST_REQUEST:
            return { ...state, shareholderQueryData: { ...state.shareholderQueryData, ...action.payload.shareholderQueryData }}
        case GET_SHAREHOLDER_LIST_SUCCESS:
            return {
                ...state,
                searchShareholderList: action.payload.shareholderSearchResp.searchData,
                searchShareholderCount: action.payload.shareholderSearchResp.total
            }
        case RESET_SHAREHOLDER_SEARCH_LIST:
            return { ...state, searchShareholderList: [], searchShareholderCount: 0 }

        case SET_CHARGES_SEARCH_PARAMS:
        case GET_CHARGES_LIST_REQUEST:
            return { ...state, chargesQueryData: { ...state.chargesQueryData, ...action.payload.chargesQueryData }}
        case GET_CHARGES_LIST_SUCCESS:
            return {
                ...state,
                searchChargesList: action.payload.chargesSearchResp.searchData,
                searchChargesCount: action.payload.chargesSearchResp.total
            }
        case RESET_CHARGES_SEARCH_LIST:
            return { ...state, searchChargesList: [], searchChargesCount: 0 }
        case SET_REGION_SEARCH_PARAMS:
        case GET_REGION_LIST_REQUEST:
            return { ...state, regionQueryData: { ...state.regionQueryData, ...action.payload.regionQueryData }}
        case GET_REGION_LIST_SUCCESS:
            return {
                ...state,
                searchRegionList: action.payload.regionSearchResp.searchData,
                searchRegionCount: action.payload.regionSearchResp.total
            }
        case RESET_REGION_SEARCH_LIST:
            return { ...state, searchRegionList: [], searchRegionCount: 0 }
        case SET_FILTER_RESTRICTION_MODAL:
            return { ...state, filterRestrictionModal: action.payload }
        case SET_DOWNLOAD_SEARCH_RESULTS_BTN_STATUS:
            return { ...state, downloadSearchResultsBtnStatus: action.payload.status }
        case TOGGLE_FEATURE_LOCK_STATUS:
            return { ...state, isFeatureLock: action.payload.status}
        default:
            return state;
    }
}

export default searchReducer;

