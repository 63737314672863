import { detect } from 'detect-browser'
const { matches } = require('ip-matching')

const getDeviceType = (userAgent) => {
  const ua = userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

const parseUrlToGetTid = (url) => {
  //let url_string = url
  return new URL(url).searchParams.get('tid')
}

const parseRequestCookies = async (cookies) => {
  var list = {},
    rc = cookies

  rc &&
    rc.split(';').forEach(function (cookie) {
      var parts = cookie.split('=')
      list[parts.shift().trim()] = decodeURI(parts.join('='))
    })
  return list
}

const getTidCookie = async (tid, cookieList) => {
  let first_tid = null
  let latest_tid = null
  if (
    tid !== null &&
    cookieList['first_tid'] &&
    cookieList['first_tid'] !== 'undefined' &&
    cookieList['first_tid'] != null
  ) {
    first_tid = cookieList['first_tid']
    latest_tid = tid
  } else {
    first_tid = tid
    latest_tid = tid
  }
  return { first_tid: first_tid, latest_tid: latest_tid }
}
const checkWhitelistIPS = (reqHeaders, ipAddress, WHITELIST_IPS) => {
    try {
    const whitelistIPArray = WHITELIST_IPS.split(',')

      let ipExists = whitelistIPArray.map((ip) => matches(ipAddress, ip))
      let isBot=reqHeaders  && typeof reqHeaders !=='undefined' && /bot|googlebot|crawler|spider|robot|crawling/i.test(reqHeaders["user-agent"])
      return ipExists.includes(true) || isBot
    } catch (error) {
      console.log(`error in checkWhitelistIPS for request ${JSON.stringify(reqHeaders)} and ip ${ipAddress}`, error)
      return true
    }
  }

const checkWhitelistUserAgent = (reqHeaders, WHITELIST_USER_AGENT) => {
  try {
      var pattern = new RegExp(WHITELIST_USER_AGENT,'i');
      let isBot = reqHeaders  && typeof reqHeaders !=='undefined' && pattern.test(reqHeaders["user-agent"])
      return isBot
    } catch (error) {
      console.log(`error in checkWhitelistUserAgent for user-agent ${reqHeaders["user-agent"]}`, error)
      return true
    }
  }

const prepareTrackingObj = async (req, ipAddress) => {
  let tid = null
  let tidList = {}
  let trackerObj = {}
  var fullUrl = req.protocol + '://' + req.get('host') + req.originalUrl
  tid = await parseUrlToGetTid(fullUrl)
  const cookieList = await parseRequestCookies(req.headers.cookies)
  tidList = await getTidCookie(tid, cookieList)
  const deviceType = await getDeviceType(req.headers['user-agent'])
  let browser = await detect(req.headers['user-agent'])

  if(browser == null)
  {
    browser = {}
    browser['name'] = "";
    browser['version'] = "";
    browser['os'] = "";
    console.log("Unrecognised browser user-agent", req.headers['user-agent'])
  }
  
  trackerObj = {
    browser: browser.name,
    browserVersion: browser.version,
    os: browser.os,
    rememberMe: false,
    ipAddress: ipAddress,
    deviceType: deviceType,
    first_tid: tidList.first_tid,
    latest_tid: tidList.latest_tid,
    tid: tid,
  }
  return trackerObj
}

export {
  parseRequestCookies,
  getDeviceType,
  parseUrlToGetTid,
  getTidCookie,
  prepareTrackingObj,
  checkWhitelistIPS,
  checkWhitelistUserAgent
}
