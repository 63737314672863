import { 
    GET_LOCATION_PROFILE_FAILED, GET_LOCATION_PROFILE_SEARCH_SUCCESS, 
    GET_LOCATION_PROFILE_SUCCESS, CLEAR_LOCATION_PROFILE_DATA 
} from '../actions/actionConstants';

const locationProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_LOCATION_PROFILE_SUCCESS:
            return { ...state, locationProfileData: action.payload.locationProfileObj };
        case GET_LOCATION_PROFILE_FAILED:
            return { ...state, locationProfileFailedMessage: action.payload.errorMessage };
        case GET_LOCATION_PROFILE_SEARCH_SUCCESS:
            return { ...state, searchResult: [...action.payload.searchResults] };
        case CLEAR_LOCATION_PROFILE_DATA:
            return { ...state, locationProfileData: {} };
        default:
            return state;
    }
}

export default locationProfileReducer;