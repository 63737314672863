import { getSearchFilterByValue } from "../components/SingleSearchBar/SingleSearchBar"

export const getSearchQueryDataObj = (queryData, allAppliedFilters = {}, searchType) => {
    return {
        sortField: queryData.sortField ? queryData.sortField : "",
        sortType: queryData.sortType ? queryData.sortType : "",
        filters: allAppliedFilters[searchType]
    }
}

export const getNameFilterKey = (powerSearchType) => {
    switch (powerSearchType) {
        case "company":
        case "charges":
            return "Company Name"
        case "director":
            return "Director Name"
        case "industry":
        case "region":
            return "Key words"
        case "shareholder":
            return "Shareholder Name"
        default:
            return ""
    }
}

export const getDefaultFiltersForCompany = () => {
    return {
        "company status": {
            "name": "company status",
            "valueType": null,
            "fields": {
                "companyStatusList": {
                    "value": [
                        {
                            "value": "L",
                            "fieldNames": [
                                "Dissolved"
                            ],
                            "displayName": "live",
                            "shareKey": "liv"
                        },
                        {
                            "value": "false",
                            "fieldNames": [
                                "Dormant"
                            ],
                            "displayName": "Exclude Dormant Companies",
                            "shareKey": "edc"
                        }
                    ],
                    "type": "check",
                    "fieldNames": [],
                    "labels": [],
                    "shareUrlKey": "1"
                }
            },
            "type": "check",
            "filterKey": "companyStatus",
            "trackingFilterKey": "company_status"
        }
    }
}

export const getDefaultFilterWithSearch = (value) => {
    const searchFilterObj = getSearchFilterByValue(value).company
    return { company: { ...searchFilterObj, ...getDefaultFiltersForCompany() } }
}