import { 
    GET_INDUSTRY_SIC_CODES_REQUEST,
    GET_INDUSTRY_SIC_CODES_RESPONSE,
    GET_INDUSTRY_SIC_CODE_COMPANY_LIST_REQUEST,
    GET_INDUSTRY_SIC_CODE_COMPANY_LIST_RESPONSE, RESET_COMPANY_LIST
} from "./actionConstants";

export const getIndustrySicCodesRequest = (index,pageNumber, size) => ({
    type: GET_INDUSTRY_SIC_CODES_REQUEST,
    payload:{index, pageNumber, size}
})
export const getSicCodeBasedCompanyListRequest = (sicCode, size, pageNumber) => ({
    type: GET_INDUSTRY_SIC_CODE_COMPANY_LIST_REQUEST,
    payload:{sicCode, size, pageNumber}
})
export const getIndustrySicCodesResponse = (industrySicCodes,pageNumber, index, size,totalRecords) => ({
    type: GET_INDUSTRY_SIC_CODES_RESPONSE,
    payload:{industrySicCodes, pageNumber, index, size, totalRecords}
})

export const getSicCodeBasedCompanyListResponse = (companyList, sicCode, size,totalRecordsPersicCode) => ({
    type: GET_INDUSTRY_SIC_CODE_COMPANY_LIST_RESPONSE,
    payload:{companyList, sicCode, size, totalRecordsPersicCode}
})

export const resetCompanyListRequest = () => ({
    type: RESET_COMPANY_LIST
})