import {
    SET_SELECTED_SUBSCRIPTION_PLAN, CANCEL_USER_SUBSCRIPTION_FAILED,
    SHOW_CANCEL_SUBSCRIPTION_MODAL, CANCEL_USER_SUBSCRIPTION_REQUEST,
    CREATE_STRIPE_SESSION, CREATE_STRIPE_SESSION_FAILED,
    CREATE_STRIPE_SESSION_SUCCESS, SET_SUBSCRIPTION_STATUS_REQUEST,
    SET_SUBSCRIPTION_STATUS_SUCCESS, SET_SUBSCRIPTION_STATUS_FAILED,
    TOGGLE_SUBSCRIPTION_SCREEN, CHECK_FREE_SEARCH_FOR_ANONYMOUS,
    SET_SUBSCRIPTION_MODAL_INITIAL_STATE, SET_SUBSCRIPTION_MODAL_DATA,
    HANDLE_SUBSCRIPTION_BTN,SET_PLAN_SELECTED,
    HANDLE_PAYMENT_SUCCESS_ERROR, CLOSE_SUBSCRIPTION_MODAL,
    RESET_STRIPE_SESSION_RETURN_DATA,
    SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL,
    SET_API_SUBSCRIPTION_PLANS, CHECK_FREE_SEARCH_ANONYMOUS_FOR_PRODUCT, CANCEL_USER_FREE_TRIAL_SUBSCRIPTION_REQ
} from "./actionConstants";

export const toggleSubcriptionScreen = (subObj) => ({
    type:  TOGGLE_SUBSCRIPTION_SCREEN,
    payload: subObj
})
export const setSubcriptionModalData = (subObj) => ({
    type: SET_SUBSCRIPTION_MODAL_DATA,
    payload: subObj
})
export const setSelectedSubscriptionPlan = (selectedSubscriptionPlan) => ({
    type: SET_SELECTED_SUBSCRIPTION_PLAN,
    payload: { selectedSubscriptionPlan }
})

export const createStripeSession = (stripeCreateSessionObj) => ({
    type: CREATE_STRIPE_SESSION,
    payload: stripeCreateSessionObj
});

export const createStripeSessionFailed = (errorMessage) => ({
    type: CREATE_STRIPE_SESSION_FAILED,
    payload: { errorMessage }
});

export const createStripeSessionSuccess = (stripeSession) => ({
    type: CREATE_STRIPE_SESSION_SUCCESS,
    payload: { stripeSession }
});

export const subscriptionStatusRequest = (statusObj) => ({
    type: SET_SUBSCRIPTION_STATUS_REQUEST,
    payload: { statusObj }
});

export const subscriptionStatusSuccess = (successResp) => ({
    type: SET_SUBSCRIPTION_STATUS_SUCCESS,
    payload: { successResp }
});

export const subscriptionStatusFailed = (failResp) => ({
    type: SET_SUBSCRIPTION_STATUS_FAILED,
    payload: { failResp }
});

export const cancelUserSubscriptionRequest = (cancellationObj) => ({
    type: CANCEL_USER_SUBSCRIPTION_REQUEST,
    payload: { cancellationObj }
});

export const cancelUserSubscriptionFailed = (failedMessage) => ({
    type: CANCEL_USER_SUBSCRIPTION_FAILED,
    payload: { failedMessage }
});

export const showCancelSubscriptionModal = (cancelModalStatus) => ({
    type: SHOW_CANCEL_SUBSCRIPTION_MODAL,
    payload: { cancelModalStatus }
});

export const checkFreeSearchForAnonymous = (searchEntity, id) => ({
    type: CHECK_FREE_SEARCH_FOR_ANONYMOUS,
    payload: { searchEntity, id }
});
export const checkFreeSearchAnonymousForProduct = (searchEntity, id) => ({
    type: CHECK_FREE_SEARCH_ANONYMOUS_FOR_PRODUCT,
    payload: { searchEntity, id }
});
export const setSubscriptionModalToInitialstate = () => ({
    type: SET_SUBSCRIPTION_MODAL_INITIAL_STATE,
    payload: {}
})

export const closeSubscriptionModal = () => ({
    type: CLOSE_SUBSCRIPTION_MODAL,
    payload: {}
})

export const handleSubscriptionBtnAction = (planSelected) => ({
    type: HANDLE_SUBSCRIPTION_BTN,
    payload: {planSelected}
})

export const setPlanSelectedAction = (data) => ({
    type: SET_PLAN_SELECTED,
    payload: data
})

export const handlePaymentSuccessErrorAction=(paymentObj)=>({
    type: HANDLE_PAYMENT_SUCCESS_ERROR,
    payload: paymentObj
})
export const resetStripeSessionReturnDataAction=()=>({
    type: RESET_STRIPE_SESSION_RETURN_DATA,
    payload: {}
})
export const setApiSubscriptionPlan= data => ({
    type: SET_API_SUBSCRIPTION_PLANS,
    payload: data
})
export const showCancelSubscriptionSuccessModal = (cancelSubscriptionSuccessModalStatus) => ({
    type: SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL,
    payload: { cancelSubscriptionSuccessModalStatus }
});

// export const checkFreeDirectorSearchForAnonymous = (searchEntity,pnr) => ({
//     type: CHECK_FREE_DIRECTOR_SEARCH_FOR_ANONYMOUS,
//     payload: { searchEntity,pnr }
// });

export const handleCancelUserFreeTrialSubscriptionReq = () => ({
    type: CANCEL_USER_FREE_TRIAL_SUBSCRIPTION_REQ,
    payload: {}
})