import {
    GET_BLOG_LIST_REQUEST,
    GET_BLOG_LIST_SUCCESS,
    GET_BLOG_DETAIL_REQUEST,
    GET_BLOG_DETAIL_SUCCESS
} from "./actionConstants";

export const getBlogListRequest = (from, size) => ({
    type: GET_BLOG_LIST_REQUEST,
    payload: { from, size }
});

export const getBlogListSuccess = (blogList,blogsCount) => ({
    type: GET_BLOG_LIST_SUCCESS,
    payload: {blogList, blogsCount}
});

export const getBlogDetailRequest = (_id) => ({
    type: GET_BLOG_DETAIL_REQUEST,
    payload: {_id}
});

export const getBlogDetailSuccess = (detailBlogObj) => ({
    type: GET_BLOG_DETAIL_SUCCESS,
    payload: {detailBlogObj}
});


