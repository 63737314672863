import { put, takeLatest, call } from "redux-saga/effects";
import {
    getAllOnboardBusinessPlansApi, getBusinessLocationApi,
    saveOrUpdateBusinessPlanOnboardApi, getSuggestedNearbyAreasApi, getActiveBusinessesAndSalesApi, getBusinessPlanOnboardApi, getAllKeyPeopleApi, getBusinessDescriptionOpenAiApi, updateBusinessPlansForCreditsApi
} from "../api/businessPlanApi";
import {
    convertAmtToUKCurrencyAndKMBWithPrecision,
    convertToUkCurrencyWithoutPoundSymbol
} from "../common/CurrencyConverter";
import {
    GET_ACTIVE_BUSINESSES_AND_SALES_REQ,
    GET_ALL_KEY_PEOPLE_API_REQUEST,
    GET_ALL_ONBOARD_BUSINESS_PLAN_REQUEST,
    GET_BUSINESSPLAN_LOCATIONS_REQUEST,
    GET_BUSINESS_PLAN_DESCRIPTION_OPENAI,
    GET_BUSINESS_PLAN_ONBOARD_REQUEST,
    GET_SUGGESTED_NEARBY_LOCATIONS_REQUEST,
    SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_REQUEST,
    UPDATE_BUSINESS_PLANS_FOR_CREDITS,
} from "../redux/actions/actionConstants";
import {
    getAllOnboardBusinessPlanApiSuccess, getBusinessPlanOnboardApiSuccess,
    getBusinessPlanLocationSuccess, getSaveOrUpdateBusinessPlanOnboardSuccess,
    getSuggestedNearbyLocationsSuccess, getActiveBusinessesAndSalesSuccess, getAllKeyPeopleRequest, getAllKeyPeopleApiSuccess, getBusinessDescriptionOpenAiApiSuccess
} from "../redux/actions/businessPlanAction";
import { setLoading } from "../redux/actions/commonAction";
import { getBusinessPlanDataRequest } from "../redux/actions/businessPlanTemplateAction";

function* getBusinessPlanLocationsReq() {
    const response = yield call(getBusinessLocationApi);
    try {
        if (response && !response.error) {
            yield put(getBusinessPlanLocationSuccess(response.businessPlanLocations));
        }
    } catch (error) {
        console.log("error in businessPlanSaga", error);
    }
}

export function* watchGetLocationBusinessPlan() {
    yield takeLatest(GET_BUSINESSPLAN_LOCATIONS_REQUEST, getBusinessPlanLocationsReq);
}

function* getActiveBusinessesAndSalesReq(action) {
    const response = yield call(getActiveBusinessesAndSalesApi, action.payload.industryList);
    try {
        if (response && !response.error) {
            let {
                activeBusinesses = 0,
                activeSales = 0
            } = response.activeBusinessesAndSales

            activeBusinesses = convertToUkCurrencyWithoutPoundSymbol(activeBusinesses);
            activeSales = convertAmtToUKCurrencyAndKMBWithPrecision(activeSales)
            yield put(getActiveBusinessesAndSalesSuccess({ activeBusinesses, activeSales }))
        }
    } catch (error) {
        console.log("error in active sales and businessesSaga", error)
    }
}

export function* watchGetActiveBusinessAndSales() {
    yield takeLatest(GET_ACTIVE_BUSINESSES_AND_SALES_REQ, getActiveBusinessesAndSalesReq)
}

function* getSuggestedNearbyLocations(action) {
    const response = yield call(getSuggestedNearbyAreasApi, action.payload);
    try {
        if (response && !response.error) {
            const {
                nearbyDistrictLocations = [],
                currentCountry = []
            } = response.nearbyLocations;
            let { nearbyRegionAndArea = [] } = response.nearbyLocations;
            nearbyRegionAndArea = nearbyRegionAndArea.sort((a, b) =>
                (Number(a.ord) < Number(b.ord)) ? 1 : (Number(a.ord) > Number(b.ord)) ? -1 : 0)
            const nearbyRegionAndAreaWithCountry = [...nearbyRegionAndArea, ...currentCountry]
            const suggestedNearbyLocations = [{
                heading: "Expand to surrounding areas",
                areas: nearbyDistrictLocations,
                showLimit: 4,
                fieldName: "expandSurroundingAreas"
            }, {
                heading: "Expand further afield",
                areas: nearbyRegionAndAreaWithCountry,
                showLimit: 2,
                fieldName: "expandFutherAreas"
            }]
            yield put(getSuggestedNearbyLocationsSuccess(suggestedNearbyLocations))
        }
    } catch (error) {
        console.log("error in getSuggestedNearbyLocations saga", error);
    }
}

export function* watchSuggestedNearbyBusinessLocations() {
    yield takeLatest(GET_SUGGESTED_NEARBY_LOCATIONS_REQUEST, getSuggestedNearbyLocations)
}

function* saveOrUpdateBusinessPlanOnboardSaga(action) {

    const {
        companyExistingStatus,
        companyName,
        companyAddress,
        tradingName,
        postCode,
        companyId,
        industryList,
        componentId,
        _id: businessPlanId,
        locationList,
        location,
        startdate,
        salesTarget,
        industryDescription = "",
        customerDesc,
        keyPeople
    } = action.payload.businessPlanObj
    const response = yield call(saveOrUpdateBusinessPlanOnboardApi, {
        companyExistingStatus, companyName, companyAddress,
        postCode, companyId, industryList, componentId,
        businessPlanId, locationList, industryDescription,
        location, salesTarget, customerDesc, startdate, tradingName, keyPeople
    });
    try {
        if (response && !response.error) {
            yield put(getSaveOrUpdateBusinessPlanOnboardSuccess(response))
        }
    } catch (error) {
        console.log("error in saveOrUpdateBusinessPlanOnboardSaga saga", error);
    }
}

export function* watchSaveOrUpdateBusinessPlanOnboardSaga() {
    yield takeLatest(SAVE_OR_UPDATE_BUSINESS_PLAN_ONBOARD_REQUEST, saveOrUpdateBusinessPlanOnboardSaga)
}

function* getAllOnboardBusinessPlanSaga() {
    const response = yield call(getAllOnboardBusinessPlansApi);
    try {
        if (response && !response.error) {
            yield put(getAllOnboardBusinessPlanApiSuccess(response.businessPlan))
        }
    } catch (error) {
        console.log("error in getAllOnboardBusinessPlanSaga saga", error);
    }
}

export function* watchGetAllOnboardBusinessPlanSaga() {
    yield takeLatest(GET_ALL_ONBOARD_BUSINESS_PLAN_REQUEST, getAllOnboardBusinessPlanSaga)
}

function* getBusinessPlanOnboardSaga(action) {
    const { businessPlanId } = action.payload
    const response = yield call(getBusinessPlanOnboardApi, businessPlanId);
    try {
        if (response && !response.error) {
            yield put(getBusinessPlanOnboardApiSuccess(response.businessPlan))
        }
    } catch (error) {
        console.log("error in getBusinessPlanOnboardSaga saga", error);
    }
}

export function* watchGetBusinessPlanOnboardSaga() {
    yield takeLatest(GET_BUSINESS_PLAN_ONBOARD_REQUEST, getBusinessPlanOnboardSaga)
}

function* getAllKeyPeopleSaga(action) {
    const { companyId } = action.payload
    const response = yield call(getAllKeyPeopleApi, companyId);
    try {
        if (response && !response.error) {
            yield put(getAllKeyPeopleApiSuccess(response.keyPeople))
        }
    } catch (error) {
        console.log("error in getBusinessPlanOnboardSaga saga", error);
    }
}

export function* watchGetAllKeyPeopleSaga() {
    yield takeLatest(GET_ALL_KEY_PEOPLE_API_REQUEST, getAllKeyPeopleSaga)
}

function* getBusinessDescriptionOpenAiSaga(action) {
    const { businessPlanConvo } = action.payload
    const response = yield call(getBusinessDescriptionOpenAiApi, businessPlanConvo);
    try {
        if (response && !response.error) {
            yield put(getBusinessDescriptionOpenAiApiSuccess(response))
            yield put(setLoading({status: false})); 
        }
    } catch (error) {
        console.log("error in getBusinessDescriptionOpenAiSaga saga", error);
        yield put(setLoading({status: false})); 
    }
}

export function* watchGetBusinessDescriptionOpenAiSaga() {
    yield takeLatest(GET_BUSINESS_PLAN_DESCRIPTION_OPENAI, getBusinessDescriptionOpenAiSaga)
}

function* updateBusinessPlansForCreditsSaga(action) {
    try {
        const { bussPlanObj } = action.payload
        const response = yield call(updateBusinessPlansForCreditsApi, bussPlanObj);
        if (response && !response.error) {
            yield put(getBusinessPlanDataRequest(bussPlanObj?.businessPlanTemp?.uniqueId)); 
            yield put(setLoading({status: false})); 
        }
    } catch (error) {
        console.log("error in getBusinessDescriptionOpenAiSaga saga", error);
        yield put(setLoading({status: false})); 
    }
}

export function* watchUpdateBusinessPlansForCreditsSaga() {
    yield takeLatest(UPDATE_BUSINESS_PLANS_FOR_CREDITS, updateBusinessPlansForCreditsSaga)
}
