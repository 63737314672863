import {
    GET_BLOG_LIST_SUCCESS, GET_BLOG_DETAIL_SUCCESS
} from "../actions/actionConstants";

const blogReducer = (state = {
    blogList: [{
        articleType: "URL",
        url: "https://pomanda.com/blog",
        header: "Pomanda blog",
        posterImgUrl: "/assets/images/pomanda_logo_blue2x.png",
        imgAltText: "Pomanda blog",
        _id: "",
        metaTitle: "Pomanda blog",
        publishedDate: "2021-11-19T00:00:00.000Z"


    }], blogDetail: {}, detailBlogList: []
}, action) => {
    switch (action.type) {
        case GET_BLOG_LIST_SUCCESS:
            return {
                ...state, blogList: action.payload.blogList,
                blogsCount: action.payload.blogsCount
            }
        case GET_BLOG_DETAIL_SUCCESS:
            return { ...state, blogDetail: action.payload.detailBlogObj.blogDetail, detailBlogList: action.payload.detailBlogObj.detailBlogList }
        default:
            return state;
    }
}

export default blogReducer;