import { call, put, takeLatest, select, all, takeEvery } from "redux-saga/effects";
import { SEND_ACTIVITY_TRACKING, GET_ONLOAD_DATA_API, CHECK_STORAGE_AND_SEND_TRK_DATA, CALL_TRACKER_API } from "../redux/actions/actionConstants";
import { sendActivityTrackingApi, sendTrackerData, callApiIpify } from "../api/trackerApi";
import { onloadApiSuccess, onloadApiError, sendActivityTracking } from "../redux/actions/commonAction"
import { getOnlaodDataApi } from '../api/onloadDataApi'
import localstorageApi from '../api/localStorageApi'
import analyticsApi from '../api/analyticsApi'
import { EP_USER_FIRST_VISIT, ET_TRACKER_ID_CREATION } from '../common/trackingConstants';
import { setApiSubscriptionPlan } from "../redux/actions/subscriptionAction"
import { isBrowser } from '../common/config'
const actTrkToken = (state) => state.common.actTrkToken;
const userInfo = (state) => state.userProfile.userInfo

function* sendActivityTrackingSaga(action) {
    try {
        var token = yield select(actTrkToken)
        var user = yield select(userInfo)
        const accessToken = yield call(localstorageApi.getUserAuthToken);
        const checkingForUserId = accessToken && !user?._id; 
        let userTrackerObj = yield call(localstorageApi.getUserData)
        if (isBrowser) {
            if (!token || !userTrackerObj || !userTrackerObj.trackerId || checkingForUserId
                || userTrackerObj.trackerId === "") {
                action.payload.activityObject.timestamp = new Date().getTime()
                yield call(localstorageApi.setActivityTrackingObjList, action.payload.activityObject);
            } else {
                if (action.payload && action.payload.activityObject) {
                    action.payload.activityObject.token = token
                    action.payload.activityObject.userId = user ? user._id : ""
                    yield call(sendActivityTrackingApi, action.payload.activityObject);
                }
            yield checkStorageAndSendTrkDataSaga()

            }
        }
    } catch (error) {
        console.log('inside sendActivityTrackingSaga ', error);

    }
}

export function* watchSendActivityTrackingRequest() {
    yield takeEvery(SEND_ACTIVITY_TRACKING, sendActivityTrackingSaga);
}

function* getOnloadDataSaga(action) {
    try {
        const response = yield call(getOnlaodDataApi, action.payload);
        if (response.error) {
            yield put(onloadApiError());
        } else {
            yield all([
                put(onloadApiSuccess({
                    actTrackFrontToken: response.actTrackFrontToken,
                    monthlyCsvRowDownloadCount: response.monthlyCSVDownloadCount
                })),
                put(setApiSubscriptionPlan({ subscriptionPlans: response.plans }))
            ]);
            yield call(localstorageApi.setActTrackToken, response.actTrackFrontToken)
        }

    } catch (error) {
        console.log('inside getOnloadDataSaga ', error);
    }
}

export function* watchGetOnloadDataRequest() {
    yield takeLatest(GET_ONLOAD_DATA_API, getOnloadDataSaga);
}


function* checkStorageAndSendTrkDataSaga(action) {
    try {
        var token = yield select(actTrkToken)
        let userTrackerObj = yield call(localstorageApi.getUserData)
        if (token && userTrackerObj && userTrackerObj.trackerId
            && userTrackerObj.trackerId !== "") {
            let actTrkList = yield call(localstorageApi.getActivityTrackingObjList)
            if (actTrkList.length > 0) {
                for (let i = 0; i < actTrkList.length; i++) {
                    yield put(sendActivityTracking(actTrkList[i]));
                }
                yield call(localstorageApi.setActivityTrackingObjList, {});
            }
        }
    } catch (error) {
        console.log('inside sendActivityTrackingSaga ', error);
    }
}

export function* watchCheckStorageAndSendTrkData() {
    yield takeLatest(CHECK_STORAGE_AND_SEND_TRK_DATA, checkStorageAndSendTrkDataSaga);
}


function* callTrackerAPISaga(action) {
    try {
        let userTrackerObj = action.payload.userTrackerObj
        let isTidUpdated = userTrackerObj.tid !== null
        delete userTrackerObj.tid
        try {
            const json = yield call(callApiIpify)
            if (json.ip) {
                userTrackerObj.ipAddress = json.ip;
                localstorageApi.setCookie("ipAddress", userTrackerObj.ipAddress)
                yield call(localstorageApi.setUserData, userTrackerObj);
            }
        } catch (e) {
            yield call(localstorageApi.setUserData, userTrackerObj);
            console.log(e);
        }
        userTrackerObj.pageUrl = window.location.href
        userTrackerObj.referrer=document.referrer
        // let trackerInfo = yield call(sendTrackerData, userTrackerObj)
        // if (trackerInfo) {
        //     let trackerObj = yield call(localstorageApi.getUserData);
        //     const prevTrackerId = trackerObj.trackerId
        //     trackerObj.trackerId = trackerInfo.trackerId;
        //     trackerObj.sessionId = trackerInfo.sessionId;
        //     yield call(localstorageApi.setUserData, trackerObj);
        //     yield call(analyticsApi.setCustomData, "trackerId", trackerInfo.trackerId);
        //     if (isTidUpdated || prevTrackerId !== trackerInfo.trackerId) {
        //         const actTrkObj = {
        //             attribute1: window.location.href,
        //             attribute2: trackerObj.latest_tid,
        //             attribute3:document.referrer,
        //             attribute4:navigator.userAgent,
        //             attribute5:userTrackerObj.ipAddress,
        //             eventPage: EP_USER_FIRST_VISIT,
        //             eventType: ET_TRACKER_ID_CREATION
        //         }
        //         yield put(sendActivityTracking(actTrkObj));
        //     }
            yield checkStorageAndSendTrkDataSaga()

        // }

    } catch (error) {
        console.log('inside  callTrackerAPISaga', error);
    }
}

export function* watchCallTrackerAPISaga() {
    yield takeLatest(CALL_TRACKER_API, callTrackerAPISaga);
}
