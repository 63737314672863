
const envVariableReducer = (state = {
    RESTAPI_URL: process.env['REACT_APP_RESTAPI_URL'],
    ACTIVITY_TRACKING_FRONTEND_URL: process.env["ACTIVITY_TRACKING_FRONTEND_URL"],
    STRIPE_PUBLIC_KEY: process.env['REACT_APP_STRIPE_PUBLIC_KEY'],
    COMPANY_DIRECTOR_SEARCH_LIMIT_FOR_ANONYMOUS: process.env["REACT_APP_COMPANY_DIRECTOR_SEARCH_LIMIT_FOR_ANONYMOUS"],
    REACT_APP_GOOGLE_INDEX: process.env["REACT_APP_GOOGLE_INDEX"] && process.env["REACT_APP_GOOGLE_INDEX"].toLowerCase() === "true" ? true : false,
    GA_AD_SENSE_ID: process.env["REACT_APP_GA_AD_SENSE_ID"],
    GA_TRACKING_ID: process.env["REACT_APP_GA_TRACKING_ID"],
    GA_PS_BTN_TAG: process.env["REACT_APP_GA_POWER_SEARCH_BTN_TAG"],
    GA_SIGNUP_BTN_TAG: process.env["REACT_APP_GA_SIGNUP_BTN_TAG"],
    GA_CONTACT_US_BTN_TAG: process.env["REACT_APP_GA_CONTACT_US_BTN_TAG"],
    GA_7DAY_TRAIL_BTN_TAG: process.env["REACT_APP_GA_7DAY_TRAIL_BTN_TAG"],
    GA_CR_PAYMENT_BTN_TAG: process.env["REACT_APP_GA_CREDIT_REPORT_PAYMENT_BTN_TAG"],
    GA_SIGNUP_HEADER_BTN_TAG: process.env["REACT_APP_GA_SIGNUP_HEADER_BTN_TAG"],
    GA_HP_FIND_YOUR_COMP_BTN: process.env["REACT_APP_GA_HOME_PAGE_FIND_YOUR_COMP_BTN"],
    GA_HP_GENERATE_LEAD_BTN: process.env["REACT_APP_GA_HOME_PAGE_GENERATE_LEAD_BTN"],
    GA_HP_SEARCHBAR_COMPANY: process.env["REACT_APP_GA_HOME_PAGE_SEARCHBAR_COMPANY"],
    GA_HP_SEARCHBAR_DIRECTOR: process.env["REACT_APP_GA_HOME_PAGE_SEARCHBAR_DIRECTOR"],
    GA_HP_SIGNUP_NOW_BTN: process.env["REACT_APP_GA_HOME_PAGE_SIGNUP_NOW_BTN"],
    GA_HP_START_RESEARCHING_BTN: process.env["REACT_APP_GA_HOME_PAGE_START_RESEARCHING_BTN"],
    REACT_APP_ADS_ENABLE: process.env["REACT_APP_ADS_ENABLE"] && process.env["REACT_APP_ADS_ENABLE"].toLowerCase() === "true" ? true : false,
    GA_VALUATION_REPORT_PAYMENT_BTN_TAG :process.env['REACT_APP_GA_VALUATION_REPORT_PAYMENT_BTN_TAG'],
    GA_BUSINESS_PLAN_PAYMENT_BTN_TAG :process.env['REACT_APP_GA_BUSINESS_PLAN_PAYMENT_BTN_TAG'],
    USER_IP_LOG: process.env["USER_IP_LOG"] ? process.env["USER_IP_LOG"] : false,
    GOOGLE_MAP_KEY: process.env['REACT_APP_GoogleMapApiKey'],
    GOOGLE_CLIENT_ID: process.env["REACT_APP_GOOGLE_CLIENT_ID"],
    LINKEDIN_CLIENT_ID: process.env["REACT_APP_LINKEDIN_CLIENT_ID"],
    LINKEDIN_OAUTH_URL: process.env["REACT_APP_LINKEDIN_OAUTH_URL"],
    REACT_APP_PROSPER_SIGNOUT_REDIRCT_URL: process.env["REACT_APP_PROSPER_SIGNOUT_REDIRCT_URL"],
    REACT_APP_ADSENSE_970_90: process.env['REACT_APP_ADSENSE_970_90'],
    REACT_APP_ADSENSE_728_90: process.env['REACT_APP_ADSENSE_728_90'],
    REACT_APP_ADSENSE_300_50: process.env['REACT_APP_ADSENSE_300_50'],
    REACT_APP_ADSENSE_CLIENT_ID: process.env['REACT_APP_ADSENSE_CLIENT_ID'],
    BUSINESS_VALUATION_YOUTUBE_VIDEO_ID: process.env['BUSINESS_VALUATION_YOUTUBE_VIDEO_ID'],
    POWER_SEARCH_YOUTUBE_VIDEO_ID: process.env['POWER_SEARCH_YOUTUBE_VIDEO_ID'],
    MICROSOFT_CLARITY_ID : process.env['MICROSOFT_CLARITY_ID'],
    DATA_SERVICE_API_URL: process.env['REACT_APP_DATA_SERVICE_API_URL'],
    USER_WATCH_LIST_LIMIT: process.env['USER_WATCH_LIST_LIMIT'],
    USER_WATCH_LIST_COMPANY_LIST_LIMIT: process.env['USER_WATCH_LIST_COMPANY_LIST_LIMIT'],
    REACT_APP_GOOGLE_RECAPTCHA_CLIENT_ID: process.env['REACT_APP_GOOGLE_RECAPTCHA_CLIENT_ID']
}, action) => {
    switch (action.type) {

        default:
            return state;
    }
}
export default envVariableReducer;