import {
    SET_SELECTED_SUBSCRIPTION_PLAN,
    CREATE_STRIPE_SESSION_SUCCESS,
    CREATE_STRIPE_SESSION_FAILED,
    SET_SUBSCRIPTION_STATUS_SUCCESS,
    SET_SUBSCRIPTION_STATUS_FAILED,
    SET_SUBSCRIPTION_MODAL_DATA,
    SHOW_CANCEL_SUBSCRIPTION_MODAL,
    CANCEL_USER_SUBSCRIPTION_FAILED,
    SET_SUBSCRIPTION_MODAL_INITIAL_STATE,
    CLOSE_SUBSCRIPTION_MODAL,
    SET_PLAN_SELECTED,
    RESET_STRIPE_SESSION_RETURN_DATA,
    TOGGLE_CR_DOWNLOAD_LOADING_MODAL,
    SET_API_SUBSCRIPTION_PLANS,
    SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL
} from "../actions/actionConstants";
const initialState = {
    subscriptionModal: {
        subscriptionToggleStatus: false,
        heading: "",
        subHeading: "",
        plans: [],
        serviceRequested: "",
        isCloseOption: false
    },
    isCancelModalOpen: false,
    isCancelSubscriptionSuccessModalOpen: false,
    redirectToStripe:false,
    subscriptionReturnObj:{},
    sessionExpire:false,
    planDetailValues:{
        PomandaPlus: {
            planAmount: 24.99,
            freeCRCount: 1
        },
        oneTimePurchase: {
            planAmount: 9.99,
        },
        percent30OffReport: {
            planAmount: 6.99,
        },
        PomandaPlusYearly:{
            planAmount: 19.99
        }
    }
}

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBSCRIPTION_MODAL_INITIAL_STATE:
            return { ...state, subscriptionModal: initialState.subscriptionModal }
        case SET_SUBSCRIPTION_MODAL_DATA:
            return { ...state, subscriptionModal: action.payload };
        case SET_PLAN_SELECTED:
            let subscriptionModal = { ...state.subscriptionModal }
            subscriptionModal.planSelected = action.payload.planSelected
            subscriptionModal.href = action.payload.href
            subscriptionModal.pathname = action.payload.pathname
            return { ...state, subscriptionModal: subscriptionModal }
        case SET_SELECTED_SUBSCRIPTION_PLAN:
            return { ...state, selectedSubscriptionPlan: action.payload.selectedSubscriptionPlan };
        case CREATE_STRIPE_SESSION_SUCCESS:
            return { ...state, subscriptionReturnObj: action.payload.stripeSession, redirectToStripe: true };
        case RESET_STRIPE_SESSION_RETURN_DATA:
            return { ...state, subscriptionReturnObj: {}, redirectToStripe: false };
        case CREATE_STRIPE_SESSION_FAILED:
            return { ...state, stripeCreateSessionError: action.payload.errorMessage };
        case SET_SUBSCRIPTION_STATUS_SUCCESS:
            return { ...state, sessionExpire:action.payload.successResp.sessionExpire,subscriptionUpdateSuccessMsg: action.payload.successResp.message };
        case SET_SUBSCRIPTION_STATUS_FAILED:
            return { ...state, sessionExpire:action.payload.failResp.sessionExpire,subscriptionUpdateErrorMsg: action.payload.failResp.message };
        case SHOW_CANCEL_SUBSCRIPTION_MODAL:
            return { ...state, isCancelModalOpen: action.payload.cancelModalStatus };
        case CANCEL_USER_SUBSCRIPTION_FAILED:
            return { ...state, cancelSubscriptionError: action.payload.failedMessage };
        case CLOSE_SUBSCRIPTION_MODAL:
            return { ...state, subscriptionModal: { ...state.subscriptionModal, subscriptionToggleStatus: false } };
        case TOGGLE_CR_DOWNLOAD_LOADING_MODAL:
            return {...state,downloadModalDataObj:action.payload.downloadModalDataObj};
        case SET_API_SUBSCRIPTION_PLANS:
            return {...state, planDetailValues:action.payload.subscriptionPlans};
        case SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL:
            return {...state, isCancelSubscriptionSuccessModalOpen: action.payload.cancelSubscriptionSuccessModalStatus}
        default:
            return state;
    }
}

export default subscriptionReducer;