import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './BulkContactDetailsCompletionModal.css';

const BulkContactDetailsCompletionModal = ({
  toggleModal,
  imageSrc,
  closeIconSrc,
  headingLine1,
  headingLine2,
  infoLine1,
  infoLine2,
  buttonLabel,
  onButtonClick
}) => {
  return (
    <Modal centered={true} isOpen={true} className="bulk-Completion-modal">
      <ModalBody className="bulk-Completion-modal-body">
        <div className='bulk-Completion-modal-info-section' >
          <div className='bulk-Completion-modal-image'>
            <img className="cursor-pointer bulk-Completion-header-icon" src={imageSrc} alt='modal_icon'/>
            <img src={closeIconSrc} className='cursor-pointer bulk-Completion-cross-btn' alt='close_btn' height={20} width={20} onClick={toggleModal} />
          </div>
          <div className='bulk-Completion-modal-info'>
            <div className='bulk-Completion-modal-heading'>
              <p className='mb-0'>{headingLine1}</p>
              <p className='mb-0'>{headingLine2}</p>
            </div>
            <div className='bulk-Completion-modal-content'>
              <p className='pl-sm-3 pr-sm-3'>{infoLine1}</p>
              <p className='mb-0 bulk-completion-text-info'>{infoLine2}</p>
            </div>
          </div>
        </div>
        <div className='bulk-Completion-modal-footer-section'>
          <button className='bulk-Completion-modal-btn' onClick={onButtonClick}>{buttonLabel}</button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BulkContactDetailsCompletionModal;
