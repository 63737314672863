import { 
    GET_DIRECTOR_DATA_REQUEST,
    GET_DIRECTOR_DATA_FAILED,
    GET_DIRECTOR_DATA_SUCCESS,
    GET_RELATED_DIRECTOR_REQUEST,
    GET_RELATED_DIRECTOR_FAILED,
    GET_RELATED_DIRECTOR_SUCCESS,
    EMPTY_DIRECTOR_PROFILE_DATA,
    // GET_DIRECTOR_INFORMATION_REPORT,
    // GET_DIRECTOR_REPORT_LINK
} from './actionConstants';

export const getDirectorDataRequest=(directorParams)=>({
    type:GET_DIRECTOR_DATA_REQUEST,
    payload:{directorParams}
})

export const getDirectorDataFailed=(message)=>({
    type:GET_DIRECTOR_DATA_FAILED,
    payload:{message}
})

export const getDirectorDataSuccess=(directorData)=>({
    type:GET_DIRECTOR_DATA_SUCCESS,
    payload:{directorData}
})

export const getRelatedDirectorRequest=(directorObj)=>({
    type:GET_RELATED_DIRECTOR_REQUEST,
    payload:{directorObj}
})

export const getRelatedDirectorFailed=(message)=>({
    type:GET_RELATED_DIRECTOR_FAILED,
    payload:{message}
})

export const getRelatedDirectorSuccess=(relatedDirector)=>({
    type:GET_RELATED_DIRECTOR_SUCCESS,
    payload:{relatedDirector}
})

export const setDirectorProfileDataEmpty = () => ({
    type: EMPTY_DIRECTOR_PROFILE_DATA
})

// export const getDirectorInfoReport = (pnr,userId)=>({
//     type:GET_DIRECTOR_INFORMATION_REPORT,
//     payload:{ pnr,userId }
// })

// export const getDirectorReportLink = (directorReportLink)=>({
//     type:GET_DIRECTOR_REPORT_LINK,
//     payload:{ directorReportLink }
// })

