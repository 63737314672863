import {
    ADD_COMPANY_TO_COMPETITIVE_ANALYSIS,
    ADD_SELECTED_COMPETITOR,
    DOWNLOAD_COMPETITOR_ANALYSIS_DATA,
    GET_COMPETITOR_ANALYSIS_DATA,
    GET_SELECTED_COMPETITORS_ANALYSIS_DATA,
    REMOVE_COMPANY_FROM_COMPETITIVE_ANALYSIS,
    REMOVE_SELECTED_COMPETITOR,
    SET_CURRENT_SELECTED_COMPETITOR,
    SET_SELECTED_COMPETITORS,
    SET_SELECTED_COMPETITORS_UPDATE_SUCCESS,
    UPDATE_SELECTED_COMPETITOR
} from "./actionConstants";

export const addCompanyToCompetitiveAnalysis = (analysisData) => ({
    type: ADD_COMPANY_TO_COMPETITIVE_ANALYSIS,
    payload: { analysisData }
})

export const getCompetitorAnalysisData = (companyId) => ({
    type: GET_COMPETITOR_ANALYSIS_DATA,
    payload: { companyId }
})

export const setCurrentSelectedCompetitor = (currentSelectedCompetitor) => ({
    type: SET_CURRENT_SELECTED_COMPETITOR,
    payload: { currentSelectedCompetitor }
})

export const removeCompanyFromCompetitiveAnalysis = (companyId) => ({
    type: REMOVE_COMPANY_FROM_COMPETITIVE_ANALYSIS,
    payload: { companyId }
})

export const updateSelectedCompetitors = (updatedSelectedCompetitor) => ({
    type: UPDATE_SELECTED_COMPETITOR,
    payload: { updatedSelectedCompetitor }
})

export const getSeletedCompetitorsAnalysisData = (companyId) => ({
    type: GET_SELECTED_COMPETITORS_ANALYSIS_DATA,
    payload: { companyId }
})

export const setSelectedCompetitorsArr = (selectedCompetitors) => ({
    type: SET_SELECTED_COMPETITORS,
    payload: { selectedCompetitors }
})

export const addSelectedCompetitor = ({ competitorId, mainCompanyREG }) => ({
    type: ADD_SELECTED_COMPETITOR,
    payload: { competitorId, mainCompanyREG }
})

export const removeSelectedCompetitor = ({ competitorId, mainCompanyREG }) => ({
    type: REMOVE_SELECTED_COMPETITOR,
    payload: { competitorId, mainCompanyREG }
})

export const downloadCompetitorAnalysisData = (competitorAnalysisData) => ({
    type: DOWNLOAD_COMPETITOR_ANALYSIS_DATA,
    payload: { competitorAnalysisData }
})

export const setSelectedCompetitorUpdateSuccess = (isSuccess) => ({
    type: SET_SELECTED_COMPETITORS_UPDATE_SUCCESS,
    payload: { isSuccess }
})