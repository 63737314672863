const { ET_POWER_SEARCH_SHAREHOLDER_ONLOAD, ET_POWER_SEARCH_INDUSTRY_ONLOAD, ET_POWER_SEARCH_DIRECTOR_ONLOAD, ET_POWER_SEARCH_COMPANY_ONLOAD, ET_POWER_SEARCH_CHARGES_ONLOAD, ET_POWER_SEARCH_REGION_ONLOAD } = require("../../common/trackingConstants");

module.exports = {
    getUserIdFrmProps:(props) => {
        // const {
        //     userProfile = {}
        // } = props;
        // const {
        //     _id = ""
        // } = userProfile;
        // return _id;
        return ""
    },
    getOnloadEventType: (type) => {
        switch (type) {
            case "company":
                return ET_POWER_SEARCH_COMPANY_ONLOAD
            case "director":
                return ET_POWER_SEARCH_DIRECTOR_ONLOAD
            case "industry":
                return ET_POWER_SEARCH_INDUSTRY_ONLOAD
            case "shareholder":
                return ET_POWER_SEARCH_SHAREHOLDER_ONLOAD
            case "charges":
                return ET_POWER_SEARCH_CHARGES_ONLOAD
            case "region":
                return ET_POWER_SEARCH_REGION_ONLOAD
            default:
                return ""
        }
    }
}