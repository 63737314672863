import { RESTAPI_URL, TRACKING_RESTAPI_URL } from '../common/config';
import { commonFetch } from './fetchComImpl'
import { refreshTokenApi } from './refreshTokenApi';

export const sendTrackerData = async (userTrackerObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
        'POST',
        {},
        new Headers({
            'content-type': 'application/json'
        })
    );
        const trackerResp = await commonFetch(RESTAPI_URL + '/tracker/saveTrackerData',
            'POST',
            userTrackerObj,
            new Headers({
                'Content-Type': 'application/json'
            }));
        return trackerResp

    } catch (e) {
        throw e
    }
};

export const sendActivityTrackingApi = async (dataObj) => {
    try {   
        let headers = new Headers({ 'Content-Type': 'application/json' })
        headers.set("actTrkToken", dataObj.token)
        delete dataObj.token;
        headers.set("userId", dataObj.userId)
        delete dataObj.userId;
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const trackerResponse = await commonFetch(TRACKING_RESTAPI_URL + '/trk/act',
            'POST',
            dataObj,
            headers
        );
        return trackerResponse;
    } catch (error) {
        throw error;
    }
}

export const callApiIpify = async () => {
    try {
        let data = await fetch("https://api.ipify.org?format=json");
        return await data.json();
    } catch (error) {
        throw error;
    }
}