import { RESTAPI_URL } from '../common/config';
import { commonFetch } from './fetchComImpl'
import localStorageApi from './localStorageApi';
import { refreshTokenApi } from './refreshTokenApi';

export const companySearchFromNameApiCall = async (searchObj) => {
    try {
        const userTrackerObj = localStorageApi.getUserData();
        searchObj.trackerId = userTrackerObj && userTrackerObj && userTrackerObj.trackerId ? userTrackerObj.trackerId : 0;
        searchObj.userId = userTrackerObj && userTrackerObj.userId ? userTrackerObj.userId : 0;
        
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/powerSearch/getCompanySearchData`,
            'POST',
            searchObj,
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getDirectorListRequestApiCall = async (directorQueryData) => {
    try {
        const userTrackerObj = localStorageApi.getUserData();
        directorQueryData.trackerId = userTrackerObj && userTrackerObj.trackerId ? userTrackerObj.trackerId : 0;
        directorQueryData.userId = userTrackerObj && userTrackerObj.userId ? userTrackerObj.userId : 0;
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
        'POST',
        {},
        new Headers({
            'content-type': 'application/json'
        })
    );
        const response = await commonFetch(`${RESTAPI_URL}/powerSearch/getDirectorSearchData`,
            'POST',
            directorQueryData,
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getIndustryListRequestApiCall = async (industryQueryData) => {
    try {
        const userTrackerObj = localStorageApi.getUserData();
        industryQueryData.trackerId = userTrackerObj && userTrackerObj.trackerId ? userTrackerObj.trackerId : 0;
        industryQueryData.userId = userTrackerObj && userTrackerObj.userId ? userTrackerObj.userId : 0;
         await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/powerSearch/getIndustrySearchData`,
            'POST',
            industryQueryData,
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getRegionListRequestApiCall = async (regionQueryData) => {
    try {
        const userTrackerObj = localStorageApi.getUserData();
        regionQueryData.trackerId = userTrackerObj && userTrackerObj.trackerId ? userTrackerObj.trackerId : 0;
        regionQueryData.userId = userTrackerObj && userTrackerObj.userId ? userTrackerObj.userId : 0;
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/powerSearch/getRegionSearchData`,
            'POST',
            regionQueryData,
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getShareholderListRequestApiCall = async (shareholderQueryData) => {
    try {
        const userTrackerObj = localStorageApi.getUserData();
        shareholderQueryData.trackerId = userTrackerObj && userTrackerObj.trackerId ? userTrackerObj.trackerId : 0;
        shareholderQueryData.userId = userTrackerObj && userTrackerObj.userId ? userTrackerObj.userId : 0;
        
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/powerSearch/getShareholderSearchData`,
            'POST',
            shareholderQueryData,
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getChargesListRequestApiCall = async (chargesQueryData) => {
    try {
        const userTrackerObj = localStorageApi.getUserData();
        chargesQueryData.trackerId = userTrackerObj && userTrackerObj.trackerId ? userTrackerObj.trackerId : 0;
        chargesQueryData.userId = userTrackerObj && userTrackerObj.userId ? userTrackerObj.userId : 0;
        
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/powerSearch/getChargesSearchData`,
            'POST',
            chargesQueryData,
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getIndustryFilterDataApiCall = async () => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/powerSearch/getOnloadFilterData`,
            'GET',
            null,
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}


export const singleSearchApiCall = async (searchObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return  await commonFetch(`${RESTAPI_URL}/powerSearch/getSingleSearchData`,
            'POST',
            searchObj,
            new Headers({
                'content-type': 'application/json'
            })
        );
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getSaveSearchesApi = async (searchObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return  await commonFetch(`${RESTAPI_URL}/powerSearch/getSaveSearches`,
            'POST',
            searchObj,
            new Headers({
                'content-type': 'application/json'
            })
        );
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const saveCurrentSearchApi = async (searchObj) => {
    try {
         await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return  await commonFetch(`${RESTAPI_URL}/powerSearch/saveSearchesData`,
            'POST',
            searchObj,
            new Headers({
                'content-type': 'application/json'
            })
        );
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const deleteSavedSearchApi = async (searchObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return  await commonFetch(`${RESTAPI_URL}/powerSearch/deleteSaveSearches`,
            'POST',
            searchObj,
            new Headers({
                'content-type': 'application/json'
            })
        );
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const downloadResultsApiCall= async(searchParamsObj)=>{
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return await commonFetch(`${RESTAPI_URL}/powerSearch/downloadResults`,
            'POST',
            searchParamsObj,
            new Headers({
                'content-type': 'application/json'
            })
        )
    } catch (error) {
        console.log('inside downloadResultsApiCall ',error);
        throw error;
    }
}

export const sendIncreaseDownloadRequestApiCall = async (requestDetails) => {
    try {
         await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return await commonFetch(`${RESTAPI_URL}/powerSearch/sendIncreaseDownloadResultsLimitRequest`,
            'POST',
            { ...requestDetails },
            new Headers({
                'content-type': 'application/json'
            })
        )
    } catch (error) {
        console.error('inside sendIncreaseDownloadRequestApiCall ', error);
        throw error;
    }
}
