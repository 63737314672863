// Startup point for the client side application
// import 'babel-polyfill';
import "regenerator-runtime/runtime.js";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import Routes from '../Routes';
import createStore from '../redux/store/createStore';
import rootSaga from '../sagas/rootSaga'
import { loadableReady } from '@loadable/component'

loadableReady(async() => {
 
const store = await createStore(true);
await store.sagaTask(rootSaga)

ReactDOM.hydrate(
    <Provider store={store}>
      <BrowserRouter>
      {/* <App.component route={Routes}/> */}
        {renderRoutes(Routes)}
      </BrowserRouter>
    </Provider>,
    document.querySelector('#root')
  );

})