
import { REACT_APP_ADS_ENABLE } from "./config"
export default [
     
    {
        injectPlace: "body", injectType: "script", id: "hs-script-loader", src: "https://js.hs-scripts.com/2551776.js", type: "text/javascript", async: true
    },
    {
        injectPlace: "body", injectType: "script", id: "gads-script", src: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js", type: "text/javascript", async: true,
        checkBeforeLoad: () => {
            return { isLoadAllowed: REACT_APP_ADS_ENABLE }
        }
    },
 
]