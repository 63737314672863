module.exports={
    CP_SI_SUCCESS:{
        header: "Thanks for getting in touch!",
        subHeader: "A member of our team will contact you shortly.",
        btnText: "got it"
    },
    PS_INCREASE_DOWNLOAD_RESULT_LIMIT_SUCCESS:{
        header: "Thank you!",
        subHeader: "We will be in touch shortly.",
        btnText: "continue",
        modalClassName:"download-result-limit-success-modal"
    },
    PS_CSV_DOWNLOAD_STARTED: {
        header: "",
        subHeader: "Download is in progress, and it is expected to take around 2 minutes.",
        btnText: "ok",
        modalClassName:"download-result-limit-success-modal"
    },
    PS_CSV_DOWNLOAD_FAILED: {
        header: "Error",
        subHeader: "Something went wrong while creating csv. Please contact pomanda team.",
        btnText: "ok",
        modalClassName:"download-result-limit-success-modal"
    },
    PARENT_COMPANY_CLICK: {
        heading: "",
        subHeading: "Sorry the parent company is a non-UK business.",
        btnText: "ok"
    },
    CREDIT_REPORT_OF_DISSOLVED_COMPANY: {
        heading: "",
        subHeading: "This company has been DISSOLVED - Credit Reports are unavailable.",
        btnText: "ok"
    },
    COMPANY_REPORT_OF_DISSOLVED_COMPANY: {
        heading: "",
        subHeading: "This company is no longer active - report not available",
        btnText: "ok"
    },
    NO_FINANCIALS_COMPANY_REPORT:{
        heading: "",
        subHeading: "This company has not filed any financial data - report not available",
        btnText: "ok"
    },
    NO_FINANCIALS_CREDIT_REPORT:{
        heading: "",
        subHeading: "This company has not filed any financial data - credit report not available",
        btnText: "ok"
    },
    EXISTING_COMPANY_VALUATION_RP:{
        heading: "",
        subHeading: "You have already purchased a Valuation Report for this company. Click OK to continue to the Valuation Calculator",
        btnText: "ok"
    },
    // Don't Remove
    // SIGNUP_SUBMIT_CLICK:{
    //     heading: "Thank you!",
    //     image: {webpIcon:"/assets/images/check@1x.webp",
    //             pngIcon:"/assets/images/check.png"},
    //     backdropBoolean:true        
    // },
    CANCEL_USER_FREE_TRIAL_SUCCESS: {
        header: '',
        subHeading: 'You have successfully cancelled your free trial and started your subscription.',
        btnText: 'ok'
    },
    CANCEL_USER_FREE_TRIAL_FAILED: {
        header: '',
        subHeading: 'Free trial cancellation is failed.',
        btnText: 'ok'
    },
    ENQUIRE_NOW_SUCCESS_DATA: {
        header: 'Thanks!',
        subHeading: "We will be in touch to discuss API solutions",
        btnText: "OK"
    }
}