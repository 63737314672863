import { createStore, applyMiddleware, compose } from 'redux';
import reducers from '../reducers/rootReducer';
import createSagaMiddleware, { END } from "redux-saga";


export default (isClientEnv) => {
  const sagaMiddleware = createSagaMiddleware();

  const componseEnhancers = isClientEnv ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose
  const preloadState = isClientEnv ? window.INITIAL_STATE : {}
  const store = createStore(
    reducers,
    preloadState,
    componseEnhancers(applyMiddleware(sagaMiddleware))
  );
  store.sagaTask = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
};