const moment = require("moment");
// UI format required here
const defaultProfitLoss = [{
    key: "turnover",
    label: "Turnover",
    values: [],
    children: [],
    isEditable: true,
    childAddBtn:"+ Add",
    isDisplayChildrenBelow: true,
    valueFormat: "currencyFormatWithoutPountSymbol"
}, {
    key:"costOfSales",
    label: "Cost Of Sales",
    values: [],
    children: [],
    isEditable: true,
    childAddBtn:"+ Add",
    isDisplayChildrenBelow: true,
    valueFormat: "currencyFormatWithoutPountSymbol"
}, {
    key: "grossProfit",
    label: "Gross Profit",
    values: [],
    isEditable: true,
    valueFormat: "currencyFormatWithoutPountSymbol"
},{
    key: "grossMargin",
    label: "Gross Margin",
    values: [],
    isEditable: false,
    postFixSymbol: "%",
    valueFormat: "valueWithPostFixSymbol"
}, {
    key: "adminExpenses",
    label: "Admin Expenses",
    values: [],
    children: [],
    isEditable: true,
    childAddBtn:"+ Add",
    isDisplayChildrenBelow: true,
    valueFormat: "currencyFormatWithoutPountSymbol"
},{
    key: "operatingProfit",
    label: "Operating Profit",
    values: [],
    isEditable: true,
    valueFormat: "currencyFormatWithoutPountSymbol"
},{
    key: "operatingMargin",
    label: "Operating Margin",
    values: [],
    isEditable: false,
    postFixSymbol: "%",
    valueFormat: "valueWithPostFixSymbol"
},{
    key: "depreciationAmortisation",
    label: "Depreciation & Amortisation",
    values: [],
    isEditable: true,
    valueFormat: "currencyFormatWithoutPountSymbol"
},{
    key: "EBITDA",
    label: "EBITDA",
    values: [],
    isEditable: true,
    valueFormat: "currencyFormatWithoutPountSymbol"
}];
const defaultBalanceSheet=[{
    key: "totalFixedAssets",
    label:"Fixed Assets",
    values:[],
    isEditable: true,
    isDisplayChildrenBelow: false,
    valueFormat: "currencyFormatWithoutPountSymbol",
    children:[{
        key: "tangibleAssets",
        label: "Tangible Assets",
        values: [],
        isEditable: true
    },{
        key: "intangibleAssets",
        label: "Intangible Assets",
        values: [],
        isEditable: true
    },{
        key: "miscFixedAssets",
        label: "Misc Assets",
        values: [],
        isEditable: true
    }]
},{
    key: "totalCurrentAssets",
    label:"Current Assets",
    values:[],
    isEditable: true,
    isDisplayChildrenBelow: false,
    valueFormat: "currencyFormatWithoutPountSymbol",
    children:[{
        key: "stocksAndWorkInProgress",
        label: "Stock & Work in Progress",
        values: [],
        isEditable: true
    },{
        key: "tradeDebtors",
        label: "Trade Debtors",
        values: [],
        isEditable: true
    },{
        key: "cash",
        label: "Cash",
        values: [],
        isEditable: true
    }]
},{
    key: "totalAssets",
    label:"Total Assets",
    values:[],
    isEditable: false,
    isDisplayChildrenBelow: false,
    valueFormat: "currencyFormatWithoutPountSymbol"
},{
    key: "totalLiabilities",
    label:"Total Liabilities",
    values:[],
    isEditable: true,
    isDisplayChildrenBelow: false,
    children:[{
        key: "tradeCreditors",
        label: "Trade Creditors",
        values: [],
        isEditable: true
    },{
        key: "totalCurrentLiabilities",
        label: "Current Liabilities",
        values: [],
        isEditable: true
    },{
        key: "totalLongTermLiabilities",
        label: "Long Term Liabilities",
        values: [],
        isEditable: true
    }]
},{
    key: "netAssets",
    label:"Net Assets",
    values:[],
    isEditable: false,
    valueFormat: "currencyFormatWithoutPountSymbol"
}];
const defaultTurnoverValuation=[{
    key: "turnover",
    label:"Turnover",
    values:[],
    isEditable: false,
    valueFormat: "currencyFormatWithPoundKMB"
},{
    key: "turnoverMultiple",
    label:"Industry Multiple",
    values:[],
    isEditable: false,
    postFixSymbol: "x",
    valueFormat: "valueWithPostFixSymbol"
},{
    key: "turnoverEnterpriseValue",
    label: "Enterprise Value",
    values: [],
    isEditable: false,
    valueFormat: "currencyFormatWithPoundKMB"
}];
const defaultEbitdaValuation=[{
    key: "EBITDA",
    label:"EBITDA",
    values:[],
    isEditable: false,
    valueFormat: "currencyFormatWithPoundKMB"
},{
    key: "EBITDAMultiple",
    label:"Industry Multiple",
    values:[],
    isEditable: false,
    postFixSymbol: "x",
    valueFormat: "valueWithPostFixSymbol"
},{
    key: "EBITDAEnterpriseValue",
    label: "Enterprise Value",
    values: [],
    isEditable: false,
    valueFormat: "currencyFormatWithPoundKMB"
}]
// Db Format Obj
// const financials = {
//     pastFinancialData: [{
//         "date": "2020-03-21",
//         "turnover": {
//             value: 480000, type: 'EDITED',
//             children: [{
//                 label: "Pomanda Subscription",
//                 value: 789,
//             },
//             {
//                 label: "Credit Report",
//                 // value: [{ value: 13000, year: "Mar 2020",isPastData:true }, { value: 89777, year: "oct 2020",isPastData:true }],
//                 value: 8867
//             }]
//         }
//         futureFinancialData:[{}]
//     }]
// }

export const editPastFinancialAndParseFinancialDbObjToFrontendFormat = (financialDbObj, financialReduxObj, valuationReduxObj, childObj) => {
    try {
        let profitLoss = [], balanceSheet = [], turnoverValuation = [], ebitdaValuation = [];
        profitLoss = JSON.parse(JSON.stringify(defaultProfitLoss));
        balanceSheet = JSON.parse(JSON.stringify(defaultBalanceSheet));
        turnoverValuation = JSON.parse(JSON.stringify(defaultTurnoverValuation));
        ebitdaValuation = JSON.parse(JSON.stringify(defaultEbitdaValuation));
        financialDbObj.pastFinancialData.forEach((dataObj, index) => {
            profitLoss = getFilledValuationDataForUI(dataObj, profitLoss,true);
            balanceSheet = getFilledBalanceSheetDataForUI(financialDbObj.pastFinancialData.length, index, dataObj, balanceSheet, true);
            turnoverValuation =  getFilledValuationDataForUI(dataObj, turnoverValuation, true)
            ebitdaValuation = getFilledValuationDataForUI(dataObj, ebitdaValuation, true)
        });
        return {
            profitLoss, balanceSheet, turnoverValuation, ebitdaValuation,
            ...getFinancialProjectionsAllGraphData(profitLoss), ...getBusinessValautionGraphData(turnoverValuation, ebitdaValuation)
        }
    } catch (error) {
        console.log('inside editFinancialAndParseFinancialDbObjToFrontendFormat ', error);
        throw error;
    }
}

const getChildSingleValueArr = (dataObj, parentKey, isPastData, existingPLChildrenArr = []) => {
    let children = [], values = []
    dataObj[parentKey]?.children.forEach(childObj => {
        existingPLChildrenArr.length > 0 && existingPLChildrenArr.forEach(item => item.label === childObj.label ? values = [...item.values] : null)
        const index = values.findIndex(valueObj => valueObj.date === dataObj.date)
        if (index === -1) {
            values.push(getSingleValueObj(dataObj, null, null, isPastData, childObj, true))
        }
        children.push({
            label: childObj.label,
            values: values,
            isChildEditable: true,
            isEditable: true
        })
    })
    return children
}

export const getSingleValueObj = (dataObj, singleTypeKey, value, isPastData, childObj, isChild) => {
    return {
        value: isChild ? childObj.value : dataObj?.[singleTypeKey] ? dataObj[singleTypeKey]['value'] : value,
        formattedDate: isPastData ? moment(dataObj.date).format("MMM YY") : dataObj.date,
        isPastData,
        date: dataObj.date,
        target: !isPastData ? dataObj.target : null
    }
}

const findAndReplaceExistingValueObj = (dataObj, financialReduxSingleTypeObj, isPastData) => {
    if (financialReduxSingleTypeObj?.values?.length > 0) {
        const foundValueObj = financialReduxSingleTypeObj.values.find(valueObj => valueObj.date === dataObj.date);
        if (foundValueObj) {
            foundValueObj.value = dataObj[financialReduxSingleTypeObj.key]['value']
            const index = financialReduxSingleTypeObj.values.findIndex(valueObj => valueObj.date === dataObj.date)
            financialReduxSingleTypeObj.values.splice(index, 1, foundValueObj)
        } else {
            financialReduxSingleTypeObj.values.push(getSingleValueObj(dataObj, financialReduxSingleTypeObj.key, financialReduxSingleTypeObj.value, isPastData))
        }
    } else if (!financialReduxSingleTypeObj.values) {
        financialReduxSingleTypeObj.values = [getSingleValueObj(dataObj, financialReduxSingleTypeObj.key, financialReduxSingleTypeObj.value, isPastData)]
    } else {
        financialReduxSingleTypeObj.values.push(getSingleValueObj(dataObj, financialReduxSingleTypeObj.key, financialReduxSingleTypeObj.value, isPastData))
    }
    if (dataObj[financialReduxSingleTypeObj.key]?.children?.length > 0) {
        financialReduxSingleTypeObj.children = getChildSingleValueArr(dataObj, financialReduxSingleTypeObj.key, isPastData, financialReduxSingleTypeObj?.children)
    }
    return financialReduxSingleTypeObj;
}

const getFilledValuationDataForUI = (dataObj, uiValuationDataArr, isPastData = true) => {
    try {
        return uiValuationDataArr.map(valuationObj => {
            return findAndReplaceExistingValueObj(dataObj, valuationObj, isPastData)
        });
    } catch (error) {
        console.error('inside getFilledValuationDataForUI', error);
        throw error;
    }
}

const getFilledBalanceSheetDataForUI = (pastFinancialDataLength, pastFinancialDataIndex, dataObj, balanceSheet, isPastData) => {
    try {
        return balanceSheet.map((balanceSheetCompObj,index) => {
            if (pastFinancialDataLength - 1 === pastFinancialDataIndex) {
                balanceSheetCompObj = findAndReplaceExistingValueObj(dataObj, balanceSheetCompObj, isPastData);
                return {
                    ...balanceSheetCompObj,
                    children: balanceSheetCompObj?.children?.length > 0 ? balanceSheetCompObj?.children.map(childObj => {
                        return findAndReplaceExistingValueObj(dataObj, childObj, isPastData)
                    }) : []
                }
            }
            return balanceSheetCompObj
        });
    } catch (error) {
        console.error('inside getFilledBalanceSheetDataForUI ', error);
        throw error;
    }
}

export const editFutureFinancialAndParseFinancialDbObjToFrontendFormat = (financialDbObj, financialReduxObj, valuationReduxObj) => {
    try {
        const anyFutureTurnoverPresent = financialDbObj.futureFinancialData.some(futureFinObj=>futureFinObj.turnover?.value);
        let profitLoss = financialReduxObj?.profitLoss?.length > 0 ? financialReduxObj.profitLoss : anyFutureTurnoverPresent ? JSON.parse(JSON.stringify(defaultProfitLoss)) : [],
            turnoverValuation = valuationReduxObj?.turnoverValuation?.length > 0 ? valuationReduxObj?.turnoverValuation : anyFutureTurnoverPresent ? JSON.parse(JSON.stringify(defaultTurnoverValuation)) : [],
            ebitdaValuation = valuationReduxObj?.ebitdaValuation?.length>0 ? valuationReduxObj?.ebitdaValuation : anyFutureTurnoverPresent ? JSON.parse(JSON.stringify(defaultEbitdaValuation)) : [];
        financialDbObj.futureFinancialData.forEach(dataObj => {
            if(dataObj?.turnover?.value !== null){
                profitLoss = getFilledValuationDataForUI(dataObj, profitLoss, false) ;
                turnoverValuation = getFilledValuationDataForUI(dataObj, turnoverValuation, false);
                ebitdaValuation = getFilledValuationDataForUI(dataObj, ebitdaValuation, false);
            }
        })
        return {
            profitLoss, turnoverValuation, ebitdaValuation,
            balanceSheet: profitLoss?.length>0 ? financialReduxObj.balanceSheet : [],
            ...getFinancialProjectionsAllGraphData(profitLoss), ...getBusinessValautionGraphData(turnoverValuation, ebitdaValuation)
        }
    } catch (error) {
        console.log('inside editFutureFinancialAndParseFinancialDbObjToFrontendFormat ', error);
        throw error;
    }
}

const getFinancialProjectionsAllGraphData = (profitLoss) => {
    try {
        let turnoverGraphData = [], grossProfitGraphData = [], operatingProfitGraphData = [];
        profitLoss.forEach(obj => {
            if (obj.key === "turnover") {
                turnoverGraphData = obj?.values;
            }
            if (obj.key === "grossProfit") {
                grossProfitGraphData = obj.values;
            }
            if (obj.key === "operatingProfit") {
                operatingProfitGraphData = obj?.values;
            }
        });
        return { turnoverGraphData, grossProfitGraphData, operatingProfitGraphData }
    } catch (error) {
        console.log('inside getFinancialProjectionsAllGraphData ', error);
        throw error;
    }
}

const getBusinessValautionGraphData = (turnoverValuation, ebitdaValuation) => {
    try {
        let turnoverValuationGraphData = [], ebitdaValuationGraphData = [];
        turnoverValuationGraphData = turnoverValuation.find(turnoverValObj => turnoverValObj.key === "turnoverEnterpriseValue")?.values;
        ebitdaValuationGraphData = ebitdaValuation.find(turnoverValObj => turnoverValObj.key === "EBITDAEnterpriseValue")?.values;
        return { turnoverValuationGraphData, ebitdaValuationGraphData };
    } catch (error) {
        console.log('inside getBusinessValautionGraphData ', error);
        throw error;
    }
}
