import {
    SIGNUP_REQUEST,
    SIGNUP_RESPONSE_SUCCESS,
    SIGNUP_RESPONSE_FAILED,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    CLEAR_LOGIN_ERROR,
    RESET_PASSWORD_EMAIL_SUCESS,
    RESET_PASSWORD_EMAIL_FAILED,
    CLEAR_RESET_PASSWORD_MESSAGE,
    RESET_PASSWORD_TOGGLE,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    TOGGLE_RESET_PASSWORD_MESSAGE,
    SET_EMAIL_PASSWORD,
    TOGGLE_LOGIN_MODAL,
    TOGGLE_FORGOT_PASSWORD_LOGIN_MODAL,
    TOGGLE_FORGOT_PASSWORD_MODAL,
    TOGGLE_SIGNUP_MODAL,
    TOGGLE_NEW_LOGIN_MODAL,
    TOGGLE_NEW_SIGNUP_MODAL,
    CLOSE_ALL_LOGIN_SIGNUP_MODAL,
    // GET_PDF_REPORT,
    SET_ON_SUCCESS_RENDER
} from '../actions/actionConstants';
const INITIAL_STATE = {
    isLoginModalOpen: false,
    isSignupModalOpen: false,
    isNewLoginModalOpen: false,
    isNewSignupModalOpen: false,
    signUpModelData: {},
    loginType: "",
    signupType: ""
}
const loginSignupReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGNUP_REQUEST:
            return { ...state, signupInProcess: true }
        case SIGNUP_RESPONSE_SUCCESS:
            return { ...state, signupInProcess: false }
        case SIGNUP_RESPONSE_FAILED:
            return { ...state, signupError: action.payload }
        case LOGIN_SUCCESS:
            return { ...state, token: action.payload, loginError: "" }
        case LOGIN_FAILED:
            return { ...state, loginError: action.payload }
        case CLEAR_LOGIN_ERROR:
            return { ...state, loginError: '' }
        case RESET_PASSWORD_EMAIL_SUCESS:
            return { ...state, forgotPasswordEmailSuccess: action.payload.successMessage }
        case RESET_PASSWORD_EMAIL_FAILED:
            return { ...state, forgotPasswordEmailError: action.payload.errorMessage };
        case CLEAR_RESET_PASSWORD_MESSAGE:
            return { ...state, forgotPasswordEmailSuccess: '', forgotPasswordEmailError: '' };
        case RESET_PASSWORD_TOGGLE:
            return { ...state, resetPasswordToggle: action.payload.toggleStatus };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, resetPaswordSuccessMessage: action.payload.message };
        case RESET_PASSWORD_FAILED:
            return { ...state, resetPasswordErrorMessage: action.payload.message };
        case TOGGLE_RESET_PASSWORD_MESSAGE:
            return { ...state, resetPasswordErrorMessage: '', resetPaswordSuccessMessage: '' };
        case SET_EMAIL_PASSWORD:
            return { ...state, email: action.payload.email, password: action.payload.password };
        case TOGGLE_LOGIN_MODAL:
            return {
                ...state,
                isLoginModalOpen: action.payload.toggleStatus.status,
                isProductPageOpen: action.payload.toggleStatus.productPageOpen,
                loginType: action.payload.toggleStatus.type,
                isSignupModalOpen: false,
                onSuccessRender: action.payload.toggleStatus.status ?
                    { ...state.onSuccessRender, ...action.payload.onSuccessRender } : null
            };
        case TOGGLE_FORGOT_PASSWORD_LOGIN_MODAL:
            return { ...state, isLoginModalOpen: action.payload.loginModalStatus, togglePasswordModal: action.payload.passwordModalStatus }
        case TOGGLE_FORGOT_PASSWORD_MODAL:
            return { ...state, togglePasswordModal: action.payload.passwordModalStatus };
        case TOGGLE_SIGNUP_MODAL:
            return {
                ...state, isSignupModalOpen: action.payload.toggleStatus.status,
                signUpModelData: action.payload.toggleStatus.signUpModelData,
                isLoginModalOpen: false,
                onSuccessRender: action.payload.toggleStatus.status ?
                    { ...state.onSuccessRender, ...action.payload.onSuccessRender } : null
            }
        case TOGGLE_NEW_SIGNUP_MODAL:
            return {
                ...state,
                isNewSignupModalOpen: action.payload.toggleStatus.status,
                signupType: action.payload.toggleStatus.type,
                isNewLoginModalOpen: false,
                onSuccessRender: action.payload.toggleStatus.status ?
                    { ...state.onSuccessRender, ...action.payload.onSuccessRender } : null
            }
        case TOGGLE_NEW_LOGIN_MODAL:
            return {
                ...state,
                isNewLoginModalOpen: action.payload.toggleStatus.status,
                loginType: action.payload.toggleStatus.type,
                isNewSignupModalOpen: false,
                onSuccessRender: action.payload.toggleStatus.status ?
                    { ...state.onSuccessRender, ...action.payload.onSuccessRender } : null
            };
        case CLOSE_ALL_LOGIN_SIGNUP_MODAL:
            return { ...state, isNewLoginModalOpen: false, isNewSignupModalOpen: false, isLoginModalOpen: false, isSignupModalOpen: false, type: "", signupType: "", loginType: "" };
        case SET_ON_SUCCESS_RENDER:
            return { ...state, onSuccessRender: action.payload.onSuccessRender };
        default:
            return state;
    }
}

export default loginSignupReducer;
