import {
    ADD_COMPANY_TO_COMPETITIVE_ANALYSIS,
    REMOVE_COMPANY_FROM_COMPETITIVE_ANALYSIS,
    SET_CURRENT_SELECTED_COMPETITOR,
    SET_SELECTED_COMPETITORS,
    SET_SELECTED_COMPETITORS_UPDATE_SUCCESS,
    UPDATE_SELECTED_COMPETITOR,
} from "../actions/actionConstants"

const INITIAL_STATE = {
    selectedCompetitors: [],
    currentSelectedCompetitor: "",
    mainCompanyREG: "",
    selectedCompetitorUpdateSuccess: false
};

const competitiveAnalysisReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_COMPANY_TO_COMPETITIVE_ANALYSIS:
            const alreadySelected = state.selectedCompetitors.some(competitorObj => competitorObj?.REG === action?.payload?.analysisData?.REG);
            return {
                ...state,
                selectedCompetitors: alreadySelected ?
                    [...state.selectedCompetitors] :
                    [...state.selectedCompetitors, action.payload.analysisData],
                selectedCompetitorUpdateSuccess: alreadySelected ? false : true
            }
        case SET_CURRENT_SELECTED_COMPETITOR:
            return { ...state, currentSelectedCompetitor: action.payload.currentSelectedCompetitor }
        case REMOVE_COMPANY_FROM_COMPETITIVE_ANALYSIS:
            const newSelectedCompetitors = state.selectedCompetitors.filter(companyObj => companyObj.REG !== action.payload.companyId);
            return { ...state, selectedCompetitors: newSelectedCompetitors }
        case UPDATE_SELECTED_COMPETITOR:
            const {
                updatedSelectedCompetitor = {}
            } = action.payload;
            const updatedCompetitors = state.selectedCompetitors.map(companyObj => {
                if (companyObj.REG === updatedSelectedCompetitor.REG) return updatedSelectedCompetitor;
                return companyObj;
            })
            return { ...state, selectedCompetitors: updatedCompetitors }
        case SET_SELECTED_COMPETITORS:
            const mainCompany = action.payload.selectedCompetitors?.find(competitorObj => competitorObj?.original) || {};
            return { ...state, selectedCompetitors: action.payload.selectedCompetitors, mainCompanyREG: mainCompany?.REG || "" }
        case SET_SELECTED_COMPETITORS_UPDATE_SUCCESS:
            return { ...state, selectedCompetitorUpdateSuccess: action.payload.isSuccess }
        default: return { ...state }
    }
}

export default competitiveAnalysisReducer