import { dataURIToBlob } from '../common/commonFunctions';
import { RESTAPI_URL } from '../common/config';
import { commonFetch } from './fetchComImpl';
import { refreshTokenApi } from './refreshTokenApi';

export const saveBusinessPlanDataApi = async (businessPlanObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return  await commonFetch(`${RESTAPI_URL}/businessPlan/saveBusinessPlan`,
            'POST',
            businessPlanObj,
            new Headers({
                'content-type': 'application/json'
            })
        );
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getIndustryDetailsApi=async(sicCodes)=>{
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return await commonFetch(`${RESTAPI_URL}/businessPlan/getIndustryDetails`,
            'POST',
        {sicCodes},
            new Headers({
                'content-type': 'application/json'
            }));
    } catch (error) {
        console.log('inside getIndustryDetails ',error);
    }
}

export const getBusinessPlanDataApi = async (uniqueId) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/businessPlan/getBusinessPlan/${uniqueId}`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
            return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const saveFinancialProjectionDataApi=async(paramsObj)=>{
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return await commonFetch(`${RESTAPI_URL}/businessPlan/saveFinancialData`,
            'POST',
            paramsObj,
            new Headers({
                'content-type': 'application/json'
            }));
    } catch (error) {
        console.error('inside saveFinancialProjectionData ',error);
        throw error;
    }
}

export const getFutureFinancialProjectionDataApi=async(paramsObj)=>{
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return await commonFetch(`${RESTAPI_URL}/businessPlan/getFinancialOnloadProjection`,
            'POST',
            paramsObj,
            new Headers({
                'content-type': 'application/json'
            }));
    } catch (error) {
        console.log('inside getFutureFinancialProjectionDataApi ',error);
        throw error;
    }
}

export const getSuggestedCompetitorsDataApi = async (companyId) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/businessPlan/getSuggestedCompetitors`,
            "POST",
            { REG: companyId },
            new Headers({
                'content-type': "application/json"
            }));
        return response;
    } catch (error) {
        console.log("error in calling suggestedCompetitorsAPi", error);
        throw error;
    }
}

export const uploadTempBussPlanFileApi = async (uploadedFileObj) => {
    try {

        let formData = new FormData();
        formData.append('uniqueId', uploadedFileObj.uniqueId);
        formData.append('isSavePermanently', uploadedFileObj.isSavePermanently);
        if(uploadedFileObj.fileBase64Str){
            const fileObj = dataURIToBlob(uploadedFileObj.fileBase64Str,uploadedFileObj.uploadFileCmpObject.fileName);
            formData.append('image', fileObj);
        }
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const uploadedFileApiRes = await commonFetch(`${RESTAPI_URL}/businessPlan/uploadTempBussPlanFile`,
            'PUT',
            formData,
            new Headers({}));
            
        return uploadedFileApiRes;

    } catch (error) {
        throw error;
    }
}

export const getBusinessPlanCompanyInsightsApi = async (REG) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/businessPlan/getPomandaInsights`,
            'POST',
            { REG },
            new Headers({
                'content-type': 'application/json'
            }));
        return response
    } catch (error) {
        console.log('error in getBusinessPlanCompanyInsightsApi', error);
        throw error;
    }
}

export const getBusinessPlanPagePreviewApi = async (previewObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/businessPlan/getBusinessPlanPagePreview`,
            'POST',
            {previewObj},
            new Headers({
                'content-type': 'application/json'
            }));
        return response
    } catch (error) {
        console.log('error in getBusinessPlanPagePreviewApi', error);
        throw error;
    }
}

export const getBusinessPlanIndustryInsightsApi = async (indMix) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/businessPlan/getBusinessPlanIndustryInsights`,
            'POST',
            {indMix},
            new Headers({
                'content-type': 'application/json'
            }));
        return response
    } catch (error) {
        console.log('error in getBusinessPlanIndustryInsightsApi', error);
        throw error;
    }
}