import { RESTAPI_URL } from '../common/config';
import { commonFetch } from './fetchComImpl';
import { refreshTokenApi } from './refreshTokenApi';

export const getLocationProfileApiCall = async (locationId) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return await commonFetch(`${RESTAPI_URL}/location/getLocationProfile/${locationId}`,
            'GET',
            {},
            new Headers({
                'Content-Type': 'application/json'
            })
        );
    } catch (error) {
        console.error('inside getLocationProfileApiCall ', error);
        throw error;
    }
}

export const getLocationProfileSearchApiCall = async (searchText) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return await commonFetch(`${RESTAPI_URL}/location/getLocationProfileSearch/${searchText}`,
            'GET',
            {},
            new Headers({
                'Content-Type': 'application/json'
            })
        );
    } catch (error) {
        console.error('inside getLocationProfileSearchApiCall ', error);
        throw error;
    }
}