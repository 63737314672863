import React, { useEffect, useState } from "react";
import './WatchListProfileCardComp.css'
import { useDispatch, useSelector } from "react-redux";
import TreeMapParent from "../../TreeMapParent/TreeMapParent";
import WatchListProfileChartZoomModal from "./WatchListProfileChartZoomModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sendActivityTracking } from "../../../redux/actions/commonAction";
import { EP_POWER_SEARCH_COMPANY, ET_WATCHLIST_PROFILING_CHART_ZOOM_CLICK, ET_WATCHLIST_PROFILING_COMPANY_PROFILE_LINK_CLICK, ET_WATCHLIST_PROFILING_COPY_RAW_DATA_CLICK, ET_WATCHLIST_PROFILING_DATA_TAB_CLICK, ET_WATCHLIST_PROFILING_TAB_MENU_CLICK } from "../../../common/trackingConstants";
import { capitalizeSentence } from "../../../common/commonUtils";
import { convertAmtToKMBWithPrecision } from "../../../common/CurrencyConverter";
import { commonWatchListProfilingChartColors } from "../../../common/commonChartColor";

const dataTabMenuItem = [
    {
        menuText: "Chart",
        menuId: "chart"
    },
    {
        menuText: "Raw Data",
        menuId: "rawData"
    }
]

const WatchListProfileCardComp = (props) => {
    const[activeMenu, setActiveMenu] = useState({text: dataTabMenuItem[0]?.menuText, menuId: dataTabMenuItem[0]?.menuId})
    const[isChartZoom, setIsChartZoom] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const screen = useSelector((state) => state.common.screen)
    const[isMouseHover, setIsMouseHover] = useState(false)
    const {
        graphData={},
        currentTabObj={},
        watchListName="",
        topActiveMenuId="",
        topActiveMenuText=""
    } = props
    const { category="", totalCount=0, children=[] } = graphData;

    const handleMenuClick = (item) => {
        if (currentTabObj[item.menuId]) {
            setActiveMenu({ text: item.menuText, menuId: item.menuId })
        }
        dispatch(sendActivityTracking({
            eventPage: EP_POWER_SEARCH_COMPANY,
            eventType: ET_WATCHLIST_PROFILING_DATA_TAB_CLICK,
            attribute1: item.menuText
        }))
    }

    const handleChartZoom = () => {
        setIsChartZoom(!isChartZoom)
        dispatch(sendActivityTracking({
            eventPage: EP_POWER_SEARCH_COMPANY,
            eventType: ET_WATCHLIST_PROFILING_CHART_ZOOM_CLICK,
            attribute1: topActiveMenuText,
            attribute2: category
        }))
    }

    const handleCopy = () => {
        let clipboardText = "";
        children.forEach((item) => {
            const value = item?.value || item?.reg || 0
            clipboardText += `${item.name || item.companyName}\t${String(value)}\n`
        }) 
        navigator.clipboard.writeText(clipboardText)
        setIsMouseHover(false)
        dispatch(sendActivityTracking({
            eventPage: EP_POWER_SEARCH_COMPANY,
            eventType: ET_WATCHLIST_PROFILING_COPY_RAW_DATA_CLICK,
            attribute1: topActiveMenuText,
            attribute2: category
        }))
    }


    const prepareTreeMapData = children.map(dataObj => {
        const formattedName = capitalizeSentence(dataObj?.name || dataObj?.companyName)
        return {
        name: formattedName,
        value: dataObj?.value !== undefined ? dataObj.value : dataObj?.reg,
        id: category
    }}).sort((a,b) => b.value - a.value)    

    const getCompanyProfileLink = (companyId, companyName) => {
        let char = { " ": "-", "%": "%25" }
        companyName = encodeURIComponent(companyName.trim().toLowerCase().replace(/[ %]/g, ch => char[ch]))
        return `/company/${companyId}/${companyName}`
    }

    const handleCompanyProfileClick = (e, companyId, companyName, watchListName) => {
        e.preventDefault();
        const url = e.target.href
        window.open(url, '_blank');
        dispatch(sendActivityTracking({
            eventPage: EP_POWER_SEARCH_COMPANY,
            eventType: ET_WATCHLIST_PROFILING_COMPANY_PROFILE_LINK_CLICK,
            attribute1: companyName,
            attribute2: companyId,
            attribute3: watchListName
        }))
    }

    const getFormattedNumber = (formatType, value) => {
        switch(formatType) {
            case "kmb": {
                return convertAmtToKMBWithPrecision(value, 1)
            }
            default: {
                return Math.round((value)).toLocaleString()
            }
        }
    }

    useEffect(() => {
        setActiveMenu({
            text: currentTabObj['chart'] ? dataTabMenuItem[0]?.menuText : dataTabMenuItem[1]?.menuText,
            menuId: currentTabObj['chart'] ? dataTabMenuItem[0]?.menuId : dataTabMenuItem[1]?.menuId
        })
    }, [graphData])

    return (
        <div className="wl-profile-card-container">
            <div className="wl-profile-card-header-section">
                <div className="wl-profile-card-header-text-wrapper">
                    { topActiveMenuId === "top10Companies" && category === "Companies" ? 
                        <p className="wl-profile-card-header-text">Top 10 Companies</p> :
                        <p className="wl-profile-card-header-text">{category} <span className="wl-profile-card-number-text">({Math.round((Number(totalCount))).toLocaleString()})</span></p>
                    }
                    <div className="wl-profile-card-icon-container">
                        <img 
                        src={isMouseHover ? "/assets/svgs/copy_yellow_icon.svg" : "/assets/svgs/copy_icon.svg"} 
                        onMouseEnter={() => setIsMouseHover(true)}
                        onMouseLeave={() => setIsMouseHover(false)}
                        width={32} 
                        height={32} 
                        className="cursor-pointer" 
                        onClick={handleCopy} />
                        {currentTabObj.chart && currentTabObj.rawData && 
                            <img src={`${activeMenu.menuId === 'rawData' ? "/assets/svgs/zoom_disabled_icon.svg" : "/assets/svgs/zoom_icon.svg"}`} className={`${activeMenu.menuId === 'chart' && 'cursor-pointer'}`} width={32} height={32} onClick={handleChartZoom}/>
                        }
                    </div>
                </div>
                <div className="wl-profile-card-btn-tap-section">
                    {dataTabMenuItem?.map((item, index) => {
                        return (
                            !currentTabObj.chart && !currentTabObj.rawData ? "" :
                            <button
                                className={`wlp-card-menu-button ${(activeMenu.menuId === item.menuId) && currentTabObj[activeMenu.menuId] ? 'wlp-card-active-menu' : !currentTabObj["chart"] || !currentTabObj["rawData"] ? 'wlp-card-disable-menu' : ""}`}
                                key={`wlp_menu_${index}`}
                                onClick={() => handleMenuClick(item)}>{item.menuText}
                            </button>
                        )
                    })}
                </div>
                    {currentTabObj["chart"] && activeMenu.menuId === "chart" ?
                        (<TreeMapParent
                            graphData={prepareTreeMapData}
                            screen={screen}
                            isWatchListProfile={true}
                            colors={commonWatchListProfilingChartColors}
                            />) 
                        : currentTabObj["rawData"] && activeMenu.menuId === "rawData" ?
                        (
                            <div className="wlp-raw-data-wrapper">
                                {
                                    prepareTreeMapData.map((obj, index) => {
                                        return (
                                            <div className="wlp-raw-data" key={index}>
                                                <p className="wlp-raw-data-name">{obj.name}</p>
                                                <p className="wlp-raw-data-value">{getFormattedNumber(currentTabObj?.formatType, obj?.value || 0)}</p>
                                            </div>
                                        )
                                    })
                                }
                             </div>
                        ) : 
                        (
                            <ol className="wlp-companies-wrapper" type="1">
                                {
                                    prepareTreeMapData.map((obj, index) => {
                                        return (
                                                <li className="wlp-top-comp-raw-data" key={index}>
                                                    <a className="wlp-top-comp-raw-data-name" href={getCompanyProfileLink(obj.value, obj.name)} onClick={(e) => handleCompanyProfileClick(e,obj.value, obj.name, watchListName)} target="_blank">{obj.name}</a>
                                                </li>
                                        )
                                    })
                                }
                            </ol>
                        )
                }
            </div>
            {
                isChartZoom && 
                    <WatchListProfileChartZoomModal 
                    toggle={handleChartZoom} 
                    graphData={prepareTreeMapData}
                    screen={screen} 
                    heading={category} />
            }
        </div>
    )
} 

export default WatchListProfileCardComp