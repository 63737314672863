let { COMPANY_DIRECTOR_SEARCH_LIMIT_FOR_ANONYMOUS } = require('../common/config');
let moment = require('moment');

let self = {
    getUserData: () => {
        return JSON.parse(localStorage.getItem("userTrackerObj"))
    },
    setUserData: (obj) => {
        localStorage.setItem("userTrackerObj", JSON.stringify(obj))
    },
    removeUserData: () => {
        localStorage.removeItem("userTrackerObj");
    },
    getTempFlag: () => {
        return localStorage.getItem("flagForReplaceData")
    },
    setTempFlag: (data) => {
        localStorage.setItem("flagForReplaceData", data)
    },
    getCookieMessFlag: () => {
        return localStorage.getItem("cookieMessaging")
    },
    setCookieMessFlag: (data) => {
        localStorage.setItem("cookieMessaging", data)
    },
    getToken: () => {
        return localStorage.getItem('token')
    },
    getUserAuthToken: () => {
        let userData = self.getUserData()
        return userData ? userData.accessToken ? userData.accessToken : null : null
    },
    setStartTime: (time) => {
        localStorage.setItem('startTime', time);
    },
    getStartTime: () => {
        return localStorage.getItem('startTime');
    },
    getCookie: (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    setCookie:(key, value) =>{
        document.cookie = key + "=" + value + "; " + "expires= Thu, 01 Jan 2100 00:00:01 GMT" + "; path=/";
    },
    removeCookie:(key) =>{
        document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
    },
    checkFreeSearchForAnonymous: (_searchEntity, id) => {
        let searchCountObj = JSON.parse(localStorage.getItem('searchCountObj'));
        if (searchCountObj && searchCountObj.searchDate && moment(searchCountObj.searchDate).isSame(moment(), 'day')) {
            let totalCount = searchCountObj && searchCountObj.companyDirectorIds ? searchCountObj.companyDirectorIds.length : 0
            let existCompanyDirectorIds = searchCountObj && searchCountObj.companyDirectorIds && searchCountObj.companyDirectorIds.find((item) => {
                if (item.type === _searchEntity && item.id === id) {
                    return item.id
                }
                return null;
            });
            if (totalCount >= COMPANY_DIRECTOR_SEARCH_LIMIT_FOR_ANONYMOUS && !existCompanyDirectorIds) {
                return { anonymousFreeSearchExpired: true };
            } else {
                if (!existCompanyDirectorIds || existCompanyDirectorIds === undefined || existCompanyDirectorIds === 'undefined') {
                    searchCountObj.companyDirectorIds.push({ type: _searchEntity, id: id });
                    localStorage.setItem("searchCountObj", JSON.stringify(searchCountObj));
                }
                return { anonymousFreeSearchExpired: false };
            }
        } else {
            searchCountObj = {
                searchDate: new Date(),
                companyDirectorIds: [{ type: _searchEntity, id: id }]
            }
            localStorage.setItem("searchCountObj", JSON.stringify(searchCountObj));
            return { anonymousFreeSearchExpired: false };
        }
    },
    getActivityTrackingObjList: () => {
        let list = JSON.parse(localStorage.getItem("actTrakObjList"))
        return list && list.length > 0 ? list : []
    },
    setActivityTrackingObjList: (actTrakObj) => {
        let actTrakObjList = self.getActivityTrackingObjList()
        if (Object.keys(actTrakObj).length !== 0) {
            actTrakObjList.push(actTrakObj)
            localStorage.setItem("actTrakObjList", JSON.stringify(actTrakObjList));
        }else{
            localStorage.setItem("actTrakObjList", JSON.stringify([]));
        }
    },
    getTid: () => {
        const userTrackObj = JSON.parse(localStorage.getItem("userTrackerObj"))
            if(userTrackObj && userTrackObj.latest_tid){
                 return userTrackObj.latest_tid;
            }
            else{
                return null;
            }
    },
    anonymousOpenUrlCount: (reportUrlVisitCount) => {
        const reportUrlOpenCountForAnonymousUser= JSON.parse(localStorage.getItem(reportUrlVisitCount))
        if(reportUrlOpenCountForAnonymousUser < 1){
            localStorage.setItem(reportUrlVisitCount, 1)
            return 0;
        }
        else{
            return 1;
        }
    },
    getSearchCountObj: () => {
        return JSON.parse(localStorage.getItem('searchCountObj'));
    },
    updateFreeSearchCountObj: (searchType, id) => {
        let searchCountObj = JSON.parse(localStorage.getItem('searchCountObj'));
        if(searchType==="company"){
            if (searchCountObj) {
                let existCompanyDirectorIds = searchCountObj.companyDirectorIds && searchCountObj.companyDirectorIds.find((item) => {
                    if (item.type === searchType && item.id === id) {
                        return item.id
                    }
                    return null;
                });
                if (!existCompanyDirectorIds) {
                    searchCountObj.companyDirectorIds.push({ type: searchType, id });
                    localStorage.setItem("searchCountObj", JSON.stringify(searchCountObj));
                }
            } else {
                searchCountObj = { 
                    searchDate: new Date(),
                    companyDirectorIds: [{ type: searchType, id }] 
                };
                localStorage.setItem("searchCountObj", JSON.stringify(searchCountObj));
            }
        }
    },
    anonymousUser:()=>{
        localStorage.setItem('anonymousUser', true);
    },
    getAnonymousUser: () => {
        return JSON.parse(localStorage.getItem('anonymousUser'));
    },
    getTemplateListShowStatus: () => JSON.parse(localStorage.getItem('showTemplateList')),
    setTemplateListShowStatus: (flag) => localStorage.setItem("showTemplateList", flag),
    setActTrackToken:(token) => localStorage.setItem('actTrkToken', token),
    getActTrackToken:() => {
        return localStorage.getItem('actTrkToken')
    }
}

export default self;