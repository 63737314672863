import { ADD_SELECTED_COMPETITOR, DOWNLOAD_COMPETITOR_ANALYSIS_DATA, GET_COMPETITOR_ANALYSIS_DATA, GET_SELECTED_COMPETITORS_ANALYSIS_DATA, REMOVE_SELECTED_COMPETITOR } from "../redux/actions/actionConstants";
import { addSelectedCompetitorReq, downloadCompetitorAnalysisData, getCompetitorAnalysisDataReq, getSelectedCompetitorsAnalysisDataReq, removeSelectedCompetitorReq, saveSelectedCompetitorReq } from "../api/competitorAnalysisApi";
import { put, takeLatest, call, select, all } from "redux-saga/effects"
import { addCompanyToCompetitiveAnalysis, removeCompanyFromCompetitiveAnalysis, setCurrentSelectedCompetitor, setSelectedCompetitorUpdateSuccess, setSelectedCompetitorsArr, updateSelectedCompetitors } from "../redux/actions/competitorAnalysisAction";
import { resetIndustryMixUpdateSuccess, setIndustryMixLoader, setIndustryMixUpdateSuccess } from "../redux/actions/companyAction";

function* getCompetitorAnalysisDataSaga(action) {
    const selectedCompetitors = yield select(state => state.competitiveAnalysis.selectedCompetitors);
    const selectedCompetitorUpdateSuccess = yield select(state => state.competitiveAnalysis.selectedCompetitorUpdateSuccess)
    try {
        const {
            companyId = ""
        } = action.payload;
        yield selectedCompetitorUpdateSuccess && put(setSelectedCompetitorUpdateSuccess(false))
        const {
            companyAnalysisData = {},
            error = true
        } = yield call(getCompetitorAnalysisDataReq, companyId)
        if (!error) {
            const isCompetitorExisted = selectedCompetitors?.some(companyObj => companyObj.REG === companyAnalysisData.REG);
            if (isCompetitorExisted) {
                yield all([
                    put(updateSelectedCompetitors(companyAnalysisData)),
                    put(setIndustryMixUpdateSuccess(false)),
                    put(setCurrentSelectedCompetitor("")),
                    put(resetIndustryMixUpdateSuccess(false)),
                    put(setSelectedCompetitorUpdateSuccess(true))
                ])
            } else {
                yield put(setCurrentSelectedCompetitor(companyAnalysisData));
            }
        }
    } catch (error) {
        console.log(error, 'error in getCompetitorAnalysisDataSaga')
    }
}

export function* watchGetCompetitorAnalysisData() {
    yield takeLatest(GET_COMPETITOR_ANALYSIS_DATA, getCompetitorAnalysisDataSaga)
}

function* getSelectedCompetitorsAnalysisSaga(action) {
    const { companyId = "" } = action.payload;
    try {
        const selectedCompetitorUpdateSuccess = yield select(state => state.competitiveAnalysis.selectedCompetitorUpdateSuccess)
        if( selectedCompetitorUpdateSuccess) {
            yield put(setSelectedCompetitorUpdateSuccess(false))
        }
        const { selectedCompetitors = [], error = true } = yield call(getSelectedCompetitorsAnalysisDataReq, companyId);
        if (!error) {
            yield put(setSelectedCompetitorsArr(selectedCompetitors));
            yield put(setSelectedCompetitorUpdateSuccess(true))
        }
        yield put(setIndustryMixLoader({ show: false, message: "" }))
    } catch (error) {
        console.log(error, 'error in getSelectedCompetitorsAnalysisSaga')
        yield put(setIndustryMixLoader({ show: false, message: "" }))
    }
}

export function* watchGetSelectedCompetitorsAnalysisData() {
    yield takeLatest(GET_SELECTED_COMPETITORS_ANALYSIS_DATA, getSelectedCompetitorsAnalysisSaga)
}

function* addSelectedCompetitorSaga(action) {
    try {
        const {
            competitorId = "",
            mainCompanyREG = ""
        } = action.payload;
        const selectedCompetitorUpdateSuccess = yield select(state => state.competitiveAnalysis.selectedCompetitorUpdateSuccess)
        yield selectedCompetitorUpdateSuccess && put(setSelectedCompetitorUpdateSuccess(false))
        const { savedCompetitor = {}, error = true } = yield call(addSelectedCompetitorReq, { competitorId, mainCompanyREG });
        if (!error) {
            yield all([
                put(addCompanyToCompetitiveAnalysis(savedCompetitor)),
                put(setCurrentSelectedCompetitor("")),
                put(setIndustryMixUpdateSuccess(false)),
                put(setSelectedCompetitorUpdateSuccess(true))
            ])
        }
    } catch (error) {
        console.log(error, 'error in addSelectedCompetitorSaga')
    }
}

export function* watchAddSelectedCompetitor() {
    yield takeLatest(ADD_SELECTED_COMPETITOR, addSelectedCompetitorSaga)
}

function* removeSelectedCompetitorSaga(action) {
    try {
        const {
            competitorId = "",
            mainCompanyREG = ""
        } = action.payload;
        const selectedCompetitorUpdateSuccess = yield select(state => state.competitiveAnalysis.selectedCompetitorUpdateSuccess)
        yield selectedCompetitorUpdateSuccess && put(setSelectedCompetitorUpdateSuccess(false));
        const { error = true, isSaved = false } = yield call(removeSelectedCompetitorReq, { competitorId, mainCompanyREG });
        if (!error && isSaved) {
            yield put(removeCompanyFromCompetitiveAnalysis(competitorId));
            yield put(setSelectedCompetitorUpdateSuccess(true))
        }
    } catch (error) {
        console.log(error, 'error in removeSelectedCompetitorSaga')
    }
}

export function* watchRemoveSelectedCompetitor() {
    yield takeLatest(REMOVE_SELECTED_COMPETITOR, removeSelectedCompetitorSaga);
}

function* downloadCompetitorAnalysisDataSaga(action) {
    try {
        const {
            competitorAnalysisData = []
        } = action.payload;
        yield call(downloadCompetitorAnalysisData, competitorAnalysisData);
    } catch (error) {
        console.log(error, "error in downloadCompetitorAnalysisDataSaga");
    }
}

export function* watchDownloadCompetitorAnalysisData() {
    yield takeLatest(DOWNLOAD_COMPETITOR_ANALYSIS_DATA, downloadCompetitorAnalysisDataSaga)
}