export function getAppliedFilterList(appliedFilter) {
    try {
        let filterList = [];
        const appliedFilterKeys = Object.keys(appliedFilter);
        appliedFilterKeys && appliedFilterKeys.length > 0 && appliedFilterKeys.forEach(key => {
            const type = appliedFilter[key].type;
            switch (type) {
                case "text":
                case "radio":
                    const textFilter = getFilterForText(appliedFilter[key])
                    filterList.push(textFilter);
                    break;
                case "range":
                case "yearrange":
                case "daterange":
                case "financialrange":
                    const rangeFilters = getFilterForRange(appliedFilter[key])
                    filterList = [...filterList, ...rangeFilters];
                    break;
                case "check":
                case "score":
                case "tags":
                    const checkFilter = getFilterForTreeAndCheckFilters(appliedFilter[key], type)
                    filterList.push(checkFilter);
                    break;
                case "location":
                case "industry":
                    const treeFilter = getFilterForTreeAndCheckFilters(appliedFilter[key], "tree")
                    filterList.push(treeFilter);
                    break;
                default:
                    break;
                // code block
            }
        });
        return filterList;
    } catch (e) {
        console.log('inside getAppliedFilterList ', e);
        throw e;
    }
}

const getFilterForText = (appliedFilterObj) => {
    const fields = Object.keys(appliedFilterObj.fields);
    return {
        key: appliedFilterObj.name.toLowerCase(),
        value: appliedFilterObj.fields[fields[0]].value,
        values: [],
        isMultipleValues: false
    }
}

const getFilterForTreeAndCheckFilters = (appliedFilterObj, type) => {
    const fieldKeys = Object.keys(appliedFilterObj.fields);
    const fieldValue = (type === "check" || type === "score" || type === "tags") ? 
        appliedFilterObj.fields[fieldKeys[0]].value : 
            appliedFilterObj.fields[fieldKeys[0]].labels;
    const valueType = (type === "check" || type === "score" || type === "tags") ? "displayName" : "label";
    let values = [];
    fieldValue && fieldValue.length > 0 && fieldValue.forEach(value => {
        values.push(value[valueType].toLowerCase())
    });
    return {
        key: appliedFilterObj.name.toLowerCase(),
        value: "",
        values,
        isMultipleValues: true
    }
}

const getFilterForRange = (appliedFilterObj) => {
    const fieldKeys = Object.keys(appliedFilterObj.fields);
    let filterList = [];
    fieldKeys.forEach(key => {
        appliedFilterObj.fields[key].value &&
            filterList.push({
                key: `${appliedFilterObj.name.toLowerCase()} ${appliedFilterObj.fields[key].type.toLowerCase()}`,
                value: appliedFilterObj.fields[key].value,
                values: [],
                isMultipleValues: false

            })
    });
    return filterList
}
