import { RESTAPI_URL } from "../common/config"
import { commonFetch } from "./fetchComImpl"
import loadable from '@loadable/component'
const XLSXlib = loadable.lib(() => import('xlsx'))
import financialsFieldsAndDataKeyMapping from './financialFieldsAndDataKeyMapping.json';

export const getCompetitorAnalysisDataReq = async (companyId) => {
    try {
        const resp = await commonFetch(`${RESTAPI_URL}/competitorAnalysis/getAnalysisData/${companyId}`,
            "GET",
            { },
            new Headers({
                'content-type': "application/json"
            }));
        return resp
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getSelectedCompetitorsAnalysisDataReq = async (companyId) => {
    try {
        const resp = await commonFetch(`${RESTAPI_URL}/competitorAnalysis/getCompetitorAnalysisData/${companyId}`,
            "GET",
            { },
            new Headers({
                'content-type': "application/json"
            }));
        return resp;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const saveSelectedCompetitorReq = async ({ mainCompanyREG, competitorId }) => {
    try {
        const resp = await commonFetch(`${RESTAPI_URL}/competitorAnalysis/addSelectedCompetitor`,
            "POST",
            { mainCompanyREG, competitorId },
            new Headers({
                'content-type': "application/json"
            }));
        return resp;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const addSelectedCompetitorReq = async ({ mainCompanyREG, competitorId }) => {
    try {
        const resp = await commonFetch(`${RESTAPI_URL}/competitorAnalysis/addSelectedCompetitor`,
            "POST",
            { mainCompanyREG, competitorId },
            new Headers({
                'content-type': "application/json"
            }));
        return resp;
    } catch (error) {
        throw error;
    }
}

export const removeSelectedCompetitorReq = async ({ mainCompanyREG, competitorId }) => {
    try {
        const resp = await commonFetch(`${RESTAPI_URL}/competitorAnalysis/removeSelectedCompetitor`,
            "POST",
            { mainCompanyREG, competitorId },
            new Headers({
                'content-type': "application/json"
            }));
        return resp;
    } catch (error) {
        throw error;
    }
}

const makeAnalysisDataForExcel = (competitiveAnalysisObj, mapping, worksheetData) => {
    let rowData = new Array;
    Object.keys(mapping).map((key) => {
        const element = competitiveAnalysisObj[key];
        rowData.push(element);
    })
    worksheetData.push(rowData);
}

export const downloadCompetitorAnalysisData = async (competitorAnalysisData) => {
    if (competitorAnalysisData.length > 0) {
        const XLSX = await XLSXlib.load();
        let new_workbook = XLSX.utils.book_new();
        const predefinedKeys = financialsFieldsAndDataKeyMapping["competitorAnalysis"];
        let headerKeys = Object.keys(predefinedKeys).map((key) => predefinedKeys[key]);
        let worksheetData = [headerKeys];
        competitorAnalysisData.forEach((competitorObj) => {
            makeAnalysisDataForExcel(competitorObj, financialsFieldsAndDataKeyMapping["competitorAnalysis"], worksheetData);
        })
        let worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "Competitor Analysis Data");
        XLSX.writeFile(new_workbook, `CompetitorAnalysisData.xlsx`);
    }
}