import { put } from "redux-saga/effects";

import { sendActivityTracking } from "../redux/actions/commonAction";
import { getAppliedFilterList } from "../components/PowerSearch/common/AppliedFilterList";
import { setActivityTrackingData } from "../redux/actions/searchAction";
import { COMPANY_HOUSE_PAGE_HEADER_SEARCH_BUTTON, COMPANY_HOUSE_PAGE_SEARCHBAR_ENTER, COMPANY_HOUSE_PAGE_SEARCHBAR_ICON, COMPANY_HOUSE_PAGE_DIRECTOR_SEARCH_BUTTON, COMPANY_HOUSE_PAGE_POWER_SEARCH_BUTTON, COMPANY_HOUSE_PAGE_SEARCH_MODAL_SEARCHBAR_ICON, COMPANY_HOUSE_PAGE_SEARCH_MODAL_SEARCHBAR_ENTER } from "../common/trackingConstants";

export function* powerSearchCompanyActivityTracking(trackingObj) {
    try {
        let activityObj = {
            attribute1: trackingObj.oldResultCount ? trackingObj.oldResultCount : 0,
            attribute2: trackingObj.newResultCount ? trackingObj.newResultCount : 0,
        };
        switch (trackingObj.eventCreated) {
            case "HOMEPAGE_HEADER_SEARCH_BUTTON":
            case "HOMEPAGE_COMPANY_INFO_SEARCHBAR_ICON":
            case "HOMEPAGE_COMPANY_INFO_SEARCHBAR_ENTER":
            // case "HOMEPAGE_COMPANY_INFO_COMPANY_SEARCH_BUTTON":
            case "HOMEPAGE_EXPLORE_BUSINESS_SEARCH_BUTTON":
            case "HOMEPAGE_POWER_SEARCH_SECTION_SEARCH_BUTTON":
            case "HOMEPAGE_BUSINESS_INSIGHT_SEARCHBAR_ICON":
            case "HOMEPAGE_BUSINESS_INSIGHT_SEARCHBAR_ENTER":
            case "HOMEPAGE_RISK_COMPLIANCE_SEARCHBAR_ICON":
            case "HOMEPAGE_RISK_COMPLIANCE_SEARCHBAR_ENTER":
            case "HOMEPAGE_INNOVATIVE_RESEARCH_SEARCH_BUTTON":
            // case "HOMEPAGE_COMPANY_INFO_SEARCHBAR_SEARCH":
            // case "HOMEPAGE_BUSINESS_INSIGHT_SEARCHBAR_SEARCH":
            // case "HOMEPAGE_RISK_COMPLIANCE_SEARCHBAR_SEARCH":
            // case "HOMEPAGE_COMPANY_INFO_DIRECTOR_SEARCH_BUTTON":
            case "HOMEPAGE_GENERATE_LEADS_DIRECTOR_SEARCH_BUTTON":
                activityObj = {
                    ...activityObj,
                    eventPage: trackingObj.eventPage,
                    eventType: trackingObj.eventType,
                    attribute3: trackingObj.eventCreated ? trackingObj.eventCreated : "",
                    attribute4: "homepage",
                    attribute5: trackingObj.searchText,
                }
                break;

            case "HOMEPAGE_FOOTER_SEARCH_BUTTON":
                activityObj = {
                    ...activityObj,
                    eventPage: trackingObj.eventPage,
                    eventType: trackingObj.eventType,
                    attribute3: trackingObj.eventCreated ? trackingObj.eventCreated : "",
                    attribute4: "footer",
                    attribute5: trackingObj.searchText,
                }
                break;

            case "POWER_SEARCH_HEADER_TOGGLE":
                activityObj = {
                    ...activityObj,
                    eventPage: trackingObj.eventPage,
                    eventType: trackingObj.eventType,
                    attribute3: trackingObj.eventCreated ? trackingObj.eventCreated : "",
                    attribute4: trackingObj.searchText,
                }
                break;
            case "POWER_SEARCH_ESTIMATION_TOGGLE":
                activityObj = {
                    ...activityObj,
                    eventPage: trackingObj.eventPage,
                    eventType: trackingObj.eventType,
                    attribute3: trackingObj.eventCreated ? trackingObj.eventCreated : "",
                    attribute4: trackingObj.status,
                    attribute5: trackingObj.searchText,

                }
                break;

            case "POWER_SEARCH_COMPANY_TABLE_SORT":
            case "POWER_SEARCH_DIRECTOR_TABLE_SORT":
                activityObj = {
                    ...activityObj,
                    eventPage: trackingObj.eventPage,
                    eventType: trackingObj.eventType,
                    attribute3: trackingObj.eventCreated ? trackingObj.eventCreated : "",
                    attribute4: trackingObj.sortColumn,
                    attribute5: trackingObj.sortOrder,
                    attribute6: trackingObj.searchText
                }
                break;

            case "POWER_SEARCH_FILTER_SECTION":
                const filterListForAppliedFilter = getAppliedFilterList(trackingObj.appliedFilter);
                activityObj = {
                    ...activityObj,
                    eventPage: trackingObj.eventPage,
                    eventType: trackingObj.eventType,
                    attribute3: trackingObj.key ? trackingObj.key : "",
                    attribute4: trackingObj.newValue,
                    attribute5: trackingObj.oldValue,
                    filters: filterListForAppliedFilter,
                }
                break;

            case "POWER_SEARCH_APPLIED_FILTER_REMOVE_BUTTON":
                const filterListForRemoveFilter = getAppliedFilterList(trackingObj.appliedFilter);
                activityObj = {
                    ...activityObj,
                    eventPage: trackingObj.eventPage,
                    eventType: trackingObj.eventType,
                    attribute3: trackingObj.key ? trackingObj.key : "",
                    attribute4: trackingObj.newValue,
                    attribute5: trackingObj.oldValue,
                    filters: filterListForRemoveFilter,
                }
                break;
            case COMPANY_HOUSE_PAGE_HEADER_SEARCH_BUTTON:
            case COMPANY_HOUSE_PAGE_SEARCHBAR_ENTER:
            case COMPANY_HOUSE_PAGE_SEARCHBAR_ICON:
            case COMPANY_HOUSE_PAGE_DIRECTOR_SEARCH_BUTTON:
            case COMPANY_HOUSE_PAGE_POWER_SEARCH_BUTTON:
            case COMPANY_HOUSE_PAGE_SEARCH_MODAL_SEARCHBAR_ICON:
            case COMPANY_HOUSE_PAGE_SEARCH_MODAL_SEARCHBAR_ENTER:
                activityObj = {
                    ...activityObj,
                    eventPage: trackingObj.eventPage,
                    eventType: trackingObj.eventType,
                    attribute3: trackingObj.eventCreated ? trackingObj.eventCreated : "",
                    attribute4: "companyhouse",
                    attribute5: trackingObj.searchText,
                }
                break;
            default:
                activityObj = {
                    ...activityObj,
                    eventPage: trackingObj.eventPage,
                    eventType: trackingObj.eventType,
                    attribute3: trackingObj.eventCreated ? trackingObj.eventCreated : "",
                    attribute4: trackingObj.searchText ? trackingObj.searchText : "",
                }
        }
        activityObj.attribute3 = typeof activityObj.attribute3 === "object" ? "" : activityObj.attribute3
        yield put(sendActivityTracking(activityObj));
        yield put(setActivityTrackingData({ obj: {}, type: "powerSearch" }));

    } catch (error) {
        console.log("error occured in activity tracking", error);
    }
}


export function* homepageSingleSearchActivityTracking(trackingObj) {
    try {
        let activityObj = {
            attribute1: trackingObj.companyCount ? trackingObj.companyCount : 0,
            attribute2: trackingObj.directorCount ? trackingObj.directorCount : 0,
            attribute5: trackingObj.industryCount ? trackingObj.industryCount : 0,
            attribute6: trackingObj.shareholderCount ? trackingObj.shareholderCount : 0,
            attribute7: trackingObj.chargesCount ? trackingObj.chargesCount : 0,
            attribute8: trackingObj.regionCount ? trackingObj.regionCount : 0
        };
        switch (trackingObj.eventCreated) {
            case "HOMEPAGE_COMPANY_INFO_SEARCHBAR_SEARCH":
            case "HOMEPAGE_BUSINESS_INSIGHT_SEARCHBAR_SEARCH":
            case "HOMEPAGE_RISK_COMPLIANCE_SEARCHBAR_SEARCH":
                activityObj = {
                    ...activityObj,
                    eventPage: trackingObj.eventPage,
                    eventType: trackingObj.eventType,
                    attribute3: trackingObj.eventCreated ? trackingObj.eventCreated : "",
                    attribute4: trackingObj.searchText
                }
                break;

            default:
                activityObj = {
                    ...activityObj,
                    eventPage: trackingObj.eventPage,
                    eventType: trackingObj.eventType,
                    attribute3: trackingObj.eventCreated ? trackingObj.eventCreated : "",
                    attribute4: trackingObj.searchText ? trackingObj.searchText : ""
                }
        }
        activityObj.attribute3 = typeof activityObj.attribute3 === "object" ? "" : activityObj.attribute3
        yield put(sendActivityTracking(activityObj));

    } catch (error) {
        console.log("error occured in activity tracking", error);
    }
}