import {
    SIGNUP_REQUEST,
    SIGNUP_RESPONSE_SUCCESS,
    SIGNUP_RESPONSE_FAILED,
    LOGIN_REQUEST,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    CLEAR_LOGIN_ERROR,
    RESET_PASSWORD_EMAIL_REQUEST,
    RESET_PASSWORD_EMAIL_FAILED,
    RESET_PASSWORD_EMAIL_SUCESS,
    CLEAR_RESET_PASSWORD_MESSAGE,
    RESET_PASSWORD_TOGGLE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    CHECK_RESET_PASWORD_LINK_VALID,
    TOGGLE_RESET_PASSWORD_MESSAGE,
    SET_EMAIL_PASSWORD,
    CLEAR_RESET_PASSWORD_MESSAGE_LOGIN,
    HANDLE_LOGIN_SIGNUP_RESP,
    CLOSE_ALL_LOGIN_SIGNUP_MODAL,
    SET_ON_SUCCESS_RENDER,
    } from './actionConstants';
import { TOGGLE_SIGNUP_MODAL,TOGGLE_LOGIN_MODAL,TOGGLE_FORGOT_PASSWORD_MODAL, TOGGLE_NEW_SIGNUP_MODAL,TOGGLE_NEW_LOGIN_MODAL} from './actionConstants';

export const signupRequest=(userInfoObj)=>({
    type:SIGNUP_REQUEST,
    payload:userInfoObj
})

export const signupResponseSuccess=(response)=>({
    type:SIGNUP_RESPONSE_SUCCESS,
    payload:response
})

export const signupResponseFailed=(error)=>({
    type:SIGNUP_RESPONSE_FAILED,
    payload:error
})

export const loginRequest=(formValue)=>({
    type:LOGIN_REQUEST,
    payload:formValue
 })

 export const loginSuccess=(token)=>({
     type:LOGIN_SUCCESS,
     payload:token
 })

 export const loginFailed=(errorMessage)=>({
     type:LOGIN_FAILED,
     payload:errorMessage
 })

 export const clearLoginError=()=>({
     type:CLEAR_LOGIN_ERROR,
     payload:''
 })

 export const resetPasswordEmailRequest=(email,trackingInfo)=>({
     type:RESET_PASSWORD_EMAIL_REQUEST,
     payload:{ email, trackingInfo }
 })

 export const resetPasswordEmailFailed=(errorMessage)=>({
     type:RESET_PASSWORD_EMAIL_FAILED,
     payload:{errorMessage}
 })

 export const resetPasswordEmailSuccess=(successMessage)=>({
     type:RESET_PASSWORD_EMAIL_SUCESS,
     payload:{successMessage}
 })

 export const clearResetPasswordMessage=()=>({
     type:CLEAR_RESET_PASSWORD_MESSAGE
 })

 export const resetPasswordToggle=(toggleStatus, trackingInfo)=>({
     type:RESET_PASSWORD_TOGGLE,
     payload:{ toggleStatus,trackingInfo }
 })

 export const resetPasswordRequest=(email,password)=>({
     type:RESET_PASSWORD_REQUEST,
     payload:{email,password}
 })

 export const resetPasswordSuccess=message=>({
     type:RESET_PASSWORD_SUCCESS,
     payload:{message}
 })

 export const resetPasswordFailed=message=>({
     type:RESET_PASSWORD_FAILED,
     payload:{message}
 })

 export const checkResetPasswordLinkValid=email=>({
     type:CHECK_RESET_PASWORD_LINK_VALID,
     payload:{email}
 })

 export const toggleResetPasswordMessageModal=()=>({
     type:TOGGLE_RESET_PASSWORD_MESSAGE
 })

 export const clearResetPasswordMessageLogin=(email,password)=>({
     type:CLEAR_RESET_PASSWORD_MESSAGE_LOGIN,
     payload:{email,password}
 })
 export const setEmailPassword=(email,password)=>({
     type:SET_EMAIL_PASSWORD,
     payload:{ email, password }
 })


 export const toggleSignupModal = (toggleStatus,trackingInfo, onSuccessRender=null) => ({
     type: TOGGLE_SIGNUP_MODAL,
     payload: { toggleStatus, trackingInfo, onSuccessRender }
 })
 
export const toggleLoginModal = (toggleStatus,trackingInfo,onSuccessRender=null) => ({
    type: TOGGLE_LOGIN_MODAL,
    payload: { toggleStatus, trackingInfo, onSuccessRender }
})

export const toggleNewSignupModal = (toggleStatus, trackingInfo=null, onSuccessRender=null) => ({
    type: TOGGLE_NEW_SIGNUP_MODAL,
    payload: { toggleStatus, trackingInfo, onSuccessRender }
})

export const toggleNewLoginModal = (toggleStatus, trackingInfo=null, onSuccessRender=null) => ({
   type: TOGGLE_NEW_LOGIN_MODAL,
   payload: { toggleStatus, trackingInfo, onSuccessRender }
})

export const toggleForgotPasswordModalStatus = (passwordModalStatus,trackingInfo) => ({
    type: TOGGLE_FORGOT_PASSWORD_MODAL,
    payload: { passwordModalStatus,trackingInfo }
})

export const callHandleLoginSignupResp=(data)=>({
    type:HANDLE_LOGIN_SIGNUP_RESP,
    payload:data
})

export const closeAllLoginSignupModal=()=>({
    type:CLOSE_ALL_LOGIN_SIGNUP_MODAL,
    payload:{}
});

export const setOnSuccessRender = (onSuccessRender) =>({
    type: SET_ON_SUCCESS_RENDER,
    payload: { onSuccessRender }
})