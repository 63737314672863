import { call, put, takeLatest, all } from "redux-saga/effects";
import { GET_DIRECTOR_DATA_REQUEST, GET_RELATED_DIRECTOR_REQUEST } from "../redux/actions/actionConstants";
import { getDirectorDataApiCall, getRelatedDirectorApiCall } from '../api/directorApi';
import { getDirectorDataSuccess, getDirectorDataFailed, getRelatedDirectorFailed, getRelatedDirectorSuccess } from "../redux/actions/directorAction";
import { setDailySearchInfo, getUserProfileSuccess } from "../redux/actions/userAccountAction";
import { checkFreeSearchAnonymousForProduct, checkFreeSearchForAnonymous } from "../redux/actions/subscriptionAction";
import localStorageApi from '../api/localStorageApi';
import { SR_DAILY_SEARCH_LIMIT } from '../common/subscriptionConfigConst'
import { isBrowser } from "../common/config";
import { EP_DIRECTOR_PROFILE, ET_CHOOSE_PACKAGE_MODAL_CLOSE, ET_CHOOSE_PACKAGE_MODAL_OPEN } from "../common/trackingConstants";
import { sendActivityTracking, setChoosePackageModalData } from "../redux/actions/commonAction";
import { setDailyFreeExpireModalStatus } from "../redux/actions/companyAction";


function* getDirectorDataSaga(action) {
    try {
        const response = yield call(getDirectorDataApiCall, action.payload.directorParams);
        if (response.error) {
            yield put(getDirectorDataFailed(response.message));
        } else {
            let token = localStorageApi.getUserAuthToken();
            if (!token || token === "" || token === null) {
                yield all([
                    put(getDirectorDataSuccess(response.directorDetail)),
                    put(checkFreeSearchAnonymousForProduct("director", action.payload.directorParams.pnr))
                ])
            } else {
                if (response.isDailyFreeSearchExpired) {
                    yield put(setDailyFreeExpireModalStatus(true));
                    // don't remove choose package     
                    // yield toggleChoosePackageModal(true, action.payload.directorParams.pnr);
                    // } else {
                    // yield toggleChoosePackageModal(false, action.payload.directorParams.pnr);
                }
                yield all([
                    put(getDirectorDataSuccess(response.directorDetail)),
                    put(setDailySearchInfo(response.isDailyFreeSearchExpired)),
                    put(getUserProfileSuccess(response.userProfile, response.isPomandaUser, response.userOrderHistory))
                ]);
                yield call(localStorageApi.updateFreeSearchCountObj, "director", action.payload.directorParams.pnr);
            }
        }
    } catch (error) {
        console.log(error);
        yield put(getDirectorDataFailed(error.message));
    }
}

function* getRelatedDirectorSaga(action) {
    try {
        const response = yield call(getRelatedDirectorApiCall, action.payload.directorObj);
        if (response.error) {
            yield put(getRelatedDirectorFailed(response.message));
        } else {
            yield put(getRelatedDirectorSuccess(response.relatedDirectorList));
        }
    } catch (error) {
        console.log(error);
        yield put(getRelatedDirectorFailed(error.message));
    }
}

export function* watchGetDirectorDataRequest() {
    yield takeLatest(GET_DIRECTOR_DATA_REQUEST, getDirectorDataSaga);
}

export function* watchGetRelatedDirectorRequest() {
    yield takeLatest(GET_RELATED_DIRECTOR_REQUEST, getRelatedDirectorSaga);
}

 // don't remove choose package     
// function* toggleChoosePackageModal(toggleStatus, pnr) {
//     yield all([
//         put(setChoosePackageModalData({
//             toggleStatus,
//             showHeading: true,
//             serviceReq: SR_DAILY_SEARCH_LIMIT,
//             eventPage: EP_DIRECTOR_PROFILE,
//             showCloseBtn: false
//         })),
//         put(sendActivityTracking({
//             eventPage: EP_DIRECTOR_PROFILE,
//             eventType: toggleStatus ? ET_CHOOSE_PACKAGE_MODAL_OPEN : ET_CHOOSE_PACKAGE_MODAL_CLOSE,
//             attribute1: pnr,
//             attribute2: SR_DAILY_SEARCH_LIMIT
//         }))
//     ])
// }