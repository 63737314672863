import { takeLatest, call, put, select, all,delay } from 'redux-saga/effects';
import {
    CREATE_STRIPE_SESSION, SET_SUBSCRIPTION_STATUS_REQUEST,
    CANCEL_USER_SUBSCRIPTION_REQUEST, CHECK_FREE_SEARCH_FOR_ANONYMOUS,
    TOGGLE_SUBSCRIPTION_SCREEN, HANDLE_SUBSCRIPTION_BTN,
    HANDLE_PAYMENT_SUCCESS_ERROR,
    CHECK_FREE_SEARCH_ANONYMOUS_FOR_PRODUCT,
    CANCEL_USER_FREE_TRIAL_SUBSCRIPTION_REQ
} from '../redux/actions/actionConstants';
import {
    createStripeSessionSuccess, createStripeSessionFailed,
    subscriptionStatusFailed, subscriptionStatusSuccess,
    showCancelSubscriptionModal, cancelUserSubscriptionFailed,
    toggleSubcriptionScreen, setSubcriptionModalData,
    createStripeSession, setPlanSelectedAction, showCancelSubscriptionSuccessModal
} from '../redux/actions/subscriptionAction';
import { createStripeSessionApiCall, subscriptionStatusApiCall, cancelUserSubscriptionApi, cancelUserFreeTrialSubscriptioApi } from '../api/subscriptionApi';
import { getUserProfileRequest, getUserProfileSuccess, setDailySearchInfo, updateUserFreeCRCountAction, updateUserInfo } from '../redux/actions/userAccountAction';
import localStorageApi from '../api/localStorageApi';
import {
    SR_CH_FILLING, SR_CREDIT_REPORT, SR_DAILY_SEARCH_LIMIT, SR_POWER_SEARCH,
    SR_SIMILAR_COMPANY, H_CHOOSE_PLAN, H_SUB_POMANDA_PLUS,
    SH_CONTINUE_POMANDA_USE, SH_UNLOCK_FULL_POWER, SR_ACCOUNT_PAGE,
    H_SUB_DISCOUNTED_CREDIT_REPORT, SH_DISCOUNTED_CREDIT_REPORT, H_SUB_FREE_CREDIT_REPORT,
    DOWNLOAD_REPORT, DOWNLOAD_REPORT_MSG, SH_NO_MINIMUM_CONTRACT, H_DOWNLOAD_CSV_FOR_SUBSCRIBERS,
    SR_DOWNLOAD_CSV, SR_SAVED_SEARCHES, H_SAVED_SEARCHES_FOR_SUBSCRIBERS, SR_UPDATE_INDUSTRIES,
    H_UPDATE_INDUSTRIES_FOR_SUBSCRIBERS, SR_DOWNLOAD_FINANCIALS, H_DOWNLOAD_FINANCIALS_EXCEL_FOR_SUBSCRIBERS,
    SR_SIGNUP_BTN_CLICK_CNT_DOT, SR_SIGNUP_BTN_CLICK_HEADER, SR_SIGNUP_BTN_CLICK_LOGIN_MODAL, SR_SIGNUP_BTN_CLICK_MBL_MENU,
    SR_TRY_POMANDA_PLUS, ET_SUBSCRIPTION_CANCEL_SUCCESS, ET_SUBSCRIPTION_CANCEL_FAILED, 
    SR_ADD_SAVED_SEARCH, H_CHARGES_PS_FOR_SUBSCRIBERS, 
    SR_RESTRICTED_FILTER_ACCESS, SR_ONE_TIME_PURCHASE_COMPANY_REPORT, SR_COMPANY_PROFILE_UPGRADE_PLAN, 
    BUSINESS_PLAN_ONBOARD_PKG_PURCHASE
} from '../common/subscriptionConfigConst';
import {
    ET_MODAL_OPEN, ET_MODAL_CLOSE, EP_SUBSCRIPTION, ET_PAYMENT_SUCCESS,
    ET_SUBSCRIPTION_BUTTON_CLICK, ET_SESSION_CREATION_SUCCESSFUL,
    ET_SESSION_CREATION_FAILED, ET_PAYMENT_FAILURE, ET_STATUS_UPDATION_SUCCESSFUL,
    ET_STATUS_UPDATION_FAILED, COMPANY_PROFILE, SIMILAR_COMPANY_MODAL_OPEN,
    EP_NEW_SIGNUP_MODAL, ET_NEW_SIGNUP_MODAL_OPEN, EP_SIGNUP_CTA_CLICK, EP_DIRECTOR_PROFILE, ET_CA_GET_STARTED_BTN_CLICK
} from '../common/trackingConstants';
import {
    setSimilarCompanyModalToggleStatus, callCreditReportApiAction,
    toggleCRDownloadLoadingModal, checkPdfCreationStatus,
    setIndustryMixUpdateModal,
    setDailyFreeExpireModalStatus
} from '../redux/actions/companyAction'
import { toggleNewSignupModal, closeAllLoginSignupModal } from '../redux/actions/loginSignupAction';
import { sendActivityTracking, setLoading, toggleCommonMsgModal } from '../redux/actions/commonAction';
import analyticsApi from '../api/analyticsApi'
import { TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS } from '../common/loginSignupSuccessConstants';
import { isBrowser } from '../common/config'
import { setAddCurrentSearch, setSavedSearches } from '../redux/actions/searchAction';
import successModalConstants from '../common/messageModalConstants';
import { callGetUserProfileApi } from '../api/userAccountApi';
import { getBusinessPlanDataRequest } from '../redux/actions/businessPlanTemplateAction';

const subscriptionModalRedux = (state) => state.subscription.subscriptionModal;
const userInfoRedux = (state) => state.userProfile.userInfo;
const companyprofileRedux = (state) => state.company.companyProfile;

export function* createStripeSessionSaga(action) {
    try {
        yield put(setLoading({status: true}));
        action.payload.href = encodeURI(action.payload.href)
        let response = yield call(createStripeSessionApiCall, action.payload);
        if (!response.error) {
            yield put(setLoading({status: false}));
            yield put(createStripeSessionSuccess(response.sessionIdObj));
        } else {
            yield put(setLoading({status: false}));
            yield put(createStripeSessionFailed(response.message));
        }
        yield put(sendActivityTracking({
            eventPage: EP_SUBSCRIPTION,
            eventType: response.error ? ET_SESSION_CREATION_FAILED : ET_SESSION_CREATION_SUCCESSFUL,
            attribute1: action.payload.planSelected,
            attribute2: action.payload.serviceRequested,
            attribute3: action.payload.href,
            attribute4: action.payload.companyId ? action.payload.companyId : "",
            attribute5: action.payload.pdfLink,
        }))

    } catch (error) {
        yield all([
            put(createStripeSessionFailed(error.message)),
            put(setLoading({status: false})),
            put(sendActivityTracking({
                eventPage: EP_SUBSCRIPTION,
                eventType: ET_SESSION_CREATION_FAILED,
                attribute1: action.payload.planSelected,
                attribute2: action.payload.serviceRequested,
                attribute3: action.payload.href,
                attribute4: action.payload.companyId ? action.payload.companyId : "",
                attribute5: action.payload.pdfLink,
            }))
        ])
    }
}

export function* watchCreateStripeSession() {
    yield takeLatest(CREATE_STRIPE_SESSION, createStripeSessionSaga);
}

export function* subscriptionStatusApiCallSaga(action) {
    try {
        yield put(sendActivityTracking({
            eventPage: EP_SUBSCRIPTION,
            eventType: action.payload.statusObj.isSuccess ? ET_PAYMENT_SUCCESS : ET_PAYMENT_FAILURE,
            attribute1: action.payload.statusObj.sessionId,
            attribute2: action.payload.statusObj.email,
            attribute3: action.payload.statusObj.planSelected,
            attribute4: action.payload.statusObj.serviceRequested,
            attribute5: action.payload.statusObj.pdfLink,
            attribute6: action.payload.statusObj.companyId ? action.payload.statusObj.companyId : "",
            attribute7: action.payload.statusObj.cohId,
            attribute8: action.payload.statusObj.checkOutJourneyCompletedStatus,
            attribute9: action.payload.statusObj.checkOutJourneyCancelStatus
        }))
        yield delay(2000);
        let response = yield call(subscriptionStatusApiCall, action.payload.statusObj);
        if (!response.error) {
            yield all([
                put(subscriptionStatusSuccess({ message:response.message, sessionExpire:response.sessionExpire })),
                put(getUserProfileSuccess(response.userObj, response.isPomandaUser, response.userOrderHistory)),
                put(getUserProfileRequest())
            ]);
            if((action.payload.statusObj?.isSuccess && action.payload.statusObj?.serviceRequested === BUSINESS_PLAN_ONBOARD_PKG_PURCHASE && action.payload.statusObj?.uniqueId)){
                yield put(getBusinessPlanDataRequest(action.payload.statusObj?.uniqueId))
            }
            let paymentType = "";
            const successError=action.payload.statusObj.isSuccess?"paymentsuccess":"paymenterror"
            switch (action.payload.statusObj.planSelected) {
                case "PomandaPlus":
                    if(action.payload.statusObj.period && action.payload.statusObj.period.toLowerCase() === "one_day") {
                        paymentType = `/pomandaplus/${successError}/day`;
                    } else if (action.payload.statusObj.period && action.payload.statusObj.period.toLowerCase() === "month") {
                        paymentType = `/pomandaplus/${successError}/month`;
                    } else {
                        paymentType = `/pomandaplus/${successError}/year`;
                    }
                    break;
                case "PomandaPlus5K": 
                    if(action.payload.statusObj.period && action.payload.statusObj.period.toLowerCase() === "month") {
                        paymentType = `/pomandapro/${successError}/month`;
                    } else {
                        paymentType = `/pomandapro/${successError}/year`
                    }
                    break;
                case "oneTimePurchase":
                    paymentType = `/creditreport/${successError}/onetime`;
                    break;
                case "percent30OffReport":
                    paymentType = `/creditreport/${successError}/discount`;
                    break;
                case "oneTimePurchaseCompanyReport":
                    paymentType = `/companyreport/${successError}/onetime`;
                    break;
                case "oneTime5CreditReportPkg":
                    paymentType = `/companyreport/${successError}/5cr`;
                    break;
                case "oneTime20CreditReportPkg":
                    paymentType = `/companyreport/${successError}/20cr`;
                    break;
                case "oneTime100CreditReportPkg":
                    paymentType = `/companyreport/${successError}/100cr`;
                    break;
                case "oneTimeInfiniteCreditReportPkg":
                    paymentType = `/companyreport/${successError}/infinite`;
                    break;
                case "oneTime1ValuationReportPkg":
                    paymentType = `/valuationreport/${successError}/1vr`;
                    break;
                case "oneTime5ValuationReportPkg":
                    paymentType = `/valuationreport/${successError}/5vr`;
                    break;
                case "oneTime20ValuationReportPkg":
                    paymentType = `/valuationreport/${successError}/20vr`;
                    break;
                case "businessPlanOnboardPkg":
                    paymentType = `/businessplan/${successError}/1bp`;
                    break;
                default:
                    paymentType = `/${action.payload?.statusObj?.planSelected}`;
            }
            yield call(analyticsApi.onPageLoad, paymentType)
        } else {
            yield put(subscriptionStatusFailed({ message:response.message, sessionExpire:response.sessionExpire }));
        }
        yield put(sendActivityTracking({
            eventPage: EP_SUBSCRIPTION,
            eventType: response.error ? ET_STATUS_UPDATION_FAILED : ET_STATUS_UPDATION_SUCCESSFUL,
            attribute1: action.payload.statusObj.sessionId,
            attribute2: action.payload.statusObj.cohId
        }))

    } catch (error) {
        yield put(subscriptionStatusFailed(error.message))
        yield put(sendActivityTracking({
            eventPage: EP_SUBSCRIPTION,
            eventType: ET_STATUS_UPDATION_FAILED,
        }))
    }
}

export function* watchSubscriptionStatus() {
    yield takeLatest(SET_SUBSCRIPTION_STATUS_REQUEST, subscriptionStatusApiCallSaga);
}

export function* cancelUserSubscriptionSaga(action) {
    try {
        let response = yield call(cancelUserSubscriptionApi, action.payload.cancellationObj);

        if (!response.error) {
            let userInfo = yield select(userInfoRedux)
            yield put(getUserProfileRequest());
            yield put(showCancelSubscriptionModal(false));
            yield put(showCancelSubscriptionSuccessModal(true));
            const uniqueUrl = "/pomandaplus/cancelsubscriptionsuccess";
            yield call(analyticsApi.onPageLoad, uniqueUrl)
            //activity tracking code
            yield put(sendActivityTracking({
                eventPage: EP_SUBSCRIPTION,
                eventType: ET_SUBSCRIPTION_CANCEL_SUCCESS,
                attribute1: userInfo && userInfo.email,
                attribute2: userInfo && userInfo.subscription && userInfo.subscription.subscriptionEndDate
            }))

        } else {
            yield put(cancelUserSubscriptionFailed(response.message));
            yield put(sendActivityTracking({
                eventPage: EP_SUBSCRIPTION,
                eventType: ET_SUBSCRIPTION_CANCEL_FAILED,
                attribute1: userInfo && userInfo.email,
                attribute2: userInfo && userInfo.subscription && userInfo.subscription.subscriptionEndDate
            }))
        }
    } catch (error) {
        yield put(cancelUserSubscriptionFailed(error.message));
        yield put(sendActivityTracking({
            eventPage: EP_SUBSCRIPTION,
            eventType: ET_SUBSCRIPTION_CANCEL_FAILED,
            attribute1: userInfo && userInfo.email,
            attribute2: userInfo && userInfo.subscription && userInfo.subscription.subscriptionEndDate
        }))
    }
}

export function* watchcancelUserSubscriptionSaga() {
    yield takeLatest(CANCEL_USER_SUBSCRIPTION_REQUEST, cancelUserSubscriptionSaga);
}

function* checkFreeSearchForAnonymousSaga(action) {
    try {
        let response;
        if (action.payload.pnr) {
            response = isBrowser && (yield call(localStorageApi.checkFreeSearchForAnonymous, action.payload.searchEntity, action.payload.pnr));
        }
        else {
            response = isBrowser && (yield call(localStorageApi.checkFreeSearchForAnonymous, action.payload.searchEntity, action.payload.id));
        }
        if (response) {
            if (response.anonymousFreeSearchExpired) {
                yield put(toggleSubcriptionScreen({
                    subscriptionToggleStatus: true,
                    serviceRequested: SR_DAILY_SEARCH_LIMIT,
                    isCloseOption: false
                }));
            }
            else {

                yield put(toggleSubcriptionScreen({
                    subscriptionToggleStatus: false,
                    serviceRequested: SR_DAILY_SEARCH_LIMIT,
                    isCloseOption: false
                }));
            }
        } else {
            yield put(toggleSubcriptionScreen({
                subscriptionToggleStatus: false,
                serviceRequested: SR_DAILY_SEARCH_LIMIT,
                isCloseOption: false
            }));
        }
    } catch (error) {
        console.log(error);
        yield put(toggleSubcriptionScreen(false));
    }
}

export function* watchCheckFreeSearchForAnonymous() {
    yield takeLatest(CHECK_FREE_SEARCH_FOR_ANONYMOUS, checkFreeSearchForAnonymousSaga);
}

function* checkFreeSearchAnonymousForProductSaga(action) {
    try {
        let response;
        if (action.payload.pnr) {
            response = isBrowser && (yield call(localStorageApi.checkFreeSearchForAnonymous, action.payload.searchEntity, action.payload.pnr));
        }
        else {
            response = isBrowser && (yield call(localStorageApi.checkFreeSearchForAnonymous, action.payload.searchEntity, action.payload.id));
        }
        const eventPage = action.payload.searchEntity === "company" ? COMPANY_PROFILE : EP_DIRECTOR_PROFILE;
        if (response && response.anonymousFreeSearchExpired) {
            yield all([
                yield put(setDailySearchInfo(response.anonymousFreeSearchExpired)),
                yield put(setDailyFreeExpireModalStatus(response.anonymousFreeSearchExpired))
            ])
            //don't remove choose package
            // yield all([
            //     put(setChoosePackageModalData({
            //         toggleStatus: true,
            //         showHeading: true,
            //         serviceReq:SR_DAILY_SEARCH_LIMIT,
            //         eventPage,
            //         showCloseBtn: false
            //     })),
            //     put(sendActivityTracking({
            //         eventPage,
            //         eventType: ET_CHOOSE_PACKAGE_MODAL_OPEN,
            //         attribute1: id,
            //         attribute2: SR_DAILY_SEARCH_LIMIT
            //     }))
        //     // ])
        // } else {
        //     yield put(setChoosePackageModalData({
        //         toggleStatus: false,
        //         serviceReq: "",
        //         eventPage
        //     }));
        }
    } catch (error) {
        console.log(error);
        // yield put(toggleSubcriptionScreen(false));
    }
}

export function* watchCheckFreeSearchAnonymousForProduct() {
    yield takeLatest(CHECK_FREE_SEARCH_ANONYMOUS_FOR_PRODUCT, checkFreeSearchAnonymousForProductSaga);
}

function* checkAndSetSubscriptionModalData(action) {
    let token = isBrowser && (yield call(localStorageApi.getUserAuthToken))
    let userInfo = yield select(userInfoRedux)
    const subscriptionModal = yield select(subscriptionModalRedux)

    action.payload.pathname = isBrowser && window.location.pathname
    action.payload.href = isBrowser && window.location.href

    let companyProfile = yield select(companyprofileRedux);

    let companyName = companyProfile && companyProfile.name
    let companyId = companyProfile && companyProfile.companyId
    switch (action.payload.serviceRequested) {
        case SR_CREDIT_REPORT:
            action.payload.companyId = action.payload.companyId ? action.payload.companyId : companyId
            action.payload.companyName = action.payload.companyName ? action.payload.companyName : companyName
            action.payload.subscriptionToggleStatus = true;
            action.payload.isCloseOption = true
            action.payload.heading = H_CHOOSE_PLAN
            action.payload.subHeading = SH_NO_MINIMUM_CONTRACT
            if (token) {
                if (userInfo && ((userInfo.subscription && userInfo.subscription.productName === "PomandaPlus" && userInfo.subscription.active === "no") || !userInfo.subscription)) {
                    action.payload.plans = ["PomandaPlus", "oneTimePurchase"]
                } else {
                    if (userInfo && userInfo.subscription && userInfo.subscription.productName === "PomandaPlus" && userInfo.subscription.active === "yes" && userInfo.subscription.freeCR >= 1) {
                        companyName = companyName.toLowerCase();
                        companyName = companyName.charAt(0).toUpperCase() + companyName.slice(1);
                        action.payload.plans = ["freeCR"]
                        action.payload.heading = H_SUB_FREE_CREDIT_REPORT + companyName
                    } else {
                        action.payload.plans = ["percent30OffReport"]
                        action.payload.heading = H_SUB_DISCOUNTED_CREDIT_REPORT
                        action.payload.subHeading = SH_DISCOUNTED_CREDIT_REPORT
                    }
                }

            } else {
                action.payload.plans = ["PomandaPlus", "oneTimePurchase"]
            }
            break;
        case SR_DAILY_SEARCH_LIMIT:
            if (!action.payload.subscriptionToggleStatus && userInfo && userInfo.isDailyFreeSearchExpired) {
                action.payload.subscriptionToggleStatus = false
            }
            action.payload.subscriptionToggleStatus = action.payload.subscriptionToggleStatus === true ? true : false;
            action.payload.heading = H_SUB_POMANDA_PLUS
            action.payload.subHeading = SH_CONTINUE_POMANDA_USE
            action.payload.plans = ["PomandaPlus"]
            // action.payload.subscriptionToggleStatus = true;
            break;
        case SR_CH_FILLING:
            action.payload.isCloseOption = true
            if (token) {
                if (userInfo && userInfo.subscription && userInfo.subscription.productName === "PomandaPlus" && userInfo.subscription.active !== "no") {
                    action.payload.subscriptionToggleStatus = false;
                    window.open(action.payload.pdfLink, '_blank');
                    break;
                } else {
                    action.payload.subscriptionToggleStatus = true;
                }
            } else {
                action.payload.subscriptionToggleStatus = true;
            }
            action.payload.heading = H_SUB_POMANDA_PLUS
            action.payload.subHeading = SH_UNLOCK_FULL_POWER
            action.payload.plans = ["PomandaPlus"]

            break;
        case SR_SIMILAR_COMPANY:
            action.payload.isCloseOption = true
            if (token) {
                if (userInfo && userInfo.subscription && userInfo.subscription.productName === "PomandaPlus" && userInfo.subscription.active !== "no") {
                    action.payload.subscriptionToggleStatus = false;
                    yield all([
                        put(sendActivityTracking({
                            eventPage: COMPANY_PROFILE,
                            eventType: SIMILAR_COMPANY_MODAL_OPEN,
                            attribute1: companyId,
                            attribute2: companyName
                        })),
                        put(setSimilarCompanyModalToggleStatus(true))
                    ]);
                    break;
                } else {
                    action.payload.subscriptionToggleStatus = true;
                }
            } else {
                action.payload.subscriptionToggleStatus = true;
            }
            action.payload.heading = H_SUB_POMANDA_PLUS
            action.payload.subHeading = SH_UNLOCK_FULL_POWER
            action.payload.plans = ["PomandaPlus"]
    
            break;
        case SR_POWER_SEARCH:
            action.payload.subscriptionToggleStatus = false
            action.payload.isCloseOption = false


            break;
        case SR_ACCOUNT_PAGE:
            action.payload.heading = H_SUB_POMANDA_PLUS
            action.payload.subHeading = SH_UNLOCK_FULL_POWER
            action.payload.plans = ["PomandaPlus"]
            break;
        case SR_DOWNLOAD_CSV:
            action.payload.heading = H_DOWNLOAD_CSV_FOR_SUBSCRIBERS;
            action.payload.subHeading = "";
            action.payload.plans = ["PomandaPlus"]
            break;
        case SR_SAVED_SEARCHES:
            action.payload.heading = H_SAVED_SEARCHES_FOR_SUBSCRIBERS;
            action.payload.subHeading = "";
            action.payload.plans = ["PomandaPlus"]
            break;
        case SR_UPDATE_INDUSTRIES:
            action.payload.heading = H_UPDATE_INDUSTRIES_FOR_SUBSCRIBERS;
            action.payload.subHeading = "";
            action.payload.plans = ["PomandaPlus"]
            break;
        case SR_DOWNLOAD_FINANCIALS:
            action.payload.heading = H_DOWNLOAD_FINANCIALS_EXCEL_FOR_SUBSCRIBERS;
            action.payload.subHeading = "";
            action.payload.plans = ["PomandaPlus"]
            break;
        case SR_SIGNUP_BTN_CLICK_LOGIN_MODAL:
        case SR_SIGNUP_BTN_CLICK_CNT_DOT:
        case SR_SIGNUP_BTN_CLICK_HEADER:
        case SR_SIGNUP_BTN_CLICK_MBL_MENU:
            yield put(sendActivityTracking({
                eventPage: EP_SIGNUP_CTA_CLICK,
                eventType: `ET_${action.payload.serviceRequested}`
            }))
            if (!subscriptionModal.subscriptionToggleStatus) {
                const toggleStatus = action.payload.subscriptionToggleStatus
                action.payload.subscriptionToggleStatus = toggleStatus ? toggleStatus : false
                action.payload.heading = H_SUB_POMANDA_PLUS
                action.payload.subHeading = SH_UNLOCK_FULL_POWER
                action.payload.plans = ["PomandaPlus"]
                action.payload.isCloseOption = action.payload.isCloseOption
            } else {
                action.payload = subscriptionModal
            }
            break;
        case SR_RESTRICTED_FILTER_ACCESS:
            action.payload.heading = H_CHARGES_PS_FOR_SUBSCRIBERS;
            action.payload.subHeading = "";
            action.payload.plans = ["PomandaPlus"]
            break;
        default:
            action.payload.subscriptionToggleStatus = action.payload.subscriptionToggleStatus ? action.payload.subscriptionToggleStatus : false;
            action.payload.heading = H_SUB_POMANDA_PLUS
            action.payload.subHeading = SH_UNLOCK_FULL_POWER
            action.payload.plans = ["PomandaPlus"]

            break;
    }
    if (subscriptionModal.serviceRequested !== action.payload.serviceRequested || subscriptionModal.subscriptionToggleStatus !== action.payload.subscriptionToggleStatus || action.payload.isLoginSuccess) {
        yield all([
            put(closeAllLoginSignupModal()),
            put(setSubcriptionModalData(action.payload)),

        ]);
    }
    if (action.payload.serviceRequested !== SR_POWER_SEARCH
        && action.payload.subscriptionToggleStatus !== subscriptionModal.subscriptionToggleStatus) {
        yield put(sendActivityTracking({
            eventPage: EP_SUBSCRIPTION,
            eventType: action.payload.subscriptionToggleStatus ? ET_MODAL_OPEN : ET_MODAL_CLOSE,
            attribute1: action.payload.companyId ? action.payload.companyId : "",
            attribute2: action.payload.serviceRequested
        }))
    }
}

export function* watchCheckAndSetSubscriptionModalData() {
    yield takeLatest(TOGGLE_SUBSCRIPTION_SCREEN, checkAndSetSubscriptionModalData)
}


function* handleSubscriptionSubscribeBtnSaga(action) {
    const subscriptionModal = yield select(subscriptionModalRedux)
    let userInfo = yield select(userInfoRedux)
    let token = isBrowser && (yield call(localStorageApi.getUserAuthToken))
    action.payload.pathname = isBrowser && window.location.pathname
    action.payload.href = isBrowser && window.location.href
    yield put(setPlanSelectedAction(action.payload))

    let newSignupModalTrackingInfo = {
        eventPage: EP_NEW_SIGNUP_MODAL,
        eventType: ET_NEW_SIGNUP_MODAL_OPEN
    };

    switch (subscriptionModal.serviceRequested) {
        case SR_CREDIT_REPORT:
            if (token) {

                if (action.payload.planSelected === "freeCR") {
                    let obj = {
                        companyId: subscriptionModal.companyId,
                        companyName: subscriptionModal.companyName,
                        userId: userInfo._id,
                        planSelected: action.payload.planSelected
                    }
                    yield all([
                        put(callCreditReportApiAction(obj)),
                        put(toggleCRDownloadLoadingModal({
                            loaderImg: true,
                            heading: DOWNLOAD_REPORT,
                            subHeading: DOWNLOAD_REPORT_MSG,
                            toggleStatus: true,
                            showContinueBtn: true
                        })),
                        put(toggleSubcriptionScreen({
                            subscriptionToggleStatus: false,
                            serviceRequested: "",
                            isCloseOption: true
                        })),
                        put(updateUserFreeCRCountAction(userInfo.subscription.freeCR - 1))
                    ]);

                } else {
                    const stripeSessionObj = {
                        planSelected: action.payload.planSelected,
                        serviceRequested: subscriptionModal.serviceRequested,
                        href: subscriptionModal.href,
                        companyId: subscriptionModal.companyId,
                        companyName: subscriptionModal.companyName,
                        pdfLink: subscriptionModal.pdfLink
                    }
                    yield put(createStripeSession(stripeSessionObj));
                }

            } else {
                // yield put(setPlanSelectedAction(action.payload))
                yield put(toggleNewSignupModal({ status: true }, newSignupModalTrackingInfo, { toggleType: TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS }));
            }
            break;
            case SR_SIMILAR_COMPANY:
            if (token) {
                if (userInfo && userInfo.subscription && userInfo.subscription.productName === "PomandaPlus"
                    && userInfo.subscription.active !== "no") {
                    yield all([
                        put(sendActivityTracking({
                            eventPage: COMPANY_PROFILE,
                            eventType: SIMILAR_COMPANY_MODAL_OPEN,
                            attribute1: subscriptionModal.companyId,
                            attribute2: subscriptionModal.companyName
                        })),
                        put(setSimilarCompanyModalToggleStatus(true))
                    ]);
                } else {
                    // yield put(setPlanSelectedAction(action.payload))
                    const stripeSessionObj = {
                        planSelected: action.payload.planSelected,
                        serviceRequested: subscriptionModal.serviceRequested,
                        href: subscriptionModal.href
                    }
                    yield put(createStripeSession(stripeSessionObj));
                }
            } else {
                // yield put(setPlanSelectedAction(action.payload))
                yield put(toggleNewSignupModal({ status: true }, newSignupModalTrackingInfo, { toggleType: TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS }))
            }
            break
;

        case SR_CH_FILLING:
            if (token) {
                if (userInfo && userInfo.subscription && userInfo.subscription.productName === "PomandaPlus"
                    && userInfo.subscription.active !== "no") {
                    window.open(subscriptionModal.pdfLink, '_blank');
                    break;
                } else {
                    // yield put(setPlanSelectedAction(action.payload))
                    const stripeSessionObj = {
                        planSelected: action.payload.planSelected,
                        serviceRequested: subscriptionModal.serviceRequested,
                        href: subscriptionModal.href,
                        pdfLink: subscriptionModal.pdfLink
                    }
                    yield put(createStripeSession(stripeSessionObj));
                }
            } else {
                // yield put(setPlanSelectedAction(action.payload))
                yield put(toggleNewSignupModal({ status: true }, newSignupModalTrackingInfo, { toggleType: TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS }))
            }
            break;
        case SR_DAILY_SEARCH_LIMIT:
            if (token) {
                if (userInfo && userInfo.subscription && userInfo.subscription.productName === "PomandaPlus"
                    && userInfo.subscription.active === "no") {
                    // yield put(setPlanSelectedAction(action.payload))
                    const stripeSessionObj = {
                        planSelected: action.payload.planSelected,
                        serviceRequested: subscriptionModal.serviceRequested,
                        href: subscriptionModal.href,
                        pdfLink: subscriptionModal.pdfLink
                    }
                    yield put(createStripeSession(stripeSessionObj));
                }
            } else {
                // yield put(setPlanSelectedAction(action.payload))
                yield put(toggleNewSignupModal({ status: true }, newSignupModalTrackingInfo, { toggleType: TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS }));
            }
            break;
        case SR_POWER_SEARCH:
            if (token) {
                if (userInfo && userInfo.subscription && userInfo.subscription.productName === "PomandaPlus"
                    && userInfo.subscription.active === "no") {
                    // yield put(setPlanSelectedAction(action.payload))
                    const stripeSessionObj = {
                        planSelected: action.payload.planSelected,
                        serviceRequested: subscriptionModal.serviceRequested,
                        href: subscriptionModal.href,
                        pdfLink: subscriptionModal.pdfLink
                    }
                    yield put(createStripeSession(stripeSessionObj));
                }
            } else {
                // yield put(setPlanSelectedAction(action.payload))
                yield put(toggleNewSignupModal({ status: true }, newSignupModalTrackingInfo, { toggleType: TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS }));
            }
            break;
        case SR_ACCOUNT_PAGE:
            // yield put(setPlanSelectedAction(action.payload))
            const stripeSessionObj = {
                planSelected: action.payload.planSelected,
                serviceRequested: subscriptionModal.serviceRequested,
                href: subscriptionModal.href,
                pdfLink: ""
            }
            yield put(createStripeSession(stripeSessionObj));
            break;
        case SR_DOWNLOAD_CSV:
            if (token) {
                if (userInfo && userInfo.subscription
                    && userInfo.subscription.productName === "PomandaPlus" && userInfo.subscription.active === "no") {
                    yield* createStripeSessionActionCall(action, subscriptionModal);
                }
            } else {
                yield put(toggleNewSignupModal({ status: true }, newSignupModalTrackingInfo, { toggleType: TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS }));
            }
            break;
        case SR_SAVED_SEARCHES:
        case SR_RESTRICTED_FILTER_ACCESS:
            if (token) {
                if (userInfo && userInfo.subscription
                    && userInfo.subscription.productName === "PomandaPlus" && userInfo.subscription.active === "no") {
                    yield* createStripeSessionActionCall(action, subscriptionModal);
                }
            } else {
                yield put(toggleNewSignupModal({ status: true }, newSignupModalTrackingInfo, { toggleType: TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS }));
            }
            break;
        case SR_UPDATE_INDUSTRIES:
            if (token) {
                if (userInfo && userInfo.subscription
                    && userInfo.subscription.productName === "PomandaPlus" && userInfo.subscription.active === "no") {
                    yield* createStripeSessionActionCall(action, subscriptionModal);
                }
            } else {
                yield put(toggleNewSignupModal({ status: true }, newSignupModalTrackingInfo, { toggleType: TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS }));
            }
            break;
        case SR_DOWNLOAD_FINANCIALS:
            if (token) {
                if (userInfo && userInfo.subscription
                    && userInfo.subscription.productName === "PomandaPlus"
                    && userInfo.subscription.active === "no") {
                    yield* createStripeSessionActionCall(action, subscriptionModal);
                }
            } else {
                yield put(toggleNewSignupModal({ status: true }, newSignupModalTrackingInfo,
                    { toggleType: TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS }));
            }
            break;
        case SR_SIGNUP_BTN_CLICK_LOGIN_MODAL:
        case SR_SIGNUP_BTN_CLICK_CNT_DOT:
        case SR_SIGNUP_BTN_CLICK_HEADER:
        case SR_SIGNUP_BTN_CLICK_MBL_MENU:
            yield put(toggleNewSignupModal({ status: true },
                newSignupModalTrackingInfo,
                { toggleType: TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS }));
            break;
        case SR_TRY_POMANDA_PLUS:
            if(token){
                if (userInfo && userInfo.subscription
                    && userInfo.subscription.productName === "PomandaPlus"
                    && userInfo.subscription.active === "no") {
                    yield* createStripeSessionActionCall(action, subscriptionModal);
                } 
            } else {
                yield put(toggleNewSignupModal({ status: true }, newSignupModalTrackingInfo,
                    { toggleType: TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS }));
            }
            break;
        default:
            break;
    }
    yield put(sendActivityTracking({
        eventPage: EP_SUBSCRIPTION,
        eventType: ET_SUBSCRIPTION_BUTTON_CLICK,
        attribute1: subscriptionModal.companyId ? subscriptionModal.companyId : "",
        attribute2: subscriptionModal.serviceRequested,
        attribute3: action.payload.planSelected,
    }));

}

export function* watchHandleSubscriptionSubscribeBtnSaga() {
    yield takeLatest(HANDLE_SUBSCRIPTION_BTN, handleSubscriptionSubscribeBtnSaga)
}

function* createStripeSessionActionCall(action, subscriptionModal) {
    const stripeSessionObj = {
        planSelected: action.payload.planSelected,
        serviceRequested: subscriptionModal.serviceRequested,
        href: subscriptionModal.href,
        pdfLink: ""
    }
    yield put(createStripeSession(stripeSessionObj));
}
function* handlePaymentSuccessErrorSaga(action) {
    // const subscriptionModal = yield select(subscriptionModalRedux)
    // let token = yield call(localStorageApi.getUserAuthToken)
    const companyName = encodeURIComponent(action.payload.companyName.trim().toLowerCase().replace(/ /g, "-"))
    if (!action.payload.isSuccess) {
        // yield put(toggleSubcriptionScreen({
        //     subscriptionToggleStatus: true,
        //     serviceRequested: "ErrorInSubscription",
        //     isCloseOption: false
        // }));
    } else {
        switch (action.payload.serviceRequested) {
            case SR_CREDIT_REPORT:
                // alert("Please check your email")
                yield all([
                    put(toggleCRDownloadLoadingModal({
                        loaderImg: true,
                        heading: DOWNLOAD_REPORT,
                        subHeading: DOWNLOAD_REPORT_MSG,
                        toggleStatus: true,
                        showContinueBtn: true
                    })),
                    put(checkPdfCreationStatus(action.payload.cohId))
                ])

                break;
            case SR_SIMILAR_COMPANY:
                yield all([
                    put(sendActivityTracking({
                        eventPage: COMPANY_PROFILE,
                        eventType: SIMILAR_COMPANY_MODAL_OPEN,
                        attribute1: action.payload.companyId ? action.payload.companyId : "",
                        attribute2: action.payload.companyName ? action.payload.companyName : ""
                    })),
                    put(setSimilarCompanyModalToggleStatus(true))
                ]);
                break;
            case SR_CH_FILLING:
                window.open(action.payload.pdfLink, '_blank');
                break;
            case SR_DAILY_SEARCH_LIMIT:
                break;
            case SR_ACCOUNT_PAGE:
                break;
            case SR_UPDATE_INDUSTRIES:
                yield put(setIndustryMixUpdateModal(true));
                break;
            case SR_SAVED_SEARCHES:
                yield put(setSavedSearches(true));
                break;
            case SR_ADD_SAVED_SEARCH:
                yield put(setAddCurrentSearch(true));
                break;   
            case SR_SIGNUP_BTN_CLICK_LOGIN_MODAL:
            case SR_SIGNUP_BTN_CLICK_CNT_DOT:
            case SR_SIGNUP_BTN_CLICK_HEADER:
            case SR_SIGNUP_BTN_CLICK_MBL_MENU:
                break;
            case "DEFAULT":
                window.open(`${window.location.origin}`, "_self");
                break;   
            // case SR_RESTRICTED_FILTER_ACCESS:
            //     window.open(`${window.location.origin}/powersearch/charges`, "_self");
            //     break;
            case SR_COMPANY_PROFILE_UPGRADE_PLAN:
                window.open(`${window.location.origin}/company/${action.payload.companyId}/${companyName}`, "_self");
                break;
            case SR_ONE_TIME_PURCHASE_COMPANY_REPORT:{
                window.open(`${window.location.origin}/downloadreport/company/${action.payload?.companyId}/${action.payload?.userId}`, "_blank");
                break;
            }
            default:
                break;
        }

    }
}

export function* watchHandlePaymentSuccessErrorSaga() {
    yield takeLatest(HANDLE_PAYMENT_SUCCESS_ERROR, handlePaymentSuccessErrorSaga)
}

function* handleCancelUserFreeTrialSubscriptionSaga() {
    try {
        yield put(setLoading({status: true, timeout: 10000}));
        const response = yield call(cancelUserFreeTrialSubscriptioApi);
        if(!response.error) {
            yield response.userProfile?._id && put(updateUserInfo(response.userProfile))
            yield all([
                put(setLoading({ status: false })),
                put(toggleCommonMsgModal({
                    toggleStatus: true,
                    heading: successModalConstants.CANCEL_USER_FREE_TRIAL_SUCCESS.header,
                    subHeading: successModalConstants.CANCEL_USER_FREE_TRIAL_SUCCESS.subHeading,
                    btnText: successModalConstants.CANCEL_USER_FREE_TRIAL_SUCCESS.btnText
                }))
            ])
        } else {
            yield put(toggleCommonMsgModal({
                toggleStatus: true,
                heading: successModalConstants.CANCEL_USER_FREE_TRIAL_FAILED.header,
                subHeading: successModalConstants.CANCEL_USER_FREE_TRIAL_FAILED.subHeading,
                btnText: successModalConstants.CANCEL_USER_FREE_TRIAL_FAILED.btnText
            }))
            yield put(setLoading({status: false}));
        }
    } catch (error) {
        console.log('inside handleCancelUserFreeTrialSubscriptionSaga', error);
    }
}

export function* watchCancelUserFreeTrialSubscriptionSaga() {
    yield takeLatest(CANCEL_USER_FREE_TRIAL_SUBSCRIPTION_REQ, handleCancelUserFreeTrialSubscriptionSaga)
}