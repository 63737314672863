import { RESTAPI_URL } from '../common/config';
import { commonFetch } from './fetchComImpl';
import { refreshTokenApi } from './refreshTokenApi';

export const getReportApiCall=async(type,id,userId)=>{
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/report/getInfoPdf/${type}/${id}/${userId}`,
        'GET',
        {},
        new Headers({
            'content-type':'application/json'
        }));
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCompanyReportUrlApiCall = async (docId) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/getCompanyReportUrl/${docId}`, 
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        return response;
    } catch (error) {
        throw error;
    }
}