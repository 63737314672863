import {
    TOGGLE_EDIT_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILED,
    CLEAR_CHANGE_PASSWORD_ERROR_MESSAGE,
    CONTACT_US_SUCCESS,
    CONTACT_US_FAILED,
    SAVE_USER_PROFILE_FAILED,
    SET_USERS_DAILY_SEARCH_INFO,
    UPDATE_USER_INFO,
    UPDATE_USER_FREE_CR_COUNT,
    COMPANY_HOUSE_EMAIL_NOTIFICATION_FLAG,
    UPDATE_USER_REPORT_CREDITS,
    SET_ORDER_HISTORY,
    SET_VALUATION_REPORTS_LIST,
    UPDATE_VALUATION_REPORT_CREDIT_SUCCESS,
    SET_VR_WHITE_LABEL_INFO,
    RESTRICT_ACCESS_FOR_PROSPER_USER,
    SET_WHITE_LABEL_FORM_SUBMIT_MSGS,
    SET_BUSINESS_PLANS_LIST,
    UPDATE_BUSINESS_PLANS_CREDIT_SUCCESS,
    REMOVE_FORM_DATA_SUCCESS,
    REMOVE_FORM_DATA_FAILED
} from '../actions/actionConstants'

const INITIAL_STATE = { toggleUserEditForm: false }
const userAccountReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_EDIT_USER_PROFILE:
            return { ...state, toggleUserEditForm: action.payload.toggleStatus }
        case SAVE_USER_PROFILE_FAILED:
            let userProfileEditForm = { ...state.userProfileEditForm };
            userProfileEditForm.submitErrors.email = action.payload.message;
            let newState = { ...state };
            newState.userProfileEditForm = userProfileEditForm;
            return { ...state };
        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state, userInfo: action.payload.userProfile,
                isPomandaUser: action.payload.pomandaUser,
                userOrderHistory: action.payload.userOrderHistory?.orderHistoryDocs,
                userCardDetails: action.payload.userCardDetails,
                orderHistoryTotal: action.payload.userOrderHistory?.total,
                valuationRptList: action.payload?.valuationReports?.valuationReportMappingDocs || [],
                valuationRptTotal: action.payload?.valuationReports?.total
            }
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, changePasswordSuccessMessage: action.payload.message };
        case CHANGE_PASSWORD_FAILED:
            return { ...state, changePasswordErrorMessage: action.payload.errorMessage };
        case CLEAR_CHANGE_PASSWORD_ERROR_MESSAGE:
            return { ...state, changePasswordErrorMessage: "" };
        case CONTACT_US_SUCCESS:
            return { ...state, contactUsSuccess: action.payload.successMessage };
        case CONTACT_US_FAILED:
            return { ...state, contactUsFailed: action.payload.errorMessage };
        case SET_USERS_DAILY_SEARCH_INFO:
            return { ...state, isDailyFreeSearchExpired: action.payload.isDailyFreeSearchExpired };
        case UPDATE_USER_INFO:
            return { ...state, userInfo: action.payload.userProfile }
        case UPDATE_USER_FREE_CR_COUNT:
            let userInfo = { ...state.userInfo }
            userInfo.subscription.freeCR = action.payload.freeCRCount
            return { ...state, userInfo }
        case COMPANY_HOUSE_EMAIL_NOTIFICATION_FLAG:
            return { ...state, companyHouseEmailNotificationFlag: action.payload.flag }
        case UPDATE_USER_REPORT_CREDITS:{
            let userInfo = { ...state.userInfo };
            userInfo.reportPkgSubscription.creditCount =action.payload.creditCount;
            return { ...state, userInfo };
        }
        case SET_ORDER_HISTORY:{
            const {orderHistoryDocs=[],total=0}=action.payload.orderHistory;
            return { ...state, userOrderHistory: orderHistoryDocs,orderHistoryTotal:total }
        }
        case SET_VALUATION_REPORTS_LIST:{
            const { valuationReportMappingDocs=[],total=0}=action.payload.valuationReports;
            return { ...state, valuationRptList:valuationReportMappingDocs, valuationRptTotal:total };
        }
        case UPDATE_VALUATION_REPORT_CREDIT_SUCCESS:
        case UPDATE_BUSINESS_PLANS_CREDIT_SUCCESS:    
            let {updatedUserObj} = action.payload.response
            return { ...state, userInfo:updatedUserObj };
        case SET_VR_WHITE_LABEL_INFO:
            return { ...state, whiteLabelInfo: action.payload.whiteLabelInfo };
        case RESTRICT_ACCESS_FOR_PROSPER_USER : 
            return {...state, restrictAccessForPrsoperUser : action.payload.isAccessable}
        case SET_WHITE_LABEL_FORM_SUBMIT_MSGS:
            return { ...state, 
                whiteLabelFormErrorMsg: action.payload.errorMsg,
                whiteLabelFormSuccessMsg: action.payload.successMsg }
        case SET_BUSINESS_PLANS_LIST:{
            const { businessPlansMappingDocs=[],total=0}=action.payload.businessPlans;
            return { ...state, businessPlanList:businessPlansMappingDocs, businessPlansTotal:total };
        }
   
        case REMOVE_FORM_DATA_SUCCESS:
            return { ...state, removeDataFormSuccess: action.payload.successMessage };
        case REMOVE_FORM_DATA_FAILED:
            return { ...state, removeDataFormFailed: action.payload.errorMessage };

        default:
            return state;
    }

}

export default userAccountReducer;