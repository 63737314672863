module.exports = {
    getUserIdFrmProps:(props) => {
        // const {
        //     userProfile = {}
        // } = props;
        // const {
        //     _id = ""
        // } = userProfile;
        // return _id;
        return ""
    },
    getCompanyIdFrmProps: (props) => {
        const {
            companyProfile = {}
        } = props;
        const {
            companyId = ""
        } = companyProfile;
        return companyId;
    }
}