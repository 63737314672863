import { RESTAPI_URL } from "../common/config";
import { commonFetch } from "./fetchComImpl";
import { refreshTokenApi } from "./refreshTokenApi";

export async function getBusinessLocationApi() {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const businessPlanLocationResp = await commonFetch(`${RESTAPI_URL}/businessplanOnboard/getLocations`,
            "GET",
            {},
            new Headers({
                'Content-Type': 'application/json'
            }))
        return businessPlanLocationResp;
    } catch (error) {
        console.log("error in businessplanlocations", error);
        throw error
    }
}

export async function getSuggestedNearbyAreasApi(locationIds) {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const suggestedNearbyLocationsResp = await commonFetch(`${RESTAPI_URL}/businessplanOnboard/getSuggestedNearbyLocations`,
            "POST",
            locationIds,
            new Headers({
                'Content-Type': 'application/json'
            })
        )
        return suggestedNearbyLocationsResp
    } catch (error) {
        console.log("error in suggestedNearbyLocationsAPi", error);
        throw error
    }
}

export async function saveOrUpdateBusinessPlanOnboardApi(businessPlanObj) {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const saveOrUpdateBusinessPlanResp = await commonFetch(`${RESTAPI_URL}/businessplanOnboard/saveOrUpdateBusinessPlanOnboard`,
            "POST",
            businessPlanObj,
            new Headers({
                'Content-Type': 'application/json'
            })
        )
        return saveOrUpdateBusinessPlanResp
    } catch (error) {
        console.log("error in saveOrUpdateBusinessPlanOnboardApi", error);
        throw error
    }
}

export async function getAllOnboardBusinessPlansApi() {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const allBusinessPlanResp = await commonFetch(`${RESTAPI_URL}/businessplanOnboard/getAllOnboardBusinessPlans`,
            "GET",
            {},
            new Headers({
                'Content-Type': 'application/json'
            }))
        return allBusinessPlanResp;
    } catch (error) {
        console.log("error in allBusinessPlanApi", error);
        throw error
    }
}

export async function getBusinessPlanOnboardApi(businessPlanId) {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const businessPlanResp = await commonFetch(`${RESTAPI_URL}/businessplanOnboard/getBusinessPlanOnboard/${businessPlanId}`,
            "GET",
            {},
            new Headers({
                'Content-Type': 'application/json'
            }))
        return businessPlanResp;
    } catch (error) {
        console.log("error in allBusinessPlanApi", error);
        throw error
    }
}

export async function getActiveBusinessesAndSalesApi(industryList) {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const activeBusinessesAndSales = await commonFetch(`${RESTAPI_URL}/businessplanOnboard/getActiveBusinessAndSales`,
            "POST",
            { industryList },
            new Headers({
                "Content-Type": "application/json"
            })
        )
        return activeBusinessesAndSales
    } catch (error) {
        console.log("error in requesting active businesses and sales", error);
    }
}

export async function getAllKeyPeopleApi(companyId) {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const allKeyPeoples = await commonFetch(`${RESTAPI_URL}/businessplanOnboard/getKeypeopleById/${companyId}`,
            "GET",
            {},
            new Headers({
                "Content-Type": "application/json"
            })
        )
        return allKeyPeoples
    } catch (error) {
        console.log("error in requesting getAllKeyPeopleApi", error);
    }
}

export async function getBusinessDescriptionOpenAiApi(businessPlanConvo) {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const businessPlanDescription = await commonFetch(`${RESTAPI_URL}/businessplanOnboard/getBusinessDescriptionOpenAi`,
            "POST",
            { businessPlanConvo },
            new Headers({
                "Content-Type": "application/json"
            })
        )
        return businessPlanDescription
    } catch (error) {
        console.log("error in getBusinessDescriptionOpenAiApi", error);
    }
}

export async function updateBusinessPlansForCreditsApi(bussPlanObj) {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const businessPlanResp = await commonFetch(`${RESTAPI_URL}/businessplanOnboard/updateBusinessPlansForCredits`,
            "POST",
            { bussPlanObj },
            new Headers({
                "Content-Type": "application/json"
            })
        )
        return businessPlanResp
    } catch (error) {
        console.log("error in updateBusinessPlansForCreditsApi", error);
    }
}